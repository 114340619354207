/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [Node-Share] /src/libs/common/enum/index.ts
 * @create:      2022-12-06 14:11:23.279
 * @modify:      2022-12-21 14:12:43.854
 * @version:     1.0.1
 * @times:       4
 * @lines:       39
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

export * from "./HttpStatusCode";

export enum ApiRequestVerifyError {
    TimestampNotExist = "TimestampNotExist",
    TimestampInvalid = "TimestampInvalid",
    TimeExpired = "TimeExpired",
    SignatureNotExist = "SignatureNotExist",
    SignatureInvalid = "SignatureInvalid",
    OtherException = "OtherException",
}

export enum CloudSubscriptionPlan {
    /**
     * Non-subscribed
     */
    Free = "Free",
    Basic = "Basic",
    Standard = "Standard",
}

export enum TwoFactorVerificationMethod {
    AppAuthenticator = "AppAuthenticator",
    Email = "Email",
    TextMessage = "TextMessage"
}
