
import { IKeyValueItem } from "@/helpers";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "TestProperties",
})
export default class TestProperties extends Vue {
    @Prop()
    items?: IKeyValueItem<unknown, unknown>[];
}
