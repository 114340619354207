/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/report.ts
 * @create:      2021-05-20 14:37:36.198
 * @modify:      2021-05-20 14:50:40.371
 * @version:     0.1.1
 * @times:       2
 * @lines:       94
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import moment from "moment";
import { saveAs } from "file-saver";

export function CreateReportTable(doc: any, tableData: any, width: number) {
    const Paragraph = (window as any).Paragraph;

    const rows = tableData.length;
    const cols = tableData[0].length;

    const table = doc.createTable(rows, cols);

    table.setWidth((window as any).WidthType.PERCENTAGE, "100%");
    // table.setWidth(docx.WidthType.DXA, 5535);

    // table.Properties.setTableFloatProperties( {
    //     horizontalAnchor: docx.TableAnchorType.MARGIN,
    //     verticalAnchor: docx.TableAnchorType.MARGIN,
    //     relativeHorizontalPosition: docx.RelativeHorizontalPosition.LEFT,
    //     relativeVerticalPosition: docx.RelativeVerticalPosition.TOP,
    // }).setFixedWidthLayout();

    // To be refined
    for (const gridRow of table.grid.root) {
        gridRow.root[0].root.w = width;
    }

    for (let i = 0; i < rows; i++) {
        const row = table.getRow(i);
        for (let j = 0; j < cols; j++) {
            const cell = row.getCell(j);
            cell.addContent(new Paragraph(tableData[i][j])).properties.setVerticalAlign((window as any).VerticalAlign.CENTER);
            // cell.properties.setWidth(1000, docx.WidthType.DXA);
            cell.CellProperties.setWidth(500, (window as any).WidthType.DXA);
        }
    }

    //     .Borders.addTopBorder(BorderStyle.DASH_DOT_STROKED, 3, "red")
    //     .addBottomBorder(BorderStyle.DOUBLE, 3, "blue")
    //     .addStartBorder(BorderStyle.DOT_DOT_DASH, 3, "green")
    //     .addEndBorder(BorderStyle.DOT_DOT_DASH, 3, "#ff8000");
}

export function CreateReport(data: any) {
    console.log(data);

    // Create document
    const doc = new Document() as any;

    // // Add some content in the document
    // let paragraph = new Paragraph("Some cool text here.");
    // // Add more text into the paragraph if you wish
    // paragraph.addRun(new TextRun("Lorem Ipsum Foo Bar"));
    // doc.addParagraph(paragraph);

    const Paragraph = (window as any).Paragraph;

    doc.addParagraph(new Paragraph("Run History").heading1().center());

    doc.addParagraph(new Paragraph().heading2());

    CreateReportTable(doc, data.RunHistoryTable, 1285);

    doc.addParagraph(new Paragraph().heading2());

    doc.addParagraph(new Paragraph(`Run Log(${data.TestName})`).heading2());

    doc.addParagraph(new Paragraph().heading4());

    // #region Run Log Table
    CreateReportTable(doc, data.RunLogTable, 1500);
    // #endregion

    // Used to export the file into a .docx file
    const packer = new (window as any).Packer();

    packer.toBlob(doc).then((blob: any) => {
        console.log(blob);
        saveAs(blob, moment().format("HH-mm-ss-SSS") + ".docx");
        console.log("Document created successfully");
    });
}
