
import { AuthenticationType, platform } from "@/helpers";
import axios from "axios";
import { HttpStatusCode, ITwoFactorVerificationData, TwoFactorVerificationMethod } from "node-share";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "TwoFactorAuthenticationDialog",
})
export default class TwoFactorAuthenticationDialog extends Vue {
    @Prop({ default: false })
    visible!: boolean;

    @Prop({ default: AuthenticationType.Login })
    type!: AuthenticationType;

    @Prop({ default: false })
    loading!: boolean;

    @Prop()
    token?: string;

    code = "";
    method = TwoFactorVerificationMethod.AppAuthenticator;
    checked = false;

    isSending = false;

    get IsLogin() {
        return this.type === AuthenticationType.Login;
    }

    get Title() {
        if (this.IsLogin) {
            return this.$t("TwoFactorAuthentication");
        }

        return this.$t("ConfirmAccess");
    }

    get Data(): ITwoFactorVerificationData {
        return {
            code: this.code,
            method: this.method,
            trustDevice: this.checked,

            platform: platform,
            userAgent: navigator.userAgent,
            userAgentData: navigator.userAgentData,
        };
    }

    verify() {
        if (!this.code) {
            return;
        }

        this.$emit("verify", this.Data);
    }

    async sendEmail() {
        if (this.isSending) {
            return;
        }

        this.method = TwoFactorVerificationMethod.Email;
        this.isSending = true;

        try {
            const r = await axios.post("/api/mfa/email", {
                edm_cloud_token: this.token,
            });

            if (r.status !== HttpStatusCode.OK) {
                this.$message({
                    message: "Email send failed, please try again.",
                    type: "warning",
                });

                return;
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.isSending = false;
        }
    }

    close(changed = false) {
        this.$emit("close", changed);
    }
}
