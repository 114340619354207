
import axios from "axios";
import { Icon } from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { HttpStatusCode } from "node-share";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LControl, LMap, LMarker, LTileLayer } from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";

import "leaflet/dist/leaflet.css";

type D = Icon.Default & {
    _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const provider = new OpenStreetMapProvider();

const tileProviders = [
    {
        name: "Open Street Map",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "&copy; <a href=\"http://osm.org/copyright\" target=\"_blank\">OpenStreetMap</a>",
    },
    {
        name: "Terrain Map",
        url: "https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg",
        attribution: "&copy; <a href=\"http://openstreetmap.org\" target=\"_blank\">OpenStreetMap</a> | <a href=\"http://stamen.com\" target=\"_blank\">Stamen Design</a>",
    },
    {
        name: "ESRI World",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        attribution: "&copy; <a href=\"http://esri.com\" target=\"_blank\">ESRI</a>",
    },
];

@Component({
    name: "CloudMap",

    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControl,
        LControlFullscreen,
    },
})
export default class CloudMap extends Vue {
    @Prop()
    address?: string;

    @Prop()
    longitude?: number;

    @Prop()
    latitude?: number;

    latLng: number[] = [];

    fullscreen = false;

    get markerLatLng() {
        if (this.latLng.length === 2) {
            return this.latLng;
        }

        return undefined;
    }

    get center() {
        return this.markerLatLng;
    }

    data() {
        return {
            zoom: 15,
            latLng: [],

            tileProviders,
            tileProvider: tileProviders[0].url,
        };
    }

    async mounted() {
        await this.display();
    }

    async display() {
        // EDM Cloud/Mobile顯示EDC的位置時, 以Latitude/Longitude為準.

        // 若有Latitude/Longitude, 就依Latitude/Longitude顯示位置
        if (this.latitude !== undefined && this.longitude !== undefined) {
            this.latLng = [
                this.latitude,
                this.longitude,
            ];
        }

        // 若無Latitude/Longitude, 就依 Chamber Location輸入的地址 顯示位置 (地址可以是完整的地址, 或只有國家名稱, 或只有 省/州/城市 )
        if (this.address) {
            if (!this.markerLatLng) {
                try {
                    const r = await axios.get(`/api/v1/geocoding?address=${this.address}`);
                    if (r.status === HttpStatusCode.OK) {
                        const { latitude, longitude } = r.data.data;
                        if (latitude && longitude) {
                            this.latLng = [latitude, longitude];
                        }
                    }
                } catch (ex) {
                    console.warn(ex);
                }
            }

            if (!this.markerLatLng) {
                try {
                    const results = await provider.search({
                        query: this.address,
                        // query: "重庆市九龙坡区谢家湾街道",
                        // query: "杭州市西湖区西港发展中心",
                    });
                    console.log(results);

                    if (results.length > 0) {
                        const result = results[0];
                        this.latLng = [result.y, result.x];
                    }
                } catch (ex) {
                    console.warn(ex);
                }
            }
        }

        // 若無Latitude/Longitude, Chamber Location輸入的字符串也不是地址, 顯示Location Not Found
        if (!this.markerLatLng) {
            console.warn("Location Not Found");
        }
    }

    handleToggle() {
        this.fullscreen = !this.fullscreen;
    }

    @Watch("address")
    onAddressChanged() {
        this.latLng = [];
        this.display();
    }

    @Watch("longitude")
    onLongitudeChanged() {
        this.display();
    }

    @Watch("latitude")
    onLatitudeChanged() {
        this.display();
    }
}
