import { render, staticRenderFns } from "./EDMInformationCard.vue?vue&type=template&id=f8a1f1f4&scoped=true"
import script from "./EDMInformationCard.vue?vue&type=script&lang=ts"
export * from "./EDMInformationCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_kqs2vsdkerigpkovfnt5zzqtse/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a1f1f4",
  null
  
)

/* custom blocks */
import block0 from "./EDMInformationCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports