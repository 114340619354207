/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/components/managers/RealTimeInteractionManager.ts
 * @create:      2022-07-22 16:38:20.446
 * @modify:      2023-04-14 16:10:35.132
 * @version:     0.1.1
 * @times:       6
 * @lines:       248
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import { CloudControlCommand, CloudControlCommandMap, CloudMessageType } from "socket/protoc/generated/cloud_enums_pb";
import { CloudControlMessage, CloudDisplayOptions, CloudMessage, CloudRecordOption, CloudRecordOptionArray, CloudSignalList, DisplayFrame, DisplayFrameArray, NumericDisplayOption } from "socket/protoc/generated/cloud_messages_pb";
import { cloud_vds_node_list } from "socket/protoc/generated/cloud_vds_node_pb";
import { IsRCM, IsVDS } from "../../helpers/base/function";
import { EDMClient } from "../../helpers/base/class/EDMClient";
import store from "@/store";
import SocketManager from "./SocketManager";
import { CreateCloudMessage } from "socket/common";
import { BuildVDSFactoryTree } from "@/helpers";

// Real-Time Interaction Management
class RealTimeInteractionManager {
    public readonly Names = {
        refreshFrame: "refreshFrame",
        refreshSignalList: "refreshSignalList",
        refreshRecordStatus: "refreshRecordStatus",
        refreshVDSFactoryTree: "refreshVDSFactoryTree",
    };

    public Clients: EDMClient[] = [];

    public HandleCloudMessage(msg: CloudMessage) {
        switch (msg.getType()) {
            case CloudMessageType.GET_NEXT_DISPLAY_FRAME_OK:
                {
                    const frame = IsRCM(msg.getAppId()) ? DisplayFrameArray.deserializeBinary(msg.getData_asU8()) : DisplayFrame.deserializeBinary(msg.getData_asU8());
                    // console.log(frame);
                    // DashboardPageController.Refresh(frame);

                    const frameObj = frame.toObject() as any;
                    frameObj.recordStatus = msg.getP1() !== 0;

                    if (frame !== undefined) {
                        store.commit(this.Names.refreshFrame, {
                            name: msg.getMachineCode() + msg.getAppId() + this.Names.refreshFrame,
                            frame: frameObj,
                        });
                    }

                    setTimeout(() => {
                        this.SendDisplayNext(msg.getMachineCode(), msg.getAppId());
                    }, 1000);
                }
                break;

            case CloudMessageType.GET_VDS_FACTORY_STRUCTURE_OK:
            case CloudMessageType.VDS_NOTIFY_FACTORY_STRUCTURE_CHANGED:
                {
                    const vdsNodeList = cloud_vds_node_list.deserializeBinary(msg.getData_asU8());
                    console.log(vdsNodeList.toObject());

                    if (vdsNodeList && vdsNodeList.getNodesList().length > 0) {
                        const tree = BuildVDSFactoryTree(vdsNodeList);
                        // console.log(tree);

                        store.commit(this.Names.refreshVDSFactoryTree, {
                            name: msg.getMachineCode() + msg.getAppId() + this.Names.refreshVDSFactoryTree,
                            tree,
                        });
                    }
                }
                break;

            case CloudMessageType.GET_SIGNAL_LIST_OK:
                {
                    const signalList = CloudSignalList.deserializeBinary(msg.getData_asU8());

                    if (signalList !== undefined) {
                        store.commit(this.Names.refreshSignalList, {
                            name: msg.getMachineCode() + msg.getAppId() + this.Names.refreshSignalList,
                            signalList: signalList.toObject(),
                        });
                    }
                }
                break;

            case CloudMessageType.GET_RECORD_STATUS_OK: {
                store.commit(this.Names.refreshRecordStatus, {
                    name: msg.getMachineCode() + msg.getAppId() + this.Names.refreshRecordStatus,
                    status: msg.getP1() !== 0,
                });

                break;
            }

            case CloudMessageType.START_DISPLAY_OK:

                // DashboardPageController.SendDisplayNext();
                this.SendDisplayNext(msg.getMachineCode(), msg.getAppId());

                break;

            default:
                break;
        }
    }

    protected SendDisplayNext(mc: string, appId: number) {
        const msg = new CloudMessage();
        msg.setType(CloudMessageType.GET_NEXT_DISPLAY_FRAME);
        msg.setMachineCode(mc);
        msg.setAppId(appId);
        SocketManager.Send(msg);
    }

    public StartRefresh(client: EDMClient) {
        SocketManager.SendCloudMessage(client, CloudMessageType.START_DISPLAY);
    }

    public StopRefresh(client: EDMClient) {
        SocketManager.SendCloudMessage(client, CloudMessageType.STOP_DISPLAY);
    }

    public ExecControlCommand(client: EDMClient, cmd: CloudControlCommandMap[keyof CloudControlCommandMap],
        p1?: number, p2?: number, p3?: boolean) {
        if (IsVDS(client.AppId)) {
            switch (cmd) {
                case CloudControlCommand.CCC_RUN:
                    SocketManager.SendCloudMessage(client, CloudMessageType.CMD_VDS_RUN);
                    break;
                case CloudControlCommand.CCC_PAUSE:
                    SocketManager.SendCloudMessage(client, CloudMessageType.CMD_VDS_PAUSE_RESUME);
                    break;
                case CloudControlCommand.CCC_STOP:
                    SocketManager.SendCloudMessage(client, CloudMessageType.CMD_VDS_STOP);
                    break;
            }
        } else {
            const ccm = new CloudControlMessage();
            ccm.setCommand(cmd);

            if (p1 !== undefined) {
                ccm.setP1(p1);
            }

            if (p2 !== undefined) {
                ccm.setP2(p2);
            }

            if (p3 !== undefined) {
                ccm.setP3(p3);
            }

            SocketManager.SendCloudMessage(client, CloudMessageType.CLOUD_CONTROL_COMMAND, ccm.serializeBinary());
        }
    }

    public GetVDSStructure(client: EDMClient) {
        SocketManager.SendCloudMessage(client, CloudMessageType.GET_VDS_FACTORY_STRUCTURE);
    }

    public GetSignalList(client: EDMClient) {
        SocketManager.SendCloudMessage(client, CloudMessageType.GET_SIGNAL_LIST);
    }

    public UpdateDisplaySignals(client: EDMClient, signalIds: number[]) {
        const options = new CloudDisplayOptions();

        options.setDisplaySignalsList(signalIds);
        options.setChangeDisplaySignals(true);

        SocketManager.SendCloudMessage(client, CloudMessageType.SET_DISPLAY_OPTIONS, options.serializeBinary());
    }

    public UpdateNumericDisplay(client: EDMClient, items: any[]) {
        const options = new CloudDisplayOptions();

        const temp = [];
        for (const item of items) {
            const option = new NumericDisplayOption();
            option.setSignalId(item.signalId);
            option.setDisplaySignalType(item.type);

            temp.push(option);
        }

        options.setDisplayNumbersList(temp);
        options.setChangeDisplayNumbers(true);

        SocketManager.SendCloudMessage(client, CloudMessageType.SET_DISPLAY_OPTIONS, options.serializeBinary());
    }

    public UpdateRecordSetting(client: EDMClient, test: any, items: any[]) {
        console.log("UpdateRecordSetting");
        console.log(test);
        console.log(items);

        const options = new CloudRecordOptionArray();

        for (const item of items) {
            const option = new CloudRecordOption();

            option.setTestGuid(test.guid);
            option.setTestName(test.name);

            option.setRunGuid(test.runGuid);
            option.setRunName(test.runName);

            if (item.type !== 1) {
                option.setType(item.type.value + 10);
                option.setTypeName(item.type.name);
            } else {
                option.setType(1);
                option.setTypeName("Signal");
            }

            option.setSignalId(item.sig.id);
            option.setSignalName(item.sig.name);

            options.addOptions(option);
        }

        SocketManager.SendCloudMessage(client, CloudMessageType.SET_RECORD_OPTIONS, options.serializeBinary());
    }

    public GetRecordStatus(client: EDMClient) {
        SocketManager.SendCloudMessage(client, CloudMessageType.GET_RECORD_STATUS);
    }

    public GetVDSNextDisplayFrame(client: EDMClient, entryId: string) {
        const msg = CreateCloudMessage(
            CloudMessageType.GET_VDS_NEXT_DISPLAY_FRAME,
            client.MachineCode,
            client.AppId,
            undefined,
        );

        msg.setP3(entryId);

        SocketManager.Send(msg);
    }
}

export default new RealTimeInteractionManager();
