<template>
    <div class="main-container-content" v-loading="loading">
        <div class="ci-card">
            <div class="header">{{ $t("TeamGroups") }}</div>
            <div class="body">
                <el-alert
                    :title="$t('TeamGroupsTip')"
                    type="success"
                    class="black-bold"
                ></el-alert>
                <p style="margin-top: 15px">{{ $t("SearchGroups") }}</p>
                <div
                    class="display-flex"
                    style="align-items: baseline; margin-top: 0.3em"
                >
                    <el-input
                        v-model="searchInput"
                        :placeholder="$t('GroupName')"
                    ></el-input>
                    <button
                        class="ci-btn"
                        style="flex: none; margin-left: 2ex"
                        @click="createGroup"
                    >
                        {{ $t("CreateGroup") }}
                    </button>
                </div>

                <el-table :data="Groups" style="width: 100%; margin-top: 1em">
                    <template slot="empty">
                        <span>{{ $t("NoData") }}</span>
                    </template>
                    <el-table-column :label="$t('Name')" sortable>
                        <template slot-scope="scope">
                            <router-link :to="scope.row.link">{{
                                scope.row.name
                            }}</router-link>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('Members')"
                        prop="members"
                        sortable
                    ></el-table-column>
                    <el-table-column
                        :label="$t('Managers')"
                        prop="managers"
                        sortable
                    ></el-table-column>
                    <!-- <el-table-column label>
                        <template slot-scope="scope">
                            <el-dropdown v-if="IsTeamAdmin">
                                <span class="el-dropdown-link">
                                    <i class="el-icon-setting member-gear"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-for="menu in getGearMenus(scope.row)"
                                        :key="scope.row.user + menu.command"
                                        @click.native="handleEdit(scope.$index, scope.row, menu.command)"
                                    >{{ menu.label }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>-->
                </el-table>
            </div>
        </div>

        <!-- Group Creation Form -->
        <el-dialog
            custom-class="create-team-dialog"
            :visible.sync="groupCreationFormVisible"
            :close-on-click-modal="false"
        >
            <!-- Create Group Page -->
            <div class="ci-card">
                <div class="header">
                    <span>{{ $t("GroupCreationForm") }}</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                </div>
                <div class="body">
                    <div>
                        <div class="display-flex align-items-center">
                            <h4 class="m-r-1-em">{{ $t("GroupName") }}*</h4>
                            <el-input
                                v-model="formGroup.name"
                                class="flex-auto width-auto"
                            ></el-input>
                        </div>
                        <div
                            class="display-flex flex-direction-column m-t-1-em"
                        >
                            <div>
                                <h4>{{ $t("InviteGroupMembers") }}</h4>
                                <p>
                                    {{
                                        $t(
                                            "EnterTheEmailAddressesOfYourTeamMembersSeparatedByCommas"
                                        )
                                    }}
                                </p>
                                <p>
                                    {{
                                        $t(
                                            "InvitesWillBeSentToTheirEmailAddresses"
                                        )
                                    }}
                                </p>
                            </div>
                            <el-input
                                type="textarea"
                                v-model="formGroup.invites"
                                placeholder="alice@acme.com, bob@acme.com, carl@acme.com"
                                style="padding-top: 0.5em"
                            ></el-input>
                        </div>
                        <div class="m-t-1-em">
                            <span
                                class="ci-color-success"
                                v-if="createGroupSucceed"
                                >Success: team created</span
                            >
                            <span
                                class="ci-color-danger"
                                v-else-if="createGroupError"
                            >
                                {{ createGroupError }}
                            </span>
                        </div>
                    </div>
                    <div class="flex-content-end m-t-1-em">
                        <button
                            class="ci-btn"
                            @click="groupCreationFormVisible = false"
                            style="background-color: grey"
                        >
                            {{ $t("Cancel") }}
                        </button>
                        <button class="ci-btn" @click="submitCreateGroup">
                            {{ $t("Submit") }}
                        </button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import store from "@/store";

@Component({
    name: "TeamGroups",
})
export default class TeamGroups extends Vue {
    loading = false;
    searchInput = "";
    groups = [];
    groupCreationFormVisible = false;

    formGroup = {
        name: "",
        invites: "",
    };

    createGroupError = "";
    createGroupSucceed = false;

    created() {
        store.commit("updateBreadcrumb", ["sidebar.team.groups"]);

        this.fetchGroups();
    }

    get Groups() {
        return this.groups.map(p => {
            return {
                name: p.name,
                members: p.members.length,
                managers: p.members.filter(m => m.status !== "Member").map(m => m.name).join(", "),
                link: `/team/groups/${p.uuid}`,
            };
        }).filter(p => p.name.toLowerCase().includes(this.searchInput.toLowerCase()));
    }

    async fetchGroups() {
        const r = await axios.get("/api/v1/groups");
        // console.log(r);

        if (r.status === HttpStatusCode.OK) {
            this.groups = r.data.data;
        }
    }

    createGroup() {
        this.groupCreationFormVisible = true;
    }

    async submitCreateGroup() {
        if (!this.formGroup.name) {
            this.$message({
                message: "Please input the group name.",
                type: "warning",
            });

            return;
        }

        const r = await axios.post("/api/v1/groups", this.formGroup);

        if (r.status === HttpStatusCode.Created) {
            await this.fetchGroups();

            this.groupCreationFormVisible = false;
        } else {
            this.$message({
                message: r.data.msg,
                type: "error",
            });
        }
    }
}
</script>

<style lang="scss">
.member-gear {
    font-size: x-large;
    cursor: pointer;
    &:hover {
        font-weight: bolder;
    }
}
</style>
