
import { getCurrentBreadcrumb } from "@/helpers";
import store from "@/store";
import { Vue, Component } from "vue-property-decorator";
// import sidebar from "@/components/partials/Sidebar";
// import Background from '@/components/Background'

@Component({
    name: "Header",
    components: {
        // Background,
        // sidebar,
    },
})
export default class Header extends Vue {
    data() {
        return {

        };
    }

    profile() {
        this.$router.push("/profile");
    }

    changeLanguage(val: string) {
        store.dispatch("changeLanguage", val);
        this.ChangeLanguage(val);
    }

    goTo(path: string) {
        if (this.$router.currentRoute.fullPath !== path) {
            this.$router.push(path);
        }
    }

    handleUserCommand(command: string) {
        switch (command) {
            case "profile":
                this.profile();
                return;

            case "myAccount":
                this.goTo("/settings");
                return;

            case "about":
                this.goTo("/settings?about");
                return;

            case "team":
                this.goTo("/team/members");
                return;

            case "logout":
                this.Logout();
                return;

            default:
                console.warn(`unhandled command: ${command}`);
        }
    }

    getActiveMenuClass(menu: string) {
        let active = false;
        if (this.currentItem === "sidebar.dashboard") {
            if (menu === "dashboard") {
                active = true;
            }
        } else if (this.currentItem === "sidebar.view_tests") {
            if (menu === "view_tests") {
                active = true;
            }
        } else if (this.currentItem === "sidebar.uploaded_files") {
            if (menu === "uploaded_files") {
                active = true;
            }
        } else if (this.currentItem === "sidebar.system_information") {
            if (menu === "system_information") {
                active = true;
            }
        } else if (this.currentItem === "sidebar.settings") {
            if (menu === "settings") {
                active = true;
            }
        } else if (this.currentItem === "sidebar.team.members") {
            if (menu === "members") {
                active = true;
            }
        } else if (this.currentItem === "sidebar.team.groups") {
            if (menu === "groups") {
                active = true;
            }
        } else if (this.currentItem === "sidebar.team.settings") {
            if (menu === "settings") {
                active = true;
            }
        } else {
            // console.warn(this.currentItem);
        }

        const list = [
            "vertical-align-middle",
            // "title-bold",
        ];

        if (active) {
            list.push("active-menu");
        }

        return list.join(" ");
    }

    get isTeam() {
        return this.currentItem.startsWith("sidebar.team");
    }

    get isNotTeam() {
        return !this.isTeam;
    }

    get currentItem() {
        return getCurrentBreadcrumb(store.state.breadcrumb);
    }
}

