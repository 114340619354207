/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/plugins/element.js
 * @create:      2020-06-01 10:13:38.131
 * @modify:      2022-07-06 10:44:57.012
 * @version:     0.1.1
 * @times:       12
 * @lines:       24
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import Vue from "vue";
import Element from "element-ui";
import "./element/element-variables.scss";
import locale from "element-ui/lib/locale/lang/en";

import i18n from "./i18n";

locale.i18n = (key, value) => i18n.t(key, value);

Vue.use(Element, { locale });
