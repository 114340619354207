
import { Vue, Component, Prop } from "vue-property-decorator";
import { DisplayFormatX, DisplayFormatY, IChartOptions } from "@/helpers";
import moment from "moment";

@Component({
    name: "ChartOptions",
})
export default class ChartOptions extends Vue {
    @Prop()
    option!: IChartOptions;

    @Prop()
    isFrequency?: boolean;

    XOptions = [{
        value: DisplayFormatX.Linear,
        label: "Linear",
    }, {
        value: DisplayFormatX.Log,
        label: "Log",
    }];

    YOptions = [{
        value: DisplayFormatY.Mag,
        label: "Linear Mag",
    }, {
        value: DisplayFormatY.LogMag,
        label: "Log Mag",
    }];

    mounted() {
        if (this.option.axisMinMax) {
            this.option.axisMinMax.xFixed = true;
            this.option.axisMinMax.yFixed = true;
        }
    }

    get XMin() {
        return this.option.axisMinMax?.xMin;
    }

    get XMax() {
        return this.option.axisMinMax?.xMax;
    }

    get XStep() {
        if (this.XMax !== undefined && this.XMin !== undefined) {
            const step = (this.XMax - this.XMin) / 100;
            return step;
        }
    }

    get XPrecision() {
        return this.option.xPrecision ?? 2;
    }

    get YMin() {
        return this.option.axisMinMax?.yMin;
    }

    get YMax() {
        return this.option.axisMinMax?.yMax;
    }

    get YStep() {
        if (this.YMax !== undefined && this.YMin !== undefined) {
            const step = (this.YMax - this.YMin) / 100;
            return step;
        }
    }

    get YPrecision() {
        let precision = this.option.yPrecision ?? 4;

        const step = this.YStep;

        if (step === undefined) {
            return precision;
        }

        // The value of precision must be a non negative integer and should not be less than the decimal places of step.
        if (this.option.yMin !== undefined && this.option.yMin < 1) {
            const s = this.option.yMin.toString();
            const items = s.split("e-");
            if (items.length === 2) {
                const c = Number(items[1]) + 1;
                if (precision < c) {
                    precision = c;
                }
            }
        }

        return precision;
    }

    get CanShowNumberBox() {
        return !this.option.isXAxisDate;
    }

    get CanChangeAxisType() {
        return !this.option.isXAxisTime;
    }

    get MinTime() {
        const minValue = this.option.xMin;
        const date = moment.utc(minValue).format("YYYY-MM-DD HH:mm:ss");
        console.log("min:" + date);
        return date;
    }

    set MinTime(time: string) {
        const maxValue = this.option.xMax;
        const date1 = moment.utc(time).toDate().getTime();
        if (date1 < (maxValue as number)) {
            this.option.xMin = date1;
        }
    }

    get MaxTime() {
        const maxValue = this.option.xMax;
        const date = moment.utc(maxValue).format("YYYY-MM-DD HH:mm:ss");
        return date;
    }

    set MaxTime(time: string) {
        const date1 = moment.utc(time).toDate().getTime();
        const minValue = this.option.xMin;
        if (date1 > (minValue as number)) {
            this.option.xMax = date1;
        }
    }

    // get pickerOptions() {
    //     return {
    //         disabledDate: (time: Date) => {
    //             const t = time.getTime();

    //             if (this.option.xMax) {
    //                 if (t > this.option.xMax) {
    //                     return true;
    //                 }
    //             }

    //             if (this.option.xMin) {
    //                 if (t < this.option.xMin) {
    //                     return true;
    //                 }
    //             }

    //             return false;
    //         },
    //     };
    // }

    ChangeYAxis() {
        if (this.option.yMin !== undefined && this.option.yMax !== undefined) {
            if (this.option.yMin > this.option.yMax) {
                const temp = this.option.yMin;
                this.option.yMin = this.option.yMax;
                this.option.yMax = temp;
            }
        }
    }

    ChangeXAxis() {
        if (this.option.xMin !== undefined && this.option.xMax !== undefined) {
            if (this.option.xMin > this.option.xMax) {
                const temp = this.option.xMin;
                this.option.xMin = this.option.xMax;
                this.option.xMax = temp;
            }
        }
    }

    searchChange() {
        return new Date().getTime();
    }
}
