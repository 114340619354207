
import { Vue, Component, Prop } from "vue-property-decorator";
import axios from "axios";

import { AddToRefreshList } from "@/helpers";
import { CloudDataType, SoftwareMode } from "socket/common";

interface IModule {
    SN: string;
}

interface ISystem {
    Modules?: IModule[];
}

interface IOS {
    MachineName?: string;
    Is64BitOperatingSystem?: boolean;
    Description?: string;
    Version?: string;
}

interface ITest {
    Name?: string;
    SystemGUID?: string;
    OSIdentifier?: string;
    SoftwareMode?: number;
    Status?: number;
    PCName?: string;
}

@Component({
    name: "TestCard",
})
export default class TestCard extends Vue {
    @Prop()
    TestGUID!: string;

    test: ITest = {};
    system: ISystem = {};
    os: IOS = {};

    page = CloudDataType.RunHistories | CloudDataType.TestProperties;

    mounted() {
        if (!this.test.Name) {
            this.getTestData();
        }

        AddToRefreshList(this, this.getTestData);
    }

    resetTest() {
        this.test = {};
        this.resetSystem();
        this.resetOS();
    }

    resetSystem() {
        this.system = {};
    }

    resetOS() {
        this.os = {};
    }

    async getTestData() {
        try {
            const r = await axios.get(
                `/api/v1/tests?SharedTo&GUID=${this.TestGUID}`,
            );

            this.test = r.data.data[0];
            // this.$message.success(r.data.msg);

            if (!this.test) {
                this.resetTest();
                return;
            }

            this.getSystemData();
            this.getOSData();
        } catch (err) {
            // reset
            this.resetTest();

            // this.$message.error(err.r.data);
            console.error(err);
        }
    }

    getSystemData() {
        axios
            .get(`/api/v1/systems?GUID=${this.test.SystemGUID}`)
            .then((r) => {
                this.system = r.data.data[0];

                if (!this.system) {
                    this.resetSystem();
                }
            })
            .catch((ex) => {
                // reset
                this.resetSystem();

                console.error(ex);
            });
    }

    getOSData() {
        axios
            .get(`/api/v1/oss?UniqueIdentifier=${this.test.OSIdentifier}`)
            .then((r) => {
                this.os = r.data.data[0];

                if (!this.os) {
                    this.resetOS();
                }
            })
            .catch((ex) => {
                // reset
                this.resetOS();

                console.error(ex);
            });
    }

    get IsEDC() {
        if (this.test) {
            return this.test.SoftwareMode === SoftwareMode.EDC;
        }

        return false;
    }

    get StatusClass() {
        return `text-align-left test-status-${this.test.Status}`;
    }

    get ModulesString() {
        if (this.system.Modules && this.system.Modules.length > 0) {
            return this.system.Modules.map((p) => p.SN).join(", ");
        }

        return "";
    }

    get OSINfo() {
        return this.os.MachineName
            ? [
                this.os.MachineName,
                this.os.Is64BitOperatingSystem ? "X64" : "X86",
                this.os.Description,
                "v" + this.os.Version,
            ].join(" | ")
            : this.test.PCName;
    }
}
