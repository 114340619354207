/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/data.ts
 * @create:      2019-10-23 15:30:47.823
 * @modify:      2023-04-14 16:10:35.130
 * @version:     0.1.1
 * @times:       23
 * @lines:       135
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import { ConvertTo } from "node-share";
import { CloudSignal } from "protoc/cloud_messages_pb";
import { cloud_vds_node_list } from "protoc/cloud_vds_node_pb";
import { GetSignalData, IsTimeStreamSignal } from "./chart";

interface INode {
    name: string;
    children?: INode[];
    node: any;
}

export function BuildVDSFactoryTree(list: cloud_vds_node_list): any {
    if (list === undefined || list.getNodesList().length === 0) {
        return undefined;
    }

    const result: INode[] = [];

    const nodes = list.getNodesList();

    // SCNType 就当做枚举，Factory=0, Space=1, Machine=2, Point=3 ,Entry=4, EntryDetail=5
    const factories = nodes.filter(p => p.getScnType() === 0);

    const GetChildren = (guid: string) => {
        if (guid === undefined || guid === "") {
            return undefined;
        }

        const children: INode[] = [];

        for (const node of nodes) {
            if (node.getScnType() === 5) {
                // ignore EntryDetail
                continue;
            }

            if (node.getScnPGuid() === guid) {
                children.push({
                    name: node.getScnName(),
                    children: GetChildren(node.getScnGuid()),
                    node,
                });
            }
        }

        return children;
    };

    for (const f of factories) {
        result.push({
            name: f.getScnName(),
            children: GetChildren(f.getScnGuid()),
            node: f,
        });
    }

    return result;
}

function getSignalData(sig: CloudSignal.AsObject) {
    if (sig && sig.dataList) {
        return GetSignalData(
            sig.dataList.map(p => p.arrayList),
            ConvertTo(sig),
        );
    }

    return [];
}

export function PrepareVDSDisplayData(signals: CloudSignal.AsObject[]) {
    try {
        if (signals.length === 0) {
            return undefined;
        }

        const sig = signals[0];

        const series = signals.map(p => {
            return {
                name: p.name,
                type: "line",
                showSymbol: false,
                // hoverAnimation: false,
                emphasis: {
                    scale: false,
                },
                smooth: true,
                data: getSignalData(p),
            };
        });

        const names = signals.map(p => p.name);
        // const title = names.join(",");
        // console.log(title);

        const newOptions = {
            // title: {
            //     text: `(${title})`
            // },
            legend: {
                data: names,
            },
            xAxis: {
                name: sig.xUnit,
                nameLocation: "end",
                scale: IsTimeStreamSignal(ConvertTo(sig)),
            },
            yAxis: {
                name: sig.yUnit,
            },
            series,
        };

        return newOptions;
    } catch (e) {
        console.error(e);
    }

    return undefined;
}
