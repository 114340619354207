/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/base/constant.ts
 * @create:      2019-11-14 16:18:35.856
 * @modify:      2023-03-17 13:49:26.768
 * @version:     0.1.1
 * @times:       106
 * @lines:       73
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const platform = require("platform");

export const sqrt10 = Math.sqrt(10);

export const ConstNames = {
    Token: "edm_cloud_token",
    Language: "edm_cloud_language",
    LanguageChinese: "zh-CHS",
    LanguageEnglish: "en-US",
    DateFormat: "edm_cloud_date_format",
    OEM: "edm_cloud_oem",
    CurrentTest: "edm_cloud_current_test",
    OpenToken: "open_token",

    PlotSetting: "edm_cloud_plot_setting",
};

export const SocketMessageType = {
    GET_CLIENT_INFO: "GET_CLIENT_INFO",
    UPDATE_CLIENT_INFO: "UPDATE_CLIENT_INFO",
    NOTIFY_CLIENT_REFRESH: "NOTIFY_CLIENT_REFRESH",
};

export const CommonConstant = {
    Ascii_Square: "\xB2",

    WellKnownFrequency: "Frequency",
    WellKnownTime: "Time",
    WellKnownTemperature: "Temperature",
    WellKnownHumidity: "Humidity",
};

export const UnitsTemperature = [
    "K",
    "°C",
    "°F",
    "°R",
];

export const UnitsHumidity = [
    "% RH",
];

export const SignalNames = {
    HighAbort: "HighAbort(f)",
    HighAlarm: "HighAlarm(f)",
    Profile: "profile(f)",
    LowAbort: "LowAbort(f)",
    LowAlarm: "LowAlarm(f)",
    // Control: "control(f)",
};

export const UserSettingKey = {
    SignalViewerLayout: "SignalViewer_Layout",
    SignalViewerPlots: "SignalViewer_Plots",
};

export const ChartEvent = "chart-event";
