
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";

interface IModule {
    SN: string;
}

interface ISystem {
    Modules?: IModule[];
}

@Component({
    name: "SystemCard",
})
export default class SystemCard extends Vue {
    @Prop()
    SystemGUID?: string;

    @Prop()
    systems?: ISystem[];

    systemList: ISystem[] = [];

    get Systems() {
        if (this.systems) {
            return this.systems.filter(p => p);
        }

        return [];
    }

    @Watch("systems")
    onSystemsChaned() {
        console.log("changed");
    }

    async mounted() {
        if (this.systems && this.systems.length > 0) {
            this.systemList = this.systems;
        } else {
            await this.getSystemData();
        }

        // console.log(this.systemList);
    }

    async getSystemData() {
        const r = await axios.get(`/api/v1/systems?GUID=${this.SystemGUID}`);
        this.systemList = [r.data.data[0]];
    }

    getModulesString(system: ISystem) {
        if (system) {
            if (system.Modules && system.Modules.length > 0) {
                return system.Modules.map((p) => p.SN).join(", ");
            }
        }

        return "";
    }
}
