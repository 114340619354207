
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { CheckLicenseSN, IKeyValueItem, ILicenseInfo, ILicenseOptionDetails } from "@/helpers";

@Component({
    name: "LicenseInformationCard",
})
export default class LicenseInformationCard extends Vue {
    @Prop()
    SN!: string;

    licenses: ILicenseInfo[] = [];
    loading = true;

    @Watch("SN")
    onChanged() {
        this.fetchData();
    }

    mounted() {
        this.fetchData();
    }

    async fetchData() {
        this.loading = true;

        try {
            const res = await axios.get("/api/v1/licenses");
            const licenses: ILicenseInfo[] = res.data.data;

            // console.log(licenses);

            this.licenses = licenses.filter((p) => CheckLicenseSN(p, this.SN));
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }

    needShowHardwareCapable(data: ILicenseOptionDetails[]) {
        const isShow = [...data].reverse().find(p => p.HardwareCapable && p.HardwareCapable.length > 0);
        return isShow !== undefined;
    }

    getLicenseProperties(lk: ILicenseInfo) {
        const result: IKeyValueItem[] = [
            {
                key: this.$t("license.hardware_device").toString(),
                value: lk.TypesString,
            },
            {
                key: this.$t("license.serial_number").toString(),
                value: lk.SerialNumbersString,
            },
            {
                key: this.$t("license.license_type").toString(),
                value: lk.LicenseType,
            },
            {
                key: this.$t("license.software_renewal_expiration").toString(),
                value: `${this.FormatDateTime(lk.RenewalExpiration).replace(" 00:00:00", "")} (${this.$t("license.software_renewal_expiration_note")})`,
            },
            {
                key: this.$t("license.software_activation_expiration").toString(),
                value: `${this.FormatDateTime(lk.ActivationExpiration).replace(" 00:00:00", "")} (${this.$t("license.software_activation_expiration_note")})`,
            },
        ];

        if (lk.VSN && lk.VSN.IncludeVSN) {
            result.push({
                key: this.$t("license.vsn").toString(),
                value: lk.VSN.VirtualSerialNumber,
            }, {
                key: this.$t("license.vsn_activated").toString(),
                value: (lk.VSN.IsVSNEnable ? this.$t("yes") : this.$t("no")).toString(),
            }, {
                key: this.$t("license.total_license_count").toString(),
                value: lk.VSN.TotalLicenseCount.toString(),
            }, {
                key: this.$t("license.available_license_count").toString(),
                value: lk.VSN.AvailableLicenseCount.toString(),
            });
        }

        return result;
    }
}
