/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [Node-Share] /src/libs/common/const.ts
 * @create:      2022-12-06 14:17:52.882
 * @modify:      2022-12-06 15:50:11.721
 * @version:     1.0.1
 * @times:       2
 * @lines:       21
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

/* cSpell:disable */
export const LowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
export const UppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
/* cSpell:enable */
export const Numbers = "0123456789";

export const OneDayInMilliseconds = 1 * 24 * 60 * 60 * 1000;
