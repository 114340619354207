
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { InviteTeamMember, ITeamMember } from "@/helpers";
import store from "@/store";

enum GEAR_COMMAND {
    RESET_PASSWORD = 1,
    REMOVE_ADMIN_PERMISSIONS,
    DELETE_MEMBER,
    ADD_ADMIN_PERMISSIONS,
    RESEND_INVITE,
    UNDO_INVITE,
    RE_ACTIVATE_MEMBER,
    ACCEPT,
    REJECT,
}

@Component({
    name: "TeamMembers",
})
export default class TeamMembers extends Vue {
    loading = false;
    searchInput = "";
    showDeletedUsers = false;

    inviteMembersDialogVisible = false;
    inviteMembers = "";

    created() {
        store.commit("updateBreadcrumb", ["sidebar.team.members"]);

        store.dispatch("refreshTeamInfo");
    }

    get team() {
        return store.getters.Team;
    }

    get UserId() {
        return store.getters.UserId;
    }

    get tableData() {
        let result: ITeamMember[] = this.team.members;

        if (!result) {
            return [];
        }

        result = result.filter(p => p.status !== "Rejected");

        if (this.searchInput) {
            result = result.filter(p =>
                (p.name && p.name.toLowerCase().includes(this.searchInput.toLowerCase())) ||
                (p.email && p.email.toLowerCase().includes(this.searchInput.toLowerCase())));
        }

        return this.showDeletedUsers ? result : result.filter(p => p.status !== "Deleted");
    }

    get IsTeamAdmin() {
        return store.getters.IsTeamAdmin;
    }

    get IsTeamCreator() {
        return store.getters.IsTeamCreator;
    }

    getCommandLabel(command: GEAR_COMMAND) {
        switch (command) {
            case GEAR_COMMAND.RESET_PASSWORD: return this.$t("ResetPassword");
            case GEAR_COMMAND.REMOVE_ADMIN_PERMISSIONS: return this.$t("RemoveAdminPermissions");
            case GEAR_COMMAND.DELETE_MEMBER: return this.$t("DeleteMember");
            case GEAR_COMMAND.ADD_ADMIN_PERMISSIONS: return this.$t("AddAdminPermissions");
            case GEAR_COMMAND.RESEND_INVITE: return this.$t("ReSendInvite");
            case GEAR_COMMAND.UNDO_INVITE: return this.$t("UndoInvite");
            case GEAR_COMMAND.RE_ACTIVATE_MEMBER: return this.$t("ReActivateMember");
            case GEAR_COMMAND.ACCEPT: return this.$t("Accept");
            case GEAR_COMMAND.REJECT: return this.$t("Reject");
        }

        throw new Error(`Unhandled command: ${command}`);
    }

    getCommandMessage(command: GEAR_COMMAND) {
        switch (command) {
            case GEAR_COMMAND.RESET_PASSWORD: return "Success: Reset Password";
            case GEAR_COMMAND.REMOVE_ADMIN_PERMISSIONS: return "Success: Removed Admin Permissions";
            case GEAR_COMMAND.DELETE_MEMBER: return "Success: Deleted Member";
            case GEAR_COMMAND.ADD_ADMIN_PERMISSIONS: return "Success: Added Admin Permissions";
            case GEAR_COMMAND.RESEND_INVITE: return "Success: Re-Send Invite";
            case GEAR_COMMAND.UNDO_INVITE: return "Success: Undo Invite";
            case GEAR_COMMAND.RE_ACTIVATE_MEMBER: return "Success: Re-Activated Member";
            case GEAR_COMMAND.ACCEPT: return "Success: Accept";
            case GEAR_COMMAND.REJECT: return "Success: Reject";
        }

        throw new Error(`Unhandled command: ${command}`);
    }

    getGearMenus(member: ITeamMember) {
        const status = member.status;
        const isCreator = this.team.creator === member.user;
        const isOwner = this.UserId === member.user;
        const commands = [];

        switch (status) {
            case "Admin":

                commands.push(GEAR_COMMAND.RESET_PASSWORD);

                if (!isCreator && !isOwner) {
                    // Cannot delete or remove admin permissions for your own account
                    commands.push(GEAR_COMMAND.REMOVE_ADMIN_PERMISSIONS, GEAR_COMMAND.DELETE_MEMBER);
                }
                break;

            case "Member":
                commands.push(
                    GEAR_COMMAND.RESET_PASSWORD,
                    GEAR_COMMAND.ADD_ADMIN_PERMISSIONS,
                    GEAR_COMMAND.DELETE_MEMBER,
                );
                break;

            case "Invited":
                commands.push(
                    GEAR_COMMAND.RESEND_INVITE,
                    GEAR_COMMAND.UNDO_INVITE,
                );
                break;

            case "Deleted":
                commands.push(GEAR_COMMAND.RE_ACTIVATE_MEMBER);
                break;

            case "Requested":
                commands.push(GEAR_COMMAND.ACCEPT, GEAR_COMMAND.REJECT);
                break;
        }

        const menus = commands.map(p => {
            return {
                label: this.getCommandLabel(p),
                command: p,
            };
        });

        return menus;
    }

    async invite() {
        try {
            this.inviteMembersDialogVisible = false;
            this.loading = true;

            const results = await Promise.all(this.inviteMembers.split(",").map(p => InviteTeamMember(this.team._id, p)));
            if (results.every(p => p === true)) {
                this.$message({
                    message: "Success: invited members",
                    type: "success",
                });
            } else {
                const messageList = results.filter(p => p !== true) as string[];
                for (const message of messageList) {
                    this.$message({
                        message: message,
                        type: "error",
                    });

                    await this.$nextTick();
                }
            }

            // refresh data
            store.dispatch("refreshUserInfo");
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }

    async handleEdit(index: number, row: ITeamMember, command: GEAR_COMMAND) {
        try {
            this.loading = true;
            // console.log(index, row, command);

            const message = this.getCommandMessage(command);
            let errorMessage: string | false = false;

            if (command === GEAR_COMMAND.RESEND_INVITE) {
                // “Resend invite” will send the invite email again

                const r = await axios.post("/api/v1/teams/email", {
                    team: this.team._id,
                    member: row.user,
                });

                if (r.status === HttpStatusCode.OK) {
                    this.$message({
                        message,
                        type: "success",
                    });
                } else {
                    console.warn(r);

                    errorMessage = r.data.msg;
                }
            } else if (command === GEAR_COMMAND.UNDO_INVITE) {
                const r = await axios.post("/api/v1/teams/undo", {
                    team: this.team._id,
                    member: row.user,
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh data
                    store.dispatch("refreshUserInfo");

                    this.$message({
                        message,
                        type: "success",
                    });
                } else {
                    console.warn(r);

                    errorMessage = r.data.msg;
                }
            } else if (command === GEAR_COMMAND.RE_ACTIVATE_MEMBER) {
                const r = await axios.post("/api/v1/teams/re-activate", {
                    team: this.team._id,
                    member: row.user,
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh data
                    store.dispatch("refreshUserInfo");

                    this.$message({
                        message,
                        type: "success",
                    });
                } else {
                    console.warn(r);

                    errorMessage = r.data.msg;
                }
            } else if (command === GEAR_COMMAND.DELETE_MEMBER) {
                const r = await axios.delete("/api/v1/teams/invitation", {
                    data: {
                        team: this.team._id,
                        member: row.user,
                    },
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh data
                    store.dispatch("refreshUserInfo");

                    this.$message({
                        message,
                        type: "success",
                    });
                } else {
                    console.warn(r);

                    errorMessage = r.data.msg;
                }
            } else if (command === GEAR_COMMAND.ADD_ADMIN_PERMISSIONS) {
                const r = await axios.post("/api/v1/teams/permissions", {
                    team: this.team._id,
                    member: row.user,
                    addAdminPermissions: true,
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh data
                    store.dispatch("refreshUserInfo");

                    this.$message({
                        message,
                        type: "success",
                    });
                } else {
                    console.warn(r);

                    errorMessage = r.data.msg;
                }
            } else if (command === GEAR_COMMAND.REMOVE_ADMIN_PERMISSIONS) {
                const r = await axios.post("/api/v1/teams/permissions", {
                    team: this.team._id,
                    member: row.user,
                    addAdminPermissions: false,
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh data
                    store.dispatch("refreshUserInfo");

                    this.$message({
                        message,
                        type: "success",
                    });
                } else {
                    console.warn(r);

                    errorMessage = r.data.msg;
                }
            } else if (command === GEAR_COMMAND.RESET_PASSWORD) {
                // send reset password to member's email
                const r = await axios.post("/api/v1/users/forgot", {
                    email: row.email,
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh data
                    store.dispatch("refreshUserInfo");

                    this.$message({
                        message,
                        type: "success",
                    });
                } else {
                    console.warn(r);

                    errorMessage = r.data.msg;
                }
            } else if (command === GEAR_COMMAND.ACCEPT || command === GEAR_COMMAND.REJECT) {
                const accept = command === GEAR_COMMAND.ACCEPT;

                const r = await axios.put("/api/v1/teams/request", {
                    user: row.user,
                    accept,
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh data
                    store.dispatch("refreshUserInfo");

                    this.$message({
                        message,
                        type: "success",
                    });
                } else {
                    console.warn(r);

                    errorMessage = r.data.msg;
                }
            }

            if (errorMessage !== false) {
                if (errorMessage) {
                    this.$message({
                        message: errorMessage,
                        type: "error",
                    });
                }

                store.dispatch("refreshUserInfo");
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }
}
