/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/main.ts
 * @create:      2019-01-08 15:18:15.147
 * @modify:      2023-04-14 15:59:48.494
 * @version:     0.1.1
 * @times:       358
 * @lines:       479
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import SocketManager from "@/components/managers/SocketManager";
import axios from "axios";
import { BarChart, LineChart, ScatterChart } from "echarts/charts";
import { DataZoomComponent, GraphicComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent } from "echarts/components";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { Message, MessageBox } from "element-ui";
import moment from "moment";
import { CutString, FormatString, HttpStatusCode, UpdateQueryString } from "node-share";
import { BROWSER_PLATFORM_DESCRIPTION, BROWSER_PLATFORM_NAME, BROWSER_PLATFORM_OS, BROWSER_PLATFORM_VERSION, CloudCode, CloudDataType, HEADER_API_ACCESS_TOKEN, HEADER_SOFTWARE_LANGUAGE, ILanguageString, Language, ServerChangeType } from "socket/common";
import { CloudMessageType } from "socket/protoc/generated/cloud_enums_pb";
import { GenericMessage } from "socket/protoc/generated/cloud_messages_pb";
import Vue from "vue";
import { Route } from "vue-router";
import { Dictionary, RawLocation } from "vue-router/types/router";
import App from "./App.vue";
import { ConstNames, FormatDateTime, FormatTimeDuration, GetSpace, GetTestStatus, RouteName, TimeFromNow, eventHub, platform } from "./helpers";
import "./plugins/element.js";
import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store";
import RealTimeInteractionManager from "./components/managers/RealTimeInteractionManager";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    ScatterChart,
    GridComponent,
    TooltipComponent,
    DataZoomComponent,
    TitleComponent,
    LegendComponent,
    ToolboxComponent,
    GraphicComponent,
]);

Vue.config.productionTip = false;

function getLanguageString(language: string, value: ILanguageString | undefined, fallback = ""): string {
    try {
        if (!value) {
            return fallback;
        }

        for (const l of value.Languages) {
            if (l.Key === language) {
                return l.Value;
            }

            if (language === ConstNames.LanguageChinese && l.Key === "zh-CN") {
                return l.Value;
            }
        }

        // console.warn("no matched, return the first value");

        if (value.Languages.length > 0) {
            return value.Languages[0].Value;
        }
    } catch (ex) {
        console.error(ex);
    }

    return fallback;
}

async function logout() {
    try {
        await axios.get("/api/logout");
    } catch (ex) {
        console.error(ex);
    }

    await store.dispatch("logout");
}

function needLogin(to: Route) {
    if ([
        "/",
        "/account/signup",
        "/account/forgot",
        "/app/products",
        "/app/shakers",
    ].includes(to.path)) {
        return false;
    }

    if (to.name) {
        const nameList: string[] = [
            RouteName.Login,
            RouteName.Demo,
            RouteName.Reset,
            RouteName.Verification,
        ];

        if (nameList.includes(to.name)) {
            return false;
        }
    }

    return true;
}

function needRedirectIfLoggedIn(route: Route) {
    const name = route.name;
    if (name) {
        if (name === RouteName.Home) {
            return true;
        }

        if (name === RouteName.ChangePassword) {
            return false;
        }
    }

    if (route.matched.some(p => p.name === RouteName.Account)) {
        return true;
    }

    return false;
}

async function redirectTo(name: RouteName, params?: Dictionary<string>) {
    console.info(`redirect to: ${name}`);

    try {
        if (router.currentRoute.name !== name) {
            await router.push({
                name,
                params,
            });
        }
    } catch (ex) {
        console.warn(ex);
    }
}

function changeLanguage(lang: string) {
    if (lang === ConstNames.LanguageChinese) {
        moment.locale("zh-cn");
    } else {
        moment.locale("en");
    }

    i18n.locale = lang;
    // console.info(i18n.locale);
}

function formatDateTime(date: moment.MomentInput) {
    return FormatDateTime(date, store.state.dateFormatString);
}

function formatDateTimeForTable(row: number, column: number, date: Date) {
    return FormatDateTime(date, store.state.dateFormatString);
}

function redirectToLogin() {
    redirectTo(RouteName.Login);
}

function redirectToDemo() {
    redirectTo(RouteName.Demo);
}

function i18nFormat(this: Vue, key: string, ...args: string[]) {
    return FormatString(this.$t(key).toString(), ...args.map(p => this.$t(p).toString()));
}

function getRedirectLocation(to?: Route): RawLocation | undefined {
    if (!to) {
        to = router.currentRoute;
    }

    if (store.getters.IsLogged) {
        if (needRedirectIfLoggedIn(to)) {
            const returnUrl = store.state.returnUrl;
            if (returnUrl) {
                store.state.returnUrl = "";
                return returnUrl;
            } else {
                if (to.name === RouteName.Forgot) {
                    return {
                        name: RouteName.ChangePassword,
                    };
                }

                return {
                    name: RouteName.Dashboard,
                };
            }
        }
    } else {
        if (needLogin(to)) {
            if (to !== router.currentRoute) {
                store.state.returnUrl = to.fullPath;
            }

            const redirectUrl = store.state.redirectUrl;
            if (redirectUrl) {
                store.state.redirectUrl = "";
                return redirectUrl;
            } else {
                if (to.name === RouteName.ChangePassword) {
                    return {
                        name: RouteName.Forgot,
                    };
                }

                return {
                    name: RouteName.Login,
                };
            }
        }
    }

    return undefined;
}

function redirectIfNecessary() {
    const location = getRedirectLocation();
    if (location) {
        router.push(location);
    }
}

declare module "vue/types/vue" {
    interface Vue {
        GetLanguageString: typeof getLanguageString;
        GetString: (value: ILanguageString | undefined, fallback?: string) => string;
        RedirectToLogin: typeof redirectToLogin;
        RedirectToDemo: typeof redirectToDemo;
        RedirectIfNecessary: typeof redirectIfNecessary;
        ChangeLanguage: typeof changeLanguage;
        CutString: typeof CutString;
        FormatDateTime: typeof formatDateTime;
        FormatDateTimeForTable: typeof formatDateTimeForTable;
        FormatTimeDuration: typeof FormatTimeDuration;
        GetSpace: typeof GetSpace;
        GetTestStatus: typeof GetTestStatus;
        TimeFromNow: typeof TimeFromNow;
        Logout: typeof logout;
        I18n: typeof i18nFormat;
    }
}

Vue.prototype.GetLanguageString = getLanguageString;
Vue.prototype.GetString = function(value: ILanguageString | undefined, fallback = "") {
    return this.GetLanguageString(this.Language, value, fallback);
};
Vue.prototype.RedirectToLogin = redirectToLogin;
Vue.prototype.RedirectToDemo = redirectToDemo;
Vue.prototype.RedirectIfNecessary = redirectIfNecessary;
Vue.prototype.ChangeLanguage = changeLanguage;
Vue.prototype.CutString = CutString;
Vue.prototype.FormatDateTime = formatDateTime;
Vue.prototype.FormatDateTimeForTable = formatDateTimeForTable;
Vue.prototype.FormatTimeDuration = FormatTimeDuration;
Vue.prototype.GetSpace = GetSpace;
Vue.prototype.GetTestStatus = GetTestStatus;
Vue.prototype.TimeFromNow = TimeFromNow;
Vue.prototype.Logout = logout;
Vue.prototype.I18n = i18nFormat;

// http request 拦截器
axios.interceptors.request.use(
    config => {
        if (store.state.token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            // config.headers.Authorization = `token ${store.state.token}`
            config.headers[HEADER_API_ACCESS_TOKEN] = store.state.token;
        }

        try {
            config.headers[BROWSER_PLATFORM_NAME] = platform.name;
            config.headers[BROWSER_PLATFORM_VERSION] = platform.version;
            config.headers[BROWSER_PLATFORM_OS] = platform.os;
            config.headers[BROWSER_PLATFORM_DESCRIPTION] = platform.description;

            config.headers[HEADER_SOFTWARE_LANGUAGE] = store.getters.Language;
        } catch (ex) {
            console.error(ex);
        }

        return config;
    },
    err => {
        return Promise.reject(err);
    },
);

axios.interceptors.response.use(function(response) {
    // Do something with response data
    return response;
}, async function(error) {
    const response = error.response;
    if (response) {
        if (response.config.method === "delete" && response.config.url === "/api/v1/users") {
            return response;
        }

        // Do something with response error
        try {
            const data = response.data;
            const cloudCode = data.cloudCode as CloudCode;

            switch (cloudCode) {
                case CloudCode.AuthenticationFailed:
                    {
                        // #57882
                        await store.dispatch("logout");
                        await redirectTo(RouteName.Login);
                    }
                    break;

                case CloudCode.EmailNotVerified:
                    {
                        await store.dispatch("logout");
                        await redirectTo(RouteName.Verification, {
                            payload: data.data,
                        });
                    }
                    break;
            }
        } catch (ex) {
            console.error(ex);
            console.log(error);
        }
    }

    return response;
});

// https://router.vuejs.org/zh-cn/advanced/navigation-guards.html
router.beforeEach(async (to, from, next) => {
    // console.log(`${from.fullPath} -> ${to.fullPath}`);
    const query = to.query;

    if ("oem" in query) {
        await store.dispatch("updateOEM", query.oem);
    }

    if ("open_token" in query) {
        await store.dispatch("updateOpenToken", query.open_token);
    }

    if ("language" in query) {
        let lang = store.state.language;
        if (query.language === Language.Chinese.toString()) {
            lang = ConstNames.LanguageChinese;
        } else {
            lang = ConstNames.LanguageEnglish;
        }

        if (lang !== store.state.language) {
            await store.dispatch("changeLanguage", lang);
            changeLanguage(lang);
        }
    }

    if (to.path === "/Team/Join") {
        const r = await axios.get(`/api/v1/teams/join?id=${query.id}`);
        if (r.status === HttpStatusCode.OK) {
            // If they do not have an account, they are redirected to the “Reset Account Password” flow.
            // The email should already be filled in, and they do not need to verify their email.
            // Only pick a password.
            if (r.data.data.resetLink) {
                const link = UpdateQueryString("from", encodeURIComponent(location.origin + "/Team/Members"), r.data.data.resetLink);
                // console.info(link);

                await MessageBox.alert("", {
                    title: "Successfully joined team",
                    confirmButtonText: "Set up password",
                    showClose: false,
                    showCancelButton: false,
                    type: "success",
                });

                window.location.href = link;
                return;
            } else {
                Message({
                    showClose: true,
                    message: "Successfully joined team",
                    type: "success",
                });

                await store.dispatch("refreshTeamInfo");

                next("/team/members");
            }
        } else {
            if (r.data.cloudCode !== CloudCode.AlreadyJoined) {
                console.error(r.data);

                await MessageBox({
                    message: r.data.msg || "Join failed",
                    confirmButtonText: "Ok",
                });
            }

            next("/team/members");
        }
    }

    const l = getRedirectLocation(to);
    if (l) {
        next(l);
    } else {
        next();
    }
});

/* eslint-disable no-new */
new Vue({
    i18n,
    el: "#app",
    router,
    store,
    template: "<App/>",
    components: {
        App,
    },
    created() {
        this.initializeSocket();
        changeLanguage(store.state.language);
    },
    methods: {
        initializeSocket() {
            SocketManager.Initialize();

            SocketManager.Connected.on(() => {
                store.commit("sendUserInfo");
            });

            SocketManager.DataReceived.on((msg) => {
                const msgType = msg.getType();
                const p1 = msg.getP1();
                const p2 = msg.getP2();

                if (msgType === CloudMessageType.OLD_NOTIFY_CLIENT_REFRESH) {
                    const gm = GenericMessage.deserializeBinary(msg.getData_asU8());
                    // console.log(p1, p2);
                    if (p1 !== CloudDataType.None) {
                        eventHub.$emit("re-fetch-data", p1, p2, gm);
                    } else {
                        if (p2 === ServerChangeType.TeamMemberChanged) {
                            store.dispatch("refreshTeamInfo");
                        }
                    }
                } else if (msgType === CloudMessageType.OLD_GET_CLIENT_INFO) {
                    store.commit("sendUserInfo");
                } else {
                    RealTimeInteractionManager.HandleCloudMessage(msg);
                }
            });
        },
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__DEBUG_EDM_CLOUD__ = {
    buildTimestamp: document.documentElement.dataset.buildTimestamp,
    state: store.state,
    socket: SocketManager,
};
