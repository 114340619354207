
import AboutCard from "@/components/cards/AboutCard.vue";
import TwoFactorAuthenticationDialog from "@/components/dialogs/TwoFactorAuthenticationDialog.vue";
import { AuthenticationType, ConstNames, IBillingAddress, IProfile, RouteName } from "@/helpers";
import store from "@/store";
import axios from "axios";
import { HttpStatusCode, ITwoFactorVerificationData } from "node-share";
import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "Settings",
    components: {
        AboutCard,
        TwoFactorAuthenticationDialog,
    },
    watch: {
        language(val) {
            store.dispatch("changeLanguage", val);
            this.ChangeLanguage(val);
        },

        dateFormat(val) {
            store.dispatch("updateDateFormat", val);
        },
    },
})
export default class Settings extends Vue {
    languageOptions = [
        {
            value: ConstNames.LanguageEnglish,
            label: "English",
        },
        {
            value: ConstNames.LanguageChinese,
            label: "简体中文",
        },
    ];

    language = "";

    datetimeFormatOptions = [
        {
            label: "MMM D, YYYY",
            value: "MMM D, YYYY",
        },
        {
            label: "YYYY-MM-DD HH:mm",
            value: "YYYY-MM-DD HH:mm",
        },
        {
            label: "YYYY-MM-DD HH:mm:ss",
            value: "YYYY-MM-DD HH:mm:ss",
        },
        {
            label: "MMM D, YYYY, HH:mm",
            value: "MMM D, YYYY, HH:mm",
        },
        {
            label: "MMM D, YYYY, HH:mm:ss",
            value: "MMM D, YYYY, HH:mm:ss",
        },
        {
            label: "MMMM Do YYYY, HH:mm:ss",
            value: "MMMM Do YYYY, HH:mm:ss",
        },
    ];

    dateFormat = "";

    profileInfo: IProfile = {};
    ShippingAddress: IBillingAddress = {};
    BillingAddress: IBillingAddress = {};
    sameAsShippingAddress = true;
    multipleSelection = [];
    formLabelWidth = "120px";
    failedCount = 0;

    countryOptions = [
        { name: "Australia", value: "AU" },
        { name: "Austria", value: "AT" },
        { name: "Belgium", value: "BE" },
        { name: "Brazil", value: "BR" },
        { name: "Canada", value: "CA" },
        { name: "China", value: "CN" },
        { name: "Denmark", value: "DK" },
        { name: "Finland", value: "FI" },
        { name: "France", value: "FR" },
        { name: "Germany", value: "DE" },
        { name: "Hong Kong", value: "HK" },
        { name: "Ireland", value: "IE" },
        { name: "Italy", value: "IT" },
        { name: "Japan", value: "JP" },
        { name: "Luxembourg", value: "LU" },
        { name: "Mexico", value: "MX" },
        { name: "Netherlands", value: "NL" },
        { name: "New Zealand", value: "NZ" },
        { name: "Norway", value: "NO" },
        { name: "Portugal", value: "PT" },
        { name: "Singapore", value: "SG" },
        { name: "Spain", value: "ES" },
        { name: "Sweden", value: "SE" },
        { name: "Switzerland", value: "CH" },
        { name: "United Kingdom", value: "GB" },
        { name: "United States", value: "US" },
    ];

    dialogVisible = false;
    yourEmail = "";
    confirmYourPassword = "";

    showAuthenticationDialog = false;
    loadingAuthenticationDialog = false;
    authenticationType = AuthenticationType.ConfirmAccess;

    get IsAbout() {
        if ("about" in this.$route.query) {
            return true;
        }

        return false;
    }

    get Is2FAEnabled() {
        return this.$store.state.user.is2faEnabled;
    }

    created() {
        store.commit("updateBreadcrumb", ["sidebar.settings"]);
    }

    mounted() {
        this.language = this.$i18n.locale;
        this.dateFormat = store.state.dateFormatString;

        this.fetchData();
    }

    sameAsShippingAddressChanged() {
        if (this.sameAsShippingAddress) {
            this.BillingAddress = this.ShippingAddress;
        } else {
            // #56032
            this.BillingAddress = Object.assign({}, this.ShippingAddress);
        }
    }

    async fetchData() {
        const res = await axios.get("/api/v1/users/me");
        if (res.status === HttpStatusCode.OK) {
            this.failedCount = 0;

            const userData = res.data.data;
            const profile = userData.profile;

            // console.info(profile);

            this.profileInfo = {
                name: profile.name,
                company: profile.company,
                email: userData.email,
                phone1: profile.phone1,
            };

            this.ShippingAddress = {
                location: profile.location,
                city: profile.city,
                state: profile.state,
                postal_code: profile.postal_code,
                country: profile.country,
            };

            this.BillingAddress = profile.billingAddress;
            this.sameAsShippingAddress = profile.sameAsShippingAddress;

            // this.sameAsShippingAddressChanged();
        } else {
            console.warn(res);
            if (res.status === 502) {
                this.failedCount++;

                if (this.failedCount < 3) {
                    this.fetchData();
                }
            }
        }
    }

    changePassword() {
        this.$router.push({
            name: RouteName.ChangePassword,
        });
    }

    async updateAccountSettings() {
        const profile = {
            billingAddress: this.BillingAddress,
            name: this.profileInfo.name,
            // gender : this.profileInfo.gender,
            company: this.profileInfo.company,
            phone1: this.profileInfo.phone1,
            country: this.ShippingAddress.country,
            state: this.ShippingAddress.state,
            location: this.ShippingAddress.location,
            // website : ,
            // picture : ,
            city: this.ShippingAddress.city,
            postal_code: this.ShippingAddress.postal_code,
            sameAsShippingAddress: this.sameAsShippingAddress,
        };

        console.log(profile);

        const r = await axios.put("/api/v1/users/profile", profile);
        if (r.status === HttpStatusCode.OK) {
            // refresh
            store.dispatch("refreshUserInfo");

            this.$message.success(r.data.msg);
        } else {
            this.$message.error(r.data.msg);
        }
    }

    async deleteAccount() {
        if (this.dialogVisible) {
            if (this.yourEmail !== this.$store.state.user.email) {
                this.$message({
                    type: "warning",
                    message: this.$t("pleaseEnterTheEmailAddressOfTheCurrentAccount").toString(),
                });
            } else {
                const r = await axios.delete("/api/v1/users", {
                    data: {
                        email: this.yourEmail,
                        password: this.confirmYourPassword,
                    },
                });

                if (r.status === HttpStatusCode.OK) {
                    this.dialogVisible = false;
                    this.Logout();
                } else {
                    this.$message.error(r.data.msg);
                }
            }
        } else {
            this.dialogVisible = true;

            this.yourEmail = "";
            this.confirmYourPassword = "";
        }
    }

    async enable2FA() {
        await this.$router.push({
            name: RouteName.MFASetup,
        });
    }

    async disable2FA() {
        this.showAuthenticationDialog = true;
    }

    async verify(data: ITwoFactorVerificationData) {
        this.loadingAuthenticationDialog = true;

        try {
            const r = await axios.delete("/api/mfa", {
                data,
            });

            if (r.status !== HttpStatusCode.OK) {
                this.$message({
                    message: "Verify failed, please try again.",
                    type: "warning",
                });

                return;
            }

            await store.dispatch("refreshUserInfo");

            this.showAuthenticationDialog = false;
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loadingAuthenticationDialog = false;
        }
    }
}
