<template>
    <div class="main-container-content" v-loading="loading">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="text-uppercase main-breadcrumb"
        >
            <el-breadcrumb-item :to="{ path: '/Dashboard' }">{{
                $t("home")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item to="/team/groups">
                {{ $t("sidebar.team.groups") }}
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{ GroupName }}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="ci-card">
            <div class="header">{{ GroupName }}</div>
            <div class="body">
                <p>{{ $t("SearchMembers") }}</p>
                <div class="table-container" style="margin-top: 0.3em">
                    <div class="table-left" style="overflow: hidden">
                        <el-input
                            v-model="searchInput"
                            :placeholder="$t('NameOrEmail')"
                        ></el-input>

                        <el-table :data="Members" style="width: 100%">
                            <el-table-column :label="$t('Name')" sortable>
                                <template slot-scope="scope">
                                    <el-link>{{
                                        scope.row.name || scope.row.email
                                    }}</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('Status')"
                                prop="status"
                                sortable
                            ></el-table-column>
                            <el-table-column label v-if="IsAdminOrManager">
                                <template slot-scope="scope">
                                    <i
                                        class="el-icon-close remove-member"
                                        @click="deleteMember(scope.row)"
                                        v-if="scope.row.status === 'Member'"
                                    ></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table-right">
                        <div class="group-actions">
                            <button
                                class="ci-btn"
                                @click="addMember"
                                v-if="IsAdminOrManager"
                            >
                                {{ $t("AddMembers") }}
                            </button>
                            <el-link
                                type="primary"
                                class="group-action"
                                @click="handleGroupAction('leave')"
                            >
                                {{ $t("LeaveGroup") }}
                            </el-link>
                            <el-link
                                type="primary"
                                class="group-action"
                                @click="handleGroupAction('delete')"
                                v-if="IsAdminOrManager"
                            >
                                {{ $t("DeleteGroup") }}
                            </el-link>
                            <el-link
                                type="primary"
                                class="group-action"
                                @click="handleGroupAction('edit')"
                                v-if="IsAdminOrManager"
                            >
                                {{ $t("EditGroup") }}
                            </el-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Group Creation Form -->
        <el-dialog
            custom-class="create-team-dialog"
            :visible.sync="groupEditFormVisible"
        >
            <!-- Create Group Page -->
            <div class="ci-card">
                <div class="header">
                    <span>{{ $t("Edit") }} "{{ GroupName }}"</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                </div>
                <div class="body">
                    <div>
                        <div class="display-flex align-items-center">
                            <h4 class="m-r-1-em">{{ $t("GroupName") }}*</h4>
                            <el-input
                                v-model="formGroup.name"
                                class="flex-auto width-auto"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-content-end m-t-1-em">
                        <button
                            class="ci-btn"
                            style="background-color: gray"
                            @click="groupEditFormVisible = false"
                        >
                            {{ $t("Cancel") }}
                        </button>
                        <button class="ci-btn" @click="submitGroupEdit">
                            {{ $t("SaveChanges") }}
                        </button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { MessageBox } from "element-ui";
import store from "@/store";
import { RouteName } from "@/helpers";

@Component({
    name: "TeamGroups",
})
export default class TeamGroups extends Vue {
    loading = false;
    searchInput = "";

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    group = undefined;

    groupEditFormVisible = false;

    formGroup = {
        name: "",
    };

    createGroupError = "";
    createGroupSucceed = false;

    data() {
        return {
            group: undefined,
        };
    }

    created() {
        store.commit("updateBreadcrumb", ["sidebar.team.groups", ""]);

        this.fetchGroup();
    }

    get GroupId() {
        return this.$route.params.id;
    }

    get GroupName() {
        if (this.group) {
            return this.group.name;
        }

        return "";
    }

    get Members() {
        if (this.group) {
            let result = this.group.members;

            if (!result) {
                return [];
            }

            if (this.searchInput) {
                result = result.filter(p =>
                    (p.name && p.name.toLowerCase().includes(this.searchInput.toLowerCase())) ||
                    (p.email && p.email.toLowerCase().includes(this.searchInput.toLowerCase())));
            }

            return result;
        }

        return [];
    }

    get Me() {
        if (this.group) {
            return this.group.members.find(p => p.user === store.getters.UserId);
        }

        return undefined;
    }

    get IsAdminOrManager() {
        if (this.Me) {
            return this.Me.status !== "Member";
        }

        return false;
    }

    async fetchGroup() {
        const r = await axios.get(`/api/v1/groups?uuid=${this.GroupId}`);
        // console.log(r);

        if (r.status === HttpStatusCode.OK) {
            this.group = r.data.data[0];
        }
    }

    async addMember() {
        try {
            this.loading = true;

            const r = await axios.post("/api/v1/groups/invite", {
                group: this.GroupId,
                email: this.searchInput,
            });

            const resData = r.data;

            if (r.status === 200) {
                // refresh data
                this.fetchGroup();

                this.$message({
                    message: "success",
                    type: "success",
                });
            } else {
                console.warn(resData);

                this.$message({
                    message: resData.msg,
                    type: "error",
                });
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }

    async deleteMember(member) {
        try {
            this.loading = true;

            const r = await axios.delete("/api/v1/groups/member", {
                data: {
                    group: this.GroupId,
                    id: member.user,
                },
            });

            const resData = r.data;

            if (r.status === 200) {
                // refresh data
                this.fetchGroup();

                this.$message({
                    message: "success",
                    type: "success",
                });
            } else {
                console.warn(resData);

                this.$message({
                    message: resData.msg,
                    type: "error",
                });
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }

    async handleGroupAction(action) {
        console.log(action);

        try {
            if (action === "leave") {
                await MessageBox.confirm(this.$t("AreYouSureYouWantToLeaveThisGroup"), this.$t("Warning"), {
                    confirmButtonText: this.$t("Yes"),
                    cancelButtonText: this.$t("No"),
                    type: "warning",
                });

                const r = await axios.post("/api/v1/groups/leave", {
                    groupId: this.GroupId,
                });

                if (r.status === HttpStatusCode.OK) {
                    this.$message({
                        type: "success",
                        message: "success!",
                    });

                    this.$router.push({
                        name: RouteName.TeamGroups,
                    });
                }
            } else if (action === "delete") {
                await MessageBox.confirm(this.$t("DeleteGroupWarning"), this.$t("Warning"), {
                    confirmButtonText: this.$t("Yes"),
                    cancelButtonText: this.$t("No"),
                    type: "warning",
                });

                const r = await axios.post("/api/v1/groups/remove", {
                    groupId: this.GroupId,
                });

                if (r.status === HttpStatusCode.OK) {
                    this.$message({
                        type: "success",
                        message: "success!",
                    });

                    this.$router.push({
                        name: RouteName.TeamGroups,
                    });
                } else {
                    this.$message({
                        message: r.data.msg,
                        type: "error",
                    });
                }
            } else if (action === "edit") {
                this.formGroup.name = this.GroupName;
                this.groupEditFormVisible = true;
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    async submitGroupEdit() {
        const r = await axios.post("/api/v1/groups/edit", {
            groupId: this.GroupId,
            ...this.formGroup,
        });

        if (r.status === HttpStatusCode.OK) {
            await this.fetchGroup();

            this.groupEditFormVisible = false;
        } else {
            this.$message({
                message: r.data.msg,
                type: "error",
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.table-container {
    display: grid;

    .table-left {
        grid-column: 1;
    }

    .table-right {
        grid-column: 2;
        padding-left: 1em;

        .group-actions {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .group-action {
                margin-left: 1.6em;
            }
        }
    }

    .remove-member {
        font-size: 1.5em;
        font-weight: bold;
        cursor: pointer;
    }
}
</style>
