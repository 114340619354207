
import BackToTop from "@/components/BackToTop.vue";
import pageFooter from "@/components/partials/Footer.vue";
import pageHeader from "@/components/partials/Header.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import store from "./store";

@Component({
    name: "app",

    components: {
        pageHeader,
        pageFooter,
        BackToTop,
    },
})
export default class App extends Vue {
    get IsLogged() {
        return store.getters.IsLogged;
    }

    async created() {
        if (this.IsLogged) {
            await store.dispatch("refreshUserInfo");
        }

        await store.dispatch("updateServerInfo");
    }

    @Watch("IsLogged")
    onLoginChanged() {
        this.RedirectIfNecessary();
    }
}
