/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/base/class/PlotSetting.ts
 * @create:      2022-05-17 14:22:48.564
 * @modify:      2023-03-22 16:11:35.734
 * @version:     0.1.1
 * @times:       9
 * @lines:       62
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import { PlotDateTimeFormat } from "../enum";
import { IPlotSetting } from "../interface";

export class PlotSetting implements IPlotSetting {
    public TimeFormat: PlotDateTimeFormat = PlotDateTimeFormat.LocalController;
    public Theme = "light";

    public get DarkMode() {
        return this.Theme === "dark";
    }

    public set DarkMode(val: boolean) {
        this.Theme = val ? "dark" : "light";
    }

    public ToJsonString() {
        return JSON.stringify({
            TimeFormat: this.TimeFormat,
            Theme: this.Theme,
        });
    }

    public FromJsonString(json?: string) {
        try {
            if (json) {
                const item = JSON.parse(json) as IPlotSetting;

                if (item) {
                    if (item.TimeFormat !== undefined) {
                        this.TimeFormat = item.TimeFormat;
                    }

                    if (item.Theme !== undefined) {
                        this.Theme = item.Theme;
                    }
                }
            }
        } catch (ex) {
            console.warn(ex, json);
        }

        return this;
    }

    public Clone() {
        return new PlotSetting().FromJsonString(this.ToJsonString());
    }
}
