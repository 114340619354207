
import TwoFactorAuthenticationDialog from "@/components/dialogs/TwoFactorAuthenticationDialog.vue";
import { AuthenticationType, DeleteCookie, GetCookie, RouteName, SetCookie } from "@/helpers";
import store from "@/store";
import axios from "axios";
import { HttpStatusCode, ITwoFactorVerificationData } from "node-share";
import { CloudCode } from "socket/common";
import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "Login",
    components: {
        TwoFactorAuthenticationDialog,
    },
})
export default class Login extends Vue {
    form = {
        email: "",
        password: "",
    };

    rules = {
        email: [
            { required: true, message: "Please input email address", trigger: "blur" },
            { type: "email", message: "Please input correct email address", trigger: ["blur", "change"] },
        ],
        password: [
            { required: true, message: "Please input password", trigger: "blur" },
        ],
    };

    remember = false;

    token = "";
    showAuthenticationDialog = false;
    loadingAuthenticationDialog = false;
    authenticationType = AuthenticationType.Login;

    get LoginType() {
        return this.$router.currentRoute.name;
    }

    get IsNormal() {
        return this.LoginType !== RouteName.Demo;
    }

    get LoginText() {
        return this.IsNormal ? this.$t("SignIn") : "Start Demo";
    }

    get RememberCookieName() {
        const name = "remember_account";

        if (this.LoginType) {
            return `${name}_${this.LoginType}`;
        }

        return name;
    }

    mounted() {
        const email = GetCookie(this.RememberCookieName);
        if (email) {
            this.form.email = email;
            this.remember = true;
        }
    }

    submit(formName = "form") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const f = this.$refs[formName] as any;
        f.validate(async (valid: boolean) => {
            if (valid) {
                if (this.remember) {
                    SetCookie(this.RememberCookieName, this.form.email);
                } else {
                    DeleteCookie(this.RememberCookieName);
                }

                const r = await axios.post("/api/login", { isDemo: !this.IsNormal, ...this.form });

                if (r.status === HttpStatusCode.OK) {
                    if (r.data.cloudCode === CloudCode.EmailNotVerified) {
                        this.$router.push({
                            name: RouteName.Verification,
                            params: {
                                payload: r.data.data,
                            },
                        });
                    } else if (r.data.cloudCode === CloudCode.NeedTwoFactorAuthentication) {
                        this.token = r.data.data;
                        this.showAuthenticationDialog = true;
                    } else {
                        await store.dispatch("updateToken", r.data.data);
                    }
                } else {
                    // console.warn(r);
                    this.$message.error(r.data.msg || "Login failed. Email or password is incorrect.");
                }
            } else {
                return false;
            }
        });
    }

    signup() {
        this.$router.push("/account/signup");
    }

    forgot() {
        this.$router.push("/account/forgot");
    }

    async verify(data: ITwoFactorVerificationData) {
        this.loadingAuthenticationDialog = true;

        try {
            const r = await axios.post("/api/mfa/verify", {
                edm_cloud_token: this.token,
                ...data,
            });

            // console.info(r);

            if (r.status !== HttpStatusCode.OK) {
                this.$message({
                    message: "Verify failed, please try again.",
                    type: "warning",
                });

                return;
            }

            await store.dispatch("updateToken", this.token);
            // await store.dispatch("refreshUserInfo");

            this.showAuthenticationDialog = false;
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loadingAuthenticationDialog = false;
        }
    }
}
