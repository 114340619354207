
import ChartOptions from "@/components/chart/ChartOptions.vue";
import { BuildDataViewTable, ChartEvent, ChartEventType, FormatNumber, GetChartName, GetChartOptionBySignal, IChartOptions, IDateLike, IPlot, IsDateSignal, IsFrequencySignal, IsSameDay, IsSameGroup } from "@/helpers";
import { ECharts, EChartsOption, ResizeOpts } from "echarts";
import { ConvertTo, GenerateGuid } from "node-share";
import { ISignal } from "socket/common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VChart from "vue-echarts";

@Component({
    name: "SignalChart",
    components: {
        ChartOptions,
        VChart,
    },
})
export default class SignalChart extends Vue {
    @Prop()
    signals!: ISignal[];

    @Prop()
    isDataView!: boolean;

    @Prop()
    title!: string;

    @Prop()
    isLoading!: boolean;

    @Prop()
    readonly id!: string;

    @Prop()
    theme: string | undefined;

    autoResize = true;

    updateOptions = {
        notMerge: true,
        // replaceMerge?: string | string[];
        lazyUpdate: false,
    };

    dialogChartProperties = false;
    dialogSavePlot = false;

    option?: EChartsOption;
    savePlotTitle = "";

    chartOptionsBackup?: IChartOptions;
    chartOptions?: IChartOptions;

    dataViewHtml = "";

    get Chart() {
        return ConvertTo<ECharts>(this.$refs.chart);
    }

    get DefaultTitle() {
        return GetChartName(this.signals?.map(p => p.Name) ?? []);
    }

    get Title() {
        if (this.chartOptions?.plotTitle) {
            return this.chartOptions.plotTitle;
        }

        return this.DefaultTitle;
    }

    get firstSignal() {
        if (this.signals && this.signals.length > 0) {
            return this.signals[0];
        }

        return undefined;
    }

    get isFrequencySignal() {
        return IsFrequencySignal(this.firstSignal);
    }

    get dataViewSelector() {
        return `div.data-view-${this.id}`;
    }

    get currentOption() {
        return this.option || this.Chart.getOption();
    }

    get isXAxisDate() {
        return IsDateSignal(this.firstSignal);
    }

    get toolIconClassForDataView() {
        return `el-icon-document  ${this.isDataView ? "active" : ""}`;
    }

    data() {
        return {
            chartOptions: undefined,
            chartOptionsBackup: undefined,
        };
    }

    created() {
        this.refresh();
    }

    @Watch("isLoading")
    onIsLoading(val: boolean) {
        if (val) {
            this.Chart.showLoading();
        } else {
            this.Chart.hideLoading();
        }
    }

    @Watch("signals", { deep: true })
    onSignalsChanged(newSignals: ISignal[], oldSignals: ISignal[]) {
        let needReset = false;

        if (newSignals.length !== oldSignals.length) {
            needReset = true;
        }

        if (!needReset) {
            for (const newSignal of newSignals) {
                const oldSignal = oldSignals.find(p => p.Name === newSignal.Name);
                if (!oldSignal) {
                    needReset = true;
                    break;
                }

                if (!IsSameGroup(newSignal, oldSignal)) {
                    needReset = true;
                    break;
                }
            }
        }

        this.refresh(needReset);
    }

    @Watch("isDataView")
    onIsDataView(val: boolean) {
        if (val) {
            this.refreshDataView();
        }
    }

    refresh(reset = false) {
        // console.info(`refresh chart, id: ${this.id}`, this.signals);

        if (reset) {
            this.chartOptions = undefined;
            this.chartOptionsBackup = undefined;
            this.option = undefined;
        }

        const options = Object.assign({}, this.chartOptions);

        if (this.chartOptionsBackup) {
            if (options.xFormat !== this.chartOptionsBackup.xFormat) {
                if (options.axisMinMax?.xFixed) {
                    options.axisMinMax.xFixed = false;
                }
            }

            if (options.yFormat !== this.chartOptionsBackup.yFormat) {
                if (options.axisMinMax?.yFixed) {
                    options.axisMinMax.yFixed = false;
                }
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const op = GetChartOptionBySignal(this.signals, options) as any;

        if (!this.chartOptions) {
            this.chartOptions = Object.assign(options, {
                plotTitle: this.title,
            });
        }

        if (op.xAxis.type === "time") {
            let lastValue: IDateLike | undefined;
            let lastIndex: number | number;

            const formatTime = "{HH}:{mm}:{ss}";
            const formatTimeDate = "{HH}:{mm}:{ss}\r\n{yyyy}/{MM}/{dd}";

            op.xAxis.axisLabel = {
                formatter: function(value: IDateLike, index: number) {
                    try {
                        if (lastValue === undefined || lastIndex === undefined) {
                            return formatTime;
                        }

                        // console.info(value, index);

                        if (index > lastIndex) {
                            if (!IsSameDay(lastValue, value)) {
                                return formatTimeDate;
                            }
                        }

                        return formatTime;
                    } finally {
                        lastValue = value;
                        lastIndex = index;
                    }
                },
            };
        }

        op.tooltip = {
            trigger: "axis",
            triggerOn: "mousemove|click",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: function(params: any) {
                let str = "";
                if (op.xAxis.type === "time") {
                    str = params[0].axisValueLabel + "<br/>";
                } else {
                    str = FormatNumber(params[0].data[0], options.xPrecision) + "<br/>";
                }
                for (let i = 0; i < params.length; i++) {
                    str += params[i].marker + "<span style=\"font-size:14px;color:#666;font-weight:400;margin-left:2px\">" + params[i].seriesName + "</span> " +
                        "<span style=\"float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900\">" + FormatNumber(params[i].data[1], options.yPrecision) + "</span><br/>";
                }
                return str;
            },
        };

        op.toolbox.feature.myTool1 = {
            show: true,
            title: "Restore",
            icon: "path://M512 981.333333c-209.866667 0-396.693333-126.026667-466.293333-314.08a35.52 35.52 0 0 1 23.626666-44.426666 38.613333 38.613333 0 0 1 48 20.693333c58.666667 158.933333 217.013333 265.493333 394.666667 265.6s336-106.666667 394.666667-266.133333a37.6 37.6 0 0 1 28.853333-23.626667 38.986667 38.986667 0 0 1 35.786667 11.946667 34.773333 34.773333 0 0 1 7.146666 35.36c-69.386667 188.373333-256.48 314.666667-466.453333 314.666666z m431.36-574.08a37.92 37.92 0 0 1-35.946667-24.266666C849.386667 222.56 690.613333 114.88 512 114.72S174.72 222.346667 116.746667 382.773333A38.72 38.72 0 0 1 69.333333 403.733333a35.786667 35.786667 0 0 1-24.106666-44.373333C113.333333 169.866667 301.013333 42.666667 512 42.666667s398.666667 127.306667 467.146667 316.96a34.56 34.56 0 0 1-4.906667 32.64 38.933333 38.933333 0 0 1-30.88 14.986666z",
            onclick: () => {
                this.refresh(true);
            },
        };

        op.toolbox.feature.myDataView = {
            show: true,
            title: "Data View",
            icon: "path://M17.5,17.3H33 M17.5,17.3H33 M45.4,29.5h-28 M11.5,2v56H51V14.8L38.4,2H11.5z M38.4,2.2v12.7H51 M45.4,41.7h-28",
            onclick: () => {
                this.toolDataView();
            },
        };

        if (!this.option) {
            this.option = op;
        } else {
            this.Chart.setOption(op);
        }

        this.refreshDataView(op);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refreshDataView(op?: any) {
        if (!this.isDataView) {
            return;
        }

        if (!op) {
            op = this.currentOption;
        }

        this.dataViewHtml = BuildDataViewTable(op.series, op.xAxis.name, op.yAxis.name, this.isXAxisDate);
    }

    resize(opts?: ResizeOpts) {
        this.Chart.resize(opts);
    }

    restore() {
        this.Chart.dispatchAction({
            type: "restore",
        });
    }

    applyChartOptions() {
        this.refresh();

        this.$emit(ChartEvent, ChartEventType.saveLayout, this.id, this.chartOptions?.plotTitle);
    }

    showChartOptions() {
        if (this.chartOptions) {
            this.chartOptionsBackup = Object.assign({}, this.chartOptions);
        } else {
            this.chartOptionsBackup = undefined;
        }

        this.dialogChartProperties = true;
    }

    onCancelDialogChartProperties() {
        this.dialogChartProperties = false;
        if (this.chartOptions && this.chartOptionsBackup) {
            Object.assign(this.chartOptions, this.chartOptionsBackup);
        }
    }

    onConfirmDialogChartProperties() {
        this.dialogChartProperties = false;
        this.applyChartOptions();
    }

    onCancelDialogSavePlot() {
        this.dialogSavePlot = false;
    }

    onConfirmDialogSavePlot() {
        const title = this.savePlotTitle || this.Title;

        const plot: IPlot = {
            signals: this.signals.map(p => p.Name),
            title,
            isDataView: false,
            i: GenerateGuid(),
        };

        console.info(plot);

        this.$emit(ChartEvent, ChartEventType.savePlot, this.id, plot, (err?: Error) => {
            if (err) {
                this.$message({
                    message: `${err}`,
                    type: "warning",
                });
            } else {
                this.dialogSavePlot = false;
            }
        });
    }

    close() {
        this.$emit(ChartEvent, ChartEventType.close, this.id);
    }

    async handleClose(done: () => void) {
        const r = await this.$confirm(this.$t("CloseDialog").toString());
        if (r === "confirm") {
            done();
        }
    }

    async toolDataView() {
        this.$emit("update:isDataView", !this.isDataView);
        await this.$nextTick();
        this.$emit(ChartEvent, ChartEventType.saveLayout, this.id, this.chartOptions?.plotTitle);
    }

    savePlot() {
        this.savePlotTitle = "";
        this.dialogSavePlot = true;
    }

    editSignals() {
        this.$emit(ChartEvent, ChartEventType.editSignals, this.id);
    }

    saveChartAsImage() {
        // const doc = this.Chart.getDom();
        // const canvas = doc.querySelector("canvas");
        // if (canvas) {
        //     canvasToImage(canvas, {
        //         name: "myImage",
        //         type: "jpg", // "png",
        //         quality: 1,
        //     });
        // }

        const src = this.Chart.getDataURL({
            pixelRatio: 2,
            backgroundColor: "#fff",
        });

        // console.info(src);

        const link = document.createElement("a");

        document.body.appendChild(link); // for Firefox

        link.setAttribute("href", src);
        link.setAttribute("download", "image");
        link.click();

        document.body.removeChild(link);
    }
}
