
import License from "@/components/cards/LicenseInformationCard.vue";
import { DeleteModule, DeleteSystem, GeneralSearchForArray, IKeyValueItem, IModule, ISubscription, ISystem } from "@/helpers";
import axios from "axios";
import moment from "moment";
import { CloudSubscriptionPlan } from "node-share";
import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "HardwareInformationCard",
    components: {
        License,
    },
})
export default class HardwareInformationCard extends Vue {
    loadingSystems = false;
    loadingModules = false;

    systems: ISystem[] = [];
    modules: IModule[] = [];

    searchSystem = "";
    searchModule = "";

    SN = "";
    dialogLicenseKeyInfoVisible = false;

    get Systems() {
        const v = GeneralSearchForArray(this.searchSystem, this.systems);
        // console.log(v);
        return v;
    }

    get Modules() {
        const v = GeneralSearchForArray(this.searchModule, this.modules);
        // console.log(v);
        return v;
    }

    created() {
        this.fetchData();
    }

    async fetchData(system = true, module = true) {
        try {
            if (system) {
                this.loadingSystems = true;
                const res = await axios.get("/api/v1/systems");
                this.systems = res.data.data;
                this.loadingSystems = false;
            }

            if (module) {
                this.loadingModules = true;
                const res = await axios.get("/api/v1/modules");
                this.modules = res.data.data;
                this.loadingModules = false;
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loadingSystems = false;
            this.loadingModules = false;
        }
    }

    displayLicenseKeyInfo(device: IModule) {
        // console.log(device);
        this.SN = device.SN;
        this.dialogLicenseKeyInfoVisible = true;
    }

    async handleDeleteSystem(sys: ISystem) {
        // console.log(sys);

        try {
            this.loadingSystems = true;

            const d = await this.$confirm(this.$t("DeleteWarning").toString().replace("{0}", sys.Name), this.$t("Warning").toString(), {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("ok").toString(),
                cancelButtonText: this.$t("Cancel").toString(),
                type: "warning",
            });

            if (d === "confirm") {
                if (await DeleteSystem(sys.GUID) !== false) {
                    await this.fetchData(true, false);
                }
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loadingSystems = false;
        }
    }

    async handleDeleteModule(module: IModule) {
        try {
            this.loadingModules = true;
            const d = await this.$confirm(this.$t("DeleteWarning").toString().replace("{0}", module.Name), this.$t("Warning").toString(), {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("ok").toString(),
                cancelButtonText: this.$t("Cancel").toString(),
                type: "warning",
            });

            if (d === "confirm") {
                if (await DeleteModule(module.SN) !== false) {
                    await this.fetchData(false, true);
                }
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loadingModules = false;
        }
    }

    getTotalChannelNumber(modules?: IModule[]) {
        if (!modules || modules.length === 0) {
            return "N/A";
        }

        let n = 0;

        for (const m of modules) {
            const module = this.modules.find(p => p.SN === m.SN);
            if (module?.TotalChannelNumber) {
                n += module.TotalChannelNumber;
            }
        }

        return n;
    }

    getTags(row: IModule): IKeyValueItem<string, unknown>[] {
        return [
            {
                key: "columns.hardware_version",
                value: row.HardwareVersion,
            },
            {
                key: "columns.type",
                value: row.Type,
            },
            {
                key: "columns.ip",
                value: row.Network
                    ? row.Network.IPAddress
                    : "",
            },
            {
                key: "columns.channel_count",
                value: row.TotalChannelNumber,
            },
        ];
    }

    displaySubscriptionPlan(subscription?: ISubscription) {
        if (subscription) {
            switch (subscription.plan) {
                case "Basic":
                    return this.$t("CloudSubscriptionPlanBasic");
                case "Standard":
                    return this.$t("CloudSubscriptionPlanStandard");
            }
        }

        return this.$t("CloudSubscriptionPlanFree");
    }

    displaySubscriptionExpiration(subscription?: ISubscription) {
        if (subscription) {
            if (subscription.plan !== CloudSubscriptionPlan.Free && subscription.expiration) {
                if (moment().isAfter(subscription.expiration)) {
                    console.info(subscription.expiration);
                    return this.$t("Expired");
                }

                return this.FormatDateTime(subscription.expiration);
            }
        }

        return this.$t("TODAY");
    }

    getSubscriptionExpirationStyle(subscription?: ISubscription) {
        const items = ["margin-left: 0px"];

        if (subscription) {
            if (subscription.plan !== CloudSubscriptionPlan.Free && subscription.expiration) {
                if (moment().add(3, "months").isBefore(subscription.expiration)) {
                    // The fonts of the Subscription expiration should be in green if it expires in more than 3 months.
                    items.push("color: green");
                } else if (moment().isBefore(subscription.expiration) && moment().add(3, "months").isAfter(subscription.expiration)) {
                    // The fonts of the Subscription expiration should be in yellow if it expires in 3 months.
                    items.push("color: #F6BE00");
                } else {
                    // The fonts of the Subscription expiration should be “Expired” if it already expired.
                    items.push("color: red");
                }
            }
        }

        return items.join(";");
    }
}
