<template>
    <el-card class="box-card">
        <!-- title -->
        <div slot="header" class="clearfix">
            <!-- <h3>{{ this.TestName }}</h3>
            <span>{{ this.RunStatus }}</span> -->
            <div style="float: right; padding: 3px 0">
                <!-- <el-checkbox v-model="refreshData" style="padding-right: 2em;">Refresh</el-checkbox> -->
                <el-switch
                    v-model="refreshData"
                    @change="handleRefreshData"
                    style="padding-right: 2em"
                />
                <!-- <el-button type="success" :disabled="IsRunning" @click="ExecControlCommand(1)">Run</el-button>
                <el-button type="warning" :disabled="!IsRunning" @click="ExecControlCommand(2)">Pause</el-button>
                <el-button type="danger" :disabled="!IsRunning" @click="ExecControlCommand(3)">Stop</el-button>
                <el-button type="primary" :disabled="!IsRunning" @click="ExecControlCommand(4)">Save</el-button> -->
            </div>
        </div>

        <el-tabs type="border-card" tab-position="left">
            <el-tab-pane label="Summary">
                <h4 class="display-group">Project Status</h4>
                <el-table :data="SiteStatus" border style="width: 100%">
                    <el-table-column
                        prop="sitename"
                        label="Site"
                    ></el-table-column>
                    <el-table-column
                        prop="connectionstatusstring"
                        label="Connection Status"
                    ></el-table-column>
                    <el-table-column
                        prop="runningstatusstring"
                        label="Running Status"
                    ></el-table-column>
                    <el-table-column
                        prop="timeelapsed"
                        label="Time Elapsed"
                    ></el-table-column>
                    <el-table-column
                        prop="newfilecount"
                        label="New Data Files"
                    ></el-table-column>
                    <el-table-column
                        prop="alarmstatusstring"
                        label="Active Alarm Status"
                    ></el-table-column>
                    <el-table-column
                        prop="location"
                        label="Location"
                    ></el-table-column>
                    <el-table-column
                        prop="lastupdated"
                        label="Last Updated"
                        :formatter="formatter"
                    ></el-table-column>
                </el-table>
            </el-tab-pane>

            <el-tab-pane
                :key="item.name"
                v-for="item in TestsTabs"
                :label="item.title"
                :name="item.name"
            >
                <h4 class="display-group">Project Status</h4>
                <el-table
                    :data="[item.content.siteStatus]"
                    border
                    style="width: 100%"
                >
                    <el-table-column
                        prop="sitename"
                        label="Site"
                    ></el-table-column>
                    <el-table-column
                        prop="connectionstatusstring"
                        label="Connection Status"
                    ></el-table-column>
                    <el-table-column
                        prop="runningstatusstring"
                        label="Running Status"
                    ></el-table-column>
                    <el-table-column
                        prop="timeelapsed"
                        label="Time Elapsed"
                    ></el-table-column>
                    <el-table-column
                        prop="newfilecount"
                        label="New Data Files"
                    ></el-table-column>
                    <el-table-column
                        prop="alarmstatusstring"
                        label="Active Alarm Status"
                    ></el-table-column>
                    <el-table-column
                        prop="location"
                        label="Location"
                    ></el-table-column>
                    <el-table-column
                        prop="lastupdated"
                        label="Last Updated"
                        :formatter="formatter"
                    ></el-table-column>
                </el-table>

                <h4 class="display-group">Alarm/Warning Status</h4>
                <el-table
                    :data="item.content.channelStatus.alarmItemsList"
                    border
                    style="width: 100%"
                >
                    <el-table-column
                        prop="channelid"
                        label="Location ID"
                    ></el-table-column>
                    <el-table-column
                        prop="lowalarmcount"
                        label="Low Alarm"
                    ></el-table-column>
                    <el-table-column
                        prop="highalarmcount"
                        label="High Alarm"
                    ></el-table-column>
                    <el-table-column
                        prop="lowwarningcount"
                        label="Low Warning"
                    ></el-table-column>
                    <el-table-column
                        prop="highwarningcount"
                        label="High Warning"
                    ></el-table-column>
                    <el-table-column
                        prop="exceededlimitcount"
                        label="Limit Exceeded"
                    ></el-table-column>
                    <!-- <el-table-column prop="" label="Reset Active">
                    </el-table-column> -->
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </el-card>
</template>

<i18n>
{
    "en-US": {

    },
    "zh-CHS": {

    }
}
</i18n>

<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import RealTimeInteractionManager from "@/components/managers/RealTimeInteractionManager";
import { groupBy, eventHub, NvhType } from "@/helpers";

@Component({
    name: "RCMLiveStatus",
    watch: {},
})
export default class RCMLiveStatus extends Vue {
    @Prop()
    clientInfo;

    refreshData = false;

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    frame = undefined;

    triggerOn = false;
    outputOn = false;

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    signals = undefined;

    defaultProps = {
        children: "children",
        label: "name",
    };

    displayingSignalId = [];
    displayChanged = false;

    // vue-convert: This property will initialized in data() method, with `this` reference.
    chartOptionTimeValue = undefined;

    // vue-convert: This property will initialized in data() method, with `this` reference.
    chartOptionValueLog = undefined;

    chartType = {
        TimeStreams: "TimeStreams",
        TimeBlocks: "TimeBlocks",
        APS: "APS",
    };

    data() {
        return {
            frame: undefined,
            signals: undefined,
            chartOptionTimeValue: this.getChartOption("value", "value"),
            chartOptionValueLog: this.getChartOption("value", "log"),
        };
    }

    formatter(row, column) {
        return this.FormatDateTime(row.lastupdated);
    }

    ExecControlCommand(cmd) {
        RealTimeInteractionManager.ExecControlCommand(this.clientInfo, cmd);
    }

    TriggerChange(on) {
        console.log(`TriggerChange: ${on}`);
        RealTimeInteractionManager.ExecControlCommand(this.clientInfo, 20, undefined, undefined, on);
    }

    OutputChange(on) {
        console.log(`OutputChange: ${on}`);
        RealTimeInteractionManager.ExecControlCommand(this.clientInfo, 21, undefined, undefined, on);
    }

    GetSignalList() {
        RealTimeInteractionManager.GetSignalList(this.clientInfo);
    }

    refreshFrame(frame) {
        if (frame) {
            this.frame = frame.framesList;

            console.log(frame);

            if (this.signals === undefined) {
                if (frame.signalsList) {
                    this.signals = { signalsList: frame.signalsList };
                }
            }

            this.refreshChart();
        }
    }

    refreshSignalList(signals) {
        this.signals = signals;

        console.log(signals);
    }

    getChartOption(xAxisType, yAxisType) {
        const option = {
            xAxis: {
                type: xAxisType, // "time", //"value",
                min: "dataMin",
                // min: function (val) {
                //     return val.min - (val.max - val.min) * 0.2;
                // },
                max: "dataMax",
                // max: function (val) {
                //     return val.max + (val.max - val.min) * 0.2;
                // },
                // scale: true,
                position: "bottom",
                axisLabel: {
                    formatter: this.valueFormat,
                },
            },
            yAxis: {
                // type: "log",
                type: yAxisType, // "value",
                // min: "dataMin",
                min: function(val) {
                    return val.min - (val.max - val.min) * 0.2;
                },
                // max: "dataMax",
                max: function(val) {
                    return val.max + (val.max - val.min) * 0.2;
                },
                // scale: true,
                axisLabel: {
                    formatter: this.valueFormat,
                },
                // splitNumber: 10,

                // log axis display bugs:
                // https://github.com/apache/incubator-echarts/issues/8041
                // https://github.com/apache/incubator-echarts/issues/8014
            },
            tooltip: {
                trigger: "axis",
            },
            toolbox: {
                show: true,
                feature: {
                    dataZoom: {
                        yAxisIndex: "none",
                    },
                    dataView: { readOnly: false },
                    magicType: { type: ["line", "bar"] },
                    restore: {},
                    saveAsImage: {},
                },
            },
            // series: [{
            //     data: [],
            //     type: "line",
            //     // smooth: true
            // }],
            // dataZoom: [
            //     {
            //         type: "slider",
            //         show: true,
            //         xAxisIndex: [0],
            //         // start: 1,
            //         // end: 35
            //     },
            //     {
            //         type: "slider",
            //         show: true,
            //         yAxisIndex: [0],
            //         // left: "93%",
            //         // start: 29,
            //         // end: 36
            //     },
            //     // {
            //     //     type: "inside",
            //     //     xAxisIndex: [0],
            //     //     start: 1,
            //     //     end: 35
            //     // },
            //     // {
            //     //     type: "inside",
            //     //     yAxisIndex: [0],
            //     //     start: 29,
            //     //     end: 36
            //     // }
            // ],
        };

        // console.log(option);

        return option;
    }

    refreshChart() {
        const signals = this.displayingSignalId.map((p) => this.frame.signalsList.find((s) => s.id === p)).filter((p) => p !== undefined);
        // console.log(signals);

        if (signals.length === 0) {
            return;
        }

        for (const chart of this.Charts) {
            try {
                // console.log(chart);
                const chartType = chart.$attrs.chartType;
                // console.log(chartType);

                const signalList = signals.filter((p) => this.getSignalChartType(p) === chartType);

                // if (chartType === this.chartType.TimeStreams) {
                //     signalList = signals.filter(p => p.group === "Time Streams");
                // } else if (chartType === this.chartType.TimeBlocks) {
                //     signalList = signals.filter(p => p.group === "Time Blocks");
                // } else if (chartType === this.chartType.APS) {
                //     signalList = signals.filter(p => p.group === "Auto-Power Spectra");
                // } else {
                //     throw new Error();
                // }

                let oldSeries;
                if (this.displayChanged === true) {
                    const old = chart.chart.getOption();
                    oldSeries = old.series;
                    // console.log(oldSeries);
                }

                if (signalList.length > 0) {
                    const sig = signalList[0];

                    const series = signalList.map((p) => {
                        return {
                            name: p.name,
                            type: "line",
                            showSymbol: false,
                            // hoverAnimation: false,
                            emphasis: {
                                scale: false,
                            },
                            smooth: true,
                            data: this.getSignalData(p),
                        };
                    });

                    if (oldSeries) {
                        for (const old of oldSeries) {
                            let exist = false;
                            for (const cur of series) {
                                if (old.name === cur.name) {
                                    exist = true;
                                    break;
                                }
                            }

                            if (exist) {
                                continue;
                            }

                            if (old.data && old.data.length > 0) {
                                console.log(`remove signal: ${old.name}`);
                                series.push({
                                    name: old.name,
                                    data: [],
                                });
                            }
                        }
                    }

                    // console.log(chartType);
                    // console.log(series);

                    const names = signalList.map((p) => p.name);
                    // const title = names.join(",");
                    // console.log(title);

                    chart.chart.setOption({
                        // title: {
                        //     text: `(${title})`
                        // },
                        legend: {
                            data: names,
                        },
                        xAxis: {
                            name: sig.xUnit,
                            nameLocation: "end",
                            scale: chartType === this.chartType.TimeStreams,
                        },
                        yAxis: {
                            name: sig.yUnit,
                        },
                        series: series,
                    });
                }
            } catch (ex) {
                console.error(ex);
                console.warn(signals);
            }
        }

        this.displayChanged = false;
    }

    getSignalChartType(sig) {
        if (sig.type === "Time") {
            if (sig.nvhType === NvhType.Equidistant) {
                return this.chartType.TimeStreams;
            } else if (sig.nvhType === "NonEquidistant") {
                return this.chartType.TimeBlocks;
            }
        } else if (sig.type === "Frequency") {
            if (sig.nvhType === "AutopowerSpectrum") {
                return this.chartType.APS;
            }
        }
    }

    getSignalData(sig) {
        // if (sig && sig.xdataList && sig.ydataList) {
        //     const data = [];

        //     for (const i in sig.xdataList) {
        //         data.push([sig.xdataList[i], sig.ydataList[i]]);
        //     }

        //     // console.log(data);
        //     return data;
        // }

        if (sig && sig.dataList) {
            // return sig.dataList.map(p => p.arrayList);

            const xdata = sig.dataList[0].arrayList;
            const ydata = sig.dataList[1].arrayList;
            const type = this.getSignalChartType(sig);

            if (xdata && ydata && type) {
                const data = [];

                switch (type) {
                    case this.chartType.TimeStreams:
                        for (const i in xdata) {
                            data.push([xdata[i] - sig.firstTimeValue, ydata[i]]);
                        }

                        break;

                    case this.chartType.TimeBlocks:
                        for (const i in xdata) {
                            data.push([xdata[i] - xdata[0], ydata[i]]);
                        }

                        break;

                    case this.chartType.APS:
                        for (const i in xdata) {
                            data.push([xdata[i], ydata[i]]);
                        }

                        break;

                    default:
                        console.warn(`Unhandled ${type}`);
                        break;
                }

                // console.log(data);
                return data;
            }
        }

        return [];
    }

    resizeChart() {
        for (const chart of this.Charts) {
            chart.chart.resize();
        }
    }

    refreshDisplaySignal() {
        const tree = this.$refs.signalTree;
        const selectedNodes = tree.getCheckedKeys().filter((p) => p !== undefined); // tree.getCheckedNodes();

        let changed = false;

        if (selectedNodes.length !== this.displayingSignalId.length) {
            changed = tree;
        } else {
            for (const item of selectedNodes) {
                if (this.displayingSignalId.findIndex((p) => p !== item) === -1) {
                    changed = tree;
                    break;
                }
            }
        }

        if (changed) {
            this.displayingSignalId = selectedNodes;
            this.displayChanged = true;

            console.log(selectedNodes);

            this.resizeChart();
        }
    }

    handleRefreshData(val) {
        if (val) {
            RealTimeInteractionManager.StartRefresh(this.clientInfo);
        } else {
            RealTimeInteractionManager.StopRefresh(this.clientInfo);
        }

        // this.refreshData = val;
    }

    handleNodeClick(data) {
        // console.log(data);
    }

    handleCheckChange(data, checked, indeterminate) {
        // console.log(data, checked, indeterminate);

        this.refreshDisplaySignal();
    }

    valueFormat(val, index) {
        if (!Number.isNaN(val) && val !== 0) {
            if (Math.abs(val) < 1e-4) {
                return val.toExponential();
            }

            // return val.toPrecision(4);
            return (val.toFixed(3) * 1).toString(); // call toString to remove trailing zeros
        }

        return val;
    }

    get SiteStatus() {
        if (this.frame) {
            return this.frame.map((p) => p.siteStatus);
        }

        return [];
    }

    get TestsTabs() {
        if (this.frame) {
            return this.frame.map((p) => {
                return {
                    title: p.test.name,
                    name: p.test.guid,
                    content: p,
                };
            });
        }

        return [];
    }

    get name() {
        return this.clientInfo.MachineCode + this.clientInfo.AppId;
    }

    get refreshFrameName() {
        return this.name + RealTimeInteractionManager.Names.refreshFrame;
    }

    get refreshSignalListName() {
        return this.name + RealTimeInteractionManager.Names.refreshSignalList;
    }

    get TestName() {
        if (this.frame) {
            return this.frame.test.name;
        }

        return "";
    }

    get IsRunning() {
        return this.RunStatus === "Running" || this.RunStatus === "Paused";
    }

    get RunStatus() {
        if (this.frame) {
            const status = this.frame.controlPanelStatus.runningStatus;
            switch (status) {
                case 0:
                    return "Unknown";

                case 1:
                    return "Running";

                case 2:
                    return "Paused";

                case 3:
                    return "Stopped";
            }
        }

        return "Unknown";
    }

    get TagValues() {
        if (this.frame) {
            const vcs = this.frame.controlPanelStatus.vcs;
            if (vcs) {
                return [`Level: ${vcs.level.toFixed(0)}%`, `DrivePk: ${vcs.drivePk.toFixed(3)}`, `CtrlPeak: ${vcs.ctrlPeak.toFixed(3)}`, `Frequency: ${vcs.frequency.toFixed(3)}`];
            }

            const dsa = this.frame.controlPanelStatus.dsa;
            if (dsa) {
                return [`Total elapsed: ${dsa.totalElapsed}`, `Output pk: ${dsa.outputPk}`, `Frame#: ${dsa.frame}`];
            }
        }

        return [];
    }

    get ParametersValues() {
        if (this.frame) {
            const dsa = this.frame.controlPanelStatus.dsa;
            if (dsa) {
                return [
                    `Frequency range(hz): ${dsa.frequencyRange}`,
                    `Block size/Line: ${dsa.blockSizeLine}`,
                    `Window: ${dsa.window}`,
                    `Overlap ratio: ${dsa.overlapRatio}`,
                    `Average mode: ${dsa.averageMode}`,
                    `Average number: ${dsa.averageNumber}`,
                ];
            }
        }

        return [];
    }

    get TriggerOn() {
        if (this.frame) {
            const dsa = this.frame.controlPanelStatus.dsa;
            if (dsa) {
                return dsa.triggerOn;
            }
        }

        return undefined;
    }

    get OutputOn() {
        if (this.frame) {
            const dsa = this.frame.controlPanelStatus.dsa;
            if (dsa) {
                return dsa.outputOn;
            }
        }

        return undefined;
    }

    get ChannelStatus() {
        if (this.frame && this.frame.channelStatus) {
            const status = this.frame.channelStatus.displayValuesList;
            const data = [];

            for (const s of status) {
                data.push({
                    LocationID: s.locationid,
                    Overload: s.isoverload ? "Yes" : "No",
                    Unit: s.unit,
                    Min: s.min.toFixed(5),
                    Max: s.max.toFixed(5),
                    RMS: s.rms.toFixed(5),
                    Peak: s.peak.toFixed(5),
                });
            }

            return data;
        }

        return undefined;
    }

    get SignalTreeData() {
        if (this.signals !== undefined) {
            // return this.signals.signalsList.map(p => {
            //     return {
            //         label: p.name
            //     };
            // });
            const all = this.signals.signalsList;
            const grouped = groupBy("group", all);

            const data = [];
            for (const g in grouped) {
                data.push({
                    name: g,
                    children: grouped[g],
                });
            }

            return data;
        }

        return undefined;
    }

    get ChartTimeStreams() {
        return this.$refs.chartTimeStreams;
    }

    get ChartTimeBlocks() {
        return this.$refs.chartTimeBlocks;
    }

    get ChartAPS() {
        return this.$refs.chartAPS;
    }

    get Charts() {
        return [this.ChartTimeStreams, this.ChartTimeBlocks, this.ChartAPS];
    }

    created() {
        console.log("created ClientLiveStatus");
        eventHub.$on(this.refreshFrameName, this.refreshFrame);
        eventHub.$on(this.refreshSignalListName, this.refreshSignalList);
    }

    destroyed() {
        RealTimeInteractionManager.StopRefresh(this.clientInfo);
        eventHub.$off(this.refreshFrameName, this.refreshFrame);
        eventHub.$off(this.refreshSignalListName, this.refreshSignalList);
        console.log("destroyed ClientLiveStatus");
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}

.tag-group {
    margin-top: 2ex;
}

.display-group {
    padding: 2ex 0;
}
</style>
