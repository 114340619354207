<template>
    <div v-loading="loading">
        <div v-if="IsTeamGroups">
            <router-view />
        </div>

        <template v-else>
            <!-- Team Page (No Team, No Invites) -->
            <div
                class="ci-card"
                v-if="$store.getters.NoTeam && $store.getters.NoInvites"
            >
                <div class="header">
                    <span>{{ $t("CreateATeam") }}</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                </div>
                <div class="body">
                    <div>
                        <p>{{ $t("CreateTeamLabel") }}</p>
                    </div>
                    <div class="flex-content-end">
                        <button
                            class="ci-btn"
                            @click="createTeamDialogVisible = true"
                        >
                            {{ $t("CreateTeam") }}
                        </button>

                        <button
                            class="ci-btn"
                            @click="joinTeamDialogVisible = true"
                        >
                            {{ $t("JoinTeam") }}
                        </button>
                    </div>
                </div>
            </div>

            <!-- Team Page (No Team, Yes Invites) -->
            <div v-else-if="$store.getters.NoTeam && !$store.getters.NoInvites">
                <JoinTeamCard />
            </div>

            <div v-else>
                <router-view />
            </div>

            <!-- Team Creation Form -->
            <el-dialog
                custom-class="create-team-dialog"
                :visible.sync="createTeamDialogVisible"
            >
                <!-- Create Team Page -->
                <div class="ci-card">
                    <div class="header">
                        <span>{{ $t("TeamCreationForm") }}</span>
                        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                    </div>
                    <div class="body">
                        <div>
                            <div class="display-flex align-items-center">
                                <h4 class="m-r-1-em">{{ $t("TeamName") }}*</h4>
                                <el-input
                                    v-model="formTeam.name"
                                    class="flex-auto width-auto"
                                ></el-input>
                            </div>
                            <div
                                class="display-flex flex-direction-column m-t-1-em"
                            >
                                <div>
                                    <h4>{{ $t("InviteTeamMembers") }}</h4>
                                    <p>
                                        {{
                                            $t(
                                                "EnterTheEmailAddressesOfYourTeamMembersSeparatedByCommas"
                                            )
                                        }}
                                    </p>
                                    <p>
                                        {{
                                            $t(
                                                "InvitesWillBeSentToTheirEmailAddresses"
                                            )
                                        }}
                                    </p>
                                </div>
                                <el-input
                                    type="textarea"
                                    v-model="formTeam.invites"
                                    placeholder="alice@acme.com, bob@acme.com, carl@acme.com"
                                    style="padding-top: 0.5em"
                                ></el-input>
                            </div>
                            <div class="m-t-1-em">
                                <span
                                    class="ci-color-success"
                                    v-if="createTeamSucceed"
                                    >Success: team created</span
                                >
                                <span
                                    class="ci-color-danger"
                                    v-else-if="createTeamError"
                                >
                                    {{ createTeamError }}
                                </span>
                            </div>
                        </div>
                        <div class="flex-content-end m-t-1-em">
                            <button class="ci-btn" @click="createTeam">
                                {{ $t("Submit") }}
                            </button>
                        </div>
                    </div>
                </div>
            </el-dialog>

            <!-- Team Join Form -->
            <el-dialog
                custom-class="create-team-dialog"
                :visible.sync="joinTeamDialogVisible"
            >
                <div class="ci-card">
                    <div class="header">
                        <span>{{ $t("JoinTeam") }}</span>
                    </div>
                    <div class="body">
                        <div>
                            <div class="display-flex flex-direction-column">
                                <div>
                                    <h4>
                                        {{
                                            $t(
                                                "PleaseEnterTheEmailAddressOfTheTeamAdministrator"
                                            )
                                        }}
                                    </h4>
                                </div>
                                <el-input
                                    v-model="teamAdminEmail"
                                    style="padding-top: 0.5em"
                                ></el-input>
                            </div>
                        </div>
                        <div class="flex-content-end m-t-1-em">
                            <button
                                class="ci-btn"
                                @click="requestToJoinTheTeam"
                            >
                                {{ $t("RequestToJoinTheTeam") }}
                            </button>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </template>
    </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
// import { MessageBox } from "element-ui";
import { HttpStatusCode, IsValidEmail } from "node-share";
import axios from "axios";
import JoinTeamCard from "@/components/cards/JoinTeamCard.vue";
import store from "@/store";

@Component({
    name: "Team",
    components: {
        JoinTeamCard,
    },
})
export default class Team extends Vue {
    loading = false;

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    team = undefined;

    invites = [];

    formTeam = {
        name: "",
        invites: "",
    };

    createTeamDialogVisible = false;
    createTeamError = "";
    createTeamSucceed = false;

    joinTeamDialogVisible = false;
    teamAdminEmail = "";

    get IsTeamGroups() {
        if (this.$route.path.startsWith("/team/groups")) {
            return true;
        }

        store.commit("updateBreadcrumb", ["sidebar.team.members"]);
        return false;
    }

    data() {
        return {
            team: undefined,
        };
    }

    created() {
        store.commit("updateBreadcrumb", ["sidebar.team.members"]);
    }

    async createTeam() {
        const r = await axios.post("/api/v1/teams", this.formTeam);

        if (r.status === HttpStatusCode.Created) {
            // refresh
            store.dispatch("refreshUserInfo");

            this.createTeamDialogVisible = false;
        } else {
            this.$message({
                message: r.data.msg,
                type: "error",
            });
        }
    }

    async requestToJoinTheTeam() {
        if (!IsValidEmail(this.teamAdminEmail)) {
            this.$message({
                type: "warning",
                message: this.$t("PleaseEnterAValidEmailAddress"),
            });

            return;
        }

        const r = await axios.post("/api/v1/teams/request", {
            email: this.teamAdminEmail,
        });

        if (r.status === HttpStatusCode.OK) {
            this.joinTeamDialogVisible = false;

            this.$message({
                type: "success",
                message: this.$t("TheRequestWasSuccessfullySentPleaseWaitForTheAdministratorSConfirmation"),
            });
        } else {
            this.$message({
                message: r.data.msg,
                type: "error",
            });
        }
    }
}
</script>

<style lang="scss">
.create-team-dialog {
    width: 500px;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 0px;
        margin: 0px;
    }

    .tags {
        display: flex;
        flex-wrap: wrap;

        .tag {
            margin-left: 0;
            margin-bottom: 10px;
            flex: 0 0 100%;
        }
    }
}
</style>
