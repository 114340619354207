
import { Vue, Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import axios from "axios";

import { ConvertTo, HttpStatusCode, UpdateQueryString, Distinct } from "node-share";
import { IShareOption, IMember, AddToRefreshList, GetTestStatus, GetMembers, TimeFromNow, ITest } from "@/helpers";
import EditShareDialog from "@/components/dialogs/EditShareDialog.vue";
import { SoftwareMode, CloudDataType } from "socket/common";
import store from "@/store";

type IRow = ITest;

interface IFilter {
    filterTestType: SoftwareMode[];
    filterCreateBy: string;
    filterSearchInput: string;
}

interface IColumn {
    className?: string;
}

interface ICell {
    rowIndex: number;
    columnIndex: number;
    row: IRow;
    column: IColumn;
}

@Component({
    name: "TestTableV2",
    components: {
        EditShareDialog,
    },
})
export default class TestTableV2 extends Vue {
    @Prop()
    hideFilter?: boolean;

    @Prop()
    hideCheckboxColumn?: boolean;

    @Prop()
    maxRows?: number;

    tests: ITest[] = [];
    members: IMember[] = [];

    isLoading = true;
    currentPage = 1;
    pageTotal = 0;
    pageSize = 10;

    // vue-convert: This property will initialized in data() method, with `this` reference.
    page = undefined;

    // vue-convert: This property will initialized in data() method, with `this` reference.
    tableFilters = undefined;

    filterDrawerVisible = false;
    filterDrawerDirection = "ltr";
    filterDrawerActiveNames = [];
    isFilterTestTypeAll = true;
    isFilterTestTypeIndeterminate = false;

    filterTestTypeCandidates = [
        {
            value: SoftwareMode.SPIDER_DSA,
            label: "DSA",
        },
        {
            value: SoftwareMode.SPIDER_VCS,
            label: "VCS",
        },
        {
            value: SoftwareMode.SPIDER_THV,
            label: "THV",
        },
        {
            value: SoftwareMode.EDC,
            label: "EDC",
        },
    ];

    filterTestType: SoftwareMode[] = [];
    filterCreateBy = "";
    filterSearchInput = "";

    appliedFilters: IFilter = {
        filterTestType: [],
        filterCreateBy: "",
        filterSearchInput: "",
    };

    selectedTests: ITest[] = [];

    search = "";

    editSharingFormVisible = false;
    editSharingTest?: ITest;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    timer?: any;

    requestRefresh = false;
    lastRefreshTime = 0;

    isDeleting = false;
    // ------

    get Loading() {
        return this.lastRefreshTime === 0 || this.isDeleting;
    }

    get AllTestTypeValues() {
        return this.filterTestTypeCandidates.map((p) => p.value);
    }

    get FilterCreateByCandidates() {
        const candidates: {
            label: string,
            value: string
        }[] = [{
            label: this.$t("AnyUser").toString(),
            value: "",
        }];

        candidates.push(...this.members.map(p => {
            return {
                label: p.name || p.email || "",
                value: p.user,
            };
        }));

        return candidates;
    }

    get SM() {
        // filter test type: ALL
        if (this.appliedFilters.filterTestType.length === this.filterTestTypeCandidates.length) {
            // old logic
            // if (this.testType) {
            //     if (this.testType.value !== SoftwareMode.ALL) {
            //         return this.testType.value;
            //     }
            // }
        } else {
            let temp = "";
            for (const t of this.appliedFilters.filterTestType) {
                if (temp === "") {
                    temp = t.toString();
                } else {
                    temp += `&SoftwareMode=${t}`;
                }
            }

            return temp;
        }

        return undefined;
    }

    get SkipCount() {
        if (this.DisplayPagination) {
            if (this.currentPage < 2) {
                return 0;
            }
            return this.pageSize * (this.currentPage - 1);
        } else {
            return 0;
        }
    }

    get SortCondition() {
        return "Status -RunDateTime -updatedAt";
    }

    get UrlCount() {
        let url = "/api/v2/tests/count?SharedTo";
        url = UpdateQueryString("sort", this.SortCondition, url);
        return this.applyFilter(url);
    }

    get Limit() {
        if (this.DisplayPagination) {
            return this.pageSize;
        }

        return this.maxRows;
    }

    get UrlData() {
        let url = "/api/v2/tests?SharedTo";

        url = UpdateQueryString("sort", this.SortCondition, url);
        url = UpdateQueryString("skip", this.SkipCount.toString(), url);

        if (this.Limit) {
            url = UpdateQueryString("limit", this.Limit.toString(), url);
        }

        return this.applyFilter(url);
    }

    get DisplayPagination() {
        if (this.maxRows) {
            return false;
        }

        return true;
    }

    get SharedOption() {
        const option: IShareOption = {
            testId: this.editSharingTest?._id,
        };

        return option;
    }

    get SharedTitle() {
        // return this.editSharingTest?.TestName;
        return this.$t("ShareTest").toString();
    }

    get Tests() {
        if (!this.search) {
            return this.tests;
        }

        return this.tests.filter(p => p.TestName.includes(this.search));
    }

    get ShowBottomButton() {
        if (this.hideCheckboxColumn) {
            return false;
        }

        if (this.selectedTests.length < 1) {
            return false;
        }

        return true;
    }

    data() {
        return {
            page: CloudDataType.TestProperties | CloudDataType.V2Test,
            tableFilters: [
                {
                    text: ConvertTo<string>(this.$t("Running")),
                    value: "Running",
                },
                {
                    text: ConvertTo<string>(this.$t("Stopped")),
                    value: "Stopped",
                },
                {
                    text: ConvertTo<string>(this.$t("Unknown")),
                    value: "Unknown",
                },
            ],

            editSharingTest: undefined,
            hoveredRows: undefined,
        };
    }

    mounted() {
        // select all by default
        this.appliedFilters.filterTestType = this.AllTestTypeValues;

        AddToRefreshList(this, (dataType, changeType, gm) => {
            if (dataType === CloudDataType.V2Test) {
                if (!gm) {
                    return;
                }

                if (this.tests && this.tests.length > 0) {
                    const testGuid = gm.getStringP1();
                    const test = this.tests.find(p => p.TestId === testGuid);

                    if (test) {
                        const state = gm.getInt32P1();
                        if (test.TestState !== state) {
                            // TODO: no need refresh all tests
                            this.refresh(true);
                        } else {
                            Vue.set(test, "updatedAt", new Date(gm.getInt64P1()));
                            this.updateTimeFromNow();
                        }
                    } else {
                        this.refresh(true);
                    }
                }
            }
        });

        // refresh
        this.refreshTableData();

        this.startRefreshTimer();
    }

    activated() {
        // refresh
        this.refreshTableData();
    }

    destroyed() {
        this.stopRefreshTimer();
    }

    async refresh(request = false) {
        if (request) {
            this.requestRefresh = true;
        }

        if (this.isLoading) {
            // console.log("ignore when loading");
            return;
        }

        if (!this.requestRefresh) {
            // console.log("ignore when no request");
            return;
        }

        const now = Date.now();
        const ms = now - this.lastRefreshTime;
        if (ms < 3000) {
            // console.log(`ignore when ${ms} < 3000`);
            return;
        }

        await this.internalFetchCount();

        this.requestRefresh = false;
    }

    startRefreshTimer() {
        this.stopRefreshTimer();
        this.timer = setInterval(async () => {
            await this.refresh();
            this.updateTimeFromNow();
        }, 5000);
    }

    stopRefreshTimer() {
        clearInterval(this.timer);
    }

    handleTestTypeCheckAllChange(val: boolean) {
        this.filterTestType = val ? this.AllTestTypeValues : [];
        this.isFilterTestTypeIndeterminate = false;
    }

    handleCheckedTestTypeChange(value: unknown[]) {
        const checkedCount = value.length;
        this.isFilterTestTypeAll = checkedCount === this.filterTestTypeCandidates.length;
        this.isFilterTestTypeIndeterminate = checkedCount > 0 && checkedCount < this.filterTestTypeCandidates.length;
    }

    showFilters() {
        // restore
        this.filterTestType = this.appliedFilters.filterTestType;
        this.filterCreateBy = this.appliedFilters.filterCreateBy;
        this.filterSearchInput = this.appliedFilters.filterSearchInput;

        this.filterDrawerVisible = true;
    }

    applyFilters() {
        this.appliedFilters = {
            filterTestType: this.filterTestType,
            filterCreateBy: this.filterCreateBy,
            filterSearchInput: this.filterSearchInput,
        };

        this.refreshTableData();

        this.filterDrawerVisible = false;
    }

    refreshTableData() {
        // console.log("refreshTableData");

        // #57792: reset current page
        this.currentPage = 1;

        this.internalFetchCount();
    }

    handleClose(done: () => void) {
        done();
    }

    async internalFetchCount() {
        this.isLoading = true;

        let loadingFailed = false;
        try {
            // console.log("refresh");
            const res = await axios.get(this.UrlCount);

            if (res.status === HttpStatusCode.OK) {
                const d = res.data.data;
                this.pageTotal = d.total;
                await this.fetchData();
            } else {
                loadingFailed = true;
            }
        } catch (ex) {
            console.log(ex);
            loadingFailed = true;
        } finally {
            this.lastRefreshTime = Date.now();
        }

        if (loadingFailed) {
            this.isLoading = false;
        }
    }

    async fetchData() {
        this.isLoading = true;

        try {
            const res = await axios.get(this.UrlData);
            if (res.status === HttpStatusCode.OK) {
                this.tests = res.data.data;

                this.updateTimeFromNow();

                if (this.members.length === 0) {
                    this.members = await GetMembers();
                }
            }
        } catch (ex) {
            console.error(ex);
        }

        this.isLoading = false;
    }

    handleClickViewLiveStatus(row: IRow) {
        this.$router.push({
            name: "RunHistories",
            query: {
                TestGUID: row.TestId,
                // Sandeep: It would be good if we add the Run History below the Live Status
                // showRunHistories: false
            },
        });
    }

    handleClickViewRunLogAndDIO(test: ITest) {
        const testGUID = test.TestId;
        if (test.SoftwareMode === SoftwareMode.EDC) {
            this.$router.push({
                name: "EDC",
                params: {
                    testGUID: testGUID,
                    TestName: test.TestName,
                },
                query: {
                    testGUID: testGUID,
                    TestName: test.TestName,
                },
            });
        }
    }

    handleClickViewRunHistories(test: ITest) {
        this.$router.push({
            name: "RunHistories",
            query: {
                TestGUID: test.TestId,
                TestName: test.TestName,
                showLiveStatus: "false",
            },
        });
    }

    handleClickAddToDashboard(row: IRow) {
        store.dispatch("removeTestFromDashboardRemovedTests", row.TestId);
    }

    selectionChange(val: ITest[]) {
        // restore selection
        if (this.selectedTests.length > 0 && val.length === 0 && this.selectedTests.some(p => !this.tests.includes(p))) {
            const rows = this.tests.filter(p => this.selectedTests.some(t => t.TestId === p.TestId));

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const table = this.$refs.testTable as any;

            if (rows.length === this.tests.length) {
                table.toggleAllSelection();
            } else {
                rows.forEach(row => {
                    table.toggleRowSelection(row);
                });
            }

            return;
        }

        // console.info(val);
        this.selectedTests = val;
    }

    async removeFromCloud(...tests: ITest[]) {
        try {
            // console.log(this.selectedTests);

            if (tests.length === 0) {
                if (!this.selectedTests || this.selectedTests.length < 1) {
                    this.$alert(this.$t("please_select_at_least_one_test") as string, {
                        type: "warning",
                    });
                    return;
                }

                tests = this.selectedTests.filter(p => this.isTestOwner(p));
            }

            if (tests.length === 0) {
                return;
            }

            const testsName = tests.map((p) => p.TestName).join(", ");

            const d = await this.$confirm(this.$t("DeleteWarning").toString().replace("{0}", testsName), this.$t("Warning").toString(), {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("ok").toString(),
                cancelButtonText: this.$t("Cancel").toString(),
                type: "warning",
            });

            if (d === "confirm") {
                try {
                    this.isDeleting = true;

                    if (tests.length === 1) {
                        for (const test of tests) {
                            try {
                                await axios.delete(`/api/v2/tests/${test._id}`);
                            } catch (ex) {
                                console.error(ex);
                            }
                        }
                    } else {
                        await axios.delete("/api/v2/tests", {
                            data: {
                                tests: tests.map(p => p._id),
                            },
                        });
                    }
                } catch (ex) {
                    console.error(ex);
                } finally {
                    this.isDeleting = false;
                }

                // refresh
                this.applyFilters();

                this.$emit("deleted");
            }

            // this.$message({
            //     type: "success",
            //     message: "Test deleted!"
            // });
        } catch (ex) {
            console.warn(ex);
        }
    }

    removeTest(test: ITest) {
        const index = this.tests.indexOf(test);
        if (index > -1) {
            this.tests.splice(index, 1);
        }
    }

    handleSizeChange(val: number) {
        this.pageSize = val;
        this.fetchData();
    }

    handleCurrentChange(val: number) {
        this.currentPage = val;
        this.fetchData();
    }

    filterTest(value: string, row: IRow) {
        // console.log(value, row);
        return GetTestStatus(row.TestState, row.updatedAt) === value;
    }

    handleSearch() {
        console.log(`search by ${this.filterSearchInput}`);
        this.internalFetchCount();
    }

    applyFilter(url: string) {
        if (this.SM) {
            url = UpdateQueryString("SoftwareMode", this.SM, url);
        }

        if (this.appliedFilters.filterSearchInput) {
            url = UpdateQueryString("searchInput", this.appliedFilters.filterSearchInput, url);
        }

        if (this.appliedFilters.filterCreateBy) {
            url = UpdateQueryString("createdBy", this.appliedFilters.filterCreateBy, url);
        }

        return url;
    }

    viewTest(test: ITest) {
        // console.log(test);

        this.$router.push({
            name: "DataViewer",
            query: {
                TestId: test.TestId,
                TestName: test.TestName,
            },
        });
    }

    getSharedMembers(row: IRow) {
        if (row.SharedTo && row.SharedTo.length > 0) {
            const items = row.SharedTo.filter(p => p);
            return Distinct(items);
        }

        return [];
    }

    hasShared(row: IRow) {
        return this.getSharedMembers(row).length > 0;
    }

    getShareCellText(row: IRow) {
        // console.log(row);

        const items = this.getSharedMembers(row);

        // console.log(items);

        if (items.length > 0) {
            return (this.$t("SharedWithNMembers") as string).replace("{0}", items.length.toString());
        }

        return this.$t("Private");
    }

    isTestOwner(test: ITest) {
        return test.userId === store.getters.UserId;
    }

    editSharing(test: ITest) {
        this.editSharingTest = test;
        this.editSharingFormVisible = true;
    }

    onEditShareFinished(changed: boolean) {
        this.editSharingFormVisible = false;

        if (changed) {
            this.fetchData();
        }
    }

    handleDelete(test: ITest) {
        this.removeFromCloud(test);
    }

    updateTimeFromNow() {
        if (this.tests && this.tests.length > 0) {
            for (const test of this.tests) {
                Vue.set(test, "TimeFromNow", TimeFromNow(test.updatedAt));
            }
        }
    }

    cellStyle(/* cell: ICell */) {
        return {
            // "text-align": "center",
            "white-space": "nowrap",
        };
    }

    cellClassName(cell: ICell) {
        const names = [];

        if (cell.column.className === "test-status") {
            // Status is Running, then use green (bold)
            if (cell.row.TestState === 1) {
                names.push(`test-status-${cell.row.TestState}`);
            }
        }

        return names.join(" ");
    }

    headerRowClassName() {
        if (this.hideCheckboxColumn) {
            return "cloud-table-header";
        }

        if (this.selectedTests.length === this.Tests.length) {
            return "cloud-table-header";
        }

        return "hoverable-table-header";
    }

    rowClassName(r: { row: IRow, rowIndex: number }) {
        if (this.selectedTests.includes(r.row)) {
            return "";
        }

        return this.hideCheckboxColumn ? "hoverable-table-row-without-checkbox" : "hoverable-table-row";
    }

    rowClick(row: IRow) {
        // Entire entry is clickable. Clicking on it will take user to the Test status page
        this.viewTest(row);
    }
}
