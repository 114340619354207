
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "DataCard",
})
export default class DataCard extends Vue {
    @Prop({ default: "" })
    title!: string;

    collapsed = false;

    onCollapse(e: PointerEvent) {
        if ((e.target as Element)?.tagName?.toLowerCase() === "span") {
            return;
        }

        this.collapsed = !this.collapsed;
    }
}
