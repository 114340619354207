
import { ITreeNode, ParseColor } from "@/helpers";
import { ILanguageString, TryGetBooleanFromAttributes, TryGetInt64FromAttributes } from "socket/common";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "Tree",
})
export default class Tree extends Vue {
    @Prop()
    data: unknown;

    buildTree(data: ITreeNode<ILanguageString>) {
        let id = 1;

        const copy = (src: ITreeNode<ILanguageString>[], to: ITreeNode[]) => {
            for (const node of src) {
                const treeNode: ITreeNode = {
                    Name: this.GetString(node.Name),
                    Nodes: [],
                    Id: `${id++}_${node.Id}`,
                    Attributes: node.Attributes,
                };

                copy(node.Nodes, treeNode.Nodes);

                to.push(treeNode);
            }
        };

        const nodes: ITreeNode[] = [];

        copy(data.Nodes, nodes);

        return nodes;
    }

    getTreeNodeClass(node: unknown, data: ITreeNode) {
        const names: string[] = [];
        if (data.Attributes?.length > 0) {
            const selected = TryGetBooleanFromAttributes(data.Attributes, "IsSelected");
            if (selected) {
                names.push("glassy-text");
            }
        }

        return names.join(" ");
    }

    getTreeNodeStyle(node: unknown, data: ITreeNode) {
        const styles: string[] = [];
        if (data.Attributes?.length > 0) {
            // console.info(data);

            const color = TryGetInt64FromAttributes(data.Attributes, "TextColor");
            if (color) {
                styles.push(`color: ${ParseColor(color)}`);
            }

            const bold = TryGetBooleanFromAttributes(data.Attributes, "IsTextBold");
            if (bold) {
                styles.push("font-weight: bold");
            }
        }

        return styles.join(";");
    }
}
