/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/i18n/i18n.en-US.ts
 * @create:      2019-01-09 10:45:54.872
 * @modify:      2023-04-11 09:39:03.813
 * @version:     0.1.1
 * @times:       166
 * @lines:       459
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

// import enLocale from "element-ui/lib/locale/lang/en";

export default {
    // ...enLocale,
    language: "Language",
    date_time_format: "Date/Time Format",
    example: "Example",
    operation: "Operation",
    detail: "Detail",
    delete: "Delete",
    user_name: "User Name",
    email_id: "Email ID",
    register: "Register",
    login: "Login",
    name: "Name",
    email: "Email",
    company: "Company",
    phone_number: "Phone Number",
    password: "Password",
    confirm_password: "Confirm Password",
    run_histories: "Run History", // "Run Histories",
    run_logs: "Run Log",
    account: {
        free: "Free Account",
        plus: "Plus Account",
        corporate: "Corporate Account",
    },
    profile: "Profile",
    myAccount: "My Account",
    team: "Team",
    uploads: "Uploads",
    messages: "Messages",
    logout: "Sign Out",
    sidebar: {
        dashboard: "Dashboard",
        view_tests: "View Tests", // "Test Management",
        uploaded_files: "Uploaded Files",
        software_info: "Software",
        license_systems: "license & systems",
        system_information: "System Information",
        system_management: "System Management",
        account_management: "Account Management",
        settings: "Settings",
        edc: "EDC",
        team: {
            members: "Members",
            groups: "Groups",
            settings: "Team Settings",
        },
    },
    view: "View",
    sys_info: "System Information",
    device_info: "Device Information",
    license_info: "License Information",

    license: {
        hardware_device: "Hardware device",
        serial_number: "Serial number",
        license_type: "License type",
        vsn_info: "VSN Information",
        vsn_activated: "VSN Activated",
        vsn: "Virtual Serial Number",
        total_license_count: "Total license count",
        available_license_count: "Available license count",
        license_key_info: "License key information",
        software_renewal_expiration: "Software renewal expiration",
        software_activation_expiration: "Software activation expiration",
        software_renewal_expiration_note: "Update possible to all software versions preceding this date.",
        software_activation_expiration_note: "Software can be used with this LK until this date.",
        installed_edm_software_options: "Installed EDM software options:",
    },
    message: {
        email_invalid: "Email is not valid",
        password_diff: "Entered passwords differ",
        password_length_limit: "Password must be at least 6 characters long less than 30 characters long",
        user_name_length_limit: "UserName must be at least 4 characters long less than 16 characters long",
        pwdChart_limit: "The password can only contain Numbers, letters, and!@ # $% & * () special characters",
        user_name_characters_limit: "The UserName can only contain Numbers, letters, and!@ # $% & * () special characters",

    },
    yes: "Yes",
    no: "No",
    pc_info: "PC Information",
    edm_info: "EDM Information",
    hardware_system: "Hardware System",
    hardware_device: "Hardware Device",
    add: "Add",
    ok: "OK",
    cancel: "Cancel",
    gender: "Gender",
    male: "male",
    female: "female",
    reset: "Reset",
    get_started: "Get Started",
    month: "Month(s)",
    year: "Year(s)",
    Standard: "Standard",
    Premium: "Premium",
    Save: "Save",
    home: "Home",

    column_name: {
        pc_name: "PC Name",
        database_name: "Database Name",
        test_name: "Test Name",
        test_type: "Test Type",
        test_description: "Test Description",
        number_of_channels: "Number of Channels",
        test_status: "Test Status",
        time_elapsed: "Time Elapsed",
        last_updated: "Last Updated",
        actions: "Actions",
        run_count: "Run Count",
    },

    view_live_status: "View Live Status",
    view_run_histories: "View Run History",
    ViewRunLogAndDIO: "Details",
    remove_from_dashboard: "Remove from Dashboard",
    remove_from_cloud: "Remove from Cloud",
    current_status: "Current Status",

    edm_created: "EDM Created",
    edc_created: "EDC Created",
    current_edm: "Current EDM",
    current_edc: "Current EDC",
    pc: "PC",
    database: "Database",
    control_panel: "Control Panel",

    run_name: "Run Name",
    start_time: "Start Time",
    end_time: "End Time",
    run_description: "Run Description",
    alarm: "Alarm",
    end_condition: "End Condition",

    view_run_log: "View Run Log",
    create_report: "Create Report",
    remove_this_run: "Remove this Run",

    run_history: "Run History",
    run_duration: "Run Duration",

    run_log: "Run Log",

    system_information: "System Information",
    spider_system_name: "Spider System Name",
    spider_serial_numbers: "Spider Serial Number(s)",

    run_log_columns: {
        absolute_time: "Absolute Time",
        test_time: "Test Time",
        event_type: "Event Type",
        elapsed: "Elapsed",
        frequency: "Freq. (Hz)",
        control_target_rms: "Control/Target RMS",
        control_target_peak: "Control/Target Peak",
        event_data: "Event Data",
        event_source: "Event Source",
    },

    hardware_information: "Hardware Information",
    account_overview: "Account Overview",

    number_of_live_running_tests: "Number of Live / Running Tests",
    total_number_of_tests_on_the_cloud: "Total number of tests on the Cloud",
    recent_tests: "Recent Tests",
    no_tests: "Currently there are no tests",

    filter_results_using_search_terms: "Filter Results using Search Terms",
    search: "Search",

    configured_spider_systems: "Configured Spider Systems:",
    spider_hardware_information: "Spider Hardware Information:",

    shipping_address: "Shipping Address",
    billing_address: "Billing Address",

    same_as_shipping_address: "Same as Shipping address",

    address: "Address",
    city: "City",
    state: "State",
    zip: "ZIP",
    country: "Country",

    update: "Update",
    permissions: "Permissions",

    remove_selected_viewers: "Remove Selected Viewers",

    upgrade_storage: "Upgrade Storage",
    current: "Current: FREE (20 MB Storage Limit)",

    please_select_at_least_one_test: "Please select at least one test.",

    Running: "Running",
    Stopped: "Stopped",
    Unknown: "Unknown",

    Updated: "Updated",
    LastUpdated: "Last updated",

    TeamMemberList: "Team Member List",
    TeamMemberListTip: "Invited members can be listed in the Team Groups. The testing results and files can be shared among different Team Groups.",
    SearchMembers: "Search members",
    NameOrEmail: "Name or email",
    InviteMembers: "Invite members",
    InviteMembersText1: "Enter the email addresses of your team members, separated by commas.",
    InviteMembersText2: "Invites will be sent to their email addresses",
    ShowDeletedUsers: "Show deleted users",

    Name: "Name",
    Status: "Status",
    Members: "Members",
    Managers: "Managers",

    ResetPassword: "Reset password",
    RemoveAdminPermissions: "Set as member",
    DeleteMember: "Remove member",
    AddAdminPermissions: "Set as admin",
    ReSendInvite: "Re-send invite",
    UndoInvite: "Undo invite",
    ReActivateMember: "Re-activate member",

    TeamGroups: "Team Groups",
    TeamGroupsTip: "The testing results and files can be shared among different Team Groups.",
    SearchGroups: "Search groups",
    GroupName: "Group name",
    CreateGroup: "Create group",
    AddMembers: "Add members",
    LeaveGroup: "Leave group",
    DeleteGroup: "Delete group",
    EditGroup: "Edit group",

    Warning: "Warning",
    AreYouSureYouWantToLeaveThisGroup: "Are you sure you want to leave this group?",
    Yes: "Yes",
    No: "No",
    DeleteGroupWarning: "Are you sure you want to delete this group? Some tests may not be visible to group members anymore",

    Edit: "Edit",
    SaveChanges: "Save changes",
    Cancel: "Cancel",

    TeamProfile: "Team Profile",
    TeamName: "Team Name",
    Update: "Update",

    GroupCreationForm: "Group Creation Form",
    InviteGroupMembers: "Invite group members",
    EnterTheEmailAddressesOfYourTeamMembersSeparatedByCommas: "Enter the email addresses of your team members, separated by commas.",
    InvitesWillBeSentToTheirEmailAddresses: "Invites will be sent to their email addresses",
    Submit: "Submit",

    CreateATeam: "Create a Team",
    CreateTeamLabel: "Create a team to set up other accounts from sharing and company user management.",
    CreateTeam: "Create Team",

    TeamCreationForm: "Team Creation Form",
    InviteTeamMembers: "Invite team members",

    Run: "Run",

    Filters: "Filters",
    TestType: "Test type",
    CreatedBy: "Created by",
    SearchTerms: "Search Terms",

    AnyUser: "Any user",
    Apply: "Apply",

    UploadedFiles: "Uploaded Files",
    Owner: "Owner",
    LastModified: "Last modified",
    Modified: "Modified",
    FileSize: "File size",
    Share: "Share",
    Sharing: "Sharing",
    Operations: "Operations",
    Delete: "Delete",

    TypeToSearch: "Type to search",
    SelectTheGroupsToBeShared: "Select the groups to be shared:",

    CheckAll: "Select all",

    Private: "Private",
    SharedWithNMembers: "Shared with {0} members",
    ShareDate: "Share date:",

    Temperature_PV: "Temperature PV: ",
    Temperature_SV: "Temperature SV: ",
    Humidity_PV: "Humidity PV: ",
    Humidity_SV: "Humidity SV: ",

    WelcomeTo: "Welcome to ",
    EDMCloud: "EDM Cloud",

    PcNameDatabaseHardware: "PC Name / Database / Hardware",
    LiveStatus: "Live Status",

    SelectSignalTitle: "Select signals to display",
    UploadTitle: "The files listed below are uploaded by the EDM or EDC software. They can be shared to the Team Group. To add or remove the sharing members, please change the Team Group setting.",
    SystemInfoTitle: "The following system information is extracted when the test data is uploaded to the cloud.",
    SystemInfos: "Systems",
    About: "About",
    LicenseTerms: "Software license terms and conditions",
    Version: "Version",
    NoData: "No Data",
    TestProperties: "Test Properties",
    Table: "Table",
    Parameters: "Parameters",
    Signals: "Signals",
    Notification: "Notification",
    Attributes: "Attributes",
    Confirm: "Confirm",
    AreYouCloseAll: "This will close all signal charts. Continue?",

    LeaveTheTeam: "Leave the team",
    AreYouSureYouWantToLeaveThisTeam: "Are you sure you want to leave this team?",

    ShareTest: "Share Test",
    EnterTheEmailAddressesOrGroupNamesBelow: "Enter the email addresses or group names below",
    Send: "Send",
    AutoRefresh: "Auto refresh",
    DeleteWarning: "Are you sure you want to delete it? Name is: <b>{0}</b>",
    DeleteFileWarning: "Are you sure you want to delete it?",
    Success: "Success",
    DeleteInvitationWarning: "Are you sure you want to delete this invitation?",
    DeleteInvitation: "Delete invitation",
    CloseDialog: "Are you sure to close this dialog?",
    CloseAllSignalCharts: "This will close all signal charts. Continue?",
    RangeSelectionError: "The minimum value selected cannot be greater than the maximum value, please select again",

    CreateAccount: "Create Account",
    SignIn: "Sign in",
    Demo: "Demo",
    RemoteOnlineAccessForYourTestingSystem: "Remote Online Access For Your Testing System",
    EdmCloudAndEdmMobileShareTheSameLogInAccount: "EDM Cloud and EDM Mobile share the same log in account",
    DownloadEdmMobileFor: "Download EDM Mobile for ",
    iOS: "iOS",
    or: " or ",
    Android: "Android",
    DownloadEdmMobileForEnd: "",

    RegisterAccount: "Register Account",
    AlreadyHaveAnAccount: "Already have an account?",
    DonTHaveAnAccount: "Don’t have an account?",
    SignUp: "Sign up",
    RememberMe: "Remember me",
    ForgotYourPassword: "Forgot your password?",
    ForgotPassword: "Forgot Password",
    EnterYourEmailAddressBelowAndWeWillSendYouPasswordResetInstructions: "Enter your email address below and we will send you password reset instructions.",

    PcMayNotBeConnected: "PC may not be connected",
    PcMayNotBeConnectedWarning: "The test status has not been updated for more than 30 minutes. Please make sure the controller software is running and connected to EDM Cloud.",
    LocationNotFound: "Location Not Found",

    DeleteAccount: "Delete Account",
    deleteYourAccount: "Delete your account",
    onceYouDeleteYourAccountThereIsNoGoingBackPleaseBeCertain: "Once you delete your account, there is no going back. Please be certain.",
    yourEmail: "Your email",
    confirmYourPassword: "Confirm your password",
    deleteThisAccount: "Delete this account",
    pleaseEnterTheEmailAddressOfTheCurrentAccount: "Please enter the email address of the current account.",

    Description: "Description",
    CreatedAt: "Created at",
    ModifiedAt: "Modified at",
    LastRunTime: "Last run time",
    SpiderSystem: "Spider system",
    CreatedVersion: "Created version",
    LastRunVersion: "Last run version",

    TwoFactorAuthentication: "Two-factor authentication",
    ConfirmAccess: "Confirm access",
    EnableTwoFactorAuthentication: "Enable two-factor authentication (2FA)",
    TwoFactorAuthenticationIsNotEnabledYet: "Two factor authentication is not enabled yet.",
    TwoFactorAuthenticationDescription: "Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to sign in.",
    Enable: "Enable",
    Disable: "Disable",

    SetupAuthenticatorApp: "Setup authenticator app",
    SetupText1: "Use a phone app like ",
    SetupText2: ", etc. to get 2FA codes when prompted during sign-in.",
    ScanTheQrCode: "Scan the QR code",
    ScanNote1: "Use an authenticator app from your phone to scan. If you are unable to scan, ",
    ScanNote2: "enter this text code",
    ScanNote3: " instead.",
    VerifyTheCodeFromTheApp: "Verify the code from the app",

    AuthenticationCode: "Authentication code",
    Verify: "Verify",
    VerifyNote: "Open the two-factor authenticator(TOTP) app on your mobile device to view your authentication code.",
    HavingProblems: "Having problems? ",
    SendCodeToEmail: "Send code to email",
    DonTAskMeAgainOnThisDevice: "Don't ask me again on this device",

    YourTwoFactorSecret: "Your two-factor secret",

    SubscriptionPlan: "Subscription Plan",
    SubscriptionExpiration: "Subscription Expiration",
    TODAY: "TODAY",
    Expired: "Expired",
    CloudSubscriptionPlanFree: "Non-subscribed",
    CloudSubscriptionPlanBasic: "Basic",
    CloudSubscriptionPlanStandard: "Standard",

    Join: "Join",
    YouCurrentlyHaveAnInvitationToJoinTheTeam: "You currently have an invitation to join the team {0}.",
    Note: "Note",
    JoiningATeam: ": joining a team will transfer ownership of your account to the team admin. You and your team members will be able to view each others' test information.",
    JoinTeam: "Join Team",

    AvailableStorage: "(Available storage: {0})",
    NoRestrictions: "No restrictions",

    AddSignals: "Add signals",
    AddPlots: "Add plots",
    SelectPlotsToDisplay: "Select plots to display",
    AddRemoveSignal: "Add/Remove Signal",
    SavePlot: "Save Plot",
    DataView: "Data View",
    PlotTitle: "Plot title",

    PlotProperties: "Plot Properties",
    TimeFormat: "Time Format",
    DarkMode: "Dark Mode",

    MinimumValue: "Minimum value",
    MaximumValue: "Maximum value",
    DisplayFormat: "Display format",
    HorizontalAxis: "Horizontal Axis",
    VerticalAxis: "Vertical Axis",

    PleaseEnterTheEmailAddressOfTheTeamAdministrator: "Send request to the email address:",
    RequestToJoinTheTeam: "Request to join the team",
    PleaseEnterAValidEmailAddress: "Please enter a valid email address.",
    TheRequestWasSuccessfullySentPleaseWaitForTheAdministratorSConfirmation: "The request was successfully sent, please wait for the administrator's confirmation.",

    Accept: "Accept",
    Reject: "Reject",
};
