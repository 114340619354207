
import store from "@/store";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "BaseLayout",
})
export default class BaseLayout extends Vue {
    get CurrentBreadcrumb() {
        return store.getters.CurrentBreadcrumb;
    }
}
