<template>
    <div class="live-status-card" v-if="IsRunning && this.controlpanelData">
        <div class="setting-card">
            <div class="setting-card-header">
                <span>{{ $t("LiveStatus") }}</span>
            </div>
            <el-card class="box-card">
                <h3>
                    Test Status:
                    <span :class="StatusClass">{{ Status }}</span>
                </h3>
                <br />

                <!-- VCS -->
                <el-row :gutter="20" v-if="IsVCS || (IsTHV && !IsTHC)">
                    <el-col :span="12">
                        <el-card class="box-card">
                            <div class="text item">Level: {{ Level }}</div>
                            <div class="text item">
                                Control {{ IsRMS ? "RMS" : "pk" }} :
                                {{ ControlRMSpk }} m²/s
                            </div>
                            <div class="text item">
                                Time Elapsed: {{ TimeElapsed }}
                            </div>
                            <div class="text item">
                                Time Elapsed at Full Level:
                                {{ TimeElapsedAtFullLevel }}
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card class="box-card">
                            <div class="text item">Drive Pk: {{ DrivePk }} V</div>
                            <div class="text item">
                                Target {{ IsRMS ? "RMS" : "pk" }}:
                                {{ TargetRMSPK }} m²/s
                            </div>
                            <div class="text item">
                                Time Remaining: {{ TimeRemaining }}
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <!-- DSA -->
                <el-card class="box-card" v-if="IsDSA">
                    <div class="text item">Time Elapsed: {{ TimeElapsed }}</div>
                    <div class="text item">
                        Recording Status: {{ RecordingStatus }}
                    </div>
                </el-card>

                <!-- THV -->
                <el-row :gutter="20" v-if="IsTHV">
                    <el-col :span="12">
                        <el-card class="box-card">
                            <div class="text item">
                                Temperature Ctrl: {{ TemperatureCtrl }} C
                            </div>
                            <div class="text item">
                                Temperature Target: {{ TemperatureTarget }} C
                            </div>
                            <div class="text item">
                                Humidity Ctrl: {{ HumidityCtrl }} %RH
                            </div>
                            <div class="text item">
                                HumidityTarget: {{ HumidityTarget }} %RH
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card class="box-card">
                            <div class="text item">
                                Head PID: {{ HeadPID }}%
                            </div>
                            <div class="text item">
                                Cool PID: {{ CoolPID }}%
                            </div>
                            <div class="text item">
                                Humidification PID: {{ HumidificationPID }}%
                            </div>
                            <div class="text item">
                                Dehumidification PID: {{ DehumidificationPID }}%
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </div>
</template>

<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import {
    AddToRefreshList,
    FormatTimeDuration,
    FormatValue,
    GetTestStatus,
} from "@/helpers";

import {
    CloudDataType,
    MeasurementConfigType,
    CloudTestStatus,
    SoftwareMode,
} from "socket/common";

@Component({
    name: "LiveStatusCard",
})
export default class LiveStatusCard extends Vue {
    @Prop()
    TestGUID;

    controlpanelData = {};
    softwareMode = 0;
    measureType = 0;
    status = 0;
    loading = false;

    // vue-convert: This property will initialized in data() method, with `this` reference.
    page = undefined;

    data() {
        return {
            page: CloudDataType.RunLogs,
        };
    }

    created() {
        AddToRefreshList(this);
    }

    mounted() {
        this.fetchData();
    }

    async fetchData() {
        this.loading = true;

        try {
            const res = await axios.get(
                `/api/v1/tests/liveStatus?GUID=${this.TestGUID}`,
            );

            if (res.status !== HttpStatusCode.OK) {
                console.error(res.data);
            } else {
                // console.log(res.data.data);
                this.controlpanelData = res.data.data.ControlPanel;
                this.softwareMode = res.data.data.SoftwareMode;
                this.measureType = res.data.data.MeasureType;
                this.status = res.data.data.Status;
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    formatTHValue(val) {
        const n = Number(val);
        if (Number.isNaN(n)) {
            return "000.0";
        }

        return n.toFixed(1);
    }

    formatPIDValue(val) {
        const n = Number(val);
        if (Number.isNaN(n)) {
            return "0.00";
        }

        return (n * 100).toFixed(2);
    }

    // common
    get TimeElapsed() {
        if (this.controlpanelData) {
            return FormatTimeDuration(
                this.controlpanelData.TimeElapsed,
            );
        }

        return undefined;
    }

    // VCS
    get Level() {
        if (this.controlpanelData) {
            return (
                FormatValue(
                    this.controlpanelData.Level * 100,
                    4,
                    2,
                ) + "%"
            );
        }

        return undefined;
    }

    get DrivePk() {
        if (this.controlpanelData) {
            return FormatValue(this.controlpanelData.DrivePk);
        }

        return undefined;
    }

    get ControlRMSpk() {
        if (this.controlpanelData) {
            return FormatValue(
                this.controlpanelData.ControlRMSpk,
            );
        }

        return undefined;
    }

    get TargetRMSPK() {
        if (this.controlpanelData) {
            return FormatValue(
                this.controlpanelData.TargetRMSPK,
            );
        }

        return undefined;
    }

    get TimeRemaining() {
        if (this.controlpanelData) {
            return FormatTimeDuration(
                this.controlpanelData.TimeRemaining,
            );
        }

        return undefined;
    }

    get TimeElapsedAtFullLevel() {
        if (this.controlpanelData) {
            return FormatTimeDuration(
                this.controlpanelData.TimeElapsedAtFullLevel,
            );
        }

        return undefined;
    }

    // DSA
    get RecordingStatus() {
        if (this.controlpanelData) {
            return this.controlpanelData.RecordingStatus;
        }

        return undefined;
    }

    get SoftwareMode() {
        if (this.softwareMode !== 0) {
            return this.softwareMode;
        }

        if (this.controlpanelData) {
            return this.controlpanelData.SoftwareMode;
        }

        return 0;
    }

    get IsVCS() {
        return this.SoftwareMode === SoftwareMode.SPIDER_VCS;
    }

    get IsDSA() {
        return this.SoftwareMode === SoftwareMode.SPIDER_DSA;
    }

    get IsTHV() {
        return this.SoftwareMode === SoftwareMode.SPIDER_THV;
    }

    get IsTHC() {
        return this.measureType === MeasurementConfigType.THV_THC;
    }

    get IsRMS() {
        switch (this.measureType) {
            case MeasurementConfigType.VCS_Random:
            case MeasurementConfigType.THV_Random:
            case MeasurementConfigType.VCS_RORSOR:
            case MeasurementConfigType.THV_RORSOR:
            case MeasurementConfigType.MIMO_Random:
            case MeasurementConfigType.MESA_Random:
            case MeasurementConfigType.MDOF_Random:
                return true;
            case MeasurementConfigType.VCS_Shock:
            case MeasurementConfigType.THV_Shock:
            case MeasurementConfigType.VCS_SRS:
            case MeasurementConfigType.THV_SRS:
            case MeasurementConfigType.VCS_Earthquake:
            case MeasurementConfigType.THV_Earthquake:
            case MeasurementConfigType.VCS_TTH:
            case MeasurementConfigType.THV_TTH:
                return false;
            case MeasurementConfigType.VCS_EDR:
            case MeasurementConfigType.VCS_ShockOnRandom:
            case MeasurementConfigType.THV_ShockOnRandom:
            case MeasurementConfigType.MIMO_Shock:
            case MeasurementConfigType.MIMO_TTH:
            case MeasurementConfigType.MIMO_SRS:
                return true;
            case MeasurementConfigType.VCS_Sine:
            case MeasurementConfigType.THV_Sine:
            case MeasurementConfigType.VCS_RSTD:
            case MeasurementConfigType.VCS_BFT:
            case MeasurementConfigType.THV_RSTD:
            case MeasurementConfigType.VCS_SineOscillator:
            case MeasurementConfigType.THV_SineOscillator:
            case MeasurementConfigType.VCS_MultiSine:
            case MeasurementConfigType.THV_MultiSine:
            case MeasurementConfigType.MDA_MIMO_SSSine:
            case MeasurementConfigType.MIMO_Sine:
            case MeasurementConfigType.MESA_Sine:
            case MeasurementConfigType.MDOF_Sine:
                return false;
            case MeasurementConfigType.VCS_TDR:
            case MeasurementConfigType.THV_TDR:
            case MeasurementConfigType.MIMO_TWR:
            case MeasurementConfigType.MDOF_TWR:
                return true;
        }
    }

    get IsRunning() {
        return this.status === CloudTestStatus.Running;
    }

    get Status() {
        return this.$t(GetTestStatus(this.status));
    }

    get StatusClass() {
        return `test-status-${this.status}`;
    }

    // #region THV Data Items
    get TemperatureCtrl() {
        if (this.controlpanelData) {
            return this.formatTHValue(
                this.controlpanelData.TemperatureCtrl,
            );
        }

        return undefined;
    }

    get TemperatureTarget() {
        if (this.controlpanelData) {
            return this.formatTHValue(
                this.controlpanelData.TemperatureTarget,
            );
        }

        return undefined;
    }

    get HumidityCtrl() {
        if (this.controlpanelData) {
            return this.formatTHValue(this.controlpanelData.HumidityCtrl);
        }

        return undefined;
    }

    get HumidityTarget() {
        if (this.controlpanelData) {
            return this.formatTHValue(this.controlpanelData.HumidityTarget);
        }

        return undefined;
    }

    get HeadPID() {
        if (this.controlpanelData) {
            return this.formatPIDValue(this.controlpanelData.HeadPID);
        }

        return undefined;
    }

    get CoolPID() {
        if (this.controlpanelData) {
            return this.formatPIDValue(this.controlpanelData.CoolPID);
        }

        return undefined;
    }

    get HumidificationPID() {
        if (this.controlpanelData) {
            return this.formatPIDValue(
                this.controlpanelData.HumidificationPID,
            );
        }

        return undefined;
    }

    get DehumidificationPID() {
        if (this.controlpanelData) {
            return this.formatPIDValue(
                this.controlpanelData.DehumidificationPID,
            );
        }

        return undefined;
    }// #endregion
}
</script>

<style lang="scss" scoped>
.live-status-card {
    /* margin-top: 3ex; */
    margin-bottom: 2ex;
    text-align: left;
}

.widget-content {
    border: none;
}

.widget-box {
    border: none;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}
</style>
