
import { Vue, Component } from "vue-property-decorator";
import FooterBar from "@/components/FooterBar.vue";
import { RouteName } from "@/helpers";
import store from "@/store";

@Component({
    name: "Home",
    components: {
        FooterBar,
    },
})
export default class Home extends Vue {
    mounted() {
        if (store.getters.IsLogged) {
            this.$router.push({
                name: RouteName.Dashboard,
            });
        }
    }

    handleClick(path: string) {
        this.$router.push(path);
    }

    appStore() {
        const url = this.$store.getters.Language ? "https://apps.apple.com/cn/app/edm-mobile/id1598838097" : "https://apps.apple.com/us/app/edm-mobile/id1598838097";
        window.open(url, "_blank");
    }

    googlePlay() {
        const url = "https://play.google.com/store/apps/details?id=com.crystalinstruments.edm.mobile";
        window.open(url, "_blank");
    }
}
