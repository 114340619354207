<template>
    <router-view />
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import store from "@/store";

@Component({
    name: "App",
})
export default class App extends Vue {
    data() {
        return {

        };
    }

    created() {
        store.commit("updateBreadcrumb", ["sidebar.team"]);
    }
}
</script>

<style lang="scss">
</style>
