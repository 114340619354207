/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/base/event.ts
 * @create:      2022-05-12 16:22:40.720
 * @modify:      2023-02-15 17:07:04.740
 * @version:     0.1.1
 * @times:       9
 * @lines:       66
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import { CloudDataType, ServerChangeType } from "socket/common";
import { GenericMessage } from "socket/protoc/generated/cloud_messages_pb";
import Vue from "vue";
import { GetCloudDataTypeNamesByValue, GetServerChangeTypeNamesByValue } from "./function";
import { TFetchFunc } from "./type";

// Create an empty Vue instance to handle events between different components
export const eventHub = new Vue();

const vueMap = new Map<Vue, TFetchFunc>();

eventHub.$on("re-fetch-data", function(dataType: CloudDataType, changeType: ServerChangeType, gm?: GenericMessage) {
    const changeTypeName = GetServerChangeTypeNamesByValue(changeType);

    for (const [k, v] of vueMap.entries()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const vueInstance = k as any;
        const fetchFunc = v;

        if (vueInstance._inactive || vueInstance._isDestroyed) {
            vueMap.delete(vueInstance);
            // console.log(`Remove: ${vueInstance.$options.name}, left: ${vueMap.size}`);
            continue;
        }

        const fetch = (type: CloudDataType) => {
            const dataTypeName = GetCloudDataTypeNamesByValue(type).join(", ");
            console.log(`re-fetch data: ${dataTypeName}, ${changeTypeName}`);

            if (fetchFunc) {
                fetchFunc(type, changeType, gm);
            } else {
                vueInstance.fetchData();
            }
        };

        if (dataType === undefined) {
            console.warn("dataType is undefined");
            fetch(dataType);
        } else {
            const type = (Number(dataType) & vueInstance.page);
            if (type > 0) {
                fetch(type);
            }
        }
    }
});

export function AddToRefreshList(vueInstance: Vue, fetchFunc?: TFetchFunc) {
    // console.log(`AddToRefreshList: ${vueInstance.$options.name}`);
    vueMap.set(vueInstance, fetchFunc);
}
