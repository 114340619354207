<template>
    <div class="main-container-content">
        <div class="container-fluid">
            <div class="main-content">
                <el-breadcrumb
                    separator-class="el-icon-arrow-right"
                    class="text-uppercase main-breadcrumb"
                >
                    <el-breadcrumb-item :to="{ path: '/Dashboard' }">{{
                        $t("home")
                    }}</el-breadcrumb-item>
                    <el-breadcrumb-item>
                        {{ $t("view") }}
                        <span class="test-type-span">
                            {{ TestName }}
                        </span>
                    </el-breadcrumb-item>
                </el-breadcrumb>

                <!-- Test Properties -->
                <TestCard :TestGUID="TestGUID" />

                <!-- <el-card class="box-card" v-if="NumericalValues.length > 0">
                <div class="numerical-box" v-if="NumericalValues.length > 0">
                    <div
                        class="numerical-item"
                        :key="tag.Name"
                        v-for="tag in NumericalValues"
                    >
                        <div class="numerical-value">
                            <h3 class="numerical-line-1">{{ tag.name }}</h3>
                            <div class="numerical-line-2">
                                <span>{{ tag.value }}</span>
                                <span>{{ " " + tag.unit }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </el-card> -->

                <!-- <DataCard :title="$t('run_logs')">
                    <el-table :data="RunLogItems" style="width: 100%">
                        <template slot="empty">
                            <span>{{ $t("NoData") }}</span>
                        </template>
                        <el-table-column
                            prop="Time"
                            :label="$t('Time')"
                        ></el-table-column>
                        <el-table-column
                            prop="RunlogType"
                            :label="$t('RunlogType')"
                        ></el-table-column>
                        <el-table-column
                            prop="RunlogSources"
                            :label="$t('RunlogSources')"
                        ></el-table-column>
                        <el-table-column
                            prop="TargetSData"
                            :label="$t('TargetSData')"
                        ></el-table-column>
                    </el-table>
                </DataCard> -->

                <!-- <DataCard :title="$t('ChannelStatus')" v-if="hasData">
                    <el-table :data="ChannelStatus" style="width: 100%">
                        <template slot="empty">
                            <span>{{ $t("NoData") }}</span>
                        </template>
                        <el-table-column
                            prop="Name"
                            :label="$t('Name')"
                        ></el-table-column>
                        <el-table-column
                            prop="Statue"
                            :label="$t('ChannelStatus')"
                            :formatter="formatterStatus"
                        ></el-table-column>
                    </el-table>
                </DataCard>

                <DataCard :title="$t('DIOStatue')" v-if="hasData">
                    <el-table :data="DIOItems" style="width: 100%">
                        <template slot="empty">
                            <span>{{ $t("NoData") }}</span>
                        </template>
                        <el-table-column
                            prop="Time"
                            :label="$t('Time')"
                            width="180"
                        ></el-table-column>
                        <el-table-column
                            prop="Code"
                            :label="$t('Code')"
                            width="180"
                        ></el-table-column>
                        <el-table-column
                            prop="Describe"
                            :label="$t('Describe')"
                        ></el-table-column>
                        <el-table-column
                            prop="Remark"
                            :label="$t('Remark')"
                        ></el-table-column>
                    </el-table>
                </DataCard> -->
            </div>
        </div>
    </div>
</template>

<i18n>
{
  "en-US": {
    "run_logs": "Run Log",
    "ChannelStatus":"Channel Status",
    "Time":"Time",
    "RunlogType":"Type",
    "RunlogSources":"Event Source",
    "TargetSData":"Target Value",
    "Name":"Channel Name",
    "Code":"Code",
    "Describe":"Describe",
    "Remark":"Remark",
    "DIOStatus":"DIO Statue"
  },
  "zh-CHS": {
    "run_logs": "运行日志",
    "ChannelStatus":"通道状态",
    "Time":"时间",
    "RunlogType":"日志类型",
    "RunlogSources":"目标源",
    "TargetSData":"目标数据",
    "Name":"名称",
    "Code":"代码",
    "Describe":"描述",
    "Remark":"备注",
    "DIOStatus":"DIO 状态"
  }
}
</i18n>

<script>
import axios from "axios";
import { AddToRefreshList } from "@/helpers";
import store from "@/store";

import {
    CloudDataType,
} from "socket/common";

import TestCard from "@/components/cards/TestCard.vue";

export default {
    name: "Settings",
    components: {
        TestCard,
    },
    data() {
        return {
            livedata: {},
            hasData: true,
            page: CloudDataType.EDCLiveData,
        };
    },
    created: function() {
        store.commit("updateBreadcrumb", ["sidebar.edc"]);
        this.fetchData();
    },
    mounted: function() {
        AddToRefreshList(this, this.fetchData);
    },
    updated: function() {
        if (this.$route.name === "Software") {
            store.commit("updateBreadcrumb", ["sidebar.edc"]);
        }
    },
    computed: {
        TestGUID: function() {
            const TestGUID = this.$route.params.testGUID || this.$route.query.testGUID;
            return TestGUID;
        },

        RunLogItems: function() {
            let items = [];
            if (this.livedata && this.livedata.RunLogItems) {
                items = this.livedata.RunLogItems;
            }

            // Most recent entry should be on top
            return items.reverse();
        },

        DIOItems: function() {
            if (this.livedata) {
                return this.livedata.DIOItems;
            }

            return [];
        },

        ChannelStatus: function() {
            if (this.livedata) {
                return this.livedata.ChannelStatus;
            }

            return [];
        },

        NumericalValues: function() {
            if (this.livedata && this.livedata.NumericalValues) {
                return this.livedata.NumericalValues.map(p => this.getTagValue(p));
            }

            return [];
        },

        OldNumericalValues: function() {
            if (this.livedata.TemperaturePV || this.livedata.HumidityPV || this.livedata.TemperatureSV || this.livedata.HumiditySV) {
                return true;
            }
            console.log(this.livedata.TemperaturePV);
            console.log(this.livedata.HumidityPV);
            console.log(this.livedata.TemperatureSV);
            console.log(this.livedata.HumiditySV);
            return false;
        },
        TestName: function() {
            console.log(this.$route.query);
            return this.$route.query.TestName;
        },
        TemperaturePV: function() {
            return this.$t("Temperature_PV") + this.livedata.TemperaturePV;
        },
        HumidityPV: function() {
            return this.$t("Humidity_PV") + this.livedata.HumidityPV;
        },
        TemperatureSV: function() {
            return this.$t("Temperature_SV") + this.livedata.TemperatureSV;
        },
        HumiditySV: function() {
            return this.$t("Humidity_SV") + this.livedata.HumiditySV;
        },
    },
    methods: {
        async fetchData() {
            try {
                // this.scope.row = this.$route.params.License
                this.error = this.post = null;
                this.loading = true;

                const res = await axios.get(`/api/v1/edc/livedata?GUID=${this.TestGUID}`);

                this.livedata = res.data.data[res.data.data.length - 1];
                this.hasData = this.livedata.ChannelStatus !== null;
            } catch (err) {
                console.error(err);
            }

            this.loading = false;
        },
        formatterStatus(row) {
            if (row.Status === "1") { return "已启用"; } else if (row.Status === "0") { return "-"; } else { return "通道丢失"; }
        },
        getTagValue(tag) {
            try {
                const lang = store.state.language.toLowerCase();

                let name = "";

                if (tag.Label.length > 0) {
                    name = tag.Label[0].Value;
                    for (const label of tag.Label) {
                        if (label.Name.toLowerCase() === lang) {
                            name = label.Value;
                            break;
                        }
                    }
                }

                // const val = `${name} ${tag.Value}${tag.Unit}`.trim();

                return {
                    name,
                    value: tag.Value,
                    unit: tag.Unit,
                };
            } catch (ex) {
                console.error(ex);
                return "";
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.box-card label {
    margin-right: 20px;
    margin-left: 10px;
}

.setting-item {
    font-size: 14px;
    padding: 18px 0;
}

.main-content {
    margin-top: 20px;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.numerical-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.numerical-item {
    border-radius: 0.25rem;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    display: block;
    margin-bottom: 20px;
    position: relative;
    background-color: #fff;
    width: 250px;
}

.numerical-item {
    margin-right: 10px;
}

.numerical-value {
    padding: 10px;
    color: rgb(0, 128, 0);
}

.numerical-line-2 {
    padding-top: 20px;
    padding-bottom: 20px;

    span:first-of-type {
        font-size: 20px;
        font-weight: bold;
    }
}
</style>
