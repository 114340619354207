// source: cloud_vds_node.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.EDM.IPC.cloud_vds_node', null, global);
goog.exportSymbol('proto.EDM.IPC.cloud_vds_node_list', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.cloud_vds_node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.cloud_vds_node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.cloud_vds_node.displayName = 'proto.EDM.IPC.cloud_vds_node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.cloud_vds_node_list = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.cloud_vds_node_list.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.cloud_vds_node_list, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.cloud_vds_node_list.displayName = 'proto.EDM.IPC.cloud_vds_node_list';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.cloud_vds_node.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.cloud_vds_node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.cloud_vds_node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.cloud_vds_node.toObject = function(includeInstance, msg) {
  var f, obj = {
    scnPGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scnGuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scnName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scnAliasname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scnDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scnType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    scnIrf1: jspb.Message.getFieldWithDefault(msg, 7, 0),
    scnIrf2: jspb.Message.getFieldWithDefault(msg, 8, 0),
    scnIrf3: jspb.Message.getFieldWithDefault(msg, 9, 0),
    scnIrf4: jspb.Message.getFieldWithDefault(msg, 10, 0),
    scnIrf5: jspb.Message.getFieldWithDefault(msg, 11, 0),
    scnIrf6: jspb.Message.getFieldWithDefault(msg, 12, 0),
    scnIrf7: jspb.Message.getFieldWithDefault(msg, 13, 0),
    scnIrf8: jspb.Message.getFieldWithDefault(msg, 14, 0),
    scnIrf9: jspb.Message.getFieldWithDefault(msg, 15, 0),
    scnIrf10: jspb.Message.getFieldWithDefault(msg, 16, 0),
    scnIrf11: jspb.Message.getFieldWithDefault(msg, 17, 0),
    scnIrf12: jspb.Message.getFieldWithDefault(msg, 18, 0),
    scnIrf13: jspb.Message.getFieldWithDefault(msg, 19, 0),
    scnIrf14: jspb.Message.getFieldWithDefault(msg, 20, 0),
    scnDrf1: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    scnDrf2: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    scnDrf3: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    scnDrf4: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    scnDrf5: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    scnDrf6: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    scnDrf7: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    scnDrf8: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    scnVrf1: jspb.Message.getFieldWithDefault(msg, 29, ""),
    scnVrf2: jspb.Message.getFieldWithDefault(msg, 30, ""),
    scnVrf3: jspb.Message.getFieldWithDefault(msg, 31, ""),
    scnVrf4: jspb.Message.getFieldWithDefault(msg, 32, ""),
    scnVrf5: jspb.Message.getFieldWithDefault(msg, 33, ""),
    scnVrf6: jspb.Message.getFieldWithDefault(msg, 34, ""),
    scnVrf7: jspb.Message.getFieldWithDefault(msg, 35, ""),
    scnVrf8: jspb.Message.getFieldWithDefault(msg, 36, ""),
    scnVrf9: jspb.Message.getFieldWithDefault(msg, 37, ""),
    scnVrf10: jspb.Message.getFieldWithDefault(msg, 38, ""),
    scnVrf11: jspb.Message.getFieldWithDefault(msg, 39, ""),
    scnVrf12: jspb.Message.getFieldWithDefault(msg, 40, ""),
    scnVrf13: jspb.Message.getFieldWithDefault(msg, 41, ""),
    scnVrf14: jspb.Message.getFieldWithDefault(msg, 42, ""),
    scnVrf15: jspb.Message.getFieldWithDefault(msg, 43, ""),
    scnVrf16: jspb.Message.getFieldWithDefault(msg, 44, ""),
    scnVrf17: jspb.Message.getFieldWithDefault(msg, 45, ""),
    scnBrf1: msg.getScnBrf1_asB64(),
    scnBrf2: msg.getScnBrf2_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.cloud_vds_node}
 */
proto.EDM.IPC.cloud_vds_node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.cloud_vds_node;
  return proto.EDM.IPC.cloud_vds_node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.cloud_vds_node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.cloud_vds_node}
 */
proto.EDM.IPC.cloud_vds_node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnPGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnGuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnAliasname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf1(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf2(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf3(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf4(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf5(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf6(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf7(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf8(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf9(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf10(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf11(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf12(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf13(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScnIrf14(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScnDrf1(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScnDrf2(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScnDrf3(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScnDrf4(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScnDrf5(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScnDrf6(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScnDrf7(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScnDrf8(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf1(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf2(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf3(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf4(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf5(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf6(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf7(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf8(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf9(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf10(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf11(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf12(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf13(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf14(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf15(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf16(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setScnVrf17(value);
      break;
    case 46:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setScnBrf1(value);
      break;
    case 47:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setScnBrf2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.cloud_vds_node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.cloud_vds_node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.cloud_vds_node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.cloud_vds_node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScnPGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScnGuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScnName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScnAliasname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScnDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScnType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getScnIrf1();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getScnIrf2();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getScnIrf3();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getScnIrf4();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getScnIrf5();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getScnIrf6();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getScnIrf7();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getScnIrf8();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getScnIrf9();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getScnIrf10();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getScnIrf11();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getScnIrf12();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getScnIrf13();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getScnIrf14();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getScnDrf1();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getScnDrf2();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getScnDrf3();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getScnDrf4();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getScnDrf5();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getScnDrf6();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getScnDrf7();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getScnDrf8();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getScnVrf1();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getScnVrf2();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getScnVrf3();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getScnVrf4();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getScnVrf5();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getScnVrf6();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getScnVrf7();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getScnVrf8();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getScnVrf9();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getScnVrf10();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getScnVrf11();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getScnVrf12();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getScnVrf13();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getScnVrf14();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getScnVrf15();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getScnVrf16();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getScnVrf17();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getScnBrf1_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      46,
      f
    );
  }
  f = message.getScnBrf2_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      47,
      f
    );
  }
};


/**
 * optional string SCN_P_GUID = 1;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnPGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnPGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string SCN_GUID = 2;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SCN_Name = 3;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string SCN_AliasName = 4;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnAliasname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnAliasname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string SCN_Description = 5;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 SCN_Type = 6;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 SCN_IRF1 = 7;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf1 = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 SCN_IRF2 = 8;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf2 = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 SCN_IRF3 = 9;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf3 = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 SCN_IRF4 = 10;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf4 = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 SCN_IRF5 = 11;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf5 = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 SCN_IRF6 = 12;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf6 = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 SCN_IRF7 = 13;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf7 = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 SCN_IRF8 = 14;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf8 = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 SCN_IRF9 = 15;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf9 = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 SCN_IRF10 = 16;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf10 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf10 = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 SCN_IRF11 = 17;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf11 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf11 = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 SCN_IRF12 = 18;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf12 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf12 = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 SCN_IRF13 = 19;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf13 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf13 = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 SCN_IRF14 = 20;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnIrf14 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnIrf14 = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional double SCN_DRF1 = 21;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDrf1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDrf1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double SCN_DRF2 = 22;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDrf2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDrf2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double SCN_DRF3 = 23;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDrf3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDrf3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double SCN_DRF4 = 24;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDrf4 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDrf4 = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double SCN_DRF5 = 25;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDrf5 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDrf5 = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional double SCN_DRF6 = 26;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDrf6 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDrf6 = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double SCN_DRF7 = 27;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDrf7 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDrf7 = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional double SCN_DRF8 = 28;
 * @return {number}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnDrf8 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnDrf8 = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional string SCN_VRF1 = 29;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf1 = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string SCN_VRF2 = 30;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf2 = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string SCN_VRF3 = 31;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf3 = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string SCN_VRF4 = 32;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf4 = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string SCN_VRF5 = 33;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf5 = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string SCN_VRF6 = 34;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf6 = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string SCN_VRF7 = 35;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf7 = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string SCN_VRF8 = 36;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf8 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf8 = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string SCN_VRF9 = 37;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf9 = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string SCN_VRF10 = 38;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf10 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf10 = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string SCN_VRF11 = 39;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf11 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf11 = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string SCN_VRF12 = 40;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf12 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf12 = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string SCN_VRF13 = 41;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf13 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf13 = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string SCN_VRF14 = 42;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf14 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf14 = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string SCN_VRF15 = 43;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf15 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf15 = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string SCN_VRF16 = 44;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf16 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf16 = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string SCN_VRF17 = 45;
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnVrf17 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnVrf17 = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional bytes SCN_BRF1 = 46;
 * @return {!(string|Uint8Array)}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnBrf1 = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * optional bytes SCN_BRF1 = 46;
 * This is a type-conversion wrapper around `getScnBrf1()`
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnBrf1_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getScnBrf1()));
};


/**
 * optional bytes SCN_BRF1 = 46;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getScnBrf1()`
 * @return {!Uint8Array}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnBrf1_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getScnBrf1()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnBrf1 = function(value) {
  return jspb.Message.setProto3BytesField(this, 46, value);
};


/**
 * optional bytes SCN_BRF2 = 47;
 * @return {!(string|Uint8Array)}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnBrf2 = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * optional bytes SCN_BRF2 = 47;
 * This is a type-conversion wrapper around `getScnBrf2()`
 * @return {string}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnBrf2_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getScnBrf2()));
};


/**
 * optional bytes SCN_BRF2 = 47;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getScnBrf2()`
 * @return {!Uint8Array}
 */
proto.EDM.IPC.cloud_vds_node.prototype.getScnBrf2_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getScnBrf2()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.EDM.IPC.cloud_vds_node} returns this
 */
proto.EDM.IPC.cloud_vds_node.prototype.setScnBrf2 = function(value) {
  return jspb.Message.setProto3BytesField(this, 47, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.cloud_vds_node_list.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.cloud_vds_node_list.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.cloud_vds_node_list.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.cloud_vds_node_list} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.cloud_vds_node_list.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.EDM.IPC.cloud_vds_node.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.cloud_vds_node_list}
 */
proto.EDM.IPC.cloud_vds_node_list.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.cloud_vds_node_list;
  return proto.EDM.IPC.cloud_vds_node_list.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.cloud_vds_node_list} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.cloud_vds_node_list}
 */
proto.EDM.IPC.cloud_vds_node_list.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.cloud_vds_node;
      reader.readMessage(value,proto.EDM.IPC.cloud_vds_node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.cloud_vds_node_list.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.cloud_vds_node_list.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.cloud_vds_node_list} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.cloud_vds_node_list.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EDM.IPC.cloud_vds_node.serializeBinaryToWriter
    );
  }
};


/**
 * repeated cloud_vds_node nodes = 1;
 * @return {!Array<!proto.EDM.IPC.cloud_vds_node>}
 */
proto.EDM.IPC.cloud_vds_node_list.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.EDM.IPC.cloud_vds_node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.cloud_vds_node, 1));
};


/**
 * @param {!Array<!proto.EDM.IPC.cloud_vds_node>} value
 * @return {!proto.EDM.IPC.cloud_vds_node_list} returns this
*/
proto.EDM.IPC.cloud_vds_node_list.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EDM.IPC.cloud_vds_node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.cloud_vds_node}
 */
proto.EDM.IPC.cloud_vds_node_list.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EDM.IPC.cloud_vds_node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.cloud_vds_node_list} returns this
 */
proto.EDM.IPC.cloud_vds_node_list.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


goog.object.extend(exports, proto.EDM.IPC);
