
import { Sleep } from "node-share";
import { Vue, Component } from "vue-property-decorator";

@Component({
})
export default class BackToTop extends Vue {
    flag = false
    scY = 0;

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    }

    async handleScroll() {
        if (this.flag) return;

        this.flag = true;

        await Sleep(100);
        this.scY = window.scrollY;

        this.flag = false;
    }

    toTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
