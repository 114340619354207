
import JoinTeamCard from "@/components/cards/JoinTeamCard.vue";
import OnlineClients from "@/components/RTIM/OnlineClients.vue";
import TestTable from "@/components/tables/TestTableV2.vue";
// import RecordViewer from "@/components/RTIM/RecordViewer.vue";
import UploadsTable from "@/components/tables/UploadsTable.vue";
import { AddToRefreshList } from "@/helpers";
import store from "@/store";
import { HumanFileSize } from "node-share";
import { CloudDataType } from "socket/common";
import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "Dashboard",
    components: {
        TestTable,
        OnlineClients,
        UploadsTable,
        JoinTeamCard,
    },
})
export default class Forgot extends Vue {
    page = CloudDataType.TestProperties;

    get hasLiveTest() {
        return store.state.liveTestCount > 0;
    }

    get AvailableStorage() {
        if (this.$store.state.storageAvailable) {
            if (this.$store.state.storageAvailable >= Number.MAX_SAFE_INTEGER) {
                return this.I18n("AvailableStorage", "NoRestrictions");
            }

            const total = HumanFileSize(this.$store.state.storageTotal);
            return this.I18n("AvailableStorage", `${HumanFileSize(this.$store.state.storageAvailable)} / ${total}`);
        }

        return "";
    }

    refresh() {
        // store.commit("refreshUserInfo");
        // store.dispatch("refreshUserInfo");
        // eslint-disable-next-line no-undef
        store.dispatch("refreshDashboardValues");
    }

    fetchData() {
        this.refresh();
    }

    created() {
        store.commit("updateBreadcrumb", ["sidebar.dashboard"]);
    }

    mounted() {
        AddToRefreshList(this);

        this.fetchData();
    }

    onDeleted() {
        store.dispatch("refreshDashboardValues");
    }
}
