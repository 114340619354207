
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "Forgot",
})
export default class Forgot extends Vue {
    form = {
        email: "",
    };

    mounted() {
        const { email } = this.$router.currentRoute.query;
        if (typeof email === "string") {
            this.form.email = email;
        }
    }

    resetPassword() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const f = this.$refs.form as any;
        f.validate(async (valid: boolean) => {
            if (valid) {
                const loading = this.$loading({
                    lock: true,
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.3)",
                });

                try {
                    const r = await axios.post("/api/v1/users/forgot", this.form);

                    if (r.status === HttpStatusCode.OK) {
                        // console.info(r.data);
                        this.$message.success(r.data.msg);
                    } else {
                        console.warn(r);
                        this.$message.error(r.data.msg || "Please try again later.");
                    }
                } catch (ex) {
                    console.error(ex);
                } finally {
                    loading.close();
                }
            } else {
                return false;
            }
        });
    }

    signIn() {
        this.$router.push("/account/login");
    }
}
