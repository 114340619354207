/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/i18n/i18n.js
 * @create:      2019-01-09 10:45:54.872
 * @modify:      2020-06-19 14:25:47.173
 * @version:     0.1.1
 * @times:       3
 * @lines:       21
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import enUS from "./i18n.en-US";
import zhCHS from "./i18n.zh-CHS";

export default {
    "en-US": enUS,
    "zh-CHS": zhCHS,
};
