<template>
    <el-table :data="tableData" style="width: 100%">
        <!-- <el-table-column type="expand">
            <template slot-scope="props">
                <RCMLiveStatus
                    v-bind:clientInfo="props.row"
                    v-if="props.row.AppId >= 500 && props.row.AppId < 600"
                />
                <ClientLiveStatus v-bind:clientInfo="props.row" v-else />
            </template>
        </el-table-column>-->

        <el-table-column
            prop="ID"
            label="#"
            type="index"
            idth="180"
        ></el-table-column>
        <el-table-column
            prop="UserName"
            label="User Name"
            width="180"
        ></el-table-column>
        <el-table-column
            prop="MachineName"
            label="Machine Name"
        ></el-table-column>
        <el-table-column prop="IP" label="IP"></el-table-column>
        <el-table-column
            prop="SoftwareMode"
            label="Software Mode"
        ></el-table-column>
        <el-table-column label="View">
            <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="primary"
                    @click="handleEdit(scope.$index, scope.row)"
                    >Details</el-button
                >
            </template>
        </el-table-column>
    </el-table>
</template>

<i18n>
{
    "en-US": {

    },
    "zh-CHS": {

    }
}
</i18n>

<script>
import store from "@/store";
// import ClientLiveStatus from "@/components/RTIM/ClientLiveStatus.vue";
// import RCMLiveStatus from "@/components/RTIM/RCMLiveStatus.vue";

export default {
    name: "OnlineClients",
    // components: {
    //     ClientLiveStatus,
    //     RCMLiveStatus,
    // },
    data() {
        return {};
    },

    methods: {
        handleEdit(index, row) {
            console.log(row);
            this.$router.push({ path: "RTIM", query: { id: row.ID } });
        },
    },

    computed: {
        tableData: function() {
            return store.getters.Clients;
        },
        cellStyle: function() {
            return {
                "text-align": "center",
                "white-space": "nowrap",
            };
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
