
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import compareVersions from "compare-versions";
import { HttpStatusCode } from "node-share";
import { CompareDate, DeleteSoftware, GeneralSearchForArray, ISoftware } from "@/helpers";

interface IRow extends ISoftware {
    children?: ISoftware[];
}

@Component({
    name: "EDMInformationCard",
})
export default class EDMInformationCard extends Vue {
    tableData: IRow[] = [];
    loading = true;
    search = "";

    get TableData() {
        return GeneralSearchForArray(this.search, this.tableData);
    }

    created() {
        this.fetchData();
    }

    async fetchData() {
        this.loading = true;

        try {
            const res = await axios.get("/api/v1/softwares");
            if (res.status === HttpStatusCode.OK) {
                const groupedData = new Map<string, ISoftware[]>();
                const items = res.data.data as ISoftware[];
                for (const item of items) {
                    const array = groupedData.get(item.MachineName);
                    if (array) {
                        array.push(item);
                    } else {
                        groupedData.set(item.MachineName, [item]);
                    }
                }

                const rows: IRow[] = [];
                for (const array of groupedData.values()) {
                    array.sort((a, b) => CompareDate(a.UpdatedOn, b.UpdatedOn));

                    rows.push({
                        ...array[0],
                        children: array.slice(1),
                    });
                }

                this.tableData = rows;
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }

    async handleDelete(software: ISoftware) {
        try {
            this.loading = true;
            const d = await this.$confirm(this.$t("DeleteWarning").toString().replace("{0}", software.Version), this.$t("Warning").toString(), {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("ok").toString(),
                cancelButtonText: this.$t("Cancel").toString(),
                type: "warning",
            });

            if (d === "confirm") {
                if (await DeleteSoftware(software._id) !== false) {
                    await this.fetchData();
                }
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }

    versionSortMethod(a: ISoftware, b: ISoftware) {
        return compareVersions(a.Version, b.Version);
    }
}
