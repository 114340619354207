
import { Vue, Component } from "vue-property-decorator";
import { Message } from "element-ui";
import { HttpStatusCode } from "node-share";
import axios, { AxiosRequestConfig } from "axios";

interface IProduct {
    _id: string;

    manufacturer: string;
    name: string;

    // 2020-10-12
    product_type: string;

    max_sampling_rate: number;
    connector_type: string;
    frontends_per_chassis: number;
    min_input_channel: number;
    max_input_channel: number;
    output_channel: number;
    digital_input: number;
    digital_output: number;
    dio_support: number;
    charge_mode_support: number;
    teds_mode_support: number;
    iepe_mode_support: number;
    voltage_mode_support: number;
    straingage_mode_support: number;
    mems_mode_support: number;
    rtd_mode_support: number;
    thermocouple_mode_support: number;
    lcd_support: number;
    start_stop_button_support: number;
    nas_support: number;
    highspeeddataport_support: number;
    channelexpansion_support: number;
    notes: string;
    image: string;
    price: number;
    currency_unit: string;
}

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

@Component({
    name: "Products",
    components: {
        ImageUploader: require("vue-image-upload-resize"),
    },
})
export default class Products extends Vue {
    loading = false;

    radioOption = "create";

    productId = "";
    product: IProduct = this.defaultProduct();
    products: IProduct[] = [];

    appToken = "";
    resetTable = false;

    imageDialogVisible = false;

    productForm = {
        name: "",
        manufacturer: "",
    }

    manufacturerOptions = [
        {
            value: "Crystal Instruments",
            label: "Crystal Instruments",
        },
        {
            value: "STI",
            label: "STI",
        },
    ]

    productTypeOptions = [
        {
            value: "Vibration Controller",
            label: "Vibration Controller",
        }, {
            value: "Handheld DSA",
            label: "Handheld DSA",
        }, {
            value: "Compact DSA",
            label: "Compact DSA",
        }, {
            value: "High Channel Count DSA",
            label: "High Channel Count DSA",
        },
    ]

    rules = {
        name: [
            { required: true, message: "Please input name", trigger: "blur" },
        ],
        manufacturer: [
            { required: true, message: "Please input manufacturer", trigger: "blur" },
        ],
    }

    get IsEdit() {
        return this.radioOption === "edit";
    }

    get RequestConfig(): AxiosRequestConfig {
        return {
            headers: {
                "api-token-app": this.appToken,
            },
        };
    }

    get Form() {
        return this.$refs.productForm as Vue & { validate: (callback: (valid: boolean) => void) => void };
    }

    get Image() {
        let image = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPk4+erBwABCgCsW/hKpgAAAABJRU5ErkJggg==";

        if (this.product.image) {
            image = this.product.image;
        }

        return `data:image/gif;base64,${image}`;
    }

    set Image(img: string) {
        this.product.image = img;
        this.$forceUpdate();
    }

    get UploadInput() {
        return document.getElementById("fileUpload") as HTMLInputElement;
    }

    uploadClick() {
        this.UploadInput.click();
    }

    resetUploadInput() {
        this.UploadInput.value = "";
    }

    setImage(base64: string) {
        const index = base64.indexOf(",");
        this.Image = base64.substr(index + 1);
    }

    defaultProduct(): IProduct {
        return {
            _id: "",
            name: "",
            manufacturer: "",

            product_type: "",

            max_sampling_rate: 0,
            connector_type: "",
            frontends_per_chassis: 0,
            min_input_channel: 0,
            max_input_channel: 0,
            output_channel: 0,
            digital_input: 0,
            digital_output: 0,
            dio_support: 0,
            charge_mode_support: 0,
            teds_mode_support: 0,
            iepe_mode_support: 0,
            voltage_mode_support: 0,
            straingage_mode_support: 0,
            mems_mode_support: 0,
            rtd_mode_support: 0,
            thermocouple_mode_support: 0,
            lcd_support: 0,
            start_stop_button_support: 0,
            nas_support: 0,
            highspeeddataport_support: 0,
            channelexpansion_support: 0,
            notes: "",
            image: "",
            price: 0,
            currency_unit: "",
        };
    }

    resetProduct() {
        this.productId = "";
        this.product = this.defaultProduct();

        this.resetUploadInput();
    }

    changeRadioOption(option: string) {
        this.resetProduct();

        if (option === "edit") {
            this.fetchProducts();
        }
    }

    changeProduct() {
        const p = this.products.find(p => p._id === this.productId);
        if (p) {
            this.product = p;
        } else {
            this.product = this.defaultProduct();
        }

        this.resetUploadInput();
    }

    async deleteProduct() {
        this.loading = true;

        try {
            const result = await axios.delete(`/api/v1/app/products/${this.product._id}`, this.RequestConfig);
            if (result.status === HttpStatusCode.OK) {
                Message({
                    message: "Success",
                    type: "success",
                });

                await this.fetchProducts();
            } else {
                Message({
                    message: "Sorry, something went wrong. Please check your input ...",
                    type: "error",
                });
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    saveProduct() {
        this.Form.validate(async (valid) => {
            if (valid) {
                this.loading = true;

                try {
                    let isError = false;

                    if (this.IsEdit) {
                        const result = await axios.put(`/api/v1/app/products/${this.product._id}`, this.product, this.RequestConfig);
                        if (result.status === HttpStatusCode.OK) {
                            Message({
                                message: "Success",
                                type: "success",
                            });
                        } else {
                            isError = true;
                        }
                    } else {
                        const result = await axios.post("/api/v1/app/products", this.product, this.RequestConfig);
                        if (result.status === HttpStatusCode.Created) {
                            Message({
                                message: "Success",
                                type: "success",
                            });
                        } else {
                            isError = true;
                        }
                    }

                    if (isError) {
                        Message({
                            message: "Sorry, something went wrong. Please check your input ...",
                            type: "error",
                        });
                    } else {
                        // update reset flag
                        if (this.resetTable) {
                            await axios.post("/api/v1/app/reset/table/products", { reset: true }, this.RequestConfig);
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                }

                this.loading = false;
            } else {
                return false;
            }
        });
    }

    async fetchProducts() {
        this.loading = true;

        try {
            const result = await axios.get("/api/v1/app/products");
            if (result.status === HttpStatusCode.OK) {
                this.products = result.data.data;

                this.resetProduct();
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    created() {
        this.fetchProducts();
    }
}
