
import { Vue, Component } from "vue-property-decorator";

import PC from "@/components/cards/PCInformationCard.vue";
import EDM from "@/components/cards/EDMInformationCard.vue";
// import License from "@/components/cards/LicenseInformationCard.vue";
import Hardware from "@/components/cards/HardwareInformationCard.vue";
import store from "@/store";

@Component({
    name: "SystemInformation",
    components: {
        PC,
        EDM,
        // License,
        Hardware,
    },
})
export default class SystemInformation extends Vue {
    created() {
        store.commit("updateBreadcrumb", ["sidebar.system_information"]);
    }
}
