/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [Node-Share] /src/libs/browser/index.ts
 * @create:      2022-12-06 14:17:52.879
 * @modify:      2022-12-06 14:17:52.879
 * @version:     1.0.1
 * @times:       1
 * @lines:       3
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

export function Todo() {

}