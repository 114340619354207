/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-server] /src/socket/common/function.ts
 * @create:      2019-07-08 10:56:52.721
 * @modify:      2023-03-23 18:03:26.278
 * @version:     1.0.1
 * @times:       17
 * @lines:       193
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import moment from "moment";
import { GenerateGuid } from "node-share/src/libs/common";
import { CloudMessageType, CloudMessageTypeMap } from "../protoc/generated/cloud_enums_pb";
import { CloudMessage } from "../protoc/generated/cloud_messages_pb";
import { CloudMessageTypeKeys } from "./constant";
import { IAttribute } from "./interface";

export function GetNameByValue(val: CloudMessageTypeMap[keyof CloudMessageTypeMap]) {
    return CloudMessageTypeKeys.find((p) => (CloudMessageType as any)[p] === val);
}

export function GetSoftwareNameFromAppId(appId: number) {
    const softwareMode = Math.floor(appId / 100);
    const index = appId % 100 + 1;

    let name = "Unknown";
    switch (softwareMode) {
        case 2:
            name = "VCS";
            break;

        case 3:
            name = "DSA";
            break;

        case 4:
            name = "EMA";
            break;

        case 5:
            name = "RCM";
            break;

        case 6:
            name = "MSC";
            break;

        case 7:
            name = "TCS";
            break;

        case 8:
            name = "THV";
            break;

        case 9:
            name = "TDA";
            break;

        case 10:
            name = "RDYN";
            break;

        default:
            if (appId === 100) {
                return "IP Config Tool";
            }

            if (appId === 101) {
                return "FECT";
            }
            break;
    }

    return index > 1 ? `${name} - ${index}` : name;
}

export function Now() {
    return moment().format("YYYY-MM-DD HH:mm:ss.SSS");
}

export function CreateCloudMessage(
    type: CloudMessageTypeMap[keyof CloudMessageTypeMap],
    mc?: string,
    appId?: number,
    pageId?: string,
    data?: Uint8Array,
) {
    const msg = new CloudMessage();

    msg.setType(type);

    if (mc !== undefined) {
        msg.setMachineCode(mc);
    }

    if (appId !== undefined) {
        msg.setAppId(appId);
    }

    if (pageId !== undefined) {
        msg.setPageId(pageId);
    }

    if (data !== undefined) {
        msg.setData(data);
    }

    return msg;
}

export function TryGetAttribute(attributes: IAttribute[], name: string): IAttribute | undefined {
    if (!attributes || attributes.length === 0) {
        return undefined;
    }

    let attr = attributes.find(p => p.Name === name);
    if (attr) {
        return attr;
    }

    attr = attributes.find(p => p?.Key?.Languages?.find(l => l?.Value === name) !== undefined);

    return attr;
}

export function TryGetStringFromAttributes(attributes: IAttribute[], name: string): string | undefined {
    const exist = TryGetAttribute(attributes, name);

    return exist?.ValueString;
}

export function TryGetInt64FromAttributes(attributes: IAttribute[], name: string): number | undefined {
    const exist = TryGetAttribute(attributes, name);

    return exist?.ValueInt64;
}

export function TryGetDoubleFromAttributes(attributes: IAttribute[], name: string): number | undefined {
    const exist = TryGetAttribute(attributes, name);

    return exist?.ValueDouble;
}

export function TryGetBooleanFromAttributes(attributes: IAttribute[], name: string): boolean | undefined {
    const v = TryGetInt64FromAttributes(attributes, name);

    if (v === undefined) {
        return undefined;
    }

    switch (v) {
        case 1: return true;
        case -1: return false;
        case 0: return undefined;
    }
}

export function CreateMessage(type: CloudMessageTypeMap[keyof CloudMessageTypeMap], data?: Uint8Array,
    p1?: number, p2?: number, p3?: string, p4?: string) {
    const msg = new CloudMessage();
    msg.setType(type);

    if (type === CloudMessageType.OLD_NOTIFY_CLIENT_REFRESH) {
        p3 = GenerateGuid();
    }

    if (data) {
        msg.setData(data);
    }

    if (p1 !== undefined) {
        msg.setP1(p1);
    }

    if (p2 !== undefined) {
        msg.setP2(p2);
    }

    if (p3 !== undefined) {
        msg.setP3(p3);
    }

    if (p4 !== undefined) {
        msg.setP4(p4);
    }

    return msg;
}
