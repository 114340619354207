/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-server] /src/socket/common/class/AbstractSocket.ts
 * @create:      2019-07-08 10:56:52.723
 * @modify:      2022-05-11 17:58:32.147
 * @version:     1.0.1
 * @times:       12
 * @lines:       58
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import { ILiteEvent } from "../interface";
import { LiteEvent } from "./LiteEvent";

export abstract class AbstractSocket<TSocket, TMessage> {
    public get Connected(): ILiteEvent<TSocket, this> {
        return this.onConnected.expose();
    }

    public get Disconnected(): ILiteEvent<TSocket, this> {
        return this.onDisconnected.expose();
    }

    public get DataReceived(): ILiteEvent<TMessage, this> {
        return this.onDataReceived.expose();
    }

    public get Socket(): TSocket {
        return this.socket;
    }

    private socket: TSocket;

    // #region Events
    private readonly onConnected = new LiteEvent<TSocket, this>();
    private readonly onDisconnected = new LiteEvent<TSocket, this>();
    private readonly onDataReceived = new LiteEvent<TMessage, this>();
    // #endregion

    public constructor(socket: TSocket) {
        this.socket = socket;
    }

    protected OnConnected() {
        return this.onConnected.trigger(this.socket, this);
    }

    protected OnDisconnected() {
        return this.onDisconnected.trigger(this.socket, this);
    }

    protected OnDataReceived(data: TMessage) {
        return this.onDataReceived.trigger(data, this);
    }
}
