
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "PcMayNotBeConnectedWarning",
})
export default class PcMayNotBeConnectedWarning extends Vue {
    @Prop({ default: false })
    isEDC!: boolean;

    mounted() {
        this.updateImage();
    }

    updated() {
        this.updateImage();
    }

    async updateImage() {
        // Comment out the following codes if the element ui fixed this issue in the future
        if (this.$refs.image) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            while ((this.$refs.image as any).loading) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.$refs.image as any).loading = false;
                await this.$nextTick();
            }
        }
    }

    get url() {
        if (this.isEDC) {
            return "/images/edc-login.png";
        }

        return "/images/edm-login.png";
    }

    get srcList() {
        return [this.url];
    }
}
