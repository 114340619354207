
import { Vue, Component } from "vue-property-decorator";

import TestCard from "@/components/cards/TestCard.vue";
import LiveStatusCard from "@/components/cards/LiveStatusCard.vue";
import RunHistoryTable from "@/components/tables/RunHistoryTable.vue";
import store from "@/store";

@Component({
    name: "RunHistories",
    components: {
        TestCard,
        LiveStatusCard,
        RunHistoryTable,
    },
})
export default class RunHistories extends Vue {
    showTestInfo = true;
    showLiveStatus = true;
    showRunHistories = true;

    get TestGUID() {
        return this.$route.query.TestGUID;
    }

    get TestName() {
        return this.$route.query.TestName;
    }

    created() {
        store.commit("updateBreadcrumb", ["sidebar.view_tests", ""]);
    }

    mounted() {
        this.refresh();
    }

    refresh() {
        this.showTestInfo = this.$router.currentRoute.query.showTestInfo !== "false";
        this.showLiveStatus = this.$router.currentRoute.query.showLiveStatus !== "false";
        this.showRunHistories = this.$router.currentRoute.query.showRunHistories !== "false";
    }
}
