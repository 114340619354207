/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [Node-Share] /src/libs/common/function.ts
 * @create:      2022-12-06 14:17:52.880
 * @modify:      2023-07-07 11:08:29.369
 * @version:     1.0.1
 * @times:       6
 * @lines:       419
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import moment from "moment";
import { LowercaseLetters, Numbers, UppercaseLetters } from "./const";
import { StringMethod, TStringObject, TStringOrUndefined } from "./type";

export function ConvertTo<T>(data: unknown) {
    return data as T;
}

export function ReplaceAll(str: string, from: string, to: string) {
    return str.split(from).join(to);
}

/**
 * date2 - date1
 * @param date1
 * @param date2
 */
export function GetDateDifMilliseconds(date1: Date, date2 = new Date()) {
    return date2.getTime() - date1.getTime();
}

export function ReverseString(str: string) {
    return str.split("").reverse().join("");
}

export function GenerateGuid() {
    // cSpell:ignore yxxx
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function GenerateRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function GenerateRandomString(
    length: number,
    validCharacters?: string,
) {
    let str: string = "";

    if (validCharacters === undefined || validCharacters.length < 2) {
        validCharacters =
            LowercaseLetters +
            UppercaseLetters +
            Numbers;
    }

    for (let i = 0; i < length; i++) {
        const char =
            validCharacters[
                GenerateRandomInt(0, validCharacters.length - 1)
            ];

        if (str === "") {
            str = char;
        } else {
            str += char;
        }
    }

    return str;
}

export function GenerateRandomRandomString(min: number, max: number) {
    const len = Math.random() * (max - min + 1) + min;
    return GenerateRandomString(len);
}

export function GenerateRandomNumberLetter(length: number, upperLetters?: boolean, lowerLetters?: boolean) {
    let validCharacters = Numbers; 

    if (upperLetters) {
        validCharacters += UppercaseLetters;
    }

    if (lowerLetters) {
        validCharacters += LowercaseLetters;
    }

    return GenerateRandomString(length, validCharacters);
}

export function GeneratePassword() {
    return GenerateRandomNumberLetter(16, true, true);
}

export function EncodeByRFC3986(str: string) {
    let result = encodeURIComponent(str);

    result = ReplaceAll(result, "!", "%21");
    result = ReplaceAll(result, "*", "%2A");
    result = ReplaceAll(result, "(", "%28");
    result = ReplaceAll(result, ")", "%29");
    result = ReplaceAll(result, "'", "%27");

    return result;
}

export function DecodeByRFC3986(str: string) {
    if (str === undefined) {
        return str;
    }

    let temp = str;

    temp = ReplaceAll(temp, "%21", "!");
    temp = ReplaceAll(temp, "%2A", "*");
    temp = ReplaceAll(temp, "%28", "(");
    temp = ReplaceAll(temp, "%29", ")");
    temp = ReplaceAll(temp, "%27", "'");

    return decodeURIComponent(temp);
}

export function RemoveQueryFromURL(url: string, ...names: string[]) {
    if (url === undefined) {
        return url;
    }

    let hash = "";

    const index = url.indexOf("#");
    if (index !== -1) {
        hash = url.substring(index);
    }

    for (const name of names) {
        // prefer to use l.search if you have a location/link object
        const urlParts = url.split("?");
        if (urlParts.length >= 2) {
            const prefix = encodeURIComponent(name) + "=";
            const pars = urlParts[1].split(/[&;]/g);

            // reverse iteration as may be destructive
            for (let i = pars.length; i-- > 0;) {
                // idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            url =
                urlParts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
        }
    }

    return url + hash;
}

export function UpdateQueryString(param: string, value: string, url: string) {
    let newURL = url;
    // Using a positive lookahead (?=\=) to find the
    // given parameter, preceded by a ? or &, and followed
    // by a = with a value after than (using a non-greedy selector)
    // and then followed by a & or the end of the string
    const val = new RegExp("(\\?|\\&)" + param + "=.*?(?=(&|$))");
    const parts = url.toString().split("#");
    url = parts[0];
    const hash = parts[1];
    const queryString = /\?.+$/;

    // Check if the parameter exists
    if (val.test(url)) {
        // if it does, replace it, using the captured group
        // to determine & or ? at the beginning
        newURL = url.replace(val, "$1" + param + "=" + value);
    } else if (queryString.test(url)) {
        // otherwise, if there is a query string at all
        // add the param to the end of it
        newURL = url + "&" + param + "=" + value;
    } else {
        // if there's no query string, add one
        newURL = url + "?" + param + "=" + value;
    }

    if (hash) {
        newURL += "#" + hash;
    }

    return newURL;
}

export function GetEmailRegExp(email: string) {
    return {
        email: new RegExp("^" + email + "$", "i"),
    };
}

export function GetQueryStringFromURL(url: string) {
    const index = url.indexOf("?");

    if (index !== -1) {
        return url.substring(index + 1);
    }

    return undefined;
}

export function ParseQueryParameters(url: string, decodeURIMethod?: StringMethod) {
    const query = GetQueryStringFromURL(url) || url;
    const items = query.split("&");
    const parameters: TStringObject<TStringOrUndefined> = {};

    const Add = (name: string, val?: string) => {
        if (decodeURIMethod) {
            if (val !== undefined) {
                parameters[decodeURIMethod(name)] = decodeURIMethod(val);
            } else {
                parameters[decodeURIMethod(name)] = undefined;
            }
        } else {
            parameters[name] = val;
        }
    };

    for (const item of items) {
        const parts = item.split("=");
        if (parts.length === 1) {
            Add(parts[0], undefined);
        } else {
            Add(parts[0], parts[1]);
        }
    }

    return parameters;
}

export function IsValidEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

export function HumanFileSize(bytes: number, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + " " + units[u];
}

export function FilterArray<T>(data: T[], condition: (a: T, b: T) => boolean, compare: (a: T, b: T) => boolean): T[] {
    const copyData: any[] = [];

    try {
        if (Array.isArray(data)) {
            data.forEach((item) => {
                const index = copyData.findIndex((copyItem) => {
                    return condition(item, copyItem);
                });

                if (index === -1) {
                    // not exist, then push it to the copyData
                    copyData.push(item);
                } else {
                    // compare and replace
                    const existItem = copyData[index];
                    if (compare(item, existItem)) {
                        copyData[index] = item;
                    }
                }
            });
        } else {
            return data;
        }
    } catch (ex) {
        console.warn(ex);
        return data;
    }

    return copyData;
}

export async function Sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function Distinct<T>(items: T[]) {
    return items.filter((x, i, a) => a.indexOf(x) === i);
}

export function FormatString(format: string, ...args: any[]) {
    return format.replace(/{(\d+)}/g, function(match, number) {
        return typeof args[number] !== "undefined"
            ? args[number]
            : match
        ;
    });
}

export function CutString(str: string, len = 20) {
    if (str) {
        return str.length > len ? str.substr(0, len) + "..." : str;
    }

    return str;
}

/**
  * based on moment.js
  * @export
  * @param {Date} [dt]
  * @param {string} [format]
  * @default YYYY-MM-DD HH:mm:ss.SSS
  * @returns
  * @see {@link https://momentjs.com/docs/#/displaying/format/}
  */
export function FormatDate(dt?: moment.MomentInput, format?: string, utc = false) {
    if (typeof dt === "object") {
        if (dt instanceof Date) {
            // ignore
        } else {
            if (dt && "toISOString" in dt) {
                dt = dt.toISOString();
            }
        }
    }

    if (dt === undefined) {
        dt = new Date();
    }

    if (!format) {
        format = "YYYY-MM-DD HH:mm:ss.SSS";
    }

    if (utc) {
        return moment.utc(dt).format(format);
    }

    return moment(dt).format(format);
}

export function GetDateTimeName() {
    return moment().format("YYYY_MM_DD HH_mm_ss_SSS");
}

/**
 * bitwise operation
 * from right to left, starts from 1 (not 0)
 */
export function SetBit(num: number, offset: number, flag: boolean) { // flag: true for 1, false for 0
    const v = offset < 2 ? offset : (2 << (offset - 2));
    return flag ? (num | v) : (num & ~v);
}

export function GetBit(num: number, offset: number) {
    return (num >> offset - 1) & 1;
}

export function ArrayContainVal(arr: unknown[], obj: unknown) {
    let i = arr.length;
    while (i--) {
        if (arr[i] === obj) {
            return true;
        }
    }
    return false;
}

export function Base642urlFormat(base64str: string) {
    const temp = ReplaceAll(base64str, "+", "@");
    return ReplaceAll(temp, "/", "_");
}

export function Url2StandardBase64(base64url: string) {
    const temp = ReplaceAll(base64url, "@", "+");
    return ReplaceAll(temp, "_", "/");
}

export function ToStandardBase64(base64Data: string) {
    base64Data = base64Data.replaceAll("_a", "+");
    base64Data = base64Data.replaceAll("_b", "/");
    base64Data = base64Data.replaceAll("_c", "=");
    return base64Data;
}

export function DecodeBase64(data: string) {
    data = ToStandardBase64(data);
    return Buffer.from(data, "base64");
}

export function DecodeBase64ToUnicode(data: string) {
    return DecodeBase64(data).toString("utf16le");
}

export function DecodeBase64ToUtf8(data: string) {
    return DecodeBase64(data).toString("utf-8");
}
