
import { Component, Vue } from "vue-property-decorator";
import QRCode from "qrcode";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { RouteName } from "@/helpers";

@Component({
    name: "MFASetup",
})
export default class MFASetup extends Vue {
    token = "";
    secret = "";

    authenticators = [
        {
            name: "Microsoft Authenticator",
            link: "https://www.microsoft.com/en-us/account/authenticator/",
        },
        {
            name: "1Password",
            link: "https://authy.com/guides/github/",
        },
        {
            name: "Authy",
            link: "https://authy.com/guides/github/",
        },
        {
            name: "LastPass Authenticator",
            link: "https://support.logmeininc.com/lastpass/help/lastpass-authenticator-lp030014",
        },
    ]

    get SetupText() {
        let text = this.$t("SetupText1").toString();

        for (let i = 0; i < this.authenticators.length; i++) {
            if (i > 0) {
                if (i === this.authenticators.length - 1) {
                    text += ", or ";
                } else {
                    text += ", ";
                }
            }

            const authenticator = this.authenticators[i];
            text += `<a href="${authenticator.link}" target="_blank">${authenticator.name}</a>`;
        }

        text += this.$t("SetupText2");

        return text;
    }

    async mounted() {
        await this.Setup();
    }

    async Setup() {
        try {
            const r = await axios.get("/api/mfa/setup");
            if (r.status === HttpStatusCode.OK) {
                const { uri, secret } = r.data;
                this.secret = secret;
                this.RefreshQRCode(uri);
            } else if (r.status === HttpStatusCode.Forbidden) {
                this.Redirect();
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    ShowCode() {
        this.$alert(this.secret, this.$t("YourTwoFactorSecret").toString(), {
            confirmButtonText: "Copy",
            callback: action => {
                if (action === "confirm") {
                    navigator.clipboard.writeText(this.secret);
                }
            },
        });
    }

    async RefreshQRCode(text: string) {
        try {
            await QRCode.toCanvas(this.$refs.canvas, text);
        } catch (ex) {
            console.error(ex);
        }
    }

    async Cancel() {
        await this.Redirect(false);
    }

    async Enable() {
        if (!this.token) {
            this.$message({
                message: "Please input the code from your authenticator app.",
                type: "warning",
            });

            return;
        }

        try {
            const r = await axios.post("/api/mfa/setup", {
                token: this.token,
            });

            if (r.status !== HttpStatusCode.OK) {
                if (r.status === HttpStatusCode.Forbidden) {
                    this.Redirect();
                } else {
                    this.$message({
                        message: "Verify failed, please try again.",
                        type: "warning",
                    });
                }

                return;
            }

            // Back to settings
            this.Redirect();
        } catch (ex) {
            console.error(ex);
        }
    }

    async Redirect(refreshUserInfo = true) {
        if (refreshUserInfo) {
            await this.$store.dispatch("refreshUserInfo");
        }

        await this.$router.push({
            name: RouteName.Settings,
        });
    }
}
