// source: cloud_enums.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.EDM.IPC.CloudControlCommand', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudMessageType', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudRecordType', null, global);
goog.exportSymbol('proto.EDM.IPC.TestRunningStatus', null, global);
/**
 * @enum {number}
 */
proto.EDM.IPC.CloudMessageType = {
  UNKNOWN: 0,
  GET_CLIENT_INFO: 1,
  GET_CLIENT_INFO_OK: 2,
  NOTIFY_CLIENT_DISCONNECTED: 3,
  NOTIFY_CLIENT_CONNECTED: 4,
  GET_NEXT_DISPLAY_FRAME: 5,
  GET_NEXT_DISPLAY_FRAME_OK: 6,
  START_DISPLAY: 7,
  START_DISPLAY_OK: 8,
  STOP_DISPLAY: 9,
  STOP_DISPLAY_OK: 10,
  CLOUD_CONTROL_COMMAND: 11,
  CLOUD_CONTROL_COMMAND_OK: 12,
  OLD_GET_CLIENT_INFO: 13,
  OLD_UPDATE_CLIENT_INFO: 14,
  OLD_NOTIFY_CLIENT_REFRESH: 15,
  GET_SIGNAL_LIST: 16,
  GET_SIGNAL_LIST_OK: 17,
  SET_DISPLAY_OPTIONS: 18,
  SET_DISPLAY_OPTIONS_OK: 19,
  SET_RECORD_OPTIONS: 20,
  SET_RECORD_OPTIONS_OK: 21,
  GET_NEXT_RECORD_DATA: 22,
  GET_NEXT_RECORD_DATA_OK: 23,
  GET_RECORD_STATUS: 24,
  GET_RECORD_STATUS_OK: 25,
  GET_VDS_FACTORY_STRUCTURE: 26,
  GET_VDS_FACTORY_STRUCTURE_OK: 27,
  GET_VDS_ENTRY_PARAMETER: 28,
  GET_VDS_ENTRY_PARAMETER_OK: 29,
  CMD_VDS_RUN: 30,
  CMD_VDS_RUN_OK: 31,
  CMD_VDS_PAUSE_RESUME: 32,
  CMD_VDS_PAUSE_RESUME_OK: 33,
  CMD_VDS_STOP: 34,
  CMD_VDS_STOP_OK: 35,
  GET_VDS_NEXT_DISPLAY_FRAME: 36,
  GET_VDS_NEXT_DISPLAY_FRAME_OK: 37,
  SET_VDS_LIMITS: 38,
  SET_VDS_LIMITS_OK: 39,
  GET_VDS_RUNNING_STATUS: 40,
  GET_VDS_RUNNING_STATUS_OK: 41,
  REQUEST_EDM_UPLOAD: 42,
  REQUEST_EDM_UPLOAD_OK: 43,
  EDM_NOTIFY_TEST_STOPPED: 100,
  EDM_NOTIFICATION: 101,
  RCM_GET_ALL_TESTS: 1000,
  RCM_GET_ALL_TESTS_OK: 1001,
  RCM_GET_NEXT_DISPLAY_FRAME: 1002,
  RCM_GET_NEXT_DISPLAY_FRAME_OK: 1003,
  VDS_NOTIFY_HRM_TEST_STATUS_CHANGED: 11000,
  VDS_NOTIFY_HRM_TRIGGER_AVERAGE_REACHED: 11001,
  VDS_NOTIFY_HRM_TRIGGER_LIMIT: 11002,
  VDS_NOTIFY_FACTORY_STRUCTURE_CHANGED: 11003
};

/**
 * @enum {number}
 */
proto.EDM.IPC.CloudControlCommand = {
  UNKNOWN_CCC: 0,
  CCC_RUN: 1,
  CCC_PAUSE: 2,
  CCC_STOP: 3,
  CCC_SAVE: 4,
  CCC_REC: 5,
  CCC_DSA_TRIGGER: 20,
  CCC_DSA_OUTPUT: 21,
  CCC_DSA_RESET_AVG: 22
};

/**
 * @enum {number}
 */
proto.EDM.IPC.TestRunningStatus = {
  UNKNOWN_TEST_RUNNING_STATUS: 0,
  RUNNING: 1,
  PAUSED: 2,
  STOPPED: 3
};

/**
 * @enum {number}
 */
proto.EDM.IPC.CloudRecordType = {
  UNKNOWN_CRT: 0,
  CRT_SIGNAL: 1,
  CRT_RMS: 10,
  CRT_PK: 11,
  CRT_PKPK: 12,
  CRT_MEAN: 13,
  CRT_RPM: 14,
  CRT_SIGNEDPEAK: 15,
  CRT_PEAKFREQUENCY: 16
};

goog.object.extend(exports, proto.EDM.IPC);
