/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-server] /src/socket/common/enum.ts
 * @create:      2022-05-11 17:35:16.391
 * @modify:      2023-01-11 11:14:09.099
 * @version:     1.0.1
 * @times:       28
 * @lines:       361
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

export enum CloudCode {

    Undefined = 0,

    Success = 1000,
    UniqueIdentifierAlreadyExist = 1001,
    AuthenticationFailed = 1002,
    UnauthorizedAccess = 1003,
    NotEnoughStorage = 1004,
    AccountWithThatEmailAddressAlreadyExists = 1005,
    EmailNotVerified = 1006,
    InvalidDemoAccount = 1007,
    IncompatibleClient = 1008,
    AccountWithThatEmailAddressIsDeleted = 1009,
    UnableToDeleteDemoAccount = 1010,
    NeedTwoFactorAuthentication = 1011,

    //
    AddDeviceFailed = 2000,
    UpdateProfileFailed = 2001,
    UpdateTestRunHistoriesFailed = 2002,
    GetRecordCountFailed = 2003,
    GetStorageInfoFailed = 2004,
    GetUserInfoFailed = 2005,

    AlreadyJoined = 3000,
}

export enum AuthenticationFailedCode {
    Unspecific,
    UserNotFound,
    WrongPassword,
    TokenMissing,
    TokenDecodeFailed,
    TokenVerifyFailed,
    TokenExpired,
    UserNotAvailable, // 2022-08-12

    TwoFactorAuthenticationFailed,

    Others = 100,
}

export enum Language {
    English,
    Chinese,
    TraditionalChinese,
}

export enum CloudDataType {
    None = 0,

    TestStatus = 1 << 0,
    TestProperties = 1 << 1,
    TestParameters = 1 << 2,

    RunHistories = 1 << 3,
    RunLogs = 1 << 4,

    SystemInfo = 1 << 5,
    ModuleInfo = 1 << 6,

    ChannelTable = 1 << 7,

    LicenseInfo = 1 << 8,

    OSInfo = 1 << 9,
    EDMInfo = 1 << 10,

    Signals = 1 << 11,

    EDCLiveData = 1 << 30,

    V2Test = 1 << 12,
}

export enum CloudTestStatus {
    Unknown = 0,

    Running = 1,
    Stopped = 2,
}

export enum ServerChangeType {
    None = 0,
    TeamMemberChanged = 1,
    GroupMemberChanged = 2,
    NewV2RunCreated = 3,
    TestDeleted = 4,
    TestsDeleted = 5,
}

export enum SocketEventName {
    BROWSER_MESSAGE = "BROWSER_MESSAGE",
    // BROWSER_FORWARDED_MESSAGE = "BROWSER_FORWARDED_MESSAGE",
}

export enum ElementName {
    MAIN_PAGE_CLIENTS_TABLE = "MAIN_PAGE_CLIENTS_TABLE",
}

export enum PageName {
    PAGE_HOME = "PAGE_HOME",
    PAGE_DETAILS_DASHBOARD = "PAGE_DETAILS_DASHBOARD",
}

export enum SoftwareMode {

    UNKNOWN = 0,

    COCO_DSA = 1,
    COCO_VDC = 2,
    COCO_VDS = 3,
    COCO_REALTIME = 4,
    // 5,6,7,8 reseved for CoCo

    SPIDER_DSA = 9,
    SPIDER_VCS = 10,
    SPIDER_EMA = 11,
    SPIDER_RCM = 12,
    SPIDER_MSC = 13,
    SPIDER_TCS = 14,
    SPIDER_RDYN = 15,
    SPIDER_IPTOOL = 16,
    SPIDER_TDA = 17, // MIMO will be merged to VCS later --> 17 will be used by TDA, pangxb 2918/08/15
    SPIDER_THV = 18,
    // 19,20,21,22,23,24 reserved for Spider

    // eslint-disable-next-line camelcase
    FECT_CalibrationTool = 24,

    PA = 25,
    WFE = 26,
    DATA_TRANSFER = 27,
    // 28,29,30,31,32 reserved for other tools

    EDC = 100,
    VDS = 101,
    MOBILE_APP = 102,

    ALL = 1000,
}

/* eslint-disable camelcase */
export enum MeasurementConfigType {
    None,
    CSA_FFT,

    VCS_Random,
    VCS_Shock, // Classic Shock
    VCS_Sine,
    VCS_TTH, // Transient Time History
    VCS_SRS, // Shock Response Spectrum
    VCS_SineOscillator,
    VCS_TDR,
    VCS_AcousticControl,

    // VCS_TCS,//Transducer Calibration System

    HaltHass = 10, // is 10
    PA_General,
    WFE_General,

    RT_FFT,
    RT_DataCondation,

    // DSA_FFT,
    DSA_Acoustic,
    DSA_OrderTracking,
    DSA_MotorVibration,

    VCS_RORSOR,
    VCS_RSTD,
    VCS_SineReduction,
    VCS_MultiSine,
    VCS_ShockOnRandom,
    MIMO_Random,
    MIMO_Sine,
    MIMO_Shock,
    MIMO_TWR,

    MDA_ImportFRF,
    MDA_HammerImpact,
    MDA_MIMO_FRF,
    MDA_MIMO_NormalModes,
    MDA_MIMO_SSSine,
    MDA_OMA,

    MIMO_SRS,
    MIMO_TTH,
    VCS_SineBeatSeismic,

    /// <summary>
    /// Blade Fatigue Testing
    /// </summary>
    VCS_BFT,

    TCS_Acc = 100, // Calibrate acceleration transducer
    TCS_Vel, // Calibrate velocity transducer
    TCS_Displ, // Calibrate displacement transducer

    DSA_RotorBalancing,
    VCS_Earthquake,
    DSA_TachoTracking,

    THV_Random = 200,
    THV_Shock,
    THV_Sine,
    THV_TTH,
    THV_SRS,
    THV_SineOscillator,
    THV_TDR,
    THV_RORSOR,
    THV_RSTD,
    THV_MultiSine,
    THV_ShockOnRandom,
    THV_THC,
    HV_HaltHass,
    THV_Earthquake,
}
/* eslint-enable camelcase */

/* cSpell:disable */
export enum _NVHType {
    Equidistant, // 1, 2
    NonEquidistant, // 1, 2

    ComplexSpectrum, // 3

    AutopowerSpectrum, // 3, 5
    CrosspowerSpectrum, // 4, 5

    ComplexOrderSpectrum, // 3

    OrderAutopowerSpectrum, // 3
    OrderCrosspowerSpectrum, // 4

    Autocorrelation, // 1
    Crosscorrelation, // 2

    FrequencyResponseSpectrum, // 4, 5
    ImpulseResponse, // 2
    OrderResponseSpectrum, // 4
    OrderImpulseResponse, // 2
    ShockResponseSpectrum, // 3

    Coherence, // 4, 5
    OrderCoherence, // 4

    Cepstrum, // 1

    OctaveAutopowerSpectrum,
    OctaveCrosspowerSpectrum,
    OctaveFrequencyResponseSpectrum,
    OctaveShockResponseSpectrum,
    OctaveCoherence,
}
/* cSpell:enable */

export enum SignalAttributeName {
    IsNeedResetZeroToRight = "IsNeedResetZeroToRight",

    isOctaveTrace = "isOctaveTrace",
    isSLMTimeTrace = "isSLMTimeTrace",
    isSLMSignal = "isSLMSignal",
    nSignalType = "nSignalType",

    xQuantity = "xQuantity",
    yQuantity = "yQuantity",
    zQuantity = "zQuantity",

    isSpiderHSignal = "isSpiderHSignal",
    IsPCTimeTrace = "IsPCTimeTrace",

    SignalID = "SignalID",
    SortIndex = "SortIndex",

    ProfileMinFrequency = "ProfileMinFrequency",
    ProfileMaxFrequency = "ProfileMaxFrequency",

    SignalColor = "SignalColor",

    IsTimeStream = "IsTimeStream",
    IsTimeBlock = "IsTimeBlock",

    GeneratedTime = "GeneratedTime",
}

export enum CloudAttribute {
    state = "state",
    system = "system",
    systemModules = "systemModules",
    startTime = "startTime",
    endTime = "endTime",
    duration = "duration",
    pcName = "pcName",
    pcVersion = "pcVersion",
    database = "database",
    edmCreated = "edmCreated",
    edmCurrent = "edmCurrent",
    SoftwareMode = "SoftwareMode",
    MeasureType = "MeasureType",
    CreateDateTime = "CreateDateTime",
    ModifiedDateTime = "ModifiedDateTime",
    LastRunDateTime = "LastRunDateTime",
    Description = "Description",
    RunTimes = "RunTimes",
    TimeElapsed = "TimeElapsed",
    endCondition = "endCondition",
}

export enum NumericalValueType {
    Level = "Level",
    TargetRMS = "TargetRMS",
    DrivePk = "DrivePk",
    Remaining = "Remaining",
    FullLevelElapsed = "FullLevelElapsed",
    Pk = "Pk",
    PkPk = "PkPk",
    TotalElapsed = "TotalElapsed",
    CtrlRMS = "CtrlRMS",
    Kurtosis = "Kurtosis",
    Frequency = "Frequency",
    SweepCount = "SweepCount",
    SweepSpeed = "SweepSpeed",
    Phase = "Phase",
    ControlPeak = "ControlPeak",
    TargetPeak = "TargetPeak",
    RMS = "RMS",
    LevelError = "LevelError",
    NextLevel = "NextLevel",
    NextDrivePk = "NextDrivePk",
    PulseWidth = "PulseWidth",
    Error = "Error",
    RmsError = "RmsError",
    Repeat = "Repeat",
    CtrlOSPL = "CtrlOSPL",
    TargetOSPL = "TargetOSPL",
    ExcitationPkA = "ExcitationPkA",
    ExcitationPkV = "ExcitationPkV",
    ExcitationPkPk = "ExcitationPkPk",
    UUTModelName = "UUTModelName",
    RunningStep = "RunningStep",
    Address = "Address",
    Latitude = "Latitude",
    Longitude = "Longitude",
}
