
import { RouteName } from "@/helpers";
import store from "@/store";
import axios from "axios";
import { HttpStatusCode, IsValidEmail } from "node-share";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "Verification",
})
export default class Verification extends Vue {
    get payload() {
        return this.$router.currentRoute.params.payload;
    }

    get email() {
        if (IsValidEmail(this.payload)) {
            return this.payload;
        }

        return undefined;
    }

    async mounted() {
        if (!this.payload) {
            this.$router.push({
                name: RouteName.SignUp,
            });
        }

        if (!this.email) {
            const r = await axios.get(`/api/v1/users/verification/${this.payload}`);

            if (r.status === HttpStatusCode.OK) {
                await store.dispatch("updateToken", r.data.data);

                if (r.data.msg) {
                    this.$message.success(r.data.msg);
                }
            } else {
                console.warn(r);
                this.$message.error(r.data.msg || "Token is invalid or has expired.");
            }
        }
    }

    async resendEmail() {
        const loading = this.$loading({
            lock: true,
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.3)",
        });

        try {
            const r = await axios.post(`/api/v1/users/verification/${this.email}`);

            if (r.status === HttpStatusCode.OK) {
                // console.info(r.data);
                this.$message.success(r.data.msg);
            } else {
                console.warn(r);
                this.$message.error(r.data.msg || "Please try again later.");
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            loading.close();
        }
    }

    tryAnotherEmailAddress() {
        this.$router.push({
            name: RouteName.SignUp,
        });
    }
}
