<template>
    <el-card class="box-card">
        <!-- title -->
        <div slot="header" class="clearfix">
            <h3>{{ this.TestName }}</h3>
            <span>{{ this.RunStatus }}</span>
            <div style="float: right; padding: 3px 0">
                <!-- <el-checkbox v-model="refreshData" style="padding-right: 2em;">Refresh</el-checkbox> -->
                <el-switch
                    v-model="refreshData"
                    @change="handleRefreshData"
                    style="padding-right: 2em"
                />
                <el-button
                    type="success"
                    :disabled="IsRunning"
                    @click="ExecControlCommand(1)"
                    >Run</el-button
                >
                <el-button
                    type="warning"
                    :disabled="!IsRunning"
                    @click="ExecControlCommand(2)"
                    >{{ IsPaused ? "Continue" : "Pause" }}</el-button
                >
                <el-button
                    type="danger"
                    :disabled="!IsRunning"
                    @click="ExecControlCommand(3)"
                    >Stop</el-button
                >
                <!-- <el-button type="primary" :disabled="!IsRunning" @click="ExecControlCommand(4)">Save</el-button> -->
            </div>
        </div>

        <!-- status tags -->
        <div class="display-group">
            <!-- <div v-for="o in this.TagValues" :key="o" class="text item">
                {{ o }}
            </div>-->

            <div class="tag-group">
                <el-tag
                    effect="dark"
                    type="info"
                    color="#000"
                    style="color: #00ffff; margin-right: 1em"
                    :disable-transitions="true"
                    v-for="o in this.TagValues"
                    :key="o"
                    >{{ o }}</el-tag
                >
            </div>

            <div class="tag-group">
                <el-tag
                    effect="light"
                    :hit="true"
                    type="info"
                    style="margin-right: 1em"
                    :disable-transitions="true"
                    v-for="o in this.ParametersValues"
                    :key="o"
                    >{{ o }}</el-tag
                >
            </div>
        </div>

        <!-- main tabs -->
        <el-tabs
            type="border-card"
            @tab-click="onTabClick"
            :value="tabNames.FactoryStructure"
        >
            <el-tab-pane
                label="Factory Structure"
                :name="tabNames.FactoryStructure"
            >
                <el-container>
                    <el-aside width="260px">
                        <div class="display-group">
                            <!-- <h4 style="padding-bottom: 1ex;">Factory Structure</h4> -->
                            <div style="margin-bottom: 20px">
                                <el-button
                                    size="small"
                                    @click="GetVDSFactoryTree()"
                                    >Refresh</el-button
                                >
                            </div>
                            <el-tree
                                :data="vdsTreeData"
                                :props="defaultPropsForVDSStructure"
                                @node-click="HandleVDSStructureNodeClick"
                                default-expand-all
                            ></el-tree>
                        </div>
                    </el-aside>
                    <el-main>
                        <SignalViewer
                            :options="options"
                            :newOptions="newOptions"
                            :isFrequencySignal="false"
                            :isLoading="isLoading"
                        />

                        <SignalViewer
                            :options="spectrumOptions"
                            :newOptions="newSpectrumOptions"
                            :isFrequencySignal="true"
                            :isLoading="isLoading"
                        />
                    </el-main>
                </el-container>
            </el-tab-pane>

            <el-tab-pane label="Signals" :name="tabNames.Signals" v-if="false">
                <el-container>
                    <el-aside width="200px">
                        <div class="display-group">
                            <div class="tag-group">
                                <el-button
                                    size="mini"
                                    type="info"
                                    plain
                                    @click="GetSignalList()"
                                    >Get Signal List</el-button
                                >
                            </div>
                        </div>

                        <el-tree
                            show-checkbox
                            ref="signalTree"
                            node-key="id"
                            :data="SignalTreeData"
                            :props="defaultProps"
                            :default-expand-all="true"
                            @node-click="handleNodeClick"
                            @check-change="handleCheckChange"
                        ></el-tree>
                    </el-aside>

                    <el-main>
                        <!-- <el-button type="primary" round @click="resizeChart()">Resize</el-button> -->
                        <!-- <v-chart :chartType="chartType.TimeStreams" ref="chartTimeStreams" :option="chartOptionTimeValue" :auto-resize="true" style="width: 100%; min-height: 400px"></v-chart>
                        <v-chart :chartType="chartType.TimeBlocks" ref="chartTimeBlocks" :option="chartOptionTimeValue" :auto-resize="true" style="width: 100%; min-height: 400px"></v-chart>
                        <v-chart :chartType="chartType.APS" ref="chartAPS" :option="chartOptionValueLog" :auto-resize="true" style="width: 100%; min-height: 400px"></v-chart>-->

                        <v-chart
                            ref="chart"
                            v-for="chart in charts"
                            v-bind:key="chart.type"
                            v-bind:chartType="chart.type"
                            v-bind:option="chart.options"
                            :auto-resize="true"
                            style="width: 100%; min-height: 400px"
                        ></v-chart>
                    </el-main>
                </el-container>
            </el-tab-pane>

            <el-tab-pane label="Record" :name="tabNames.Record" v-if="false">
                <div class="display-group">
                    <el-row :gutter="5">
                        <!-- signals -->
                        <el-col :span="12">
                            <el-transfer
                                v-model="recordSignals"
                                :data="RecordSignalList"
                                :titles="['Signal name', 'Record list']"
                            />
                        </el-col>

                        <!-- numeric -->
                        <el-col :span="12">
                            <el-transfer
                                v-model="recordNumericValues"
                                :data="RecordNumericValueList"
                                :titles="['Signal attribute', 'Record list']"
                            />
                        </el-col>
                    </el-row>

                    <el-button
                        @click="handleSaveRecordSetting"
                        type="primary"
                        style="margin: 2ex 0; float: right"
                        >{{ isRecording ? "Stop Record" : "Record" }}</el-button
                    >
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-card>
</template>

<i18n>
{
    "en-US": {

    },
    "zh-CHS": {

    }
}
</i18n>

<script>
import SignalViewer from "@/components/chart/SignalViewer.vue";
import { eventHub, GetChartOption, GetSignalData, groupBy, IsDSA, IsTimeBlockSignal, IsTimeStreamSignal, PrepareVDSDisplayData } from "@/helpers";
import store from "@/store";
import { GenerateGuid } from "node-share";
import { RCM_Runner_Notify_Message } from "protoc/rcm_messages_pb";
import { CloudMessageType, TestRunningStatus } from "socket/protoc/generated/cloud_enums_pb";
import { CloudSignalList } from "socket/protoc/generated/cloud_messages_pb";
import { Component, Prop, Vue } from "vue-property-decorator";
import RealTimeInteractionManager from "../managers/RealTimeInteractionManager";
import VChart from "vue-echarts";

@Component({
    components: {
        SignalViewer,
        VChart,
    },

    name: "VDSLiveStatus",

    watch: {
        NumericDisplayType(val) {
            if (val && val.length > 0) {
                this.ns_form.attribute = val[0].value;
            }
        },
    },
})
export default class VDSLiveStatus extends Vue {
    @Prop()
    clientInfo;

    refreshData = false;

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    frame = undefined;

    triggerOn = false;
    outputOn = false;

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    signals = undefined;

    defaultProps = {
        children: "children",
        label: "name",
    };

    displayingSignalId = [];
    displayChanged = false;

    // vue-convert: This property will initialized in data() method, with `this` reference.
    chartOptionTimeValue = undefined;

    // vue-convert: This property will initialized in data() method, with `this` reference.
    chartOptionValueLog = undefined;

    chartType = {
        TimeStreams: "TimeStreams",
        TimeBlocks: "TimeBlocks",
        APS: "APS",
    };

    // timeStreamXAxisFirstValue: undefined
    charts = [];

    numericDisplayTags = [];
    addNumericDisplay = false;

    ns_form = {
        guid: undefined,
        signal: undefined,
        digits: 5,
        attribute: undefined,
    };

    formLabelWidth = "120px";

    NumericDisplaySignalTypes = [
        {
            name: "RMS",
            value: 0,
        },
        {
            name: "Peak",
            value: 1,
        },
        {
            name: "Peak-Peak",
            value: 2,
        },
        {
            name: "Mean",
            value: 3,
        },
        {
            name: "RPM",
            value: 4,
        },
        {
            name: "Signed peak",
            value: 5,
        },
        {
            name: "Peak Frequency",
            value: 6,
        },
    ];

    recordSignals = [];
    recordNumericValues = [];

    // limits
    limitItems = [
        {
            enable: false,
            value: 1,
            color: "#ffff00",
        },
        {
            enable: false,
            value: 2,
            color: "#ff0000",
        },
    ];

    predefineColors = [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94, 0.5)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577",
    ];

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    isRecording = undefined;

    tabNames = {
        FactoryStructure: "FactoryStructure",
        Signals: "Signals",
        Record: "Record",
    };

    isSignalsIdApplied = false;

    // ================== VDS
    vdsTreeData = [];

    defaultPropsForVDSStructure = {
        children: "children",
        label: "name",
    };

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    testRunningStatus = undefined;

    options = GetChartOption("value", "value", true);

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    newOptions = undefined;

    spectrumOptions = GetChartOption("log", "log", true);

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    newSpectrumOptions = undefined;

    isLoading = false;

    // vue-convert: vue-class-component ignores property with undefined, so data() method is required for this property.
    displayEntryId = undefined;

    data() {
        return {
            frame: undefined,
            signals: undefined,
            chartOptionTimeValue: this.getChartOption("value", "value"),
            chartOptionValueLog: this.getChartOption("log", "log"),
            isRecording: undefined,
            testRunningStatus: undefined,
            newOptions: undefined,
            newSpectrumOptions: undefined,
            displayEntryId: undefined,
        };
    }

    DataMessageReceived(message) {
        switch (message.getType()) {
            case CloudMessageType.VDS_NOTIFY_HRM_TEST_STATUS_CHANGED:
                {
                    const msg = RCM_Runner_Notify_Message.deserializeBinary(message.getData_asU8());
                    const status = Object.keys(TestRunningStatus).find(x => TestRunningStatus[x] === msg.getP1());

                    console.info(`VDS_NOTIFY_HRM_TEST_STATUS_CHANGED, ${status}`);

                    this.testRunningStatus = msg.getP1();

                    if (this.testRunningStatus === TestRunningStatus.RUNNING) {
                        this.SendDisplayNext();
                    }
                }
                break;

            case CloudMessageType.VDS_NOTIFY_HRM_TRIGGER_AVERAGE_REACHED:

                console.info("VDS_NOTIFY_HRM_TRIGGER_AVERAGE_REACHED");

                break;

            case CloudMessageType.GET_VDS_NEXT_DISPLAY_FRAME_OK:
                {
                    const msg = CloudSignalList.deserializeBinary(message.getData_asU8());
                    const signals = msg.toObject().signalsList;

                    // console.log(signals);

                    let options = PrepareVDSDisplayData(signals.filter(p => IsTimeBlockSignal(p)));
                    if (options !== undefined) {
                        this.newOptions = options;
                    }

                    options = PrepareVDSDisplayData(signals.filter(p => !IsTimeBlockSignal(p)));
                    if (options !== undefined) {
                        this.newSpectrumOptions = options;
                    }

                    setTimeout(() => {
                        if (this.testRunningStatus === TestRunningStatus.RUNNING) {
                            this.SendDisplayNext();
                        }
                    }, 500);
                }
                break;
        }
    }

    GetVDSFactoryTree() {
        this.GetVDSStructure();
    }

    HandleVDSStructureNodeClick(data) {
        // console.log(data);

        if (data.node && data.node.getScnType() === 4) {
            this.displayEntryId = data.node.getScnGuid();
            this.SendDisplayNext();
        }
    }

    SendDisplayNext() {
        if (this.displayEntryId !== undefined) {
            RealTimeInteractionManager.GetVDSNextDisplayFrame(this.clientInfo, this.displayEntryId);
        }
    }

    ExecControlCommand(cmd) {
        RealTimeInteractionManager.ExecControlCommand(this.clientInfo, cmd);
    }

    TriggerChange(on) {
        console.log(`TriggerChange: ${on}`);
        RealTimeInteractionManager.ExecControlCommand(this.clientInfo, 20, undefined, undefined, on);
    }

    OutputChange(on) {
        console.log(`OutputChange: ${on}`);
        RealTimeInteractionManager.ExecControlCommand(this.clientInfo, 21, undefined, undefined, on);
    }

    GetSignalList() {
        RealTimeInteractionManager.GetSignalList(this.clientInfo);
    }

    GetVDSStructure() {
        RealTimeInteractionManager.GetVDSStructure(this.clientInfo);
    }

    refreshFrame(frame) {
        this.frame = frame;

        this.triggerOn = this.TriggerOn;
        this.outputOn = this.OutputOn;

        // console.log(frame);

        if (this.signals === undefined) {
            if (frame.signalsList) {
                this.signals = { signalsList: frame.signalsList };
            }
        }

        this.isRecording = frame.recordStatus;

        this.refreshChart();
        this.refreshNumericDisplay();
    }

    refreshVDSFactoryTree(tree) {
        console.log(tree);

        this.vdsTreeData = tree;
    }

    refreshSignalList(signals) {
        this.signals = signals;

        // console.log(signals);

        if (this.ns_form.signal === undefined) {
            this.ns_form.signal = this.NumericDisplaySignals[0].id;
        }
    }

    refreshRecordStatus(status) {
        this.isRecording = status;
    }

    getChartOption(xAxisType, yAxisType) {
        return GetChartOption(xAxisType, yAxisType);
    }

    getChartOptionBySignal(sig) {
        if (sig.type === "Time") {
            return this.chartOptionTimeValue;
        } else if (sig.type === "Frequency") {
            return this.chartOptionValueLog;
        }

        throw new Error(sig);
    }

    refreshChart(signals) {
        if (this.NeedRefreshSignalChart() !== true) {
            return;
        }

        if (signals === undefined) {
            signals = this.displayingSignalId.map((p) => this.frame.signalsList.find((s) => s.id === p)).filter((p) => p !== undefined);
        }
        // console.log(signals);

        const displayedSignals = [];

        if (signals.length === 0) {
            return;
        }

        const allCharts = this.$refs.chart;

        if (allCharts !== undefined) {
            for (const chart of allCharts) {
                try {
                    // console.log(chart);
                    const chartType = chart.$attrs.chartType;
                    // console.log(chartType);

                    const signalList = signals.filter((p) => this.getSignalChartType(p) === chartType);

                    // if (chartType === this.chartType.TimeStreams) {
                    //     signalList = signals.filter(p => p.group === "Time Streams");
                    // } else if (chartType === this.chartType.TimeBlocks) {
                    //     signalList = signals.filter(p => p.group === "Time Blocks");
                    // } else if (chartType === this.chartType.APS) {
                    //     signalList = signals.filter(p => p.group === "Auto-Power Spectra");
                    // } else {
                    //     throw new Error();
                    // }

                    if (signalList.length > 0) {
                        const oldChartOptions = chart.chart.getOption();
                        const oldSeries = oldChartOptions.series;

                        const xAxisType = oldChartOptions.xAxis[0].type;
                        const yAxisType = oldChartOptions.yAxis[0].type;

                        for (const s of signalList) {
                            displayedSignals.push(s.id);
                        }

                        const sig = signalList[0];

                        const series = signalList.map((p) => {
                            return {
                                name: p.name,
                                type: "line",
                                showSymbol: false,
                                // hoverAnimation: false,
                                emphasis: {
                                    scale: false,
                                },
                                smooth: true,
                                data: this.getSignalData(p),
                            };
                        });

                        if (this.displayChanged === true) {
                            for (const old of oldSeries) {
                                let exist = false;
                                for (const cur of series) {
                                    if (old.name === cur.name) {
                                        exist = true;
                                        break;
                                    }
                                }

                                if (exist) {
                                    continue;
                                }

                                if (old.data && old.data.length > 0) {
                                    console.log(`remove signal: ${old.name}`);
                                    series.push({
                                        name: old.name,
                                        data: [],
                                    });
                                }
                            }
                        }

                        // console.log(chartType);
                        // console.log(series);

                        const names = signalList.map((p) => p.name);
                        // const title = names.join(",");
                        // console.log(title);

                        const newOptions = {
                            // title: {
                            //     text: `(${title})`
                            // },
                            legend: {
                                data: names,
                            },
                            xAxis: {
                                name: sig.xUnit,
                                nameLocation: "end",
                                scale: xAxisType === "log" || this.getSignalIsTimeStream(sig),
                            },
                            yAxis: {
                                name: sig.yUnit,
                            },
                            series: series,
                        };

                        if (yAxisType === "log") {
                            // to be refined

                            const minY = Math.min(...series.reduce((p, c) => p.concat(c.data.map((d) => d[1])), []).filter((p) => p > 0));

                            newOptions.yAxis.min = function(value) {
                                return value.min === 0 ? minY / 10 : value.min / 10;
                            };
                            newOptions.yAxis.max = function(value) {
                                return value.max * 10;
                            };
                        }

                        chart.chart.setOption(newOptions);
                    }
                } catch (ex) {
                    console.error(ex);
                    console.warn(signals);
                }
            }
        }

        if (displayedSignals.length < signals.length) {
            // need add signal to new chart
            const addedSignals = [];
            for (const s1 of signals) {
                let displayed = false;
                for (const s2 of displayedSignals) {
                    if (s1.id === s2) {
                        displayed = true;
                        break;
                    }
                }

                if (displayed) {
                    continue;
                }

                const type = this.getSignalChartType(s1);
                if (this.charts.findIndex((p) => p.type === type) === -1) {
                    console.log(`add signal ${s1.name} to new chart.`);
                    addedSignals.push(s1);
                    this.charts.push({
                        type: type,
                        options: this.getChartOptionBySignal(s1),
                    });
                }
            }

            if (addedSignals.length > 0) {
                setTimeout(() => {
                    this.refreshChart(addedSignals);
                }, 1000);
            }
        }

        this.displayChanged = false;
    }

    getSignalChartType(sig) {
        // if (sig.type === "Time") {
        //     if (sig.nvhType === NvhType.Equidistant) {
        //         return this.chartType.TimeStreams;
        //     } else if (sig.nvhType === "NonEquidistant") {
        //         return this.chartType.TimeBlocks;
        //     }
        // } else if (sig.type === "Frequency") {
        //     if (sig.nvhType === "AutopowerSpectrum") {
        //         return this.chartType.APS;
        //     }
        // }
        return sig.group;
    }

    getSignalIsTimeStream(sig) {
        return IsTimeStreamSignal(sig);
    }

    getSignalIsTimeBlock(sig) {
        return IsTimeBlockSignal(sig);
    }

    getSignalData(sig) {
        if (sig && sig.dataList) {
            return GetSignalData(
                sig.dataList.map((p) => p.arrayList),
                sig,
            );
        }

        return [];
    }

    resizeChart() {
        // for (const chart of this.$refs.chart) {
        //     chart.chart.resize();
        // }
    }

    refreshDisplaySignal() {
        const tree = this.$refs.signalTree;
        const selectedNodes = tree.getCheckedKeys().filter((p) => p !== undefined); // tree.getCheckedNodes();

        let changed = false;

        if (selectedNodes.length !== this.displayingSignalId.length) {
            changed = tree;
        } else {
            for (const item of selectedNodes) {
                if (this.displayingSignalId.findIndex((p) => p !== item) === -1) {
                    changed = tree;
                    break;
                }
            }
        }

        if (changed) {
            this.displayingSignalId = selectedNodes;
            this.displayChanged = true;

            console.log(selectedNodes);

            RealTimeInteractionManager.UpdateDisplaySignals(this.clientInfo, this.displayingSignalId);

            this.resizeChart();
        }
    }

    handleRefreshData(val) {
        if (val) {
            RealTimeInteractionManager.StartRefresh(this.clientInfo);
        } else {
            RealTimeInteractionManager.StopRefresh(this.clientInfo);
        }

        // this.refreshData = val;
    }

    handleNodeClick(data) {
        // console.log(data);
    }

    handleCheckChange(data, checked, indeterminate) {
        // console.log(data, checked, indeterminate);

        if (data.id !== undefined) {
            // ignore changes of parent node
            this.refreshDisplaySignal();
        }
    }

    // Numeric Display
    handleClose(tag) {
        this.numericDisplayTags.splice(
            this.numericDisplayTags.findIndex((p) => p.guid === tag.guid),
            1,
        );

        RealTimeInteractionManager.UpdateNumericDisplay(this.clientInfo, this.NumericDisplayOptions);
    }

    handleSetting(tag) {
        // load
        this.ns_form.guid = tag.guid;
        this.ns_form.signal = tag.signal;
        this.ns_form.digits = tag.digits;
        this.ns_form.attribute = tag.attribute;

        this.limitItems = tag.limitItems.map((a) => ({ ...a }));

        this.addNumericDisplay = true;
    }

    showInput() {
        if (this.Signals.length === 0) {
            this.GetSignalList();
        }

        this.ns_form.guid = undefined;
        this.addNumericDisplay = true;
    }

    handleAddNumericDisplay() {
        let guid = this.ns_form.guid;
        let index;
        if (this.ns_form.guid !== undefined) {
            index = this.numericDisplayTags.findIndex((p) => p.guid === this.ns_form.guid);
            this.ns_form.guid = undefined;
        }

        if (guid === undefined) {
            guid = GenerateGuid();
        }

        // add new
        const newTag = {};

        Object.assign(newTag, this.ns_form);

        newTag.guid = guid;
        newTag.value = "0";

        newTag.limitItems = this.limitItems.map((a) => ({ ...a }));

        if (index !== undefined) {
            this.$set(this.numericDisplayTags, index, newTag);
        } else {
            this.numericDisplayTags.push(newTag);
        }

        RealTimeInteractionManager.UpdateNumericDisplay(this.clientInfo, this.NumericDisplayOptions);

        this.addNumericDisplay = false;
    }

    refreshNumericDisplay() {
        for (const val of this.NumericDisplayValues) {
            for (const tag of this.numericDisplayTags) {
                if (val.option.signalId === tag.signal && val.option.displaySignalType === tag.attribute) {
                    Object.assign(tag, val);
                    tag.value = (val.value.toPrecision(tag.digits) * 1).toString();
                }
            }
        }
    }

    getSignalById(id) {
        return this.Signals.find((p) => p.id === id);
    }

    getNumericDisplayTypeName(val) {
        return this.NumericDisplaySignalTypes.find((p) => p.value === val).name;
    }

    getNumericDisplayTypes(sig) {
        if (sig !== undefined) {
            const types = [...this.NumericDisplaySignalTypes];
            if (sig.type !== "Frequency") {
                types.splice(6, 1); // remove 'Peak Frequency'
            }

            if (sig.nvhyype !== "NonEquidistant" || !this.MeasurementConfigTypeName.includes("TTH")) {
                types.splice(5, 1); // remove 'Signed peak'
            }

            // TODO
            const IsTachoRPMSignal = false;
            if (!IsTachoRPMSignal) {
                types.splice(4, 1); // remove RPM
            }

            if (sig.nvhType === "AutopowerSpectrum") {
                types.splice(2, 2);
            }

            return types;
        }

        return [];
    }

    handleSaveRecordSetting() {
        if (this.isRecording) {
            RealTimeInteractionManager.UpdateRecordSetting(this.clientInfo, undefined, []);
        } else {
            RealTimeInteractionManager.UpdateRecordSetting(this.clientInfo, this.Test, this.RecordOptions);
        }
    }

    GetNumericDisplayStyle(tag) {
        let style = "";
        for (const limit of tag.limitItems) {
            if (limit.enable !== true) {
                continue;
            }

            if (tag.value > limit.value) {
                style = `color: ${limit.color}`;
            }
        }

        return style;
    }

    LoadConfig(name, defaultVal) {
        const configStr = localStorage.getItem(name);
        if (configStr) {
            try {
                return JSON.parse(configStr);
            } catch (ex) {
                console.error(ex);
            }
        }

        return defaultVal;
    }

    onTabClick(tab) {
        // console.log(tab);
        this.currentTab = tab.name;

        if (tab.name === this.tabNames.Signals) {
            if (this.isSignalsIdApplied !== true) {
                this.displayingSignalId = this.LoadConfig(this.ConfigName_DisplaySignalIDs, this.displayingSignalId);

                if (this.displayingSignalId && this.displayingSignalId.length > 0) {
                    this.$refs.signalTree.setCheckedKeys(this.displayingSignalId);

                    RealTimeInteractionManager.UpdateDisplaySignals(this.clientInfo, this.displayingSignalId);
                }

                this.isSignalsIdApplied = true;
            }
        }
    }

    NeedRefreshSignalChart() {
        return this.currentTab === this.tabNames.Signals;
    }

    get IsDSA() {
        return IsDSA(this.clientInfo.AppId);
    }

    get tableData() {
        return store.getters.Clients;
    }

    get name() {
        return this.clientInfo.MachineCode + this.clientInfo.AppId;
    }

    get refreshFrameName() {
        return this.name + RealTimeInteractionManager.Names.refreshFrame;
    }

    get refreshSignalListName() {
        return this.name + RealTimeInteractionManager.Names.refreshSignalList;
    }

    get refreshRecordStatusName() {
        return this.name + RealTimeInteractionManager.Names.refreshRecordStatus;
    }

    get refreshVDSFactoryTreeName() {
        return this.name + RealTimeInteractionManager.Names.refreshVDSFactoryTree;
    }

    get Test() {
        if (this.frame && this.frame.test) {
            return this.frame.test;
        }

        return undefined;
    }

    get MeasurementConfigTypeName() {
        if (this.Test) {
            return this.Test.measurementConfigTypeName;
        }

        return undefined;
    }

    get TestName() {
        if (this.frame && this.frame.test) {
            return this.frame.test.name;
        }

        return "";
    }

    get IsRunning() {
        return this.RunStatus === "Running" || this.RunStatus === "Paused";
    }

    get IsPaused() {
        return this.RunStatus === "Paused";
    }

    get RunStatus() {
        if (this.testRunningStatus !== undefined) {
            switch (this.testRunningStatus) {
                case 0:
                    return "Unknown";

                case 1:
                    return "Running";

                case 2:
                    return "Paused";

                case 3:
                    return "Stopped";
            }
        }

        return "Unknown";
    }

    get TagValues() {
        if (this.frame && this.frame.controlPanelStatus) {
            const vcs = this.frame.controlPanelStatus.vcs;
            if (vcs) {
                return [`Level: ${vcs.level.toFixed(0)}%`, `DrivePk: ${vcs.drivePk.toFixed(3)}`, `CtrlPeak: ${vcs.ctrlPeak.toFixed(3)}`, `Frequency: ${vcs.frequency.toFixed(3)}`];
            }

            const dsa = this.frame.controlPanelStatus.dsa;
            if (dsa) {
                return [`Total elapsed: ${dsa.totalElapsed}`, `Output pk: ${dsa.outputPk}`, `Frame#: ${dsa.frame}`];
            }
        }

        return [];
    }

    get ParametersValues() {
        if (this.frame && this.frame.controlPanelStatus) {
            const dsa = this.frame.controlPanelStatus.dsa;
            if (dsa) {
                return [
                    `Frequency range(hz): ${dsa.frequencyRange}`,
                    `Block size/Line: ${dsa.blockSizeLine}`,
                    `Window: ${dsa.window}`,
                    `Overlap ratio: ${dsa.overlapRatio}`,
                    `Average mode: ${dsa.averageMode}`,
                    `Average number: ${dsa.averageNumber}`,
                ];
            }
        }

        return [];
    }

    get TriggerOn() {
        if (this.frame && this.frame.controlPanelStatus) {
            const dsa = this.frame.controlPanelStatus.dsa;
            if (dsa) {
                return dsa.triggerOn;
            }
        }

        return undefined;
    }

    get OutputOn() {
        if (this.frame && this.frame.controlPanelStatus) {
            const dsa = this.frame.controlPanelStatus.dsa;
            if (dsa) {
                return dsa.outputOn;
            }
        }

        return undefined;
    }

    get FactoryStructure() {
        if (this.frame && this.frame.channelStatus) {
            const status = this.frame.channelStatus.displayValuesList;
            const data = [];

            for (const s of status) {
                data.push({
                    LocationID: s.locationid,
                    Overload: s.isoverload ? "Yes" : "No",
                    Unit: s.unit,
                    Min: s.min.toFixed(5),
                    Max: s.max.toFixed(5),
                    RMS: s.rms.toFixed(5),
                    Peak: s.peak.toFixed(5),
                });
            }

            return data;
        }

        return undefined;
    }

    get Signals() {
        if (this.signals) {
            return this.signals.signalsList;
        }

        return [];
    }

    get NumericDisplaySignals() {
        return this.Signals.filter((p) => this.getSignalIsTimeStream(p) === false);
    }

    get NumericDisplayType() {
        const sig = this.Signals.find((p) => p.id === this.ns_form.signal);
        return this.getNumericDisplayTypes(sig);
    }

    get SignalTreeData() {
        if (this.signals !== undefined) {
            // return this.signals.signalsList.map(p => {
            //     return {
            //         label: p.name
            //     };
            // });
            const all = this.signals.signalsList;
            const grouped = groupBy("group", all);

            const data = [];
            for (const g in grouped) {
                data.push({
                    name: g,
                    children: grouped[g],
                });
            }

            return data;
        }

        return undefined;
    }

    get NumericDisplayOptions() {
        if (this.numericDisplayTags) {
            return this.numericDisplayTags.map((p) => {
                return {
                    signalId: p.signal,
                    type: p.attribute,
                };
            });
        }

        return [];
    }

    get NumericDisplayValues() {
        if (this.frame && this.frame.numericDisplayItems) {
            return this.frame.numericDisplayItems.itemsList;
        }

        return [];
    }

    get RecordSignalList() {
        const data = [];

        if (this.Signals) {
            for (const sig of this.Signals) {
                data.push({
                    key: `Signal_${sig.id}`,
                    label: sig.name,
                    disabled: false,
                });
            }
        }

        return data;
    }

    get RecordNumericValueList() {
        const data = [];

        if (this.Signals) {
            for (const sig of this.NumericDisplaySignals) {
                const types = this.getNumericDisplayTypes(sig);

                for (const t of types) {
                    data.push({
                        key: `${t.name}_${sig.id}`,
                        label: `${sig.name}: ${t.name}`,
                        disabled: false,
                    });
                }
            }
        }

        return data;
    }

    get RecordOptions() {
        const options = [];

        for (const item of this.recordSignals) {
            const temp = item.split("_");
            options.push({
                type: 1,
                sig: this.Signals.find((p) => p.id.toString() === temp[1]),
            });
        }

        for (const item of this.recordNumericValues) {
            const temp = item.split("_");
            options.push({
                type: this.NumericDisplaySignalTypes.find((p) => p.name === temp[0]),
                sig: this.Signals.find((p) => p.id.toString() === temp[1]),
            });
        }

        return options;
    }

    get ConfigName_NumericDisplay() {
        return `${this.name}_NumericDisplay`;
    }

    get ConfigName_RecordSignals() {
        return `${this.name}_RecordSignals`;
    }

    get ConfigName_RecordSignalAttributes() {
        return `${this.name}_RecordSignalAttributes`;
    }

    get ConfigName_DisplaySignalIDs() {
        return `${this.name}_ConfigName_DisplaySignalIDs`;
    }

    mounted() {
        // this.GetSignalList();
        // // auto start refresh when mounted
        // SocketManager.StartRefresh(this.clientInfo);
        // // load configs
        // this.numericDisplayTags = this.LoadConfig(
        //     this.ConfigName_NumericDisplay,
        //     this.numericDisplayTags
        // );
        // this.recordSignals = this.LoadConfig(
        //     this.ConfigName_RecordSignals,
        //     this.recordSignals
        // );
        // this.recordNumericValues = this.LoadConfig(
        //     this.ConfigName_RecordSignalAttributes,
        //     this.recordNumericValues
        // );
        // // apply
        // if (this.numericDisplayTags && this.numericDisplayTags.length > 0) {
        //     SocketManager.UpdateNumericDisplay(
        //         this.clientInfo,
        //         this.NumericDisplayOptions
        //     );
        // }
        // // if (this.displayingSignalId && this.displayingSignalId.length > 0) {
        // //     SocketManager.UpdateDisplaySignals(
        // //         this.clientInfo,
        // //         this.displayingSignalId
        // //     );
        // // }

        this.GetVDSFactoryTree();
    }

    created() {
        console.log("created VDSLiveStatus");

        eventHub.$on(this.refreshFrameName, this.refreshFrame);
        eventHub.$on(this.refreshSignalListName, this.refreshSignalList);
        eventHub.$on(this.refreshRecordStatusName, this.refreshRecordStatus);
        eventHub.$on(this.refreshVDSFactoryTreeName, this.refreshVDSFactoryTree);

        RealTimeInteractionManager.DataReceived.on(this.DataMessageReceived);
    }

    destroyed() {
        RealTimeInteractionManager.StopRefresh(this.clientInfo);
        eventHub.$off(this.refreshFrameName, this.refreshFrame);
        eventHub.$off(this.refreshSignalListName, this.refreshSignalList);
        eventHub.$off(this.refreshRecordStatusName, this.refreshRecordStatus);
        eventHub.$off(this.refreshVDSFactoryTreeName, this.refreshVDSFactoryTree);

        RealTimeInteractionManager.DataReceived.off(this.DataMessageReceived);

        console.log("destroyed VDSLiveStatus");

        // save configs
        localStorage.setItem(this.ConfigName_NumericDisplay, JSON.stringify(this.numericDisplayTags));
        localStorage.setItem(this.ConfigName_RecordSignals, JSON.stringify(this.recordSignals));
        localStorage.setItem(this.ConfigName_RecordSignalAttributes, JSON.stringify(this.recordNumericValues));
        localStorage.setItem(this.ConfigName_DisplaySignalIDs, JSON.stringify(this.displayingSignalId));
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}

.tag-group {
    margin-top: 2ex;
}

.display-group {
    padding: 2ex 0;
}

/*numeric display*/
.el-tag + .el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    /* height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0; */
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.display-group .tags {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
}

.box-card-numeric {
    width: calc(33.3% - 1rem);
    min-width: 380px;
    margin: 1rem 0.5rem;
    /* display: inline-block; */
}

.numeric-display-text {
    text-align: center;
    color: #008000;
    /* font-size: -webkit-xxx-large; */
    font-weight: 500;
    font-size: 4vw;
}

.numeric-display-btn-close {
    float: right;
    padding: 3px;
    margin: -14px -16px 3px 10px;
}

.numeric-display-btn-setting {
    float: right;
    padding: 3px;
    margin: -14px -6px 3px 10px;
}

.el-main {
    background-color: #e9eef3;
    color: #333;
    height: 100%;
}
</style>
