
import store from "@/store";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "ChangePassword",
})
export default class ChangePassword extends Vue {
    form = {
        currentPassword: "",
        password: "",
        confirmPassword: "",
    };

    rules = {
        currentPassword: [
            { required: true, message: "Please enter your current password", trigger: "blur" },
        ],
        password: [
            { required: true, message: "Please type a new password.", trigger: "blur" },
            { min: 6, message: "A password must be at least six characters long.", trigger: "blur" },
        ],
        confirmPassword: [
            { required: true, message: "Please retype your new password.", trigger: "blur" },
            {
                validator: (rule: never, value: string, callback: (err?: Error) => void): void => {
                    if (value === "") {
                        callback(new Error("Please input the password again"));
                    } else if (value !== this.form.password) {
                        callback(new Error("Two inputs don't match"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
    };

    created() {
        store.state.useBaseLayout = false;
    }

    destroyed() {
        store.state.useBaseLayout = true;
    }

    submitForm(formName: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const f = this.$refs[formName] as any;
        f.validate(async (valid: boolean) => {
            if (valid) {
                const loading = this.$loading({
                    lock: true,
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.3)",
                });

                try {
                    const r = await axios.put("/api/v1/users/password", this.form);

                    if (r.status === HttpStatusCode.OK) {
                        this.$router.back();

                        if (r.data.msg) {
                            this.$message.success(r.data.msg);
                        }
                    } else {
                        if (r.data.msg) {
                            this.$message.error(r.data.msg);
                        } else {
                            console.warn(r);
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                } finally {
                    loading.close();
                }
            } else {
                return false;
            }
        });
    }
}
