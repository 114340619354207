<template>
    <div class="main-container-content">
        <!--div id="content-header">
        <div id="breadcrumb">
            <router-link to="/dashboard" title="Go to Home" class="tip-bottom">
            <i class="ivu-icon ivu-icon-home"></i> Home
            </router-link>
            <a href="#" class="current">Test Runlog</a>
        </div>
        </div-->

        <div class="container-fluid">
            <div class="row-fluid">
                <div class="widget-box">
                    <div class="widget-content nopadding">
                        <keep-alive>
                            <router-view />
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DataTable",
    data() {
        return {
            columns1: [
                {
                    title: "absoluteTime",
                    width: 245,
                    key: "absoluteTime",
                },
                {
                    title: "testTime",
                    width: 180,
                    key: "testTime",
                },
                {
                    title: "title",
                    key: "title",
                },
            ],
            data1: [],
        };
    },
    created: function() {
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
