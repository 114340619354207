/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/chart.ts
 * @create:      2019-07-29 15:52:57.410
 * @modify:      2023-04-14 17:18:23.409
 * @version:     0.1.1
 * @times:       473
 * @lines:       1187
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import { DisplayFormatX, DisplayFormatY, FormatNumber, IChartOptions, ParseColor, SignalNames } from "@/helpers";
import store from "@/store";
import { LineSeriesOption } from "echarts";
import { ECBasicOption } from "echarts/types/dist/shared";
import moment, { Moment } from "moment";
import { ConvertTo } from "node-share";
import { ISignal, ISignalProperty, SignalAttributeName, TryGetAttribute, TryGetBooleanFromAttributes, TryGetDoubleFromAttributes, TryGetInt64FromAttributes } from "socket/common";
import { CommonConstant, IAxisMinMax, IDateLike, NvhType, PlotDateTimeFormat, UnitsHumidity, UnitsTemperature, sqrt10 } from "./base";

type OptionAxisType = "value" | "category" | "time" | "log";

export function GetChartName(signalNames: string[]) {
    let name = "(No Signals)";
    if (signalNames.length > 0) {
        const limit = 4;
        name = signalNames.slice(0, 4).join(",");

        if (signalNames.length > limit) {
            name = `(${name}, etc.[${signalNames.length}])`;
        } else {
            name = `(${name})`;
        }
    }

    return name;
}

export function BuildDataViewTable(series: LineSeriesOption[], xAxisName: string, yAxisName: string, isXAxisDate: boolean): string {
    try {
        const xType = `X ${xAxisName}`;

        const names = series.map(p => "<th>" + `${p.name} Y ${yAxisName}` + "</th>").join("");
        let table =
            "<thead><tr>" +
            "<th/>" +
            `<th>${xType}</th>` +
            names +
            "</tr></thead><tbody>";

        // console.log(series);
        const len = Math.max(...series.map(p => p.data?.length ?? 0));

        if (len === Infinity) {
            return "";
        }

        for (let i = 0; i < len; i++) {
            try {
                let x;
                const yValues: string[] = [];

                let anyValidY = false;

                for (const s of series) {
                    const data = s?.data?.[i];
                    let y;

                    if (Array.isArray(data)) {
                        if (x === undefined) {
                            if (isXAxisDate) {
                                x = moment.utc(data[0]).format("YYYY/MM/DD HH:mm:ss.SSS");
                            } else {
                                x = FormatNumber(data[0], 3);
                            }
                        }

                        y = data[1];

                        if (y !== undefined) {
                            anyValidY = true;
                        }
                    }

                    yValues.push(`<td class='cell'>${FormatNumber(y, 4)}</td>`);
                }

                if (!anyValidY) {
                    continue;
                }

                table += "<tr>" +
                    `<td>${i}</td>` +
                    `<td>${x}</td>` +
                    yValues.join("") +
                    "</tr>";
            } catch (e) {
                console.warn(e);
            }
        }
        table += "</tbody>";

        return table;
    } catch (ex) {
        console.error(ex);
        return "";
    }
}

export function GetChartOption(
    xAxisType: OptionAxisType,
    yAxisType: OptionAxisType,
    enableDataZoom = false,
) {
    const isXTime = xAxisType === "time";

    const option: ECBasicOption = {
        grid: {
            containLabel: true,
            left: 20,
            right: 120,
            bottom: 20,
        },
        xAxis: {
            type: xAxisType, // "time", //"value",
            // min: "dataMin",
            // min: function (val) {
            //     return val.min - (val.max - val.min) * 0.2;
            // },
            // max: "dataMax",
            // max: function (val) {
            //     return val.max + (val.max - val.min) * 0.2;
            // },
            scale: true,
            axisLine: {
                onZero: false,
            },
            position: "bottom",
            axisLabel: {
                formatter: isXTime ? "{HH}:{mm}:{ss}" : ConvertTo(AxisLabelFormat),
            },
        },
        yAxis: {
            // type: "log",
            type: yAxisType, // "value",
            // min: "dataMin",
            // min: (val: any) => {
            //     return val.min - (val.max - val.min) * 0.2;
            // },
            // max: "dataMax",
            // max: (val: any) => {
            //     return val.max + (val.max - val.min) * 0.2;
            // },
            scale: true,
            axisLine: {
                onZero: false,
            },
            axisLabel: {
                formatter: ConvertTo(AxisLabelFormat),
            },
            // splitNumber: 10,

            position: "left",

            // log axis display bugs:
            // https://github.com/apache/incubator-echarts/issues/8041
            // https://github.com/apache/incubator-echarts/issues/8014
        },
        tooltip: {
            trigger: "axis",
            triggerOn: "mousemove|click",
        },
        toolbox: {
            show: true,
            orient: "vertical",
            top: 30,
            right: 3,
            showTitle: true,
            feature: {
                dataZoom: {
                    yAxisIndex: "none",
                },
                myDataView: {},
                // myDataView: {
                //     show: true,
                //     title: "Data View",
                //     icon: "path://M17.5,17.3H33 M17.5,17.3H33 M45.4,29.5h-28 M11.5,2v56H51V14.8L38.4,2H11.5z M38.4,2.2v12.7H51 M45.4,41.7h-28",
                //     onclick(arg: unknown) {
                //         console.info(arg);
                //     },
                // },
                magicType: { type: ["line", "bar"] },
                myTool1: {},
                saveAsImage: {},
                // myTool2: {
                //     show: true,
                //     title: "yAxis Log/Value",
                //     // icon: "image://http://echarts.baidu.com/images/favicon.png",
                //     icon: "path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891",
                //     onclick() {
                //         alert("myToolHandler2");
                //     },
                // },
            },
        },
        series: [], // reset
        dataZoom: [],
        useUTC: true, // always use UTC
    };

    // (option.yAxis as any).min = (val: any) => {
    //     return val.min - (val.max - val.min) * 0.2;
    // };

    // (option.yAxis as any).max = (val: any) => {
    //     return val.max + (val.max - val.min) * 0.2;
    // };

    if (enableDataZoom) {
        option.dataZoom = [
            {
                type: "slider",
                show: true,
                xAxisIndex: [0],
                // start: 1,
                // end: 35
            },
            {
                type: "slider",
                show: true,
                yAxisIndex: [0],
                // left: "93%",
                // start: 29,
                // end: 36
            },
            // {
            //     type: "inside",
            //     xAxisIndex: [0],
            //     // start: 1,
            //     // end: 35
            // },
            // {
            //     type: "inside",
            //     yAxisIndex: [0],
            //     // start: 29,
            //     // end: 36
            // },
        ];
    }

    // console.log(option);

    return option;
}

export function GetSignalDataMinMax(data: (number[][] | undefined)[], options: IChartOptions, minMax?: IAxisMinMax, isXIncreasing = true) {
    if (!minMax) {
        minMax = {};
    }

    const validData = data.filter(p => p && p.length > 1 && p[0].length > 1 && p[1].length > 1) as number[][][];

    const getValues = (values: number[][], d: number, noZero: boolean) => {
        const dValues = values.map(p => p[d]).filter(p => !isNaN(p));
        return noZero ? dValues.filter(p => p > 0) : dValues;
    };

    const getFirstValue = (values: number[][], d: number, noZero: boolean) => {
        for (const v of values[d]) {
            if (isNaN(v)) {
                continue;
            }

            if (noZero && v <= 0) {
                continue;
            }

            return v;
        }

        return undefined;
    };

    const getLastValue = (values: number[][], d: number, noZero: boolean) => {
        for (let i = values.length - 1; i >= 0; i--) {
            const v = values[i][d];
            if (isNaN(v)) {
                continue;
            }

            if (noZero && v <= 0) {
                continue;
            }

            return v;
        }

        return undefined;
    };

    const isXLog = options.xFormat === DisplayFormatX.Log;
    const isYLog = options.yFormat === DisplayFormatY.LogMag;

    if (minMax.xMin === undefined) {
        if (isXIncreasing) {
            minMax.xMin = Math.min(...(validData.map(p => getFirstValue(p, 0, isXLog)).filter(p => p !== undefined) as number[]));
        } else {
            minMax.xMin = Math.min(...validData.map(p => Math.min(...getValues(p, 0, isXLog))));
        }
    }

    if (minMax.xMax === undefined) {
        if (isXIncreasing) {
            minMax.xMax = Math.max(...(validData.map(p => getLastValue(p, 0, isXLog)).filter(p => p !== undefined) as number[]));
        } else {
            minMax.xMax = Math.max(...validData.map(p => Math.max(...getValues(p, 0, isXLog))));
        }
    }

    for (const d of validData) {
        for (let i = 0; i < d.length; i++) {
            const x = d[i][0];
            const y = d[i][1];

            if (x < minMax.xMin) {
                continue;
            }

            if (x > minMax.xMax) {
                break;
            }

            if (y === undefined || y === null) {
                continue;
            }

            if (minMax.yMin === undefined) {
                minMax.yMin = y;
            }

            if (minMax.yMax === undefined) {
                minMax.yMax = y;
            }

            if (y < minMax.yMin) {
                minMax.yMin = y;
            }

            if (y > minMax.yMax) {
                minMax.yMax = y;
            }
        }
    }

    if (minMax.yMin !== undefined && minMax.yMax !== undefined) {
        const yMin = minMax.yMin;
        const yMax = minMax.yMax;

        let minY = yMin;
        let maxY = yMax;

        if (isYLog) {
            minY /= sqrt10;
            maxY *= sqrt10;
        } else {
            const d1 = yMax - yMin;

            minY = yMin - (d1 * 0.2);
            maxY = yMax + (d1 * 0.2);

            if (isYLog && d1 > 1) {
                const d2 = Math.log10(d1);

                minY = yMin * (d2 * 0.2);
                maxY = yMax / (d2 * 0.2);
            } else if (minY === maxY) {
                minY -= 0.5;
                maxY += 0.5;
            }
        }

        minMax.yMin = minY;
        minMax.yMax = maxY;
    }

    return minMax;
}

export function GetChartLabel(signal: ISignal, dimension: number): string {
    if (!IsSignal(signal)) {
        return "";
    }

    try {
        if (dimension === 0) {
            const isXAxisTimeSignal = IsDateSignal(signal);
            if (isXAxisTimeSignal) {
                return CommonConstant.WellKnownTime;
            }
        }

        if (Array.isArray(signal.Data)) {
            if (signal.Data[dimension]?.Label) {
                return signal.Data[dimension].Label;
            }
        }

        if (signal.Units) {
            return signal.Units[dimension] ?? "";
        }
    } catch (ex) {
        console.error(ex);
    }

    return "";
}

export function GetChartOptionBySignal(
    signals: ISignal[],
    options: IChartOptions,
) {
    let xAxisType: OptionAxisType = "value";
    let yAxisType: OptionAxisType = "value";

    if (options.xFormat) {
        xAxisType = options.xFormat === DisplayFormatX.Log ? "log" : "value";
    }

    if (options.yFormat) {
        yAxisType = options.yFormat === DisplayFormatY.LogMag ? "log" : "value";
    }

    const enableDataZoom = options.enableDataZoom === true;

    if (!signals || signals.length < 1) {
        console.warn("no signals");

        const option = GetChartOption(xAxisType, yAxisType, enableDataZoom);
        return option;
    }

    const signal = signals[0];
    // const isTimeSignal = IsTimeSignal(signal);
    const isFrequencySignal = IsFrequencySignal(signal);
    const isTemperatureSignal = IsTemperatureSignal(signal);
    const isHumiditySignal = IsHumiditySignal(signal);
    const isDateSignal = IsDateSignal(signal);
    const isTimeSignal = IsTimeSignal(signal);

    const isHum = IsHumiditySignal(signal);
    const isTemp = IsTemperatureSignal(signal) || signal?.Name?.includes("_PID");

    options.xPrecision = 2;
    options.yPrecision = isHum ? 1 : isTemp ? 2 : 4;

    if (isFrequencySignal) {
        if (!options.xFormat) {
            options.xFormat = DisplayFormatX.Log;
            xAxisType = "log";
        }

        if (!options.yFormat) {
            options.yFormat = DisplayFormatY.LogMag;
            yAxisType = "log";
        }
    }

    options.isXAxisDate = isDateSignal;
    options.isXAxisTime = isTimeSignal;

    if (isDateSignal) {
        xAxisType = "time";
    }

    const series = GetSignalSeries(signals, options);
    const option = GetChartOption(xAxisType, yAxisType, enableDataZoom);

    option.legend = {
        data: signals.map(p => p.Name),
        type: "scroll",
        // orient: "vertical",
    };

    option.series = ConvertTo(series);

    let minMax: IAxisMinMax = {};

    if (isFrequencySignal) {
        const minFrequency = TryGetDoubleFromAttributes(signal.Attributes, SignalAttributeName.ProfileMinFrequency);
        const maxFrequency = TryGetDoubleFromAttributes(signal.Attributes, SignalAttributeName.ProfileMaxFrequency);

        if (minFrequency !== undefined && maxFrequency !== undefined) {
            minMax.xMin = minFrequency;
            minMax.xMax = maxFrequency;
        }
    }

    minMax = GetSignalDataMinMax(series.map(p => p.data), options, minMax);

    if (isHumiditySignal) {
        if (minMax.yMin !== undefined && minMax.yMin > 0 && minMax.yMin < 100) {
            minMax.yMin = 0;
        }

        if (minMax.yMax !== undefined && minMax.yMax > 0 && minMax.yMax < 100) {
            minMax.yMax = 100;
        }
    } else if (isTemperatureSignal) {
        if (signals.length === 1) {
            if (minMax.yMin !== undefined) {
                minMax.yMin -= 10;
            }

            if (minMax.yMax !== undefined) {
                minMax.yMax += 10;
            }
        }
    }

    options.axisMinMax ??= {};
    Object.assign(options.axisMinMax, minMax);

    if (options.axisMinMax.xFixed) {
        if (options.xMin !== undefined) {
            minMax.xMin = options.xMin;
        }

        if (options.xMax !== undefined) {
            minMax.xMax = options.xMax;
        }
    }

    if (options.axisMinMax.yFixed) {
        if (options.yMin !== undefined) {
            minMax.yMin = options.yMin;
        }

        if (options.yMax !== undefined) {
            minMax.yMax = options.yMax;
        }
    }

    options.xMin = minMax.xMin;
    options.xMax = minMax.xMax;

    options.yMin = minMax.yMin;
    options.yMax = minMax.yMax;

    Object.assign(option.xAxis as Record<string, unknown>, {
        name: GetChartLabel(signal, 0),
        nameLocation: "end",
        scale: true,
        min: options.xMin,
        max: options.xMax,
    });

    Object.assign(option.yAxis as Record<string, unknown>, {
        name: GetChartLabel(signal, 1),
        nameLocation: "end",
        scale: true,
        min: options.yMin,
        max: options.yMax,
    });

    BuildChartGraphic(option, signal);

    // console.log(option);

    return option;
}

/**
 * Data format: [[x1, y1], [x2, y2], ... , [xn, yn]]
 * @param values
 * @param signalProperty
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function GetSignalData(values: number[][], sig?: ISignalProperty) {
    let x = values[0];

    if (sig) {
        if (sig.type === "Time") {
            x = ResetZeroToLeft(x);
        }
    }

    return CombineData(x, values[1]);
}

export function ResetZeroToRight(xData: number[]) {
    const lastValue = xData[xData.length - 1];
    for (let index = 0; index < xData.length; ++index) {
        xData[index] = xData[index] - lastValue;
    }

    return xData;
}

export function ResetZeroToLeft(xData: number[], firstValue?: number) {
    firstValue ??= xData[0];

    if (firstValue !== 0) {
        for (let index = 0; index < xData.length; ++index) {
            xData[index] = xData[index] - firstValue;
        }
    }

    return xData;
}

export function DateTimeToXLDateEx(dt: Moment) {
    const XLStartDateTime = new Date(1899, 12, 30, 0, 0, 0);
    const ts = dt.diff(XLStartDateTime, "milliseconds", true);
    return ts;
}

export function ResetZeroToDate(drawDataXValue: number[], startTime: Moment) {
    if (drawDataXValue != null && drawDataXValue.length > 0) {
        for (let index = 0; index < drawDataXValue.length; ++index) {
            if (Number.isNaN(drawDataXValue[index])) {
                continue;
            }

            const value = drawDataXValue[index];
            // if ((DateTime.MaxValue.Ticks - startTime.Ticks) < value) {
            //     continue;
            // }

            const temp = startTime.add(value, "milliseconds");
            drawDataXValue[index] = DateTimeToXLDateEx(temp);
        }
    }

    return drawDataXValue;
}

export function ResetZeroForTest(drawDataXValue: number[], firstValue: number, isZAxis = false) {
    if (drawDataXValue && drawDataXValue.length > 0) {
        if (!isZAxis) {
            for (let index = 0; index < drawDataXValue.length; ++index) {
                if (Number.isNaN(drawDataXValue[index])) {
                    continue;
                }
                // if (drawDataXValue[index] < 0)
                // {
                //    continue;
                // }
                drawDataXValue[index] = drawDataXValue[index] - firstValue;
            }
        } else {
            drawDataXValue[0] = drawDataXValue[0] - firstValue;
        }
    }

    return drawDataXValue;
}

export function ResetZeroForCapture(drawDataXValue: number[], firstValue?: number) {
    if (drawDataXValue && drawDataXValue.length > 0) {
        const firstVal = firstValue ?? drawDataXValue[0];
        for (let index = 0; index < drawDataXValue.length; ++index) {
            drawDataXValue[index] = drawDataXValue[index] - firstVal;
        }
    }

    return drawDataXValue;
}

export function CombineData(xData: number[], yData: number[]): number[][] {
    const data = [];
    for (let i = 0; i < xData.length; i++) {
        data.push([xData[i], yData[i]]);
    }

    return data;
}

export function BuildSignalData(signal: ISignal, options: IChartOptions) {
    // console.info(`BuildSignalData: ${signal.Name}`);

    let xData = signal.Data[0].Values;
    let yData = signal.Data[1].Values;

    if (xData && yData) {
        const xFirstValueIndex = signal.Data[0].FirstValueIndex;

        // clone data
        xData = xData.slice(0);
        yData = yData.slice(0);

        const isTimeStream = IsTimeStreamSignal(signal);
        const isTimeBlock = IsTimeBlockSignal(signal);
        const isTemperatureSignal = IsTemperatureSignal(signal);
        const isHumiditySignal = IsHumiditySignal(signal);
        const isFrequencySignal = IsFrequencySignal(signal);

        let data: number[][] = [];

        const IsNeedResetZeroToRight = TryGetBooleanFromAttributes(signal.Attributes, SignalAttributeName.IsNeedResetZeroToRight);

        if (signal.Type === CommonConstant.WellKnownTime) {
            let ok = false;

            const isXAxisTimeSignal = IsDateSignal(signal);
            if (isXAxisTimeSignal) {
                options.isXAxisTime = false;

                let lastValue = 0;
                for (let index = xData.length - 1; index >= 0; index--) {
                    if (!Number.isNaN(xData[index])) {
                        lastValue = xData[index];
                        break;
                    }
                }

                if (lastValue >= 0) {
                    const attr = TryGetAttribute(signal.Attributes, SignalAttributeName.GeneratedTime);
                    if (attr) {
                        const startTime = moment.utc(attr.ValueString);

                        let base = startTime.valueOf();
                        const plotSetting = store.state.plotSetting;
                        if (plotSetting.TimeFormat === PlotDateTimeFormat.LocalController) {
                            if (attr?.ValueDouble) {
                                base += attr.ValueDouble;
                            }
                        } else if (plotSetting.TimeFormat === PlotDateTimeFormat.LocalCloud) {
                            const offset = new Date().getTimezoneOffset() * 60 * 1000;
                            base -= offset;
                        }

                        const offset = base - startTime.valueOf();
                        if (offset !== 0) {
                            console.info(`${signal.Name}, startTime: ${startTime.toISOString()}, PlotDateTimeFormat: ${PlotDateTimeFormat[plotSetting.TimeFormat]}, offset: ${moment.duration(offset, "milliseconds").humanize()}`);
                        }

                        ResetZeroToLeft(xData);

                        for (let i = 0; i < xData.length; i++) {
                            xData[i] = (xData[i] * 1000) + base;
                        }

                        for (let i = 0; i < yData.length; i++) {
                            if (yData[i] && !isNaN(yData[i])) {
                                if (isTemperatureSignal) {
                                    yData[i] = Number(yData[i].toFixed(2));
                                } else if (isHumiditySignal) {
                                    yData[i] = Number(yData[i].toFixed(1));
                                }
                            }
                        }
                        data = CombineData(xData, yData);
                        ok = true;

                        options.isXAxisTime = true;
                    }
                }
            }

            if (!ok) {
                if (isTimeStream) {
                    xData = xData.map(p => p - signal.FirstTime);

                    if (xFirstValueIndex && xFirstValueIndex >= 0 && xFirstValueIndex < xData.length) {
                        xData = ResetZeroForTest(xData, xData[xFirstValueIndex]);
                    } else {
                        if (isTimeBlock) {
                            xData = ResetZeroForCapture(xData);
                        } else {
                            xData = ResetZeroToRight(xData);
                        }
                    }
                    data = CombineData(xData, yData);
                    ok = true;
                }
            }

            if (!ok) {
                if (IsNeedResetZeroToRight) {
                    xData = ResetZeroToRight(xData);
                    data = CombineData(xData, yData);
                } else {
                    for (let i = 0; i < xData.length; i++) {
                        data.push([xData[i] - xData[0], yData[i]]);
                    }
                }
            }
        } else {
            for (let i = 0; i < xData.length; i++) {
                if (isFrequencySignal) {
                    if (xData[i] === 0) {
                        // console.warn(`Ignore zero point: ${i}`);
                        continue;
                    }

                    // TFS #67464
                    if (yData[i] === 0) {
                        yData[i] = Number.NaN;
                    }
                }

                data.push([xData[i], yData[i]]);
            }
        }

        return data;
    }
}

export function AxisLabelFormat(val: number, index: number): string {
    const format = () => {
        if (val === 1e-10 && index === 0) {
            return "0";
        }

        if (Number.isNaN(val)) {
            return "";
        }

        if (typeof val !== "number") {
            val = Number(val);
        }

        if (val === 0) {
            return "0";
        }

        const absVal = Math.abs(val);
        if (absVal > 0.0005) {
            return Number(val.toFixed(absVal > 0.005 ? 2 : 3)).toString();
        }

        const e1 = val.toExponential();
        const e2 = val.toExponential(2);

        return e1.length < e2.length ? e1 : e2;
    };

    const r = format();

    // console.info(`${val} -> ${r}`);

    return r;
}

export function GetSignalSeries(signals: ISignal[], options: IChartOptions) {
    if (signals.length < 1) {
        return [];
    }

    const result = [];

    for (const signal of signals) {
        if (signal.Data.length < 1) {
            continue;
        }

        const signalData = BuildSignalData(signal, options);

        // console.log(signal);
        // console.log(signalData);

        result.push({
            name: signal.Name,
            type: "line",
            showSymbol: false,
            // hoverAnimation: false,
            emphasis: {
                scale: false,
            },
            smooth: false,
            data: signalData,
            color: GetSignalColor(signal),
        });
    }

    return result;
}

export function GetSignalColor(signal: ISignal) {
    const color = TryGetInt64FromAttributes(signal.Attributes, SignalAttributeName.SignalColor);
    if (color !== undefined) {
        return ParseColor(color);
    }

    switch (signal.Name) {
        case SignalNames.HighAbort:
        case SignalNames.LowAbort: return "#ff0000";

        case SignalNames.HighAlarm:
        case SignalNames.LowAlarm: return "#ffa500";

        case SignalNames.Profile: return "#008000";
        default:
            return undefined;
    }
}

export function GetSignalGenerateTime(signal: ISignal, format: PlotDateTimeFormat = PlotDateTimeFormat.LocalController) {
    const attr = TryGetAttribute(signal.Attributes, SignalAttributeName.GeneratedTime);
    if (!attr) {
        return undefined;
    }

    switch (format) {
        case PlotDateTimeFormat.LocalController: {
            const time = moment.utc(attr.ValueString);
            return time.add(attr.ValueDouble, "milliseconds");
        }

        case PlotDateTimeFormat.LocalCloud: {
            const time = moment(attr.ValueString);
            return time;
        }

        case PlotDateTimeFormat.UTC: {
            const time = moment.utc(attr.ValueString);
            return time;
        }
    }
}

export function BuildChartGraphic(option: ECBasicOption, signal: ISignal) {
    const isXAxisTimeSignal = IsDateSignal(signal);
    if (!isXAxisTimeSignal) {
        return;
    }

    const startTime = GetSignalGenerateTime(signal);
    if (!startTime) {
        return;
    }

    option.graphic = [
        {
            type: "text",
            left: 2,
            bottom: 2,
            z: 100,
            style: {
                fill: "#333",
                width: 220,
                overflow: "break",
                text: startTime.format("YYYY/MM/DD"),
                font: "12px Microsoft YaHei",
            },
        },
    ];
}

// export function GetSignals() {
//     const names = Object.values(SignalNames);
//         signals = run?.Signals.filter(p => names.includes(p.Name)) ?? [];
// }

export function IsSignal(value?: unknown): value is ISignal {
    if (!value) {
        return false;
    }

    if (typeof value !== "object") {
        return false;
    }

    return "Name" in value && "NvhType" in value && "Data" in value;
}

export function IsTimeSignal(sig?: unknown) {
    if (!IsSignal(sig)) {
        return false;
    }

    return sig.Type === CommonConstant.WellKnownTime;
}

export function IsTimeEquidistantSignal(sig?: unknown) {
    if (!IsSignal(sig)) {
        return false;
    }

    return IsTimeSignal(sig) && sig.NvhType === NvhType.Equidistant;
}

export function IsTimeNonEquidistantSignal(sig: ISignal) {
    return IsTimeSignal(sig) && sig.NvhType === NvhType.NonEquidistant;
}

export function IsTimeStreamSignal(sig?: unknown) {
    if (!IsSignal(sig)) {
        return false;
    }

    const flag = TryGetBooleanFromAttributes(sig.Attributes, SignalAttributeName.IsTimeStream);
    if (flag !== undefined) {
        return flag;
    }

    return IsTimeEquidistantSignal(sig);
}

export function IsTimeBlockSignal(sig?: unknown) {
    if (!IsSignal(sig)) {
        return false;
    }

    const flag = TryGetBooleanFromAttributes(sig.Attributes, SignalAttributeName.IsTimeBlock);
    if (flag !== undefined) {
        return flag;
    }

    return IsTimeNonEquidistantSignal(sig);
}

export function IsFrequencySignal(sig?: unknown) {
    if (!IsSignal(sig)) {
        return false;
    }

    return sig.Type === CommonConstant.WellKnownFrequency && sig.NvhType !== NvhType.NonEquidistant;
}

export function IsTemperatureSignal(sig?: unknown) {
    if (!IsSignal(sig)) {
        return false;
    }

    if (!IsTimeEquidistantSignal(sig)) {
        return false;
    }

    if (sig.Data && sig.Data[1]?.Quantity === CommonConstant.WellKnownTemperature) {
        return true;
    }

    const units = UnitsTemperature;

    if (sig.Data && units.includes(sig.Data[1]?.Unit)) {
        return true;
    }

    if (sig.Units && units.includes(sig.Units[1])) {
        return true;
    }

    return false;
}

export function IsHumiditySignal(sig?: unknown) {
    if (!IsSignal(sig)) {
        return false;
    }

    if (!IsTimeEquidistantSignal(sig)) {
        return false;
    }

    if (sig.Data && sig.Data[1]?.Quantity === CommonConstant.WellKnownHumidity) {
        return true;
    }

    const units = UnitsHumidity;

    if (sig.Data && units.includes(sig.Data[1]?.Unit)) {
        return true;
    }

    if (sig.Units && units.includes(sig.Units[1])) {
        return true;
    }

    return false;
}

export function IsDateSignal(sig?: unknown) {
    if (!IsSignal(sig)) {
        return false;
    }

    const isSpiderHSignal = TryGetBooleanFromAttributes(sig.Attributes, SignalAttributeName.isSpiderHSignal);
    if (isSpiderHSignal) {
        return true;
    }

    const IsPCTimeTrace = TryGetBooleanFromAttributes(sig.Attributes, SignalAttributeName.IsPCTimeTrace);
    if (IsPCTimeTrace) {
        return true;
    }

    if (IsHumiditySignal(sig) || IsTemperatureSignal(sig)) {
        return true;
    }

    // ...

    return false;
}

export function GetSignalGroup(signal: ISignal) {
    const values = [signal.Group.Languages[0].Value];

    for (const d of signal.Data) {
        if (d.Quantity) {
            values.push(d.Quantity);
        }

        if (d.Unit) {
            values.push(d.Unit);
        }
    }

    if (values.length === 1 && Array.isArray(signal.Units)) {
        values.push(...signal.Units);
    }

    const key = values.join("_");

    return key;
}

export function GroupSignalsForChart(signals: ISignal[]) {
    if (!signals || signals.length === 0) {
        return undefined;
    }

    const map = new Map<string, Set<ISignal>>();

    for (const sig of signals) {
        const key = GetSignalGroup(sig);

        let set = map.get(key);
        if (!set) {
            set = new Set<ISignal>();
            map.set(key, set);
        }

        set.add(sig);
    }

    return map;
}

export function IsSameGroup(a: ISignal, b: ISignal) {
    return GetSignalGroup(a) === GetSignalGroup(b);
}

export function BuildSignalTree(signals: ISignal[], rootName: string) {
    const all = signals;
    const grouped = new Map<string, Set<ISignal>>();

    for (const s of all) {
        const key = s.Group.Languages[0].Value;
        let set = grouped.get(key);
        if (set) {
            set.add(s);
        } else {
            set = new Set<ISignal>();
            set.add(s);

            grouped.set(key, set);
        }
    }

    // console.log(grouped);

    const data = [];
    for (const [k, v] of grouped.entries()) {
        data.push({
            Name: k,
            children: Array.from(v),
        });
    }

    return [{
        Name: rootName,
        children: data,
    }];
}

export function IsSameDay(date1: IDateLike, date2: IDateLike) {
    const d1 = moment.utc(date1);
    const d2 = moment.utc(date2);

    return d1.isSame(d2, "day");
}
