/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/base/function.ts
 * @create:      2022-05-12 16:22:44.449
 * @modify:      2023-04-14 16:28:01.338
 * @version:     0.1.1
 * @times:       40
 * @lines:       531
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import moment from "moment";
import { CloudDataType, CloudTestStatus, MeasurementConfigType, ServerChangeType, SoftwareMode } from "socket/common";
import { ILicenseInfo, IPlot } from "./interface";
import { compare } from "compare-versions";
import { PbNotification } from "socket/protoc/generated/cloud_messages_pb";
import { GenerateGuid, UpdateQueryString } from "node-share";
import Cookies from "js-cookie";

export function GetSoftwareModeFromAppId(appId: number) {
    if (appId >= 200 && appId < 300) {
        return SoftwareMode.SPIDER_VCS;
    }

    if (appId >= 300 && appId < 400) {
        return SoftwareMode.SPIDER_DSA;
    }

    if (appId >= 400 && appId < 500) {
        return SoftwareMode.SPIDER_EMA;
    }

    if (appId >= 500 && appId < 600) {
        return SoftwareMode.SPIDER_RCM;
    }

    if (appId >= 600 && appId < 700) {
        return SoftwareMode.SPIDER_MSC;
    }

    if (appId >= 700 && appId < 800) {
        return SoftwareMode.SPIDER_TCS;
    }

    if (appId >= 800 && appId < 900) {
        return SoftwareMode.SPIDER_THV;
    }

    if (appId >= 900 && appId < 1000) {
        return SoftwareMode.SPIDER_TDA;
    }

    if (appId >= 1000 && appId < 1100) {
        return SoftwareMode.SPIDER_RDYN;
    }

    if (appId === 5) {
        return SoftwareMode.VDS;
    }

    if (appId === 7) {
        return SoftwareMode.EDC;
    }

    console.warn(`Unknown app id: ${appId}`);
    return SoftwareMode.UNKNOWN;
}

export function IsRCM(appId: number) {
    return GetSoftwareModeFromAppId(appId) === SoftwareMode.SPIDER_RCM;
}

export function IsDSA(appId: number) {
    return GetSoftwareModeFromAppId(appId) === SoftwareMode.SPIDER_DSA;
}

export function IsVDS(appId: number) {
    return GetSoftwareModeFromAppId(appId) === SoftwareMode.VDS;
}

export function GetSoftwareModeNameFromAppId(appId: number) {
    switch (GetSoftwareModeFromAppId(appId)) {
        case SoftwareMode.SPIDER_VCS:
            return "VCS";

        case SoftwareMode.SPIDER_DSA:
            return "DSA";

        case SoftwareMode.SPIDER_EMA:
            return "EMA";

        case SoftwareMode.SPIDER_RCM:
            return "RCM";

        case SoftwareMode.SPIDER_MSC:
            return "MSC";

        case SoftwareMode.SPIDER_TCS:
            return "TCS";

        case SoftwareMode.SPIDER_THV:
            return "THV";

        case SoftwareMode.SPIDER_TDA:
            return "TDA";

        case SoftwareMode.SPIDER_RDYN:
            return "RDYN";

        case SoftwareMode.VDS:
            return "VDS";

        case SoftwareMode.EDC:
            return "EDC";

        default:
            return "Unknown";
    }
}

export function FormatDateTime(date: moment.MomentInput, format: string) {
    if (date) {
        const d = moment(date);
        return d.isValid() ? d.format(format) : "";
    }

    return "";
}

export function FormatTimeDuration(val: moment.DurationInputArg1) {
    const duration = moment.duration(val, "seconds");
    return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm:ss");
}

export function FormatValue(value: unknown, precision = 4, fractionDigits = 3) {
    const n = typeof value === "number" ? value : Number(value);

    if (Number.isNaN(n)) {
        return value;
    }

    return Number(n.toPrecision(precision)).toFixed(fractionDigits).toString(); // toFixed(6) //val.toString()
}

export function GetCookie(name: string, defaultVal?: string) {
    let val = Cookies.get(name);

    // console.log(`name: ${name}, value: ${val}`)

    if (val === undefined || val === null) {
        val = defaultVal;
    }

    return val;
}

export function SetCookie(name: string, value?: string, expiredDays = 400) {
    if (value === undefined) {
        return;
    }

    Cookies.set(name, value, {
        expires: expiredDays,
    });
}

export function DeleteCookie(name: string) {
    Cookies.remove(name);
}

export function RedirectTo(url: string) {
    // console.log(`redirect to: ${url}`);
    window.location.href = url;
}

export async function OpenLicensePage(target = "_blank") {
    const url = "/cloud/license";
    window.open(url, target);
}

/**
 *
 * @param {string} url
 * @param  {...string} names
 */
export function RemoveQueryFromURL(url: string, ...names: string[]) {
    if (url === undefined) {
        return url;
    }

    let hash = "";

    const index = url.indexOf("#");
    if (index !== -1) {
        hash = url.substring(index);
    }

    for (const name of names) {
        // prefer to use l.search if you have a location/link object
        const urlParts = url.split("?");
        if (urlParts.length >= 2) {
            const prefix = encodeURIComponent(name) + "=";
            const pars = urlParts[1].split(/[&;]/g);

            // reverse iteration as may be destructive
            for (let i = pars.length; i-- > 0;) {
                // idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            url =
                urlParts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
        }
    }

    return url + hash;
}

export function GetCloudDataTypeNamesByValue(value: CloudDataType) {
    const names: string[] = [];
    for (const name of Object.keys(CloudDataType)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (((CloudDataType as any)[name] & value) > 0) {
            names.push(name);
        }
    }

    return names;
}

export function GetServerChangeTypeNamesByValue(value: ServerChangeType) {
    for (const name of Object.keys(ServerChangeType)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((ServerChangeType as any)[name] === value) {
            return name;
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function GetTestStatus(status?: number, updatedAt?: moment.MomentInput) {
    if (status === CloudTestStatus.Unknown) {
        return "Unknown";
    }

    if (status === CloudTestStatus.Running) {
        // When the test status is “Running” and the last updated time is more than 30 minutes,
        // then we have to display “Unknown (Last updated 40 minutes ago)

        // #67414
        if (updatedAt) {
            const minutes = moment().diff(moment(updatedAt), "minutes");

            if (minutes > 30) {
                // #68519
                return "PcMayNotBeConnected";

                // return "Unknown";
            }
        }

        return "Running";
    }

    if (status === CloudTestStatus.Stopped) {
        return "Stopped";
    }

    return "";
}

export function getBreadcrumbs(arr: unknown[]) {
    const result: string[] = [];
    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if ((typeof item === "string" || item instanceof String) && item !== "") {
            result.push(item.toString());
        } else {
            break;
        }
    }

    return result;
}

export function getMiddleBreadcrumbs(arr: unknown[]) {
    const temp = getBreadcrumbs(arr);
    temp.pop();
    return temp;
}

export function getCurrentBreadcrumb(arr: unknown[]) {
    const temp = getBreadcrumbs(arr);
    return temp[temp.length - 1];
}

export function getBreadcrumbPath(str: string, currentTestGUID: string) {
    let name = "Home";

    switch (str) {
        case "sidebar.dashboard":
            name = "Dashboard";
            break;
        case "sidebar.view_tests":
            name = "AllTests";
            break;
        case "sidebar.uploaded_files":
            name = "Uploads";
            break;
        case "run_histories":
            name = "RunHistories";
            return {
                name: name,
                query: {
                    TestGUID: currentTestGUID,
                },
            };
        case "sidebar.software_info":
            name = "Software";
            break;
        case "sidebar.system_management":
            name = "SystemManagement";
            break;

        // case "sidebar.edc":
        //     name = "edc";
        //     break;
    }

    return {
        name: name,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy(key: string, array: any[]): any {
    return array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});
}

export function TimeFromNow(date: moment.MomentInput) {
    return moment(date).fromNow();
}

export function IsRunLogElapsedVisible(measureType: MeasurementConfigType) {
    return [
        MeasurementConfigType.VCS_Shock,
        MeasurementConfigType.VCS_TTH,
        MeasurementConfigType.VCS_SRS,
        MeasurementConfigType.VCS_ShockOnRandom,
        MeasurementConfigType.THV_Shock,
        MeasurementConfigType.THV_TTH,
        MeasurementConfigType.THV_SRS,
        MeasurementConfigType.THV_ShockOnRandom,
        MeasurementConfigType.VCS_Earthquake,
        MeasurementConfigType.THV_Earthquake,
    ].includes(measureType);
}

export function CheckLicenseSN(lk: ILicenseInfo, sn: string) {
    if (!lk || !sn) {
        return false;
    }

    const list = lk.SerialNumbersString.split(/,| /).filter(p => p.length > 2);

    if (list.includes(sn)) {
        // TFS #71117
        if (lk.Options && lk.Options.length > 0) {
            return true;
        }
    }

    // console.log(list);

    return false;
}

export function IsString<T>(obj: T) {
    if (typeof obj === "string" || obj instanceof String) {
        return true;
    }

    return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GeneralSearch(search: string, obj: any, ignoreCase = true) {
    if (!search) {
        return true;
    }

    for (const v of Object.values(obj)) {
        if (IsString(v)) {
            const str = v as string;

            if (ignoreCase) {
                if (str.toLowerCase().includes(search.toLowerCase())) {
                    return true;
                }
            } else {
                if (str.includes(search)) {
                    return true;
                }
            }
        }
    }

    return false;
}

export function GeneralSearchForArray<T>(search: string, objArray: T[], ignoreCase = true) {
    if (!search) {
        return objArray;
    }

    return objArray.filter(p => GeneralSearch(search, p, ignoreCase));
}

export function FormatNumber(val: unknown, digits = 4): string {
    if (val === "" || val === undefined) {
        return "";
    }

    if (val === null || Number.isNaN(val)) {
        return "NaN";
    }

    const n = Number(val);

    if (Number.isNaN(n)) {
        return "NaN";
    }

    const abs = Math.abs(n);

    if (abs < 0.0001 || abs > 10000) {
        return n.toExponential(digits);
    }

    return n.toFixed(digits);
}

export function ParseColor(num?: number) {
    if (num === undefined || num === null) {
        return "";
    }

    num >>>= 0;
    const b = num & 0xFF;
    const g = (num & 0xFF00) >>> 8;
    const r = (num & 0xFF0000) >>> 16;
    const a = ((num & 0xFF000000) >>> 24) / 255;

    return "rgba(" + [r, g, b, a].join(",") + ")";
}

export function GetSpace(n = 1) {
    return Array(n).fill(" ");
}

export function CompareDate(a: moment.MomentInput, b: moment.MomentInput) {
    const dA = moment(a);
    const dB = moment(b);

    if (dA.isAfter(dB)) {
        return -1;
    }

    if (dB.isAfter(dA)) {
        return 1;
    }

    return 0;
}

export function CompareVersion(firstVersion: string, secondVersion: string) {
    if (compare(firstVersion, secondVersion, ">")) {
        return -1;
    }

    if (compare(firstVersion, secondVersion, "<")) {
        return 1;
    }

    return 0;
}

export async function ShowBrowserNotification(data: PbNotification) {
    let permission = Notification.permission;
    if (permission === "default") {
        permission = await Notification.requestPermission();
    }

    if (permission === "granted") {
        const notification = new Notification(data.getTestName(), {
            icon: `${window.location.origin}/favicon.png`,
            body: data.getMessage(),
        });
        notification.onclick = function() {
            let url = `${window.location.origin}/dataViewer`;
            url = UpdateQueryString("TestId", data.getTestId(), url);
            url = UpdateQueryString("TestName", data.getTestName(), url);
            url = UpdateQueryString("Tab", "Notification", url);

            // window.open(url);

            window.parent.focus();
            window.parent.location = url;
        };
    } else {
        console.warn(permission);
    }
}

export function CreatePlot(signals: string[] = [], title = ""): IPlot {
    return {
        i: GenerateGuid(),
        isDataView: false,
        title,
        signals,
    };
}
