<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <span style="line-height: 36px; font-size: 16px">
                <b>{{ $t("columns.name") }}:</b>
                {{ tableData.Name }}
            </span>
            <span
                style="line-height: 36px; font-size: 16px; margin-left: 20px"
                v-if="tableData.CreateDate"
            >
                <b>{{ $t("columns.create_date") }}:</b>
                {{ FormatDateTime(tableData.CreateDate) }}
            </span>
            <span
                style="line-height: 36px; font-size: 16px; margin-left: 20px"
                v-if="tableData.Description"
            >
                <b>{{ $t("columns.desc") }}:</b>
                {{ tableData.Description }}
            </span>
            <!--el-button style="float: right;" type="primary">操作按钮</el-button-->
        </div>

        <el-table
            highlight-current-row
            :data="tableData.Modules"
            style="width: 100%"
        >
            <template slot="empty">
                <span>{{ $t("NoData") }}</span>
            </template>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="table-expand">
                        <el-form-item :label="$t('columns.is_master') + ': '">
                            <span>{{ props.row.IsMaster }}</span>
                        </el-form-item>

                        <el-form-item
                            :label="$t('columns.is_charge_enable') + ': '"
                        >
                            <span>{{ props.row.IsChargeEnabled }}</span>
                        </el-form-item>

                        <el-form-item
                            :label="$t('columns.last_calibration_date') + ': '"
                        >
                            <span>{{ props.row.LastCalibrationDate }}</span>
                        </el-form-item>

                        <el-form-item
                            :label="$t('columns.next_calibration_date') + ': '"
                        >
                            <span>{{ props.row.NextCalibrationDate }}</span>
                        </el-form-item>

                        <el-form-item
                            :label="$t('columns.FirmwareVersion') + ': '"
                        >
                            <span>{{ props.row.DSPVersion }}</span>
                        </el-form-item>

                        <el-form-item :label="$t('columns.bit_version') + ': '">
                            <span>{{ props.row.BITVersion }}</span>
                        </el-form-item>

                        <el-form-item
                            :label="$t('columns.hardware_version') + ': '"
                        >
                            <span>{{ props.row.HardwareVersion }}</span>
                        </el-form-item>

                        <el-form-item
                            :label="$t('columns.firmware_version') + ': '"
                        >
                            <span>{{ props.row.FirmwareVersion }}</span>
                        </el-form-item>

                        <el-form-item :label="$t('columns.pcb_id') + ': '">
                            <span>{{ props.row.PCBID }}</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>

            <el-table-column
                prop="Name"
                :label="$t('columns.name')"
            ></el-table-column>
            <el-table-column
                prop="Type"
                :label="$t('columns.type')"
            ></el-table-column>
            <el-table-column
                prop="SN"
                :label="$t('columns.sn')"
            ></el-table-column>
            <el-table-column
                prop="Network.IPAddress"
                :label="$t('columns.ip')"
            ></el-table-column>
            <el-table-column
                prop="AvailableChannelNumber"
                :label="$t('columns.channel_count')"
            ></el-table-column>
        </el-table>
    </el-card>
</template>

<i18n>
{
  "en-US": {
    "columns": {
      "name": "Name",
      "create_date": "Create Date",
      "desc": "Description",

      "type": "Type",
      "sn": "SN",
      "ip": "IP",
      "channel_count": "Channel Count",
      "is_master": "Master",
      "is_charge_enable": "",
      "last_calibration_date": "LastCalibrationDate",
      "next_calibration_date": "NextCalibrationDate",

      "FirmwareVersion": "Firmware Version",
      "bit_version": "BIT Version",
      "hardware_version": "Hardware Version",
      "firmware_version": "Firmware Version",
      "pcb_id": "PCB ID"
    }
  },
  "zh-CHS": {
    "columns": {
      "name": "名称",
      "create_date": "创建日期",
      "desc": "描述",

      "type": "类型",
      "sn": "序列号",
      "ip": "IP地址",
      "channel_count": "通道数",
      "is_master": "主机",
      "is_charge_enable": "电荷启用",
      "last_calibration_date": "最后校准日期",
      "next_calibration_date": "校准到期日期",

      "FirmwareVersion": "固件版本",
      "bit_version": "BIT版本",
      "hardware_version": "硬件版本",
      "firmware_version": "固件版本",
      "pcb_id": "PCB序号"
    }
  }
}
</i18n>

<style>
.table-expand {
    font-size: 0;
}
.table-expand label {
    /* width: 120px; */
    color: #99a9bf;
}
.table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>

<script>
import axios from "axios";
import store from "@/store";

export default {
    data() {
        return {
            tableData: [],
        };
    },
    created: function() {
        store.commit("updateBreadcrumb", [
            "sidebar.view_tests",
            "run_histories",
            "device_info",
        ]);

        this.fetchData();
    },
    methods: {
        async fetchData() {
            if (this.$route.params.System) {
                this.loading = true;

                try {
                    const r = await axios.get(
                        `/api/v1/systems/${this.$route.params.System}?populate=Modules`,
                    );
                    this.tableData = r.data.data;
                } catch (ex) {
                    console.error(ex);
                } finally {
                    this.loading = false;
                }
            }
        },
    },
};
</script>
