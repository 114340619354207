
import { Vue, Component } from "vue-property-decorator";
import { MessageBox } from "element-ui";
import { HttpStatusCode } from "node-share";
import axios from "axios";

interface IInvitation {
    _id: string;
}

@Component({
    name: "JoinTeamCard",
})
export default class JoinTeamCard extends Vue {
    loading = false;

    async deleteInvitation(invite: IInvitation) {
        try {
            const dr = await MessageBox.confirm(this.$t("DeleteInvitationWarning").toString(), this.$t("DeleteInvitation").toString(), {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("Yes").toString(),
                cancelButtonText: this.$t("No").toString(),
                type: "warning",
            });

            if (dr === "confirm") {
                const r = await axios.delete("/api/v1/teams/invitation", {
                    data: {
                        id: invite._id,
                    },
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh
                    this.$store.dispatch("refreshUserInfo");
                } else {
                    if (r.data.msg) {
                        this.$message({
                            message: r.data.msg,
                            type: "error",
                        });
                    } else {
                        await this.$store.dispatch("refreshUserInfo");
                    }
                }
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    async join(invite: IInvitation) {
        this.loading = true;

        try {
            const r = await axios.post("/api/v1/teams/join", {
                id: invite._id,
            });

            if (r.status === HttpStatusCode.OK) {
                await this.$store.dispatch("refreshUserInfo");

                if (!this.$route.path.startsWith("/team")) {
                    await this.$router.push("/team/members");
                }
            } else {
                if (r.data.msg) {
                    this.$message({
                        message: r.data.msg,
                        type: "error",
                    });
                } else {
                    await this.$store.dispatch("refreshUserInfo");
                }
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }
}
