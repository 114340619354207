<template>
    <div class="main-container-content" v-loading="loading">
        <div class="ci-card">
            <div class="header">{{ $t("TeamProfile") }}</div>
            <div class="body">
                <div>
                    <div class="display-flex align-items-center">
                        <h4 class="m-r-1-em">{{ $t("TeamName") }}</h4>
                        <el-input
                            v-model="TeamName"
                            class="flex-auto width-auto"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-content-end m-t-1-em">
                    <button class="ci-btn" @click="Update">
                        {{ $t("Update") }}
                    </button>
                </div>

                <div class="m-t-1-em" v-if="!IsTeamCreator">
                    <el-button type="danger" @click="LeaveTheTeam">{{
                        $t("LeaveTheTeam")
                    }}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { MessageBox } from "element-ui";
import store from "@/store";

@Component({
    name: "TeamSettings",
})
export default class TeamSettings extends Vue {
    loading = false;
    teamName = "";

    get TeamName() {
        if (this.teamName) {
            return this.teamName;
        }

        return store.getters.Team.name;
    }

    set TeamName(name) {
        this.teamName = name;
    }

    get IsTeamCreator() {
        return store.getters.IsTeamCreator;
    }

    get TeamId() {
        return store.getters.Team._id;
    }

    created() {
        store.commit("updateBreadcrumb", ["sidebar.team.settings"]);
    }

    async Update() {
        try {
            const r = await axios.put("/api/v1/teams/profile", {
                name: this.TeamName,
            });

            if (r.status === HttpStatusCode.OK) {
                store.dispatch("refreshUserInfo");

                this.$message({
                    message: this.$t("Success"),
                    type: "success",
                });
            } else {
                this.$message({
                    message: r.data.msg,
                    type: "error",
                });
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    async LeaveTheTeam() {
        try {
            const dr = await MessageBox.confirm(
                this.$t("AreYouSureYouWantToLeaveThisTeam"),
                this.$t("LeaveTheTeam"),
                {
                    confirmButtonText: this.$t("Yes"),
                    cancelButtonText: this.$t("No"),
                    type: "warning",
                },
            );

            if (dr === "confirm") {
                const r = await axios.delete("/api/v1/teams/invitation", {
                    data: {
                        id: this.TeamId,
                    },
                });

                if (r.status === HttpStatusCode.OK) {
                    // refresh
                    store.dispatch("refreshUserInfo");
                } else {
                    if (r.data.msg) {
                        this.$message({
                            message: r.data.msg,
                            type: "error",
                        });
                    } else {
                        await store.dispatch("refreshUserInfo");
                    }
                }
            }
        } catch (ex) {
            console.error(ex);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
