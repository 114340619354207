/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] d:/Codes/TFS/Workspaces/EDM_Cloud_New/EDM-Cloud/edm-cloud-server/src/socket/common/class/LiteEvent.ts
 * @create:      2019-11-22 13:49:58.877
 * @modify:      2022-07-01 15:04:49.128
 * @version:     0.1.1
 * @times:       9
 * @lines:       56
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import { ILiteEvent } from "../interface";
import { Handler } from "../type";

export class LiteEvent<D, S> implements ILiteEvent<D, S> {
    private handlers = new Map<number, Handler<D, S>>();
    private index = 0;

    public on(handler: Handler<D, S>): number {
        const index = this.index;
        this.handlers.set(index, handler);

        this.index++;

        return index;
    }

    public off(handler: Handler<D, S>): void {
        for (const [k, v] of this.handlers) {
            if (v === handler) {
                this.offByIndex(k);
                return;
            }
        }
    }

    public offByIndex(index: number): void {
        this.handlers.delete(index);
    }

    public trigger(data: D, sender: S): boolean {
        if (this.handlers.size > 0) {
            Array.from(this.handlers.values()).forEach(h => h(data, sender));
            return true;
        }

        return false;
    }

    public expose(): ILiteEvent<D, S> {
        return this;
    }
}
