
import { Vue, Component, Watch } from "vue-property-decorator";
import axios from "axios";
import { HttpStatusCode, UpdateQueryString } from "node-share";
import { GenericMessage, UploadOptions } from "socket/protoc/generated/cloud_messages_pb";
import SignalViewer from "@/components/chart/SignalViewer.vue";
import PcMayNotBeConnectedWarning from "@/components/chart/PcMayNotBeConnectedWarning.vue";
import SocketManager from "@/components/managers/SocketManager";
import DataCard from "@/components/cards/DataCard.vue";
import CloudMap from "@/components/map/CloudMap.vue";
import TestProperties from "@/components/cards/TestProperties.vue";
import Tree from "@/components/chart/Tree.vue";
import moment from "moment";

import {
    INumericalValue,
    IParameter,
    ITest,
    AddToRefreshList,
    ITableRow,
    ITextStyle,
    ITextSpan,
    ParseColor,
    GetSoftwareModeFromAppId,
    EDMClient,
    GetSoftwareModeNameFromAppId,
    IRun,
    GetTestStatus,
    IKeyValueItem,
} from "@/helpers";

import {
    CloudDataType,
    CloudTestStatus,
    IAttribute,
    TryGetAttribute,
    CloudAttribute,
    NumericalValueType,
    SoftwareMode,
    ServerChangeType,
} from "socket/common";
import store from "@/store";

@Component({
    name: "DataViewer",

    components: {
        SignalViewer,
        PcMayNotBeConnectedWarning,
        DataCard,
        CloudMap,
        TestProperties,
        Tree,
    },
})
export default class DataViewer extends Vue {
    page = CloudDataType.V2Test;
    loading = false;

    tests: ITest[] = [];

    options = [];

    testIndex = -1;
    runIndex = -1;

    isLoading = false;
    isAutoRefresh = true;

    refreshIndex = 0;

    isRequest = false;

    testPropertiesDialogVisible = false;

    uploadOptions = new UploadOptions();

    // ------------------------------------

    get IsDebug() {
        return store.getters.IsDebug;
    }

    get ActiveTab() {
        let tab = this.$route.query.Tab;

        if (!tab) {
            tab = "Status";
        }

        return tab as string;
    }

    set ActiveTab(tab: string) {
        const query = { ...this.$route.query, Tab: tab };
        this.$router.push({ query }).catch(() => {
            // ignore
        });
    }

    get QueryTestId() {
        return this.$route.query.TestId as string;
    }

    get QueryTestName() {
        return this.$route.query.TestName as string ?? "Test";
    }

    get SingleTest() {
        if (this.QueryTestId) {
            return true;
        }

        return false;
    }

    get Language() {
        return this.$i18n.locale;
    }

    get Test() {
        if (this.testIndex >= 0 && this.testIndex < this.tests.length) {
            return this.tests[this.testIndex];
        }

        return undefined;
    }

    get Run() {
        if (this.Test && this.Test.Runs && this.Test.Runs.length > 0 && this.runIndex >= 0 && this.runIndex < this.Test.Runs.length) {
            return this.Test.Runs[this.runIndex];
        }

        return undefined;
    }

    set Run(val) {
        if (this.Test && val) {
            this.Test.Runs.splice(this.runIndex, 1, val);
        }
    }

    get NumericalValues(): INumericalValue[] | undefined {
        return this.Run?.NumericalValues;
    }

    get IsNumericalValuesReady() {
        return this.IsReady(this.NumericalValues);
    }

    get LastModified() {
        return this.Run?.updatedAt;
    }

    get GroupedNumericalValues() {
        if (!this.NumericalValues || !this.IsNumericalValuesReady) {
            return [];
        }

        const map = new Map<string, INumericalValue[]>();
        for (const item of this.NumericalValues) {
            const group = this.GetString(item.Group) || "";
            const list = map.get(group);
            if (list) {
                list.push(item);
            } else {
                map.set(group, [item]);
            }
        }

        // console.log(map);

        return map;
    }

    get Address() {
        return this.NumericalValues?.find(p => p.Type === NumericalValueType.Address)?.ValueStr;
    }

    get Longitude(): number | undefined {
        const nv = this.NumericalValues?.find(p => p.Type === NumericalValueType.Longitude);
        if (!nv) {
            return undefined;
        }

        let v: number | undefined;
        if (nv.Value !== undefined) {
            v = nv.Value;
        }

        if (v === 0) {
            const n = Number(nv.ValueStr);
            if (!Number.isNaN(n)) {
                if (n !== 0) {
                    v = n;
                }
            }
        }

        return v;
    }

    get Latitude() {
        const nv = this.NumericalValues?.find(p => p.Type === NumericalValueType.Latitude);
        if (!nv) {
            return undefined;
        }

        let v: number | undefined;
        if (nv.Value !== undefined) {
            v = nv.Value;
        }

        if (v === 0) {
            const n = Number(nv.ValueStr);
            if (!Number.isNaN(n)) {
                if (n !== 0) {
                    v = n;
                }
            }
        }

        return v;
    }

    get IsAddressReady() {
        return (this.Longitude !== undefined && this.Longitude !== undefined) || !!this.Address;
    }

    get Tables() {
        return this.Run?.Tables;
    }

    get IsTablesReady() {
        return this.IsReady(this.Tables);
    }

    get Parameters() {
        return this.Run?.Parameters;
    }

    get IsParametersReady() {
        return this.IsReady(this.Parameters);
    }

    get GroupedParameters() {
        if (!this.Parameters || !this.IsParametersReady) {
            return [];
        }

        const map = new Map<string, IParameter[]>();
        for (const item of this.Parameters) {
            const group = this.GetString(item.Group) || "";
            const list = map.get(group);
            if (list) {
                list.push(item);
            } else {
                map.set(group, [item]);
            }
        }

        // console.log(map);

        return map;
    }

    get Notifications() {
        if (!this.Run) {
            return [];
        }

        return this.Run.Notifications;
    }

    get IsNotificationsReady() {
        return this.IsReady(this.Notifications);
    }

    get SortedNotifications() {
        if (this.IsNotificationsReady) {
            const list: { Time: string }[] = this.Notifications;
            list.sort((a, b) => a.Time.localeCompare(b.Time));
            return list;
        }

        return [];
    }

    get RunAttributes() {
        return this.Run?.Attributes;
    }

    get TestAttributes() {
        return this.Test?.Attributes;
    }

    get Signals() {
        return this.Run?.Signals ?? [];
    }

    get IsSignalsReady() {
        return this.IsReady(this.Signals);
    }

    get CanRefresh() {
        if (this.refreshIndex === 0) {
            return true;
        }

        const status = this.getAttributeNumber(CloudAttribute.state);
        if (status === CloudTestStatus.Running) {
            if (store.getters.Clients?.length > 0) {
                for (const item of store.getters.Clients) {
                    const sm = GetSoftwareModeFromAppId(item.AppId);
                    if (sm === this.Test?.SoftwareMode) {
                        return true;
                    }
                }

                console.warn(`No client with mode ${this.Test?.SoftwareMode} connected!`);
                return false;
            } else {
                console.warn("No client connected!");
            }
        }

        return false;
    }

    get EDMClients(): EDMClient[] {
        return store.getters.Clients;
    }

    get IsEDC() {
        return this.Test?.SoftwareMode === SoftwareMode.EDC;
    }

    get TestGuid() {
        return this.Test?.TestId ?? this.QueryTestId;
    }

    get TestName() {
        return this.Test?.TestName || this.QueryTestName;
    }

    get TestStatus() {
        return this.getAttributeNumber(CloudAttribute.state);
    }

    get TestStatusString() {
        return GetTestStatus(this.TestStatus, this.Run?.updatedAt);
    }

    get IsPcMayNotBeConnectedWarning() {
        return this.TestStatusString === "PcMayNotBeConnected";
    }

    get TestLastModifiedString() {
        if (this.Run) {
            return this.FormatDateTime(this.Run.updatedAt);
        }

        return "";
    }

    get TestInfo() {
        const items: IKeyValueItem<unknown, unknown>[] = [];

        const add = (name: string, value: unknown) => {
            if (value) {
                items.push({
                    key: this.$t(name),
                    value: value,
                });
            }
        };

        if (this.Test) {
            add("column_name.test_type", this.GetString(this.Test.TestType, this.Test.Type));
            add("column_name.test_description", this.getAttributeString(CloudAttribute.Description));
            add("column_name.time_elapsed", this.getAttributeString(CloudAttribute.TimeElapsed));
            add("column_name.run_count", this.getAttributeNumber(CloudAttribute.RunTimes));
            add("LastModified", this.TestLastModifiedString);
            add("column_name.test_status", this.TestStatusString);
        }

        return items;
    }

    get TestProperties() {
        const items: IKeyValueItem<unknown, unknown>[] = [];

        const add = (name: string, value: unknown) => {
            items.push({
                key: this.$t(name),
                value: value,
            });
        };

        add("Name", this.QueryTestName);
        add("Description", this.getAttributeString(CloudAttribute.Description));
        add("CreatedAt", this.FormatDateTime(this.getAttributeString(CloudAttribute.CreateDateTime)));
        add("ModifiedAt", this.FormatDateTime(this.getAttributeString(CloudAttribute.ModifiedDateTime)));
        add("LastRunTime", this.FormatDateTime(this.getAttributeString(CloudAttribute.LastRunDateTime)));
        add("SpiderSystem", this.getAttributeString(CloudAttribute.system));
        add("CreatedVersion", this.getAttributeString(CloudAttribute.edmCreated));
        add("LastRunVersion", this.getAttributeString(CloudAttribute.edmCurrent));

        return items;
    }

    get NeedDisplaySubscriptionMessage() {
        if (!this.Run) {
            return false;
        }

        if (this.Run.nextRealTimeUpdate) {
            return true;
        }

        return false;
    }

    get SubscriptionMessage() {
        if (!this.Run) {
            return false;
        }

        const diff = moment.utc(this.Run.nextRealTimeUpdate).diff(moment());
        const d = moment.duration(diff);
        const time = `${d.minutes().toString().padStart(2, "0")}:${d.seconds().toString().padStart(2, "0")}`;
        return `The current plan is on-subscribed. The test status display is updated in real-time for 5 minutes. Subscribe for continuous real-time update. Time to the next real-time update is: ${time}`;
    }

    // ------------------------------------

    @Watch("runIndex")
    async onRunChanged() {
        if (this.Run) {
            this.loading = true;

            const run = await this.fetchRun(this.Run);
            if (run) {
                this.Run = run;
            }

            this.loading = false;
        }
    }

    // ------------------------------------

    created() {
        this.fetchData();
    }

    mounted() {
        AddToRefreshList(this, this.refreshData);
    }

    // ------------------------------------

    IsReady(value: unknown) {
        if (Array.isArray(value) && value.length > 0 && typeof value[0] !== "string") {
            return true;
        }

        return false;
    }

    async refreshData(dataType: CloudDataType, changeType?: ServerChangeType, gm?: GenericMessage) {
        if (gm) {
            const testId = gm.getStringP1();

            if (testId) {
                if (this.Test?.TestId === testId) {
                    if (this.Run) {
                        const runIds = JSON.parse(gm.getStringP3()) as string[];
                        if (runIds.includes(this.Run._id)) {
                            await this.fetchCurrentRun();
                        } else {
                            if (changeType === ServerChangeType.NewV2RunCreated || runIds.length > this.Test.Runs.length) {
                                await this.fetchData();
                            }
                        }
                    }
                }
            }
        }
    }

    async fetchRun(run: IRun) {
        if (!run) {
            return;
        }

        try {
            console.log(`fetch run: ${run.RunId}`);

            let url = "/api/v2/runs?SharedTo";
            url = UpdateQueryString("SharedTestGuid", this.TestGuid, url);
            url = UpdateQueryString("RunId", run.RunId, url);

            const res = await axios.get(url);

            // console.log(res);

            if (res.status === HttpStatusCode.OK) {
                return res.data.data[0] as IRun;
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    async fetchCurrentRun() {
        if (!this.isAutoRefresh) {
            return;
        }

        if (!this.Run) {
            return;
        }

        if (this.isLoading) {
            console.warn("ignore loading");
            return;
        }

        this.isLoading = true;

        const run = await this.fetchRun(this.Run);
        if (run) {
            this.Run = run;
        }

        this.isRequest = false;
        this.isLoading = false;
    }

    async fetchData() {
        if (this.loading) {
            console.warn("ignore loading");
            return;
        }

        try {
            this.loading = true;

            let url = "/api/v2/tests?SharedTo";

            if (this.TestGuid) {
                url = UpdateQueryString("TestId", this.TestGuid, url);
            }

            const res = await axios.get(url);

            this.refreshIndex++;

            if (res.status === HttpStatusCode.OK) {
                const selectedTest = this.Test;
                const selectedRun = this.Run;

                this.tests = res.data.data;
                if (!this.tests || this.tests.length === 0) {
                    this.testIndex = -1;
                    return;
                }

                let testIndex = 0;
                if (selectedTest && this.tests && this.tests.length > 0) {
                    const index = this.tests.findIndex(p => p.TestId === selectedTest.TestId);
                    if (index !== -1) {
                        testIndex = index;
                    }
                }

                this.testIndex = testIndex;

                if (!this.Test || !this.Test.Runs || this.Test.Runs.length === 0) {
                    this.runIndex = -1;
                    return;
                }

                let runIndex = this.Test.Runs.length - 1;
                if (selectedRun && this.Test.Runs && this.Test.Runs.length > 0) {
                    const index = this.Test.Runs.findIndex(p => p.RunId === selectedRun.RunId);
                    if (index !== -1) {
                        runIndex = index;
                    }
                }

                // Reset first to trigger refresh
                this.runIndex = -1;
                await this.$nextTick();

                this.runIndex = runIndex;
            } else {
                console.error(res);
            }
        } catch (err) {
            console.error(err);
        } finally {
            this.loading = false;
        }
    }

    async onRefresh() {
        if (this.CanRefresh) {
            // this.uploadOptions.setAllData(true);

            this.uploadOptions.setTestId(this.Test?.TestId ?? this.TestGuid ?? "");

            if (this.Run?.RunId) {
                this.uploadOptions.setRunId(this.Run.RunId);
            }

            this.uploadOptions.setNumericalValues(true);
            this.uploadOptions.setNotifications(true);

            if (this.Run) {
                if (this.Run?.TableList?.length > 0) {
                    this.uploadOptions.setTablesList(this.Run.TableList);
                } else if (this.Run.Tables?.length > 0) {
                    this.uploadOptions.setTablesList(this.Run.Tables.map(p => p.Type));
                }
            }

            for (const client of this.EDMClients) {
                const softwareMode = GetSoftwareModeFromAppId(client.AppId);
                const name = GetSoftwareModeNameFromAppId(client.AppId);
                if (this.Test?.SoftwareMode) {
                    if (this.Test.SoftwareMode === softwareMode) {
                        console.log(`RequestEDMUpload: ${name}, ${softwareMode}`);
                        SocketManager.RequestEDMUpload(client, this.uploadOptions);
                    }
                } else {
                    console.log(`RequestEDMUpload: ${name}, ${softwareMode}`);
                    SocketManager.RequestEDMUpload(client, this.uploadOptions);
                }
            }

            this.isRequest = true;
        } else {
            // old logic
            await this.fetchCurrentRun();
        }
    }

    onSuspend(val: boolean) {
        this.isAutoRefresh = !val;
    }

    getCssStyle(style?: ITextStyle) {
        if (style === undefined || style === null) {
            return "";
        }

        return `style="color:${ParseColor(style.Color)}"`;
    }

    getTextSpanText(span: ITextSpan): string {
        const text = span.Text ?? "";
        if (span.LocalizedText) {
            return this.GetString(span.LocalizedText, text);
        }

        return text;
    }

    getTableCellValue(row: ITableRow, index: number) {
        let result = "";

        if (row.Value && row.Value.length > 0) {
            result = row.Value[index];
        } else if (row.RichValue) {
            const v = row.RichValue[index];
            if (v) {
                for (const item of v.Text) {
                    result += `<span ${this.getCssStyle(item?.Style)}>${this.getTextSpanText(item)}</span>`;
                }
            }
        }

        // console.log(result, index);
        return result;
    }

    getTestAttribute(key: string): IAttribute | undefined {
        if (this.TestAttributes) {
            return TryGetAttribute(this.TestAttributes, key);
        }

        return undefined;
    }

    getRunAttribute(key: string): IAttribute | undefined {
        if (this.RunAttributes) {
            return TryGetAttribute(this.RunAttributes, key);
        }

        return undefined;
    }

    getAttributeString(key: string) {
        let attr = this.getRunAttribute(key);
        if (attr?.ValueString) {
            return attr.ValueString;
        }

        attr = this.getTestAttribute(key);
        return attr?.ValueString;
    }

    getAttributeNumber(key: string, double?: boolean) {
        let attr = this.getRunAttribute(key);
        if (attr) {
            if (double === true) {
                return attr.ValueDouble;
            } else if (double === false) {
                return attr.ValueInt64;
            } else {
                if (attr.ValueDouble) {
                    return attr.ValueDouble;
                }

                return attr.ValueInt64;
            }
        }

        attr = this.getTestAttribute(key);
        if (attr) {
            if (double === true) {
                return attr.ValueDouble;
            } else if (double === false) {
                return attr.ValueInt64;
            } else {
                if (attr.ValueDouble) {
                    return attr.ValueDouble;
                }

                return attr.ValueInt64;
            }
        }

        return undefined;
    }

    displayValue(tag?: INumericalValue) {
        if (!tag) {
            return "";
        }

        return tag.ValueStr
            ? tag.ValueStr
            : (tag.Value?.toFixed(4) ?? "");
    }
}
