// source: cloud_messages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var cloud_signal_properties_pb = require('./cloud_signal_properties_pb.js');
goog.object.extend(proto, cloud_signal_properties_pb);
var cloud_enums_pb = require('./cloud_enums_pb.js');
goog.object.extend(proto, cloud_enums_pb);
goog.exportSymbol('proto.EDM.IPC.Alarm', null, global);
goog.exportSymbol('proto.EDM.IPC.BrowserClientInfo', null, global);
goog.exportSymbol('proto.EDM.IPC.ClientInfo', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudCHStatusDisplayValues', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudChannelStatus', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudControlMessage', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudDisplayOptions', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudDoubleArray', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudMessage', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudRCM_AlarmItem', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudRCM_SiteStatus', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudRecordData', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudRecordDataArray', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudRecordOption', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudRecordOptionArray', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudSignal', null, global);
goog.exportSymbol('proto.EDM.IPC.CloudSignalList', null, global);
goog.exportSymbol('proto.EDM.IPC.Config', null, global);
goog.exportSymbol('proto.EDM.IPC.ControlPanelStatus', null, global);
goog.exportSymbol('proto.EDM.IPC.DSAStatus', null, global);
goog.exportSymbol('proto.EDM.IPC.DisplayFrame', null, global);
goog.exportSymbol('proto.EDM.IPC.DisplayFrameArray', null, global);
goog.exportSymbol('proto.EDM.IPC.EDMInfo', null, global);
goog.exportSymbol('proto.EDM.IPC.GenericMessage', null, global);
goog.exportSymbol('proto.EDM.IPC.NumericDisplayArray', null, global);
goog.exportSymbol('proto.EDM.IPC.NumericDisplayItem', null, global);
goog.exportSymbol('proto.EDM.IPC.NumericDisplayOption', null, global);
goog.exportSymbol('proto.EDM.IPC.PbNotification', null, global);
goog.exportSymbol('proto.EDM.IPC.PbNotifications', null, global);
goog.exportSymbol('proto.EDM.IPC.RunLog', null, global);
goog.exportSymbol('proto.EDM.IPC.TestInfo', null, global);
goog.exportSymbol('proto.EDM.IPC.TestInfos', null, global);
goog.exportSymbol('proto.EDM.IPC.UploadOptions', null, global);
goog.exportSymbol('proto.EDM.IPC.VCSStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudDoubleArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.CloudDoubleArray.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.CloudDoubleArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudDoubleArray.displayName = 'proto.EDM.IPC.CloudDoubleArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.CloudMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudMessage.displayName = 'proto.EDM.IPC.CloudMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudControlMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.CloudControlMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudControlMessage.displayName = 'proto.EDM.IPC.CloudControlMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.ClientInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.ClientInfo.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.ClientInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.ClientInfo.displayName = 'proto.EDM.IPC.ClientInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.EDMInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.EDMInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.EDMInfo.displayName = 'proto.EDM.IPC.EDMInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.TestInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.TestInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.TestInfo.displayName = 'proto.EDM.IPC.TestInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.TestInfos = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.TestInfos.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.TestInfos, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.TestInfos.displayName = 'proto.EDM.IPC.TestInfos';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.DisplayFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.DisplayFrame.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.DisplayFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.DisplayFrame.displayName = 'proto.EDM.IPC.DisplayFrame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.DisplayFrameArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.DisplayFrameArray.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.DisplayFrameArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.DisplayFrameArray.displayName = 'proto.EDM.IPC.DisplayFrameArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.RunLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.RunLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.RunLog.displayName = 'proto.EDM.IPC.RunLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.ControlPanelStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.ControlPanelStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.ControlPanelStatus.displayName = 'proto.EDM.IPC.ControlPanelStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.VCSStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.VCSStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.VCSStatus.displayName = 'proto.EDM.IPC.VCSStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.DSAStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.DSAStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.DSAStatus.displayName = 'proto.EDM.IPC.DSAStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudSignal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.CloudSignal.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.CloudSignal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudSignal.displayName = 'proto.EDM.IPC.CloudSignal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudSignalList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.CloudSignalList.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.CloudSignalList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudSignalList.displayName = 'proto.EDM.IPC.CloudSignalList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.Alarm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.Alarm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.Alarm.displayName = 'proto.EDM.IPC.Alarm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.Config = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.Config, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.Config.displayName = 'proto.EDM.IPC.Config';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.BrowserClientInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.BrowserClientInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.BrowserClientInfo.displayName = 'proto.EDM.IPC.BrowserClientInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudChannelStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.CloudChannelStatus.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.CloudChannelStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudChannelStatus.displayName = 'proto.EDM.IPC.CloudChannelStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudCHStatusDisplayValues = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.CloudCHStatusDisplayValues, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudCHStatusDisplayValues.displayName = 'proto.EDM.IPC.CloudCHStatusDisplayValues';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudDisplayOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.CloudDisplayOptions.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.CloudDisplayOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudDisplayOptions.displayName = 'proto.EDM.IPC.CloudDisplayOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.NumericDisplayOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.NumericDisplayOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.NumericDisplayOption.displayName = 'proto.EDM.IPC.NumericDisplayOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.NumericDisplayItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.NumericDisplayItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.NumericDisplayItem.displayName = 'proto.EDM.IPC.NumericDisplayItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.NumericDisplayArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.NumericDisplayArray.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.NumericDisplayArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.NumericDisplayArray.displayName = 'proto.EDM.IPC.NumericDisplayArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudRecordOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.CloudRecordOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudRecordOption.displayName = 'proto.EDM.IPC.CloudRecordOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudRecordOptionArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.CloudRecordOptionArray.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.CloudRecordOptionArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudRecordOptionArray.displayName = 'proto.EDM.IPC.CloudRecordOptionArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudRecordData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.CloudRecordData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudRecordData.displayName = 'proto.EDM.IPC.CloudRecordData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudRecordDataArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.CloudRecordDataArray.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.CloudRecordDataArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudRecordDataArray.displayName = 'proto.EDM.IPC.CloudRecordDataArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudRCM_SiteStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.CloudRCM_SiteStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudRCM_SiteStatus.displayName = 'proto.EDM.IPC.CloudRCM_SiteStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.CloudRCM_AlarmItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.CloudRCM_AlarmItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.CloudRCM_AlarmItem.displayName = 'proto.EDM.IPC.CloudRCM_AlarmItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.UploadOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.UploadOptions.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.UploadOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.UploadOptions.displayName = 'proto.EDM.IPC.UploadOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.GenericMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.GenericMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.GenericMessage.displayName = 'proto.EDM.IPC.GenericMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.PbNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EDM.IPC.PbNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.PbNotification.displayName = 'proto.EDM.IPC.PbNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EDM.IPC.PbNotifications = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EDM.IPC.PbNotifications.repeatedFields_, null);
};
goog.inherits(proto.EDM.IPC.PbNotifications, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EDM.IPC.PbNotifications.displayName = 'proto.EDM.IPC.PbNotifications';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.CloudDoubleArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudDoubleArray.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudDoubleArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudDoubleArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudDoubleArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    arrayList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudDoubleArray}
 */
proto.EDM.IPC.CloudDoubleArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudDoubleArray;
  return proto.EDM.IPC.CloudDoubleArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudDoubleArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudDoubleArray}
 */
proto.EDM.IPC.CloudDoubleArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addArray(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudDoubleArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudDoubleArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudDoubleArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudDoubleArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArrayList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
};


/**
 * repeated double array = 1;
 * @return {!Array<number>}
 */
proto.EDM.IPC.CloudDoubleArray.prototype.getArrayList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.EDM.IPC.CloudDoubleArray} returns this
 */
proto.EDM.IPC.CloudDoubleArray.prototype.setArrayList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudDoubleArray} returns this
 */
proto.EDM.IPC.CloudDoubleArray.prototype.addArray = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudDoubleArray} returns this
 */
proto.EDM.IPC.CloudDoubleArray.prototype.clearArrayList = function() {
  return this.setArrayList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: msg.getData_asB64(),
    machineCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    p1: jspb.Message.getFieldWithDefault(msg, 6, 0),
    p2: jspb.Message.getFieldWithDefault(msg, 7, 0),
    p3: jspb.Message.getFieldWithDefault(msg, 8, ""),
    p4: jspb.Message.getFieldWithDefault(msg, 9, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudMessage}
 */
proto.EDM.IPC.CloudMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudMessage;
  return proto.EDM.IPC.CloudMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudMessage}
 */
proto.EDM.IPC.CloudMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.EDM.IPC.CloudMessageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineCode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAppId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setP1(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setP2(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setP3(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setP4(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getMachineCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getP1();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getP2();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getP3();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getP4();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional CloudMessageType Type = 1;
 * @return {!proto.EDM.IPC.CloudMessageType}
 */
proto.EDM.IPC.CloudMessage.prototype.getType = function() {
  return /** @type {!proto.EDM.IPC.CloudMessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.EDM.IPC.CloudMessageType} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes Data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.EDM.IPC.CloudMessage.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes Data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.EDM.IPC.CloudMessage.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes Data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudMessage.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string machine_code = 3;
 * @return {string}
 */
proto.EDM.IPC.CloudMessage.prototype.getMachineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setMachineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 app_id = 4;
 * @return {number}
 */
proto.EDM.IPC.CloudMessage.prototype.getAppId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setAppId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string page_id = 5;
 * @return {string}
 */
proto.EDM.IPC.CloudMessage.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 p1 = 6;
 * @return {number}
 */
proto.EDM.IPC.CloudMessage.prototype.getP1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setP1 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 p2 = 7;
 * @return {number}
 */
proto.EDM.IPC.CloudMessage.prototype.getP2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setP2 = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string p3 = 8;
 * @return {string}
 */
proto.EDM.IPC.CloudMessage.prototype.getP3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setP3 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string p4 = 9;
 * @return {string}
 */
proto.EDM.IPC.CloudMessage.prototype.getP4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setP4 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string user_id = 10;
 * @return {string}
 */
proto.EDM.IPC.CloudMessage.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string user_email = 11;
 * @return {string}
 */
proto.EDM.IPC.CloudMessage.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudMessage} returns this
 */
proto.EDM.IPC.CloudMessage.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudControlMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudControlMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudControlMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudControlMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    command: jspb.Message.getFieldWithDefault(msg, 1, 0),
    p1: jspb.Message.getFieldWithDefault(msg, 2, 0),
    p2: jspb.Message.getFieldWithDefault(msg, 3, 0),
    p3: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudControlMessage}
 */
proto.EDM.IPC.CloudControlMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudControlMessage;
  return proto.EDM.IPC.CloudControlMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudControlMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudControlMessage}
 */
proto.EDM.IPC.CloudControlMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.EDM.IPC.CloudControlCommand} */ (reader.readEnum());
      msg.setCommand(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setP1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setP2(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setP3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudControlMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudControlMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudControlMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudControlMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommand();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getP1();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getP2();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getP3();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional CloudControlCommand Command = 1;
 * @return {!proto.EDM.IPC.CloudControlCommand}
 */
proto.EDM.IPC.CloudControlMessage.prototype.getCommand = function() {
  return /** @type {!proto.EDM.IPC.CloudControlCommand} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.EDM.IPC.CloudControlCommand} value
 * @return {!proto.EDM.IPC.CloudControlMessage} returns this
 */
proto.EDM.IPC.CloudControlMessage.prototype.setCommand = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 P1 = 2;
 * @return {number}
 */
proto.EDM.IPC.CloudControlMessage.prototype.getP1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudControlMessage} returns this
 */
proto.EDM.IPC.CloudControlMessage.prototype.setP1 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 P2 = 3;
 * @return {number}
 */
proto.EDM.IPC.CloudControlMessage.prototype.getP2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudControlMessage} returns this
 */
proto.EDM.IPC.CloudControlMessage.prototype.setP2 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool P3 = 4;
 * @return {boolean}
 */
proto.EDM.IPC.CloudControlMessage.prototype.getP3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.CloudControlMessage} returns this
 */
proto.EDM.IPC.CloudControlMessage.prototype.setP3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.ClientInfo.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.ClientInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.ClientInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.ClientInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.ClientInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    machineUserName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    machineCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    appsList: jspb.Message.toObjectList(msg.getAppsList(),
    proto.EDM.IPC.EDMInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.ClientInfo}
 */
proto.EDM.IPC.ClientInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.ClientInfo;
  return proto.EDM.IPC.ClientInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.ClientInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.ClientInfo}
 */
proto.EDM.IPC.ClientInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 5:
      var value = new proto.EDM.IPC.EDMInfo;
      reader.readMessage(value,proto.EDM.IPC.EDMInfo.deserializeBinaryFromReader);
      msg.addApps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.ClientInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.ClientInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.ClientInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.ClientInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMachineUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMachineCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAppsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.EDM.IPC.EDMInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string machine_name = 1;
 * @return {string}
 */
proto.EDM.IPC.ClientInfo.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.ClientInfo} returns this
 */
proto.EDM.IPC.ClientInfo.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string machine_user_name = 2;
 * @return {string}
 */
proto.EDM.IPC.ClientInfo.prototype.getMachineUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.ClientInfo} returns this
 */
proto.EDM.IPC.ClientInfo.prototype.setMachineUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string machine_code = 3;
 * @return {string}
 */
proto.EDM.IPC.ClientInfo.prototype.getMachineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.ClientInfo} returns this
 */
proto.EDM.IPC.ClientInfo.prototype.setMachineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ip = 4;
 * @return {string}
 */
proto.EDM.IPC.ClientInfo.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.ClientInfo} returns this
 */
proto.EDM.IPC.ClientInfo.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated EDMInfo apps = 5;
 * @return {!Array<!proto.EDM.IPC.EDMInfo>}
 */
proto.EDM.IPC.ClientInfo.prototype.getAppsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.EDMInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.EDMInfo, 5));
};


/**
 * @param {!Array<!proto.EDM.IPC.EDMInfo>} value
 * @return {!proto.EDM.IPC.ClientInfo} returns this
*/
proto.EDM.IPC.ClientInfo.prototype.setAppsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.EDM.IPC.EDMInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.EDMInfo}
 */
proto.EDM.IPC.ClientInfo.prototype.addApps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.EDM.IPC.EDMInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.ClientInfo} returns this
 */
proto.EDM.IPC.ClientInfo.prototype.clearAppsList = function() {
  return this.setAppsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.EDMInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.EDMInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.EDMInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.EDMInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    softwareMode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, ""),
    testId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isRunning: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.EDMInfo}
 */
proto.EDM.IPC.EDMInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.EDMInfo;
  return proto.EDM.IPC.EDMInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.EDMInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.EDMInfo}
 */
proto.EDM.IPC.EDMInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSoftwareMode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRunning(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.EDMInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.EDMInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.EDMInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.EDMInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSoftwareMode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTestId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsRunning();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 app_id = 1;
 * @return {number}
 */
proto.EDM.IPC.EDMInfo.prototype.getAppId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.EDMInfo} returns this
 */
proto.EDM.IPC.EDMInfo.prototype.setAppId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string software_mode = 2;
 * @return {string}
 */
proto.EDM.IPC.EDMInfo.prototype.getSoftwareMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.EDMInfo} returns this
 */
proto.EDM.IPC.EDMInfo.prototype.setSoftwareMode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_email = 3;
 * @return {string}
 */
proto.EDM.IPC.EDMInfo.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.EDMInfo} returns this
 */
proto.EDM.IPC.EDMInfo.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string version = 4;
 * @return {string}
 */
proto.EDM.IPC.EDMInfo.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.EDMInfo} returns this
 */
proto.EDM.IPC.EDMInfo.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string test_id = 5;
 * @return {string}
 */
proto.EDM.IPC.EDMInfo.prototype.getTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.EDMInfo} returns this
 */
proto.EDM.IPC.EDMInfo.prototype.setTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_running = 6;
 * @return {boolean}
 */
proto.EDM.IPC.EDMInfo.prototype.getIsRunning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.EDMInfo} returns this
 */
proto.EDM.IPC.EDMInfo.prototype.setIsRunning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.TestInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.TestInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.TestInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.TestInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createDateTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createVersion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    guid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    measurementConfigType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    measurementConfigTypeName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    runGuid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    runName: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.TestInfo}
 */
proto.EDM.IPC.TestInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.TestInfo;
  return proto.EDM.IPC.TestInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.TestInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.TestInfo}
 */
proto.EDM.IPC.TestInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateDateTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMeasurementConfigType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasurementConfigTypeName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunGuid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.TestInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.TestInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.TestInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.TestInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreateDateTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreateVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGuid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMeasurementConfigType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMeasurementConfigTypeName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRunGuid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.EDM.IPC.TestInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Type = 2;
 * @return {string}
 */
proto.EDM.IPC.TestInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Create_Date_Time = 3;
 * @return {string}
 */
proto.EDM.IPC.TestInfo.prototype.getCreateDateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setCreateDateTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Create_Version = 4;
 * @return {string}
 */
proto.EDM.IPC.TestInfo.prototype.getCreateVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setCreateVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Guid = 6;
 * @return {string}
 */
proto.EDM.IPC.TestInfo.prototype.getGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 Measurement_Config_Type = 7;
 * @return {number}
 */
proto.EDM.IPC.TestInfo.prototype.getMeasurementConfigType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setMeasurementConfigType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string Measurement_Config_Type_Name = 8;
 * @return {string}
 */
proto.EDM.IPC.TestInfo.prototype.getMeasurementConfigTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setMeasurementConfigTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string run_guid = 9;
 * @return {string}
 */
proto.EDM.IPC.TestInfo.prototype.getRunGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setRunGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string run_name = 10;
 * @return {string}
 */
proto.EDM.IPC.TestInfo.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.TestInfo} returns this
 */
proto.EDM.IPC.TestInfo.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.TestInfos.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.TestInfos.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.TestInfos.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.TestInfos} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.TestInfos.toObject = function(includeInstance, msg) {
  var f, obj = {
    testsList: jspb.Message.toObjectList(msg.getTestsList(),
    proto.EDM.IPC.TestInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.TestInfos}
 */
proto.EDM.IPC.TestInfos.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.TestInfos;
  return proto.EDM.IPC.TestInfos.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.TestInfos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.TestInfos}
 */
proto.EDM.IPC.TestInfos.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.TestInfo;
      reader.readMessage(value,proto.EDM.IPC.TestInfo.deserializeBinaryFromReader);
      msg.addTests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.TestInfos.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.TestInfos.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.TestInfos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.TestInfos.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EDM.IPC.TestInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TestInfo Tests = 1;
 * @return {!Array<!proto.EDM.IPC.TestInfo>}
 */
proto.EDM.IPC.TestInfos.prototype.getTestsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.TestInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.TestInfo, 1));
};


/**
 * @param {!Array<!proto.EDM.IPC.TestInfo>} value
 * @return {!proto.EDM.IPC.TestInfos} returns this
*/
proto.EDM.IPC.TestInfos.prototype.setTestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EDM.IPC.TestInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.TestInfo}
 */
proto.EDM.IPC.TestInfos.prototype.addTests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EDM.IPC.TestInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.TestInfos} returns this
 */
proto.EDM.IPC.TestInfos.prototype.clearTestsList = function() {
  return this.setTestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.DisplayFrame.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.DisplayFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.DisplayFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.DisplayFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.DisplayFrame.toObject = function(includeInstance, msg) {
  var f, obj = {
    test: (f = msg.getTest()) && proto.EDM.IPC.TestInfo.toObject(includeInstance, f),
    runLog: (f = msg.getRunLog()) && proto.EDM.IPC.RunLog.toObject(includeInstance, f),
    controlPanelStatus: (f = msg.getControlPanelStatus()) && proto.EDM.IPC.ControlPanelStatus.toObject(includeInstance, f),
    signalsList: jspb.Message.toObjectList(msg.getSignalsList(),
    proto.EDM.IPC.CloudSignal.toObject, includeInstance),
    alarmsList: jspb.Message.toObjectList(msg.getAlarmsList(),
    proto.EDM.IPC.Alarm.toObject, includeInstance),
    channelStatus: (f = msg.getChannelStatus()) && proto.EDM.IPC.CloudChannelStatus.toObject(includeInstance, f),
    siteStatus: (f = msg.getSiteStatus()) && proto.EDM.IPC.CloudRCM_SiteStatus.toObject(includeInstance, f),
    numericDisplayItems: (f = msg.getNumericDisplayItems()) && proto.EDM.IPC.NumericDisplayArray.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.DisplayFrame}
 */
proto.EDM.IPC.DisplayFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.DisplayFrame;
  return proto.EDM.IPC.DisplayFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.DisplayFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.DisplayFrame}
 */
proto.EDM.IPC.DisplayFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.TestInfo;
      reader.readMessage(value,proto.EDM.IPC.TestInfo.deserializeBinaryFromReader);
      msg.setTest(value);
      break;
    case 2:
      var value = new proto.EDM.IPC.RunLog;
      reader.readMessage(value,proto.EDM.IPC.RunLog.deserializeBinaryFromReader);
      msg.setRunLog(value);
      break;
    case 3:
      var value = new proto.EDM.IPC.ControlPanelStatus;
      reader.readMessage(value,proto.EDM.IPC.ControlPanelStatus.deserializeBinaryFromReader);
      msg.setControlPanelStatus(value);
      break;
    case 4:
      var value = new proto.EDM.IPC.CloudSignal;
      reader.readMessage(value,proto.EDM.IPC.CloudSignal.deserializeBinaryFromReader);
      msg.addSignals(value);
      break;
    case 5:
      var value = new proto.EDM.IPC.Alarm;
      reader.readMessage(value,proto.EDM.IPC.Alarm.deserializeBinaryFromReader);
      msg.addAlarms(value);
      break;
    case 6:
      var value = new proto.EDM.IPC.CloudChannelStatus;
      reader.readMessage(value,proto.EDM.IPC.CloudChannelStatus.deserializeBinaryFromReader);
      msg.setChannelStatus(value);
      break;
    case 7:
      var value = new proto.EDM.IPC.CloudRCM_SiteStatus;
      reader.readMessage(value,proto.EDM.IPC.CloudRCM_SiteStatus.deserializeBinaryFromReader);
      msg.setSiteStatus(value);
      break;
    case 8:
      var value = new proto.EDM.IPC.NumericDisplayArray;
      reader.readMessage(value,proto.EDM.IPC.NumericDisplayArray.deserializeBinaryFromReader);
      msg.setNumericDisplayItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.DisplayFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.DisplayFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.DisplayFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.DisplayFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.EDM.IPC.TestInfo.serializeBinaryToWriter
    );
  }
  f = message.getRunLog();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.EDM.IPC.RunLog.serializeBinaryToWriter
    );
  }
  f = message.getControlPanelStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.EDM.IPC.ControlPanelStatus.serializeBinaryToWriter
    );
  }
  f = message.getSignalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.EDM.IPC.CloudSignal.serializeBinaryToWriter
    );
  }
  f = message.getAlarmsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.EDM.IPC.Alarm.serializeBinaryToWriter
    );
  }
  f = message.getChannelStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.EDM.IPC.CloudChannelStatus.serializeBinaryToWriter
    );
  }
  f = message.getSiteStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.EDM.IPC.CloudRCM_SiteStatus.serializeBinaryToWriter
    );
  }
  f = message.getNumericDisplayItems();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.EDM.IPC.NumericDisplayArray.serializeBinaryToWriter
    );
  }
};


/**
 * optional TestInfo Test = 1;
 * @return {?proto.EDM.IPC.TestInfo}
 */
proto.EDM.IPC.DisplayFrame.prototype.getTest = function() {
  return /** @type{?proto.EDM.IPC.TestInfo} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.TestInfo, 1));
};


/**
 * @param {?proto.EDM.IPC.TestInfo|undefined} value
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
*/
proto.EDM.IPC.DisplayFrame.prototype.setTest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
 */
proto.EDM.IPC.DisplayFrame.prototype.clearTest = function() {
  return this.setTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.DisplayFrame.prototype.hasTest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RunLog Run_Log = 2;
 * @return {?proto.EDM.IPC.RunLog}
 */
proto.EDM.IPC.DisplayFrame.prototype.getRunLog = function() {
  return /** @type{?proto.EDM.IPC.RunLog} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.RunLog, 2));
};


/**
 * @param {?proto.EDM.IPC.RunLog|undefined} value
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
*/
proto.EDM.IPC.DisplayFrame.prototype.setRunLog = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
 */
proto.EDM.IPC.DisplayFrame.prototype.clearRunLog = function() {
  return this.setRunLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.DisplayFrame.prototype.hasRunLog = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ControlPanelStatus Control_Panel_Status = 3;
 * @return {?proto.EDM.IPC.ControlPanelStatus}
 */
proto.EDM.IPC.DisplayFrame.prototype.getControlPanelStatus = function() {
  return /** @type{?proto.EDM.IPC.ControlPanelStatus} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.ControlPanelStatus, 3));
};


/**
 * @param {?proto.EDM.IPC.ControlPanelStatus|undefined} value
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
*/
proto.EDM.IPC.DisplayFrame.prototype.setControlPanelStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
 */
proto.EDM.IPC.DisplayFrame.prototype.clearControlPanelStatus = function() {
  return this.setControlPanelStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.DisplayFrame.prototype.hasControlPanelStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated CloudSignal Signals = 4;
 * @return {!Array<!proto.EDM.IPC.CloudSignal>}
 */
proto.EDM.IPC.DisplayFrame.prototype.getSignalsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.CloudSignal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.CloudSignal, 4));
};


/**
 * @param {!Array<!proto.EDM.IPC.CloudSignal>} value
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
*/
proto.EDM.IPC.DisplayFrame.prototype.setSignalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.EDM.IPC.CloudSignal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudSignal}
 */
proto.EDM.IPC.DisplayFrame.prototype.addSignals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.EDM.IPC.CloudSignal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
 */
proto.EDM.IPC.DisplayFrame.prototype.clearSignalsList = function() {
  return this.setSignalsList([]);
};


/**
 * repeated Alarm Alarms = 5;
 * @return {!Array<!proto.EDM.IPC.Alarm>}
 */
proto.EDM.IPC.DisplayFrame.prototype.getAlarmsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.Alarm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.Alarm, 5));
};


/**
 * @param {!Array<!proto.EDM.IPC.Alarm>} value
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
*/
proto.EDM.IPC.DisplayFrame.prototype.setAlarmsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.EDM.IPC.Alarm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.Alarm}
 */
proto.EDM.IPC.DisplayFrame.prototype.addAlarms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.EDM.IPC.Alarm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
 */
proto.EDM.IPC.DisplayFrame.prototype.clearAlarmsList = function() {
  return this.setAlarmsList([]);
};


/**
 * optional CloudChannelStatus Channel_Status = 6;
 * @return {?proto.EDM.IPC.CloudChannelStatus}
 */
proto.EDM.IPC.DisplayFrame.prototype.getChannelStatus = function() {
  return /** @type{?proto.EDM.IPC.CloudChannelStatus} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.CloudChannelStatus, 6));
};


/**
 * @param {?proto.EDM.IPC.CloudChannelStatus|undefined} value
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
*/
proto.EDM.IPC.DisplayFrame.prototype.setChannelStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
 */
proto.EDM.IPC.DisplayFrame.prototype.clearChannelStatus = function() {
  return this.setChannelStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.DisplayFrame.prototype.hasChannelStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CloudRCM_SiteStatus Site_Status = 7;
 * @return {?proto.EDM.IPC.CloudRCM_SiteStatus}
 */
proto.EDM.IPC.DisplayFrame.prototype.getSiteStatus = function() {
  return /** @type{?proto.EDM.IPC.CloudRCM_SiteStatus} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.CloudRCM_SiteStatus, 7));
};


/**
 * @param {?proto.EDM.IPC.CloudRCM_SiteStatus|undefined} value
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
*/
proto.EDM.IPC.DisplayFrame.prototype.setSiteStatus = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
 */
proto.EDM.IPC.DisplayFrame.prototype.clearSiteStatus = function() {
  return this.setSiteStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.DisplayFrame.prototype.hasSiteStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NumericDisplayArray Numeric_Display_Items = 8;
 * @return {?proto.EDM.IPC.NumericDisplayArray}
 */
proto.EDM.IPC.DisplayFrame.prototype.getNumericDisplayItems = function() {
  return /** @type{?proto.EDM.IPC.NumericDisplayArray} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.NumericDisplayArray, 8));
};


/**
 * @param {?proto.EDM.IPC.NumericDisplayArray|undefined} value
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
*/
proto.EDM.IPC.DisplayFrame.prototype.setNumericDisplayItems = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.DisplayFrame} returns this
 */
proto.EDM.IPC.DisplayFrame.prototype.clearNumericDisplayItems = function() {
  return this.setNumericDisplayItems(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.DisplayFrame.prototype.hasNumericDisplayItems = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.DisplayFrameArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.DisplayFrameArray.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.DisplayFrameArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.DisplayFrameArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.DisplayFrameArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    framesList: jspb.Message.toObjectList(msg.getFramesList(),
    proto.EDM.IPC.DisplayFrame.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.DisplayFrameArray}
 */
proto.EDM.IPC.DisplayFrameArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.DisplayFrameArray;
  return proto.EDM.IPC.DisplayFrameArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.DisplayFrameArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.DisplayFrameArray}
 */
proto.EDM.IPC.DisplayFrameArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.DisplayFrame;
      reader.readMessage(value,proto.EDM.IPC.DisplayFrame.deserializeBinaryFromReader);
      msg.addFrames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.DisplayFrameArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.DisplayFrameArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.DisplayFrameArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.DisplayFrameArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFramesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EDM.IPC.DisplayFrame.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DisplayFrame Frames = 1;
 * @return {!Array<!proto.EDM.IPC.DisplayFrame>}
 */
proto.EDM.IPC.DisplayFrameArray.prototype.getFramesList = function() {
  return /** @type{!Array<!proto.EDM.IPC.DisplayFrame>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.DisplayFrame, 1));
};


/**
 * @param {!Array<!proto.EDM.IPC.DisplayFrame>} value
 * @return {!proto.EDM.IPC.DisplayFrameArray} returns this
*/
proto.EDM.IPC.DisplayFrameArray.prototype.setFramesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EDM.IPC.DisplayFrame=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.DisplayFrame}
 */
proto.EDM.IPC.DisplayFrameArray.prototype.addFrames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EDM.IPC.DisplayFrame, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.DisplayFrameArray} returns this
 */
proto.EDM.IPC.DisplayFrameArray.prototype.clearFramesList = function() {
  return this.setFramesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.RunLog.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.RunLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.RunLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.RunLog.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.RunLog}
 */
proto.EDM.IPC.RunLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.RunLog;
  return proto.EDM.IPC.RunLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.RunLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.RunLog}
 */
proto.EDM.IPC.RunLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.RunLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.RunLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.RunLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.RunLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.ControlPanelStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.ControlPanelStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.ControlPanelStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.ControlPanelStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    runningStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vcs: (f = msg.getVcs()) && proto.EDM.IPC.VCSStatus.toObject(includeInstance, f),
    dsa: (f = msg.getDsa()) && proto.EDM.IPC.DSAStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.ControlPanelStatus}
 */
proto.EDM.IPC.ControlPanelStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.ControlPanelStatus;
  return proto.EDM.IPC.ControlPanelStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.ControlPanelStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.ControlPanelStatus}
 */
proto.EDM.IPC.ControlPanelStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.EDM.IPC.TestRunningStatus} */ (reader.readEnum());
      msg.setRunningStatus(value);
      break;
    case 2:
      var value = new proto.EDM.IPC.VCSStatus;
      reader.readMessage(value,proto.EDM.IPC.VCSStatus.deserializeBinaryFromReader);
      msg.setVcs(value);
      break;
    case 3:
      var value = new proto.EDM.IPC.DSAStatus;
      reader.readMessage(value,proto.EDM.IPC.DSAStatus.deserializeBinaryFromReader);
      msg.setDsa(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.ControlPanelStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.ControlPanelStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.ControlPanelStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.ControlPanelStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunningStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVcs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.EDM.IPC.VCSStatus.serializeBinaryToWriter
    );
  }
  f = message.getDsa();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.EDM.IPC.DSAStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional TestRunningStatus Running_Status = 1;
 * @return {!proto.EDM.IPC.TestRunningStatus}
 */
proto.EDM.IPC.ControlPanelStatus.prototype.getRunningStatus = function() {
  return /** @type {!proto.EDM.IPC.TestRunningStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.EDM.IPC.TestRunningStatus} value
 * @return {!proto.EDM.IPC.ControlPanelStatus} returns this
 */
proto.EDM.IPC.ControlPanelStatus.prototype.setRunningStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional VCSStatus vcs = 2;
 * @return {?proto.EDM.IPC.VCSStatus}
 */
proto.EDM.IPC.ControlPanelStatus.prototype.getVcs = function() {
  return /** @type{?proto.EDM.IPC.VCSStatus} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.VCSStatus, 2));
};


/**
 * @param {?proto.EDM.IPC.VCSStatus|undefined} value
 * @return {!proto.EDM.IPC.ControlPanelStatus} returns this
*/
proto.EDM.IPC.ControlPanelStatus.prototype.setVcs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.ControlPanelStatus} returns this
 */
proto.EDM.IPC.ControlPanelStatus.prototype.clearVcs = function() {
  return this.setVcs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.ControlPanelStatus.prototype.hasVcs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DSAStatus dsa = 3;
 * @return {?proto.EDM.IPC.DSAStatus}
 */
proto.EDM.IPC.ControlPanelStatus.prototype.getDsa = function() {
  return /** @type{?proto.EDM.IPC.DSAStatus} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.DSAStatus, 3));
};


/**
 * @param {?proto.EDM.IPC.DSAStatus|undefined} value
 * @return {!proto.EDM.IPC.ControlPanelStatus} returns this
*/
proto.EDM.IPC.ControlPanelStatus.prototype.setDsa = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.ControlPanelStatus} returns this
 */
proto.EDM.IPC.ControlPanelStatus.prototype.clearDsa = function() {
  return this.setDsa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.ControlPanelStatus.prototype.hasDsa = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.VCSStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.VCSStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.VCSStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.VCSStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    drivePk: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    ctrlPeak: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    frequency: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.VCSStatus}
 */
proto.EDM.IPC.VCSStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.VCSStatus;
  return proto.EDM.IPC.VCSStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.VCSStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.VCSStatus}
 */
proto.EDM.IPC.VCSStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLevel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDrivePk(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCtrlPeak(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrequency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.VCSStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.VCSStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.VCSStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.VCSStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getDrivePk();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getCtrlPeak();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float Level = 1;
 * @return {number}
 */
proto.EDM.IPC.VCSStatus.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.VCSStatus} returns this
 */
proto.EDM.IPC.VCSStatus.prototype.setLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float Drive_Pk = 2;
 * @return {number}
 */
proto.EDM.IPC.VCSStatus.prototype.getDrivePk = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.VCSStatus} returns this
 */
proto.EDM.IPC.VCSStatus.prototype.setDrivePk = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float Ctrl_Peak = 3;
 * @return {number}
 */
proto.EDM.IPC.VCSStatus.prototype.getCtrlPeak = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.VCSStatus} returns this
 */
proto.EDM.IPC.VCSStatus.prototype.setCtrlPeak = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float Frequency = 4;
 * @return {number}
 */
proto.EDM.IPC.VCSStatus.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.VCSStatus} returns this
 */
proto.EDM.IPC.VCSStatus.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.DSAStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.DSAStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.DSAStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.DSAStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalElapsed: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outputPk: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frame: jspb.Message.getFieldWithDefault(msg, 3, ""),
    frequencyRange: jspb.Message.getFieldWithDefault(msg, 4, ""),
    blockSizeLine: jspb.Message.getFieldWithDefault(msg, 5, ""),
    window: jspb.Message.getFieldWithDefault(msg, 6, ""),
    overlapRatio: jspb.Message.getFieldWithDefault(msg, 7, ""),
    averageMode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    averageNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    triggerOn: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    outputOn: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.DSAStatus}
 */
proto.EDM.IPC.DSAStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.DSAStatus;
  return proto.EDM.IPC.DSAStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.DSAStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.DSAStatus}
 */
proto.EDM.IPC.DSAStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalElapsed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputPk(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrame(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequencyRange(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockSizeLine(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWindow(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverlapRatio(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAverageMode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAverageNumber(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTriggerOn(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutputOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.DSAStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.DSAStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.DSAStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.DSAStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalElapsed();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutputPk();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrame();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFrequencyRange();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBlockSizeLine();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWindow();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOverlapRatio();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAverageMode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAverageNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTriggerOn();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getOutputOn();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string Total_Elapsed = 1;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getTotalElapsed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setTotalElapsed = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Output_Pk = 2;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getOutputPk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setOutputPk = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Frame = 3;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getFrame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setFrame = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Frequency_Range = 4;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getFrequencyRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setFrequencyRange = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Block_Size_Line = 5;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getBlockSizeLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setBlockSizeLine = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Window = 6;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getWindow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setWindow = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Overlap_Ratio = 7;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getOverlapRatio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setOverlapRatio = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Average_Mode = 8;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getAverageMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setAverageMode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Average_Number = 9;
 * @return {string}
 */
proto.EDM.IPC.DSAStatus.prototype.getAverageNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setAverageNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool Trigger_On = 10;
 * @return {boolean}
 */
proto.EDM.IPC.DSAStatus.prototype.getTriggerOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setTriggerOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool Output_On = 11;
 * @return {boolean}
 */
proto.EDM.IPC.DSAStatus.prototype.getOutputOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.DSAStatus} returns this
 */
proto.EDM.IPC.DSAStatus.prototype.setOutputOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.CloudSignal.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudSignal.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudSignal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudSignal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudSignal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    xUnit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    yUnit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.EDM.IPC.CloudDoubleArray.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 6, ""),
    group: jspb.Message.getFieldWithDefault(msg, 7, ""),
    nvhType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    firstTimeValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    properties: (f = msg.getProperties()) && cloud_signal_properties_pb.cloud_signal_properties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudSignal}
 */
proto.EDM.IPC.CloudSignal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudSignal;
  return proto.EDM.IPC.CloudSignal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudSignal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudSignal}
 */
proto.EDM.IPC.CloudSignal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setXUnit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setYUnit(value);
      break;
    case 5:
      var value = new proto.EDM.IPC.CloudDoubleArray;
      reader.readMessage(value,proto.EDM.IPC.CloudDoubleArray.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNvhType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFirstTimeValue(value);
      break;
    case 10:
      var value = new cloud_signal_properties_pb.cloud_signal_properties;
      reader.readMessage(value,cloud_signal_properties_pb.cloud_signal_properties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudSignal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudSignal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudSignal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudSignal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getXUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getYUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.EDM.IPC.CloudDoubleArray.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNvhType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFirstTimeValue();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      cloud_signal_properties_pb.cloud_signal_properties.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.EDM.IPC.CloudSignal.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.EDM.IPC.CloudSignal.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string x_unit = 3;
 * @return {string}
 */
proto.EDM.IPC.CloudSignal.prototype.getXUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.setXUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string y_unit = 4;
 * @return {string}
 */
proto.EDM.IPC.CloudSignal.prototype.getYUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.setYUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CloudDoubleArray data = 5;
 * @return {!Array<!proto.EDM.IPC.CloudDoubleArray>}
 */
proto.EDM.IPC.CloudSignal.prototype.getDataList = function() {
  return /** @type{!Array<!proto.EDM.IPC.CloudDoubleArray>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.CloudDoubleArray, 5));
};


/**
 * @param {!Array<!proto.EDM.IPC.CloudDoubleArray>} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
*/
proto.EDM.IPC.CloudSignal.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.EDM.IPC.CloudDoubleArray=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudDoubleArray}
 */
proto.EDM.IPC.CloudSignal.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.EDM.IPC.CloudDoubleArray, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional string type = 6;
 * @return {string}
 */
proto.EDM.IPC.CloudSignal.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string group = 7;
 * @return {string}
 */
proto.EDM.IPC.CloudSignal.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string nvh_type = 8;
 * @return {string}
 */
proto.EDM.IPC.CloudSignal.prototype.getNvhType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.setNvhType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double first_time_value = 9;
 * @return {number}
 */
proto.EDM.IPC.CloudSignal.prototype.getFirstTimeValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.setFirstTimeValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional cloud_signal_properties properties = 10;
 * @return {?proto.EDM.IPC.cloud_signal_properties}
 */
proto.EDM.IPC.CloudSignal.prototype.getProperties = function() {
  return /** @type{?proto.EDM.IPC.cloud_signal_properties} */ (
    jspb.Message.getWrapperField(this, cloud_signal_properties_pb.cloud_signal_properties, 10));
};


/**
 * @param {?proto.EDM.IPC.cloud_signal_properties|undefined} value
 * @return {!proto.EDM.IPC.CloudSignal} returns this
*/
proto.EDM.IPC.CloudSignal.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.CloudSignal} returns this
 */
proto.EDM.IPC.CloudSignal.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.CloudSignal.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.CloudSignalList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudSignalList.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudSignalList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudSignalList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudSignalList.toObject = function(includeInstance, msg) {
  var f, obj = {
    signalsList: jspb.Message.toObjectList(msg.getSignalsList(),
    proto.EDM.IPC.CloudSignal.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudSignalList}
 */
proto.EDM.IPC.CloudSignalList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudSignalList;
  return proto.EDM.IPC.CloudSignalList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudSignalList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudSignalList}
 */
proto.EDM.IPC.CloudSignalList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.CloudSignal;
      reader.readMessage(value,proto.EDM.IPC.CloudSignal.deserializeBinaryFromReader);
      msg.addSignals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudSignalList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudSignalList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudSignalList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudSignalList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EDM.IPC.CloudSignal.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CloudSignal Signals = 1;
 * @return {!Array<!proto.EDM.IPC.CloudSignal>}
 */
proto.EDM.IPC.CloudSignalList.prototype.getSignalsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.CloudSignal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.CloudSignal, 1));
};


/**
 * @param {!Array<!proto.EDM.IPC.CloudSignal>} value
 * @return {!proto.EDM.IPC.CloudSignalList} returns this
*/
proto.EDM.IPC.CloudSignalList.prototype.setSignalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EDM.IPC.CloudSignal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudSignal}
 */
proto.EDM.IPC.CloudSignalList.prototype.addSignals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EDM.IPC.CloudSignal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudSignalList} returns this
 */
proto.EDM.IPC.CloudSignalList.prototype.clearSignalsList = function() {
  return this.setSignalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.Alarm.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.Alarm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.Alarm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.Alarm.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.Alarm}
 */
proto.EDM.IPC.Alarm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.Alarm;
  return proto.EDM.IPC.Alarm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.Alarm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.Alarm}
 */
proto.EDM.IPC.Alarm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.Alarm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.Alarm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.Alarm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.Alarm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.Config.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.Config.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.Config} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.Config.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.Config}
 */
proto.EDM.IPC.Config.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.Config;
  return proto.EDM.IPC.Config.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.Config} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.Config}
 */
proto.EDM.IPC.Config.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.Config.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.Config.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.Config} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.Config.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.BrowserClientInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.BrowserClientInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.BrowserClientInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.BrowserClientInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.BrowserClientInfo}
 */
proto.EDM.IPC.BrowserClientInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.BrowserClientInfo;
  return proto.EDM.IPC.BrowserClientInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.BrowserClientInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.BrowserClientInfo}
 */
proto.EDM.IPC.BrowserClientInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.BrowserClientInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.BrowserClientInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.BrowserClientInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.BrowserClientInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.EDM.IPC.BrowserClientInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.BrowserClientInfo} returns this
 */
proto.EDM.IPC.BrowserClientInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.CloudChannelStatus.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudChannelStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudChannelStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudChannelStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudChannelStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayValuesList: jspb.Message.toObjectList(msg.getDisplayValuesList(),
    proto.EDM.IPC.CloudCHStatusDisplayValues.toObject, includeInstance),
    alarmItemsList: jspb.Message.toObjectList(msg.getAlarmItemsList(),
    proto.EDM.IPC.CloudRCM_AlarmItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudChannelStatus}
 */
proto.EDM.IPC.CloudChannelStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudChannelStatus;
  return proto.EDM.IPC.CloudChannelStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudChannelStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudChannelStatus}
 */
proto.EDM.IPC.CloudChannelStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = new proto.EDM.IPC.CloudCHStatusDisplayValues;
      reader.readMessage(value,proto.EDM.IPC.CloudCHStatusDisplayValues.deserializeBinaryFromReader);
      msg.addDisplayValues(value);
      break;
    case 5:
      var value = new proto.EDM.IPC.CloudRCM_AlarmItem;
      reader.readMessage(value,proto.EDM.IPC.CloudRCM_AlarmItem.deserializeBinaryFromReader);
      msg.addAlarmItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudChannelStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudChannelStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudChannelStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudChannelStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.EDM.IPC.CloudCHStatusDisplayValues.serializeBinaryToWriter
    );
  }
  f = message.getAlarmItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.EDM.IPC.CloudRCM_AlarmItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CloudCHStatusDisplayValues Display_Values = 4;
 * @return {!Array<!proto.EDM.IPC.CloudCHStatusDisplayValues>}
 */
proto.EDM.IPC.CloudChannelStatus.prototype.getDisplayValuesList = function() {
  return /** @type{!Array<!proto.EDM.IPC.CloudCHStatusDisplayValues>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.CloudCHStatusDisplayValues, 4));
};


/**
 * @param {!Array<!proto.EDM.IPC.CloudCHStatusDisplayValues>} value
 * @return {!proto.EDM.IPC.CloudChannelStatus} returns this
*/
proto.EDM.IPC.CloudChannelStatus.prototype.setDisplayValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.EDM.IPC.CloudCHStatusDisplayValues=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues}
 */
proto.EDM.IPC.CloudChannelStatus.prototype.addDisplayValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.EDM.IPC.CloudCHStatusDisplayValues, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudChannelStatus} returns this
 */
proto.EDM.IPC.CloudChannelStatus.prototype.clearDisplayValuesList = function() {
  return this.setDisplayValuesList([]);
};


/**
 * repeated CloudRCM_AlarmItem Alarm_Items = 5;
 * @return {!Array<!proto.EDM.IPC.CloudRCM_AlarmItem>}
 */
proto.EDM.IPC.CloudChannelStatus.prototype.getAlarmItemsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.CloudRCM_AlarmItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.CloudRCM_AlarmItem, 5));
};


/**
 * @param {!Array<!proto.EDM.IPC.CloudRCM_AlarmItem>} value
 * @return {!proto.EDM.IPC.CloudChannelStatus} returns this
*/
proto.EDM.IPC.CloudChannelStatus.prototype.setAlarmItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.EDM.IPC.CloudRCM_AlarmItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem}
 */
proto.EDM.IPC.CloudChannelStatus.prototype.addAlarmItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.EDM.IPC.CloudRCM_AlarmItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudChannelStatus} returns this
 */
proto.EDM.IPC.CloudChannelStatus.prototype.clearAlarmItemsList = function() {
  return this.setAlarmItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudCHStatusDisplayValues.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudCHStatusDisplayValues} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    origmin: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    origmax: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    peak: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    rms: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    velpeak: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    displpeak: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    isenable: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isiepe: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isoverload: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isiepewithsensor: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    unit: jspb.Message.getFieldWithDefault(msg, 13, ""),
    locationid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    velunit: jspb.Message.getFieldWithDefault(msg, 15, ""),
    displunit: jspb.Message.getFieldWithDefault(msg, 16, ""),
    chargegain: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    maxinputrange: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudCHStatusDisplayValues;
  return proto.EDM.IPC.CloudCHStatusDisplayValues.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudCHStatusDisplayValues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMax(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOrigmin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOrigmax(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPeak(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRms(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVelpeak(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDisplpeak(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsenable(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsiepe(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverload(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsiepewithsensor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setVelunit(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplunit(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChargegain(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxinputrange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudCHStatusDisplayValues.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudCHStatusDisplayValues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getOrigmin();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOrigmax();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getPeak();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getRms();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getVelpeak();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDisplpeak();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getIsenable();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsiepe();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsoverload();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsiepewithsensor();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLocationid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getVelunit();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDisplunit();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getChargegain();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getMaxinputrange();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
};


/**
 * optional float Min = 1;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float Max = 2;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float OrigMin = 3;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getOrigmin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setOrigmin = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float OrigMax = 4;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getOrigmax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setOrigmax = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float Peak = 5;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getPeak = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setPeak = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float RMS = 6;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getRms = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setRms = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float VelPeak = 7;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getVelpeak = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setVelpeak = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float DisplPeak = 8;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getDisplpeak = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setDisplpeak = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool IsEnable = 9;
 * @return {boolean}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getIsenable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setIsenable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool IsIEPE = 10;
 * @return {boolean}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getIsiepe = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setIsiepe = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool IsOverload = 11;
 * @return {boolean}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getIsoverload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setIsoverload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool IsIEPEWithSensor = 12;
 * @return {boolean}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getIsiepewithsensor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setIsiepewithsensor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string Unit = 13;
 * @return {string}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string LocationId = 14;
 * @return {string}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getLocationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setLocationid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string VelUnit = 15;
 * @return {string}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getVelunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setVelunit = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string DisplUnit = 16;
 * @return {string}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getDisplunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setDisplunit = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional float ChargeGain = 17;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getChargegain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setChargegain = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional int32 MaxInputRange = 18;
 * @return {number}
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.getMaxinputrange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudCHStatusDisplayValues} returns this
 */
proto.EDM.IPC.CloudCHStatusDisplayValues.prototype.setMaxinputrange = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.CloudDisplayOptions.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudDisplayOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudDisplayOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudDisplayOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    displaySignalsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    changeDisplaySignals: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    displayNumbersList: jspb.Message.toObjectList(msg.getDisplayNumbersList(),
    proto.EDM.IPC.NumericDisplayOption.toObject, includeInstance),
    changeDisplayNumbers: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudDisplayOptions}
 */
proto.EDM.IPC.CloudDisplayOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudDisplayOptions;
  return proto.EDM.IPC.CloudDisplayOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudDisplayOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudDisplayOptions}
 */
proto.EDM.IPC.CloudDisplayOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDisplaySignals(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChangeDisplaySignals(value);
      break;
    case 3:
      var value = new proto.EDM.IPC.NumericDisplayOption;
      reader.readMessage(value,proto.EDM.IPC.NumericDisplayOption.deserializeBinaryFromReader);
      msg.addDisplayNumbers(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChangeDisplayNumbers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudDisplayOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudDisplayOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudDisplayOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplaySignalsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getChangeDisplaySignals();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDisplayNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.EDM.IPC.NumericDisplayOption.serializeBinaryToWriter
    );
  }
  f = message.getChangeDisplayNumbers();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * repeated int32 Display_Signals = 1;
 * @return {!Array<number>}
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.getDisplaySignalsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.EDM.IPC.CloudDisplayOptions} returns this
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.setDisplaySignalsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudDisplayOptions} returns this
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.addDisplaySignals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudDisplayOptions} returns this
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.clearDisplaySignalsList = function() {
  return this.setDisplaySignalsList([]);
};


/**
 * optional bool change_display_signals = 2;
 * @return {boolean}
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.getChangeDisplaySignals = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.CloudDisplayOptions} returns this
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.setChangeDisplaySignals = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated NumericDisplayOption Display_Numbers = 3;
 * @return {!Array<!proto.EDM.IPC.NumericDisplayOption>}
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.getDisplayNumbersList = function() {
  return /** @type{!Array<!proto.EDM.IPC.NumericDisplayOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.NumericDisplayOption, 3));
};


/**
 * @param {!Array<!proto.EDM.IPC.NumericDisplayOption>} value
 * @return {!proto.EDM.IPC.CloudDisplayOptions} returns this
*/
proto.EDM.IPC.CloudDisplayOptions.prototype.setDisplayNumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.EDM.IPC.NumericDisplayOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.NumericDisplayOption}
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.addDisplayNumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.EDM.IPC.NumericDisplayOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudDisplayOptions} returns this
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.clearDisplayNumbersList = function() {
  return this.setDisplayNumbersList([]);
};


/**
 * optional bool change_display_numbers = 4;
 * @return {boolean}
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.getChangeDisplayNumbers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.CloudDisplayOptions} returns this
 */
proto.EDM.IPC.CloudDisplayOptions.prototype.setChangeDisplayNumbers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.NumericDisplayOption.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.NumericDisplayOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.NumericDisplayOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.NumericDisplayOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    displaySignalType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayStatusType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    signalId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rangeLeft: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    rangeRight: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.NumericDisplayOption}
 */
proto.EDM.IPC.NumericDisplayOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.NumericDisplayOption;
  return proto.EDM.IPC.NumericDisplayOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.NumericDisplayOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.NumericDisplayOption}
 */
proto.EDM.IPC.NumericDisplayOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDisplaySignalType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDisplayStatusType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSignalId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRangeLeft(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRangeRight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.NumericDisplayOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.NumericDisplayOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.NumericDisplayOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.NumericDisplayOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplaySignalType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayStatusType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSignalId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRangeLeft();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRangeRight();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 display_signal_type = 1;
 * @return {number}
 */
proto.EDM.IPC.NumericDisplayOption.prototype.getDisplaySignalType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.NumericDisplayOption} returns this
 */
proto.EDM.IPC.NumericDisplayOption.prototype.setDisplaySignalType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 display_status_type = 2;
 * @return {number}
 */
proto.EDM.IPC.NumericDisplayOption.prototype.getDisplayStatusType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.NumericDisplayOption} returns this
 */
proto.EDM.IPC.NumericDisplayOption.prototype.setDisplayStatusType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 signal_id = 3;
 * @return {number}
 */
proto.EDM.IPC.NumericDisplayOption.prototype.getSignalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.NumericDisplayOption} returns this
 */
proto.EDM.IPC.NumericDisplayOption.prototype.setSignalId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double range_left = 4;
 * @return {number}
 */
proto.EDM.IPC.NumericDisplayOption.prototype.getRangeLeft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.NumericDisplayOption} returns this
 */
proto.EDM.IPC.NumericDisplayOption.prototype.setRangeLeft = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double range_right = 5;
 * @return {number}
 */
proto.EDM.IPC.NumericDisplayOption.prototype.getRangeRight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.NumericDisplayOption} returns this
 */
proto.EDM.IPC.NumericDisplayOption.prototype.setRangeRight = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.NumericDisplayItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.NumericDisplayItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.NumericDisplayItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    option: (f = msg.getOption()) && proto.EDM.IPC.NumericDisplayOption.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    valueString: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unitsText: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.NumericDisplayItem}
 */
proto.EDM.IPC.NumericDisplayItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.NumericDisplayItem;
  return proto.EDM.IPC.NumericDisplayItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.NumericDisplayItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.NumericDisplayItem}
 */
proto.EDM.IPC.NumericDisplayItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.NumericDisplayOption;
      reader.readMessage(value,proto.EDM.IPC.NumericDisplayOption.deserializeBinaryFromReader);
      msg.setOption(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueString(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitsText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.NumericDisplayItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.NumericDisplayItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.NumericDisplayItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOption();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.EDM.IPC.NumericDisplayOption.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getValueString();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnitsText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional NumericDisplayOption option = 1;
 * @return {?proto.EDM.IPC.NumericDisplayOption}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.getOption = function() {
  return /** @type{?proto.EDM.IPC.NumericDisplayOption} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.NumericDisplayOption, 1));
};


/**
 * @param {?proto.EDM.IPC.NumericDisplayOption|undefined} value
 * @return {!proto.EDM.IPC.NumericDisplayItem} returns this
*/
proto.EDM.IPC.NumericDisplayItem.prototype.setOption = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.NumericDisplayItem} returns this
 */
proto.EDM.IPC.NumericDisplayItem.prototype.clearOption = function() {
  return this.setOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.hasOption = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.NumericDisplayItem} returns this
 */
proto.EDM.IPC.NumericDisplayItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.NumericDisplayItem} returns this
 */
proto.EDM.IPC.NumericDisplayItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string value_string = 4;
 * @return {string}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.getValueString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.NumericDisplayItem} returns this
 */
proto.EDM.IPC.NumericDisplayItem.prototype.setValueString = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit = 5;
 * @return {string}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.NumericDisplayItem} returns this
 */
proto.EDM.IPC.NumericDisplayItem.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string units_text = 6;
 * @return {string}
 */
proto.EDM.IPC.NumericDisplayItem.prototype.getUnitsText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.NumericDisplayItem} returns this
 */
proto.EDM.IPC.NumericDisplayItem.prototype.setUnitsText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.NumericDisplayArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.NumericDisplayArray.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.NumericDisplayArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.NumericDisplayArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.NumericDisplayArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.EDM.IPC.NumericDisplayItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.NumericDisplayArray}
 */
proto.EDM.IPC.NumericDisplayArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.NumericDisplayArray;
  return proto.EDM.IPC.NumericDisplayArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.NumericDisplayArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.NumericDisplayArray}
 */
proto.EDM.IPC.NumericDisplayArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.NumericDisplayItem;
      reader.readMessage(value,proto.EDM.IPC.NumericDisplayItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.NumericDisplayArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.NumericDisplayArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.NumericDisplayArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.NumericDisplayArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EDM.IPC.NumericDisplayItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NumericDisplayItem Items = 1;
 * @return {!Array<!proto.EDM.IPC.NumericDisplayItem>}
 */
proto.EDM.IPC.NumericDisplayArray.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.NumericDisplayItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.NumericDisplayItem, 1));
};


/**
 * @param {!Array<!proto.EDM.IPC.NumericDisplayItem>} value
 * @return {!proto.EDM.IPC.NumericDisplayArray} returns this
*/
proto.EDM.IPC.NumericDisplayArray.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EDM.IPC.NumericDisplayItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.NumericDisplayItem}
 */
proto.EDM.IPC.NumericDisplayArray.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EDM.IPC.NumericDisplayItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.NumericDisplayArray} returns this
 */
proto.EDM.IPC.NumericDisplayArray.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudRecordOption.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudRecordOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudRecordOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRecordOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    testGuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    runGuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    signalId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    typeName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    testName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    runName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    signalName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudRecordOption}
 */
proto.EDM.IPC.CloudRecordOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudRecordOption;
  return proto.EDM.IPC.CloudRecordOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudRecordOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudRecordOption}
 */
proto.EDM.IPC.CloudRecordOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.EDM.IPC.CloudRecordType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestGuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunGuid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSignalId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudRecordOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudRecordOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudRecordOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRecordOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTestGuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRunGuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSignalId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTypeName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTestName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSignalName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional CloudRecordType type = 1;
 * @return {!proto.EDM.IPC.CloudRecordType}
 */
proto.EDM.IPC.CloudRecordOption.prototype.getType = function() {
  return /** @type {!proto.EDM.IPC.CloudRecordType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.EDM.IPC.CloudRecordType} value
 * @return {!proto.EDM.IPC.CloudRecordOption} returns this
 */
proto.EDM.IPC.CloudRecordOption.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string test_guid = 2;
 * @return {string}
 */
proto.EDM.IPC.CloudRecordOption.prototype.getTestGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRecordOption} returns this
 */
proto.EDM.IPC.CloudRecordOption.prototype.setTestGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string run_guid = 3;
 * @return {string}
 */
proto.EDM.IPC.CloudRecordOption.prototype.getRunGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRecordOption} returns this
 */
proto.EDM.IPC.CloudRecordOption.prototype.setRunGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 signal_id = 4;
 * @return {number}
 */
proto.EDM.IPC.CloudRecordOption.prototype.getSignalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRecordOption} returns this
 */
proto.EDM.IPC.CloudRecordOption.prototype.setSignalId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string type_name = 5;
 * @return {string}
 */
proto.EDM.IPC.CloudRecordOption.prototype.getTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRecordOption} returns this
 */
proto.EDM.IPC.CloudRecordOption.prototype.setTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string test_name = 6;
 * @return {string}
 */
proto.EDM.IPC.CloudRecordOption.prototype.getTestName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRecordOption} returns this
 */
proto.EDM.IPC.CloudRecordOption.prototype.setTestName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string run_name = 7;
 * @return {string}
 */
proto.EDM.IPC.CloudRecordOption.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRecordOption} returns this
 */
proto.EDM.IPC.CloudRecordOption.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string signal_name = 8;
 * @return {string}
 */
proto.EDM.IPC.CloudRecordOption.prototype.getSignalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRecordOption} returns this
 */
proto.EDM.IPC.CloudRecordOption.prototype.setSignalName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.CloudRecordOptionArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudRecordOptionArray.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudRecordOptionArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudRecordOptionArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRecordOptionArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.EDM.IPC.CloudRecordOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudRecordOptionArray}
 */
proto.EDM.IPC.CloudRecordOptionArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudRecordOptionArray;
  return proto.EDM.IPC.CloudRecordOptionArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudRecordOptionArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudRecordOptionArray}
 */
proto.EDM.IPC.CloudRecordOptionArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.CloudRecordOption;
      reader.readMessage(value,proto.EDM.IPC.CloudRecordOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudRecordOptionArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudRecordOptionArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudRecordOptionArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRecordOptionArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EDM.IPC.CloudRecordOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CloudRecordOption options = 1;
 * @return {!Array<!proto.EDM.IPC.CloudRecordOption>}
 */
proto.EDM.IPC.CloudRecordOptionArray.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.CloudRecordOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.CloudRecordOption, 1));
};


/**
 * @param {!Array<!proto.EDM.IPC.CloudRecordOption>} value
 * @return {!proto.EDM.IPC.CloudRecordOptionArray} returns this
*/
proto.EDM.IPC.CloudRecordOptionArray.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EDM.IPC.CloudRecordOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudRecordOption}
 */
proto.EDM.IPC.CloudRecordOptionArray.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EDM.IPC.CloudRecordOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudRecordOptionArray} returns this
 */
proto.EDM.IPC.CloudRecordOptionArray.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudRecordData.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudRecordData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudRecordData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRecordData.toObject = function(includeInstance, msg) {
  var f, obj = {
    option: (f = msg.getOption()) && proto.EDM.IPC.CloudRecordOption.toObject(includeInstance, f),
    signal: (f = msg.getSignal()) && proto.EDM.IPC.CloudSignal.toObject(includeInstance, f),
    attribute: (f = msg.getAttribute()) && proto.EDM.IPC.NumericDisplayItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudRecordData}
 */
proto.EDM.IPC.CloudRecordData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudRecordData;
  return proto.EDM.IPC.CloudRecordData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudRecordData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudRecordData}
 */
proto.EDM.IPC.CloudRecordData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.CloudRecordOption;
      reader.readMessage(value,proto.EDM.IPC.CloudRecordOption.deserializeBinaryFromReader);
      msg.setOption(value);
      break;
    case 2:
      var value = new proto.EDM.IPC.CloudSignal;
      reader.readMessage(value,proto.EDM.IPC.CloudSignal.deserializeBinaryFromReader);
      msg.setSignal(value);
      break;
    case 3:
      var value = new proto.EDM.IPC.NumericDisplayItem;
      reader.readMessage(value,proto.EDM.IPC.NumericDisplayItem.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudRecordData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudRecordData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudRecordData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRecordData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOption();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.EDM.IPC.CloudRecordOption.serializeBinaryToWriter
    );
  }
  f = message.getSignal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.EDM.IPC.CloudSignal.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.EDM.IPC.NumericDisplayItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional CloudRecordOption option = 1;
 * @return {?proto.EDM.IPC.CloudRecordOption}
 */
proto.EDM.IPC.CloudRecordData.prototype.getOption = function() {
  return /** @type{?proto.EDM.IPC.CloudRecordOption} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.CloudRecordOption, 1));
};


/**
 * @param {?proto.EDM.IPC.CloudRecordOption|undefined} value
 * @return {!proto.EDM.IPC.CloudRecordData} returns this
*/
proto.EDM.IPC.CloudRecordData.prototype.setOption = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.CloudRecordData} returns this
 */
proto.EDM.IPC.CloudRecordData.prototype.clearOption = function() {
  return this.setOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.CloudRecordData.prototype.hasOption = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CloudSignal signal = 2;
 * @return {?proto.EDM.IPC.CloudSignal}
 */
proto.EDM.IPC.CloudRecordData.prototype.getSignal = function() {
  return /** @type{?proto.EDM.IPC.CloudSignal} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.CloudSignal, 2));
};


/**
 * @param {?proto.EDM.IPC.CloudSignal|undefined} value
 * @return {!proto.EDM.IPC.CloudRecordData} returns this
*/
proto.EDM.IPC.CloudRecordData.prototype.setSignal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.CloudRecordData} returns this
 */
proto.EDM.IPC.CloudRecordData.prototype.clearSignal = function() {
  return this.setSignal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.CloudRecordData.prototype.hasSignal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NumericDisplayItem attribute = 3;
 * @return {?proto.EDM.IPC.NumericDisplayItem}
 */
proto.EDM.IPC.CloudRecordData.prototype.getAttribute = function() {
  return /** @type{?proto.EDM.IPC.NumericDisplayItem} */ (
    jspb.Message.getWrapperField(this, proto.EDM.IPC.NumericDisplayItem, 3));
};


/**
 * @param {?proto.EDM.IPC.NumericDisplayItem|undefined} value
 * @return {!proto.EDM.IPC.CloudRecordData} returns this
*/
proto.EDM.IPC.CloudRecordData.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EDM.IPC.CloudRecordData} returns this
 */
proto.EDM.IPC.CloudRecordData.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EDM.IPC.CloudRecordData.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.CloudRecordDataArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudRecordDataArray.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudRecordDataArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudRecordDataArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRecordDataArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.EDM.IPC.CloudRecordData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudRecordDataArray}
 */
proto.EDM.IPC.CloudRecordDataArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudRecordDataArray;
  return proto.EDM.IPC.CloudRecordDataArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudRecordDataArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudRecordDataArray}
 */
proto.EDM.IPC.CloudRecordDataArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.CloudRecordData;
      reader.readMessage(value,proto.EDM.IPC.CloudRecordData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudRecordDataArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudRecordDataArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudRecordDataArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRecordDataArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EDM.IPC.CloudRecordData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CloudRecordData data = 1;
 * @return {!Array<!proto.EDM.IPC.CloudRecordData>}
 */
proto.EDM.IPC.CloudRecordDataArray.prototype.getDataList = function() {
  return /** @type{!Array<!proto.EDM.IPC.CloudRecordData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.CloudRecordData, 1));
};


/**
 * @param {!Array<!proto.EDM.IPC.CloudRecordData>} value
 * @return {!proto.EDM.IPC.CloudRecordDataArray} returns this
*/
proto.EDM.IPC.CloudRecordDataArray.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EDM.IPC.CloudRecordData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.CloudRecordData}
 */
proto.EDM.IPC.CloudRecordDataArray.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EDM.IPC.CloudRecordData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.CloudRecordDataArray} returns this
 */
proto.EDM.IPC.CloudRecordDataArray.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudRCM_SiteStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudRCM_SiteStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRCM_SiteStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connectionstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    connectionstatusstring: jspb.Message.getFieldWithDefault(msg, 3, ""),
    runningstatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    runningstatusstring: jspb.Message.getFieldWithDefault(msg, 5, ""),
    elapsedseconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    timeelapsed: jspb.Message.getFieldWithDefault(msg, 7, ""),
    newfilecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isrecording: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    alarmstatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    alarmstatusstring: jspb.Message.getFieldWithDefault(msg, 11, ""),
    location: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 13, ""),
    alarmcount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudRCM_SiteStatus;
  return proto.EDM.IPC.CloudRCM_SiteStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudRCM_SiteStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSitename(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConnectionstatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectionstatusstring(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRunningstatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunningstatusstring(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setElapsedseconds(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeelapsed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewfilecount(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrecording(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAlarmstatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlarmstatusstring(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAlarmcount(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudRCM_SiteStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudRCM_SiteStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRCM_SiteStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectionstatus();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getConnectionstatusstring();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRunningstatus();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRunningstatusstring();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getElapsedseconds();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTimeelapsed();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNewfilecount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIsrecording();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAlarmstatus();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAlarmstatusstring();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAlarmcount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
};


/**
 * optional string SiteName = 1;
 * @return {string}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getSitename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setSitename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 ConnectionStatus = 2;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getConnectionstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setConnectionstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string ConnectionStatusString = 3;
 * @return {string}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getConnectionstatusstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setConnectionstatusstring = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 RunningStatus = 4;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getRunningstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setRunningstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string RunningStatusString = 5;
 * @return {string}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getRunningstatusstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setRunningstatusstring = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double ElapsedSeconds = 6;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getElapsedseconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setElapsedseconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string TimeElapsed = 7;
 * @return {string}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getTimeelapsed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setTimeelapsed = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 NewFileCount = 8;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getNewfilecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setNewfilecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool IsRecording = 9;
 * @return {boolean}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getIsrecording = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setIsrecording = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 AlarmStatus = 10;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getAlarmstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setAlarmstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string AlarmStatusString = 11;
 * @return {string}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getAlarmstatusstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setAlarmstatusstring = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string Location = 12;
 * @return {string}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string LastUpdated = 13;
 * @return {string}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 AlarmCount = 14;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getAlarmcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setAlarmcount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional double Longitude = 15;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double Latitude = 16;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_SiteStatus} returns this
 */
proto.EDM.IPC.CloudRCM_SiteStatus.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.CloudRCM_AlarmItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.CloudRCM_AlarmItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRCM_AlarmItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lowalarmcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    highalarmcount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lowwarningcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    highwarningcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    exceededlimitcount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.CloudRCM_AlarmItem;
  return proto.EDM.IPC.CloudRCM_AlarmItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.CloudRCM_AlarmItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannelid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLowalarmcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHighalarmcount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLowwarningcount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHighwarningcount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExceededlimitcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.CloudRCM_AlarmItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.CloudRCM_AlarmItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.CloudRCM_AlarmItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLowalarmcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHighalarmcount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLowwarningcount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getHighwarningcount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getExceededlimitcount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 ChannelID = 1;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.getChannelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem} returns this
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.setChannelid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 LowAlarmCount = 2;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.getLowalarmcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem} returns this
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.setLowalarmcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 HighAlarmCount = 3;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.getHighalarmcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem} returns this
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.setHighalarmcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 LowWarningCount = 4;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.getLowwarningcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem} returns this
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.setLowwarningcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 HighWarningCount = 5;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.getHighwarningcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem} returns this
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.setHighwarningcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 ExceededLimitCount = 6;
 * @return {number}
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.getExceededlimitcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.CloudRCM_AlarmItem} returns this
 */
proto.EDM.IPC.CloudRCM_AlarmItem.prototype.setExceededlimitcount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.UploadOptions.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.UploadOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.UploadOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.UploadOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.UploadOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    signalsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    signalList: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    tablesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    tableList: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    testId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    runId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    numericalValues: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    parameters: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    notifications: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    v1Data: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
    allData: jspb.Message.getBooleanFieldWithDefault(msg, 101, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.UploadOptions}
 */
proto.EDM.IPC.UploadOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.UploadOptions;
  return proto.EDM.IPC.UploadOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.UploadOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.UploadOptions}
 */
proto.EDM.IPC.UploadOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSignals(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSignalList(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTables(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTableList(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNumericalValues(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setParameters(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotifications(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setV1Data(value);
      break;
    case 101:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.UploadOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.UploadOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.UploadOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.UploadOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignalsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSignalList();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTablesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getTableList();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTestId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNumericalValues();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getParameters();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getNotifications();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getV1Data();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getAllData();
  if (f) {
    writer.writeBool(
      101,
      f
    );
  }
};


/**
 * repeated string signals = 1;
 * @return {!Array<string>}
 */
proto.EDM.IPC.UploadOptions.prototype.getSignalsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setSignalsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.addSignals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.clearSignalsList = function() {
  return this.setSignalsList([]);
};


/**
 * optional bool signal_list = 2;
 * @return {boolean}
 */
proto.EDM.IPC.UploadOptions.prototype.getSignalList = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setSignalList = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string tables = 3;
 * @return {!Array<string>}
 */
proto.EDM.IPC.UploadOptions.prototype.getTablesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setTablesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.addTables = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.clearTablesList = function() {
  return this.setTablesList([]);
};


/**
 * optional bool table_list = 4;
 * @return {boolean}
 */
proto.EDM.IPC.UploadOptions.prototype.getTableList = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setTableList = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string test_id = 10;
 * @return {string}
 */
proto.EDM.IPC.UploadOptions.prototype.getTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string run_id = 11;
 * @return {string}
 */
proto.EDM.IPC.UploadOptions.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool numerical_values = 20;
 * @return {boolean}
 */
proto.EDM.IPC.UploadOptions.prototype.getNumericalValues = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setNumericalValues = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool parameters = 21;
 * @return {boolean}
 */
proto.EDM.IPC.UploadOptions.prototype.getParameters = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setParameters = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool notifications = 22;
 * @return {boolean}
 */
proto.EDM.IPC.UploadOptions.prototype.getNotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setNotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool v1_data = 100;
 * @return {boolean}
 */
proto.EDM.IPC.UploadOptions.prototype.getV1Data = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setV1Data = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


/**
 * optional bool all_data = 101;
 * @return {boolean}
 */
proto.EDM.IPC.UploadOptions.prototype.getAllData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 101, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.UploadOptions} returns this
 */
proto.EDM.IPC.UploadOptions.prototype.setAllData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 101, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.GenericMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.GenericMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.GenericMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.GenericMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    doubleP1: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    doubleP2: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    doubleP3: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    doubleP4: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    doubleP5: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    doubleP6: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    doubleP7: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    doubleP8: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    doubleP9: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    floatP1: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    floatP2: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    floatP3: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    floatP4: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    floatP5: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    floatP6: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    floatP7: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    floatP8: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    floatP9: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    int32P1: jspb.Message.getFieldWithDefault(msg, 21, 0),
    int32P2: jspb.Message.getFieldWithDefault(msg, 22, 0),
    int32P3: jspb.Message.getFieldWithDefault(msg, 23, 0),
    int32P4: jspb.Message.getFieldWithDefault(msg, 24, 0),
    int32P5: jspb.Message.getFieldWithDefault(msg, 25, 0),
    int32P6: jspb.Message.getFieldWithDefault(msg, 26, 0),
    int32P7: jspb.Message.getFieldWithDefault(msg, 27, 0),
    int32P8: jspb.Message.getFieldWithDefault(msg, 28, 0),
    int32P9: jspb.Message.getFieldWithDefault(msg, 29, 0),
    int64P1: jspb.Message.getFieldWithDefault(msg, 31, 0),
    int64P2: jspb.Message.getFieldWithDefault(msg, 32, 0),
    int64P3: jspb.Message.getFieldWithDefault(msg, 33, 0),
    int64P4: jspb.Message.getFieldWithDefault(msg, 34, 0),
    int64P5: jspb.Message.getFieldWithDefault(msg, 35, 0),
    int64P6: jspb.Message.getFieldWithDefault(msg, 36, 0),
    int64P7: jspb.Message.getFieldWithDefault(msg, 37, 0),
    int64P8: jspb.Message.getFieldWithDefault(msg, 38, 0),
    int64P9: jspb.Message.getFieldWithDefault(msg, 39, 0),
    uint32P1: jspb.Message.getFieldWithDefault(msg, 41, 0),
    uint32P2: jspb.Message.getFieldWithDefault(msg, 42, 0),
    uint32P3: jspb.Message.getFieldWithDefault(msg, 43, 0),
    uint32P4: jspb.Message.getFieldWithDefault(msg, 44, 0),
    uint32P5: jspb.Message.getFieldWithDefault(msg, 45, 0),
    uint32P6: jspb.Message.getFieldWithDefault(msg, 46, 0),
    uint32P7: jspb.Message.getFieldWithDefault(msg, 47, 0),
    uint32P8: jspb.Message.getFieldWithDefault(msg, 48, 0),
    uint32P9: jspb.Message.getFieldWithDefault(msg, 49, 0),
    uint64P1: jspb.Message.getFieldWithDefault(msg, 51, 0),
    uint64P2: jspb.Message.getFieldWithDefault(msg, 52, 0),
    uint64P3: jspb.Message.getFieldWithDefault(msg, 53, 0),
    uint64P4: jspb.Message.getFieldWithDefault(msg, 54, 0),
    uint64P5: jspb.Message.getFieldWithDefault(msg, 55, 0),
    uint64P6: jspb.Message.getFieldWithDefault(msg, 56, 0),
    uint64P7: jspb.Message.getFieldWithDefault(msg, 57, 0),
    uint64P8: jspb.Message.getFieldWithDefault(msg, 58, 0),
    uint64P9: jspb.Message.getFieldWithDefault(msg, 59, 0),
    sint32P1: jspb.Message.getFieldWithDefault(msg, 61, 0),
    sint32P2: jspb.Message.getFieldWithDefault(msg, 62, 0),
    sint32P3: jspb.Message.getFieldWithDefault(msg, 63, 0),
    sint32P4: jspb.Message.getFieldWithDefault(msg, 64, 0),
    sint32P5: jspb.Message.getFieldWithDefault(msg, 65, 0),
    sint32P6: jspb.Message.getFieldWithDefault(msg, 66, 0),
    sint32P7: jspb.Message.getFieldWithDefault(msg, 67, 0),
    sint32P8: jspb.Message.getFieldWithDefault(msg, 68, 0),
    sint32P9: jspb.Message.getFieldWithDefault(msg, 69, 0),
    sint64P1: jspb.Message.getFieldWithDefault(msg, 71, 0),
    sint64P2: jspb.Message.getFieldWithDefault(msg, 72, 0),
    sint64P3: jspb.Message.getFieldWithDefault(msg, 73, 0),
    sint64P4: jspb.Message.getFieldWithDefault(msg, 74, 0),
    sint64P5: jspb.Message.getFieldWithDefault(msg, 75, 0),
    sint64P6: jspb.Message.getFieldWithDefault(msg, 76, 0),
    sint64P7: jspb.Message.getFieldWithDefault(msg, 77, 0),
    sint64P8: jspb.Message.getFieldWithDefault(msg, 78, 0),
    sint64P9: jspb.Message.getFieldWithDefault(msg, 79, 0),
    fixed32P1: jspb.Message.getFieldWithDefault(msg, 81, 0),
    fixed32P2: jspb.Message.getFieldWithDefault(msg, 82, 0),
    fixed32P3: jspb.Message.getFieldWithDefault(msg, 83, 0),
    fixed32P4: jspb.Message.getFieldWithDefault(msg, 84, 0),
    fixed32P5: jspb.Message.getFieldWithDefault(msg, 85, 0),
    fixed32P6: jspb.Message.getFieldWithDefault(msg, 86, 0),
    fixed32P7: jspb.Message.getFieldWithDefault(msg, 87, 0),
    fixed32P8: jspb.Message.getFieldWithDefault(msg, 88, 0),
    fixed32P9: jspb.Message.getFieldWithDefault(msg, 89, 0),
    fixed64P1: jspb.Message.getFieldWithDefault(msg, 91, 0),
    fixed64P2: jspb.Message.getFieldWithDefault(msg, 92, 0),
    fixed64P3: jspb.Message.getFieldWithDefault(msg, 93, 0),
    fixed64P4: jspb.Message.getFieldWithDefault(msg, 94, 0),
    fixed64P5: jspb.Message.getFieldWithDefault(msg, 95, 0),
    fixed64P6: jspb.Message.getFieldWithDefault(msg, 96, 0),
    fixed64P7: jspb.Message.getFieldWithDefault(msg, 97, 0),
    fixed64P8: jspb.Message.getFieldWithDefault(msg, 98, 0),
    fixed64P9: jspb.Message.getFieldWithDefault(msg, 99, 0),
    sfixed32P1: jspb.Message.getFieldWithDefault(msg, 101, 0),
    sfixed32P2: jspb.Message.getFieldWithDefault(msg, 102, 0),
    sfixed32P3: jspb.Message.getFieldWithDefault(msg, 103, 0),
    sfixed32P4: jspb.Message.getFieldWithDefault(msg, 104, 0),
    sfixed32P5: jspb.Message.getFieldWithDefault(msg, 105, 0),
    sfixed32P6: jspb.Message.getFieldWithDefault(msg, 106, 0),
    sfixed32P7: jspb.Message.getFieldWithDefault(msg, 107, 0),
    sfixed32P8: jspb.Message.getFieldWithDefault(msg, 108, 0),
    sfixed32P9: jspb.Message.getFieldWithDefault(msg, 109, 0),
    sfixed64P1: jspb.Message.getFieldWithDefault(msg, 111, 0),
    sfixed64P2: jspb.Message.getFieldWithDefault(msg, 112, 0),
    sfixed64P3: jspb.Message.getFieldWithDefault(msg, 113, 0),
    sfixed64P4: jspb.Message.getFieldWithDefault(msg, 114, 0),
    sfixed64P5: jspb.Message.getFieldWithDefault(msg, 115, 0),
    sfixed64P6: jspb.Message.getFieldWithDefault(msg, 116, 0),
    sfixed64P7: jspb.Message.getFieldWithDefault(msg, 117, 0),
    sfixed64P8: jspb.Message.getFieldWithDefault(msg, 118, 0),
    sfixed64P9: jspb.Message.getFieldWithDefault(msg, 119, 0),
    boolP1: jspb.Message.getBooleanFieldWithDefault(msg, 121, false),
    boolP2: jspb.Message.getBooleanFieldWithDefault(msg, 122, false),
    boolP3: jspb.Message.getBooleanFieldWithDefault(msg, 123, false),
    boolP4: jspb.Message.getBooleanFieldWithDefault(msg, 124, false),
    boolP5: jspb.Message.getBooleanFieldWithDefault(msg, 125, false),
    boolP6: jspb.Message.getBooleanFieldWithDefault(msg, 126, false),
    boolP7: jspb.Message.getBooleanFieldWithDefault(msg, 127, false),
    boolP8: jspb.Message.getBooleanFieldWithDefault(msg, 128, false),
    boolP9: jspb.Message.getBooleanFieldWithDefault(msg, 129, false),
    stringP1: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stringP2: jspb.Message.getFieldWithDefault(msg, 20, ""),
    stringP3: jspb.Message.getFieldWithDefault(msg, 30, ""),
    stringP4: jspb.Message.getFieldWithDefault(msg, 40, ""),
    stringP5: jspb.Message.getFieldWithDefault(msg, 50, ""),
    stringP6: jspb.Message.getFieldWithDefault(msg, 60, ""),
    stringP7: jspb.Message.getFieldWithDefault(msg, 70, ""),
    stringP8: jspb.Message.getFieldWithDefault(msg, 80, ""),
    stringP9: jspb.Message.getFieldWithDefault(msg, 90, ""),
    bytesP1: msg.getBytesP1_asB64(),
    bytesP2: msg.getBytesP2_asB64(),
    bytesP3: msg.getBytesP3_asB64(),
    bytesP4: msg.getBytesP4_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.GenericMessage}
 */
proto.EDM.IPC.GenericMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.GenericMessage;
  return proto.EDM.IPC.GenericMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.GenericMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.GenericMessage}
 */
proto.EDM.IPC.GenericMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP2(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP3(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP4(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP5(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP6(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP7(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP8(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleP9(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP1(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP2(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP3(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP4(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP5(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP6(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP7(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP8(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatP9(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P1(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P2(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P3(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P4(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P5(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P6(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P7(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P8(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32P9(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P1(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P2(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P3(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P4(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P5(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P6(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P7(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P8(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64P9(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P1(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P2(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P3(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P4(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P5(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P6(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P7(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P8(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32P9(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P1(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P2(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P3(value);
      break;
    case 54:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P4(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P5(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P6(value);
      break;
    case 57:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P7(value);
      break;
    case 58:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P8(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64P9(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P1(value);
      break;
    case 62:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P2(value);
      break;
    case 63:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P3(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P4(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P5(value);
      break;
    case 66:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P6(value);
      break;
    case 67:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P7(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P8(value);
      break;
    case 69:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSint32P9(value);
      break;
    case 71:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P1(value);
      break;
    case 72:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P2(value);
      break;
    case 73:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P3(value);
      break;
    case 74:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P4(value);
      break;
    case 75:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P5(value);
      break;
    case 76:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P6(value);
      break;
    case 77:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P7(value);
      break;
    case 78:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P8(value);
      break;
    case 79:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64P9(value);
      break;
    case 81:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P1(value);
      break;
    case 82:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P2(value);
      break;
    case 83:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P3(value);
      break;
    case 84:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P4(value);
      break;
    case 85:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P5(value);
      break;
    case 86:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P6(value);
      break;
    case 87:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P7(value);
      break;
    case 88:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P8(value);
      break;
    case 89:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setFixed32P9(value);
      break;
    case 91:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P1(value);
      break;
    case 92:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P2(value);
      break;
    case 93:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P3(value);
      break;
    case 94:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P4(value);
      break;
    case 95:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P5(value);
      break;
    case 96:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P6(value);
      break;
    case 97:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P7(value);
      break;
    case 98:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P8(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setFixed64P9(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P1(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P2(value);
      break;
    case 103:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P3(value);
      break;
    case 104:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P4(value);
      break;
    case 105:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P5(value);
      break;
    case 106:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P6(value);
      break;
    case 107:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P7(value);
      break;
    case 108:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P8(value);
      break;
    case 109:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setSfixed32P9(value);
      break;
    case 111:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P1(value);
      break;
    case 112:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P2(value);
      break;
    case 113:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P3(value);
      break;
    case 114:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P4(value);
      break;
    case 115:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P5(value);
      break;
    case 116:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P6(value);
      break;
    case 117:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P7(value);
      break;
    case 118:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P8(value);
      break;
    case 119:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSfixed64P9(value);
      break;
    case 121:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP1(value);
      break;
    case 122:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP2(value);
      break;
    case 123:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP3(value);
      break;
    case 124:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP4(value);
      break;
    case 125:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP5(value);
      break;
    case 126:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP6(value);
      break;
    case 127:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP7(value);
      break;
    case 128:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP8(value);
      break;
    case 129:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolP9(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP1(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP2(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP3(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP4(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP5(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP6(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP7(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP8(value);
      break;
    case 90:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringP9(value);
      break;
    case 100:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBytesP1(value);
      break;
    case 110:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBytesP2(value);
      break;
    case 120:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBytesP3(value);
      break;
    case 130:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBytesP4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.GenericMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.GenericMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.GenericMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.GenericMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoubleP1();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDoubleP2();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDoubleP3();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDoubleP4();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDoubleP5();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDoubleP6();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDoubleP7();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDoubleP8();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDoubleP9();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getFloatP1();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getFloatP2();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getFloatP3();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getFloatP4();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getFloatP5();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getFloatP6();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getFloatP7();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getFloatP8();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getFloatP9();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getInt32P1();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getInt32P2();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getInt32P3();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getInt32P4();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getInt32P5();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getInt32P6();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getInt32P7();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getInt32P8();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getInt32P9();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
  f = message.getInt64P1();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
  f = message.getInt64P2();
  if (f !== 0) {
    writer.writeInt64(
      32,
      f
    );
  }
  f = message.getInt64P3();
  if (f !== 0) {
    writer.writeInt64(
      33,
      f
    );
  }
  f = message.getInt64P4();
  if (f !== 0) {
    writer.writeInt64(
      34,
      f
    );
  }
  f = message.getInt64P5();
  if (f !== 0) {
    writer.writeInt64(
      35,
      f
    );
  }
  f = message.getInt64P6();
  if (f !== 0) {
    writer.writeInt64(
      36,
      f
    );
  }
  f = message.getInt64P7();
  if (f !== 0) {
    writer.writeInt64(
      37,
      f
    );
  }
  f = message.getInt64P8();
  if (f !== 0) {
    writer.writeInt64(
      38,
      f
    );
  }
  f = message.getInt64P9();
  if (f !== 0) {
    writer.writeInt64(
      39,
      f
    );
  }
  f = message.getUint32P1();
  if (f !== 0) {
    writer.writeUint32(
      41,
      f
    );
  }
  f = message.getUint32P2();
  if (f !== 0) {
    writer.writeUint32(
      42,
      f
    );
  }
  f = message.getUint32P3();
  if (f !== 0) {
    writer.writeUint32(
      43,
      f
    );
  }
  f = message.getUint32P4();
  if (f !== 0) {
    writer.writeUint32(
      44,
      f
    );
  }
  f = message.getUint32P5();
  if (f !== 0) {
    writer.writeUint32(
      45,
      f
    );
  }
  f = message.getUint32P6();
  if (f !== 0) {
    writer.writeUint32(
      46,
      f
    );
  }
  f = message.getUint32P7();
  if (f !== 0) {
    writer.writeUint32(
      47,
      f
    );
  }
  f = message.getUint32P8();
  if (f !== 0) {
    writer.writeUint32(
      48,
      f
    );
  }
  f = message.getUint32P9();
  if (f !== 0) {
    writer.writeUint32(
      49,
      f
    );
  }
  f = message.getUint64P1();
  if (f !== 0) {
    writer.writeUint64(
      51,
      f
    );
  }
  f = message.getUint64P2();
  if (f !== 0) {
    writer.writeUint64(
      52,
      f
    );
  }
  f = message.getUint64P3();
  if (f !== 0) {
    writer.writeUint64(
      53,
      f
    );
  }
  f = message.getUint64P4();
  if (f !== 0) {
    writer.writeUint64(
      54,
      f
    );
  }
  f = message.getUint64P5();
  if (f !== 0) {
    writer.writeUint64(
      55,
      f
    );
  }
  f = message.getUint64P6();
  if (f !== 0) {
    writer.writeUint64(
      56,
      f
    );
  }
  f = message.getUint64P7();
  if (f !== 0) {
    writer.writeUint64(
      57,
      f
    );
  }
  f = message.getUint64P8();
  if (f !== 0) {
    writer.writeUint64(
      58,
      f
    );
  }
  f = message.getUint64P9();
  if (f !== 0) {
    writer.writeUint64(
      59,
      f
    );
  }
  f = message.getSint32P1();
  if (f !== 0) {
    writer.writeSint32(
      61,
      f
    );
  }
  f = message.getSint32P2();
  if (f !== 0) {
    writer.writeSint32(
      62,
      f
    );
  }
  f = message.getSint32P3();
  if (f !== 0) {
    writer.writeSint32(
      63,
      f
    );
  }
  f = message.getSint32P4();
  if (f !== 0) {
    writer.writeSint32(
      64,
      f
    );
  }
  f = message.getSint32P5();
  if (f !== 0) {
    writer.writeSint32(
      65,
      f
    );
  }
  f = message.getSint32P6();
  if (f !== 0) {
    writer.writeSint32(
      66,
      f
    );
  }
  f = message.getSint32P7();
  if (f !== 0) {
    writer.writeSint32(
      67,
      f
    );
  }
  f = message.getSint32P8();
  if (f !== 0) {
    writer.writeSint32(
      68,
      f
    );
  }
  f = message.getSint32P9();
  if (f !== 0) {
    writer.writeSint32(
      69,
      f
    );
  }
  f = message.getSint64P1();
  if (f !== 0) {
    writer.writeSint64(
      71,
      f
    );
  }
  f = message.getSint64P2();
  if (f !== 0) {
    writer.writeSint64(
      72,
      f
    );
  }
  f = message.getSint64P3();
  if (f !== 0) {
    writer.writeSint64(
      73,
      f
    );
  }
  f = message.getSint64P4();
  if (f !== 0) {
    writer.writeSint64(
      74,
      f
    );
  }
  f = message.getSint64P5();
  if (f !== 0) {
    writer.writeSint64(
      75,
      f
    );
  }
  f = message.getSint64P6();
  if (f !== 0) {
    writer.writeSint64(
      76,
      f
    );
  }
  f = message.getSint64P7();
  if (f !== 0) {
    writer.writeSint64(
      77,
      f
    );
  }
  f = message.getSint64P8();
  if (f !== 0) {
    writer.writeSint64(
      78,
      f
    );
  }
  f = message.getSint64P9();
  if (f !== 0) {
    writer.writeSint64(
      79,
      f
    );
  }
  f = message.getFixed32P1();
  if (f !== 0) {
    writer.writeFixed32(
      81,
      f
    );
  }
  f = message.getFixed32P2();
  if (f !== 0) {
    writer.writeFixed32(
      82,
      f
    );
  }
  f = message.getFixed32P3();
  if (f !== 0) {
    writer.writeFixed32(
      83,
      f
    );
  }
  f = message.getFixed32P4();
  if (f !== 0) {
    writer.writeFixed32(
      84,
      f
    );
  }
  f = message.getFixed32P5();
  if (f !== 0) {
    writer.writeFixed32(
      85,
      f
    );
  }
  f = message.getFixed32P6();
  if (f !== 0) {
    writer.writeFixed32(
      86,
      f
    );
  }
  f = message.getFixed32P7();
  if (f !== 0) {
    writer.writeFixed32(
      87,
      f
    );
  }
  f = message.getFixed32P8();
  if (f !== 0) {
    writer.writeFixed32(
      88,
      f
    );
  }
  f = message.getFixed32P9();
  if (f !== 0) {
    writer.writeFixed32(
      89,
      f
    );
  }
  f = message.getFixed64P1();
  if (f !== 0) {
    writer.writeFixed64(
      91,
      f
    );
  }
  f = message.getFixed64P2();
  if (f !== 0) {
    writer.writeFixed64(
      92,
      f
    );
  }
  f = message.getFixed64P3();
  if (f !== 0) {
    writer.writeFixed64(
      93,
      f
    );
  }
  f = message.getFixed64P4();
  if (f !== 0) {
    writer.writeFixed64(
      94,
      f
    );
  }
  f = message.getFixed64P5();
  if (f !== 0) {
    writer.writeFixed64(
      95,
      f
    );
  }
  f = message.getFixed64P6();
  if (f !== 0) {
    writer.writeFixed64(
      96,
      f
    );
  }
  f = message.getFixed64P7();
  if (f !== 0) {
    writer.writeFixed64(
      97,
      f
    );
  }
  f = message.getFixed64P8();
  if (f !== 0) {
    writer.writeFixed64(
      98,
      f
    );
  }
  f = message.getFixed64P9();
  if (f !== 0) {
    writer.writeFixed64(
      99,
      f
    );
  }
  f = message.getSfixed32P1();
  if (f !== 0) {
    writer.writeSfixed32(
      101,
      f
    );
  }
  f = message.getSfixed32P2();
  if (f !== 0) {
    writer.writeSfixed32(
      102,
      f
    );
  }
  f = message.getSfixed32P3();
  if (f !== 0) {
    writer.writeSfixed32(
      103,
      f
    );
  }
  f = message.getSfixed32P4();
  if (f !== 0) {
    writer.writeSfixed32(
      104,
      f
    );
  }
  f = message.getSfixed32P5();
  if (f !== 0) {
    writer.writeSfixed32(
      105,
      f
    );
  }
  f = message.getSfixed32P6();
  if (f !== 0) {
    writer.writeSfixed32(
      106,
      f
    );
  }
  f = message.getSfixed32P7();
  if (f !== 0) {
    writer.writeSfixed32(
      107,
      f
    );
  }
  f = message.getSfixed32P8();
  if (f !== 0) {
    writer.writeSfixed32(
      108,
      f
    );
  }
  f = message.getSfixed32P9();
  if (f !== 0) {
    writer.writeSfixed32(
      109,
      f
    );
  }
  f = message.getSfixed64P1();
  if (f !== 0) {
    writer.writeSfixed64(
      111,
      f
    );
  }
  f = message.getSfixed64P2();
  if (f !== 0) {
    writer.writeSfixed64(
      112,
      f
    );
  }
  f = message.getSfixed64P3();
  if (f !== 0) {
    writer.writeSfixed64(
      113,
      f
    );
  }
  f = message.getSfixed64P4();
  if (f !== 0) {
    writer.writeSfixed64(
      114,
      f
    );
  }
  f = message.getSfixed64P5();
  if (f !== 0) {
    writer.writeSfixed64(
      115,
      f
    );
  }
  f = message.getSfixed64P6();
  if (f !== 0) {
    writer.writeSfixed64(
      116,
      f
    );
  }
  f = message.getSfixed64P7();
  if (f !== 0) {
    writer.writeSfixed64(
      117,
      f
    );
  }
  f = message.getSfixed64P8();
  if (f !== 0) {
    writer.writeSfixed64(
      118,
      f
    );
  }
  f = message.getSfixed64P9();
  if (f !== 0) {
    writer.writeSfixed64(
      119,
      f
    );
  }
  f = message.getBoolP1();
  if (f) {
    writer.writeBool(
      121,
      f
    );
  }
  f = message.getBoolP2();
  if (f) {
    writer.writeBool(
      122,
      f
    );
  }
  f = message.getBoolP3();
  if (f) {
    writer.writeBool(
      123,
      f
    );
  }
  f = message.getBoolP4();
  if (f) {
    writer.writeBool(
      124,
      f
    );
  }
  f = message.getBoolP5();
  if (f) {
    writer.writeBool(
      125,
      f
    );
  }
  f = message.getBoolP6();
  if (f) {
    writer.writeBool(
      126,
      f
    );
  }
  f = message.getBoolP7();
  if (f) {
    writer.writeBool(
      127,
      f
    );
  }
  f = message.getBoolP8();
  if (f) {
    writer.writeBool(
      128,
      f
    );
  }
  f = message.getBoolP9();
  if (f) {
    writer.writeBool(
      129,
      f
    );
  }
  f = message.getStringP1();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStringP2();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getStringP3();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getStringP4();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getStringP5();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getStringP6();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getStringP7();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getStringP8();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getStringP9();
  if (f.length > 0) {
    writer.writeString(
      90,
      f
    );
  }
  f = message.getBytesP1_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      100,
      f
    );
  }
  f = message.getBytesP2_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      110,
      f
    );
  }
  f = message.getBytesP3_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      120,
      f
    );
  }
  f = message.getBytesP4_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      130,
      f
    );
  }
};


/**
 * optional double double_p1 = 1;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double double_p2 = 2;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double double_p3 = 3;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double double_p4 = 4;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP4 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP4 = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double double_p5 = 5;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP5 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP5 = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double double_p6 = 6;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP6 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP6 = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double double_p7 = 7;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP7 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP7 = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double double_p8 = 8;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP8 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP8 = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double double_p9 = 9;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getDoubleP9 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setDoubleP9 = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float float_p1 = 11;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float float_p2 = 12;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float float_p3 = 13;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float float_p4 = 14;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP4 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP4 = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float float_p5 = 15;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP5 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP5 = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float float_p6 = 16;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP6 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP6 = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float float_p7 = 17;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP7 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP7 = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float float_p8 = 18;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP8 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP8 = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float float_p9 = 19;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFloatP9 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFloatP9 = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional int32 int32_p1 = 21;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 int32_p2 = 22;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 int32_p3 = 23;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 int32_p4 = 24;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 int32_p5 = 25;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int32 int32_p6 = 26;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int32 int32_p7 = 27;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int32 int32_p8 = 28;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int32 int32_p9 = 29;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt32P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt32P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional int64 int64_p1 = 31;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional int64 int64_p2 = 32;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int64 int64_p3 = 33;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional int64 int64_p4 = 34;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional int64 int64_p5 = 35;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional int64 int64_p6 = 36;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 36, value);
};


/**
 * optional int64 int64_p7 = 37;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional int64 int64_p8 = 38;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 38, value);
};


/**
 * optional int64 int64_p9 = 39;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getInt64P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setInt64P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 39, value);
};


/**
 * optional uint32 uint32_p1 = 41;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 41, value);
};


/**
 * optional uint32 uint32_p2 = 42;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional uint32 uint32_p3 = 43;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 43, value);
};


/**
 * optional uint32 uint32_p4 = 44;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional uint32 uint32_p5 = 45;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 45, value);
};


/**
 * optional uint32 uint32_p6 = 46;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 46, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 46, value);
};


/**
 * optional uint32 uint32_p7 = 47;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional uint32 uint32_p8 = 48;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 48, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 48, value);
};


/**
 * optional uint32 uint32_p9 = 49;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint32P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint32P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 49, value);
};


/**
 * optional uint64 uint64_p1 = 51;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 51, value);
};


/**
 * optional uint64 uint64_p2 = 52;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 52, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 52, value);
};


/**
 * optional uint64 uint64_p3 = 53;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 53, value);
};


/**
 * optional uint64 uint64_p4 = 54;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 54, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 54, value);
};


/**
 * optional uint64 uint64_p5 = 55;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 55, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 55, value);
};


/**
 * optional uint64 uint64_p6 = 56;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 56, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 56, value);
};


/**
 * optional uint64 uint64_p7 = 57;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 57, value);
};


/**
 * optional uint64 uint64_p8 = 58;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 58, value);
};


/**
 * optional uint64 uint64_p9 = 59;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getUint64P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setUint64P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 59, value);
};


/**
 * optional sint32 sint32_p1 = 61;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 61, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 61, value);
};


/**
 * optional sint32 sint32_p2 = 62;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 62, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 62, value);
};


/**
 * optional sint32 sint32_p3 = 63;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 63, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 63, value);
};


/**
 * optional sint32 sint32_p4 = 64;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 64, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 64, value);
};


/**
 * optional sint32 sint32_p5 = 65;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 65, value);
};


/**
 * optional sint32 sint32_p6 = 66;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 66, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 66, value);
};


/**
 * optional sint32 sint32_p7 = 67;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 67, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 67, value);
};


/**
 * optional sint32 sint32_p8 = 68;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 68, value);
};


/**
 * optional sint32 sint32_p9 = 69;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint32P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 69, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint32P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 69, value);
};


/**
 * optional sint64 sint64_p1 = 71;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 71, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 71, value);
};


/**
 * optional sint64 sint64_p2 = 72;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 72, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 72, value);
};


/**
 * optional sint64 sint64_p3 = 73;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 73, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 73, value);
};


/**
 * optional sint64 sint64_p4 = 74;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 74, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 74, value);
};


/**
 * optional sint64 sint64_p5 = 75;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 75, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 75, value);
};


/**
 * optional sint64 sint64_p6 = 76;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 76, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 76, value);
};


/**
 * optional sint64 sint64_p7 = 77;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 77, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 77, value);
};


/**
 * optional sint64 sint64_p8 = 78;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 78, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 78, value);
};


/**
 * optional sint64 sint64_p9 = 79;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSint64P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 79, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSint64P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 79, value);
};


/**
 * optional fixed32 fixed32_p1 = 81;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 81, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 81, value);
};


/**
 * optional fixed32 fixed32_p2 = 82;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 82, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 82, value);
};


/**
 * optional fixed32 fixed32_p3 = 83;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 83, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 83, value);
};


/**
 * optional fixed32 fixed32_p4 = 84;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 84, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 84, value);
};


/**
 * optional fixed32 fixed32_p5 = 85;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 85, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 85, value);
};


/**
 * optional fixed32 fixed32_p6 = 86;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 86, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 86, value);
};


/**
 * optional fixed32 fixed32_p7 = 87;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 87, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 87, value);
};


/**
 * optional fixed32 fixed32_p8 = 88;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 88, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 88, value);
};


/**
 * optional fixed32 fixed32_p9 = 89;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed32P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 89, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed32P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 89, value);
};


/**
 * optional fixed64 fixed64_p1 = 91;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 91, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 91, value);
};


/**
 * optional fixed64 fixed64_p2 = 92;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 92, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 92, value);
};


/**
 * optional fixed64 fixed64_p3 = 93;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 93, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 93, value);
};


/**
 * optional fixed64 fixed64_p4 = 94;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 94, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 94, value);
};


/**
 * optional fixed64 fixed64_p5 = 95;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 95, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 95, value);
};


/**
 * optional fixed64 fixed64_p6 = 96;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 96, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 96, value);
};


/**
 * optional fixed64 fixed64_p7 = 97;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 97, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 97, value);
};


/**
 * optional fixed64 fixed64_p8 = 98;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 98, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 98, value);
};


/**
 * optional fixed64 fixed64_p9 = 99;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getFixed64P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setFixed64P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};


/**
 * optional sfixed32 sfixed32_p1 = 101;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};


/**
 * optional sfixed32 sfixed32_p2 = 102;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};


/**
 * optional sfixed32 sfixed32_p3 = 103;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 103, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 103, value);
};


/**
 * optional sfixed32 sfixed32_p4 = 104;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 104, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 104, value);
};


/**
 * optional sfixed32 sfixed32_p5 = 105;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 105, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 105, value);
};


/**
 * optional sfixed32 sfixed32_p6 = 106;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 106, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 106, value);
};


/**
 * optional sfixed32 sfixed32_p7 = 107;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 107, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 107, value);
};


/**
 * optional sfixed32 sfixed32_p8 = 108;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 108, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 108, value);
};


/**
 * optional sfixed32 sfixed32_p9 = 109;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed32P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 109, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed32P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 109, value);
};


/**
 * optional sfixed64 sfixed64_p1 = 111;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 111, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P1 = function(value) {
  return jspb.Message.setProto3IntField(this, 111, value);
};


/**
 * optional sfixed64 sfixed64_p2 = 112;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 112, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P2 = function(value) {
  return jspb.Message.setProto3IntField(this, 112, value);
};


/**
 * optional sfixed64 sfixed64_p3 = 113;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 113, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P3 = function(value) {
  return jspb.Message.setProto3IntField(this, 113, value);
};


/**
 * optional sfixed64 sfixed64_p4 = 114;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 114, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P4 = function(value) {
  return jspb.Message.setProto3IntField(this, 114, value);
};


/**
 * optional sfixed64 sfixed64_p5 = 115;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 115, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P5 = function(value) {
  return jspb.Message.setProto3IntField(this, 115, value);
};


/**
 * optional sfixed64 sfixed64_p6 = 116;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P6 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 116, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P6 = function(value) {
  return jspb.Message.setProto3IntField(this, 116, value);
};


/**
 * optional sfixed64 sfixed64_p7 = 117;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 117, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P7 = function(value) {
  return jspb.Message.setProto3IntField(this, 117, value);
};


/**
 * optional sfixed64 sfixed64_p8 = 118;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P8 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 118, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P8 = function(value) {
  return jspb.Message.setProto3IntField(this, 118, value);
};


/**
 * optional sfixed64 sfixed64_p9 = 119;
 * @return {number}
 */
proto.EDM.IPC.GenericMessage.prototype.getSfixed64P9 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 119, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setSfixed64P9 = function(value) {
  return jspb.Message.setProto3IntField(this, 119, value);
};


/**
 * optional bool bool_p1 = 121;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 121, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 121, value);
};


/**
 * optional bool bool_p2 = 122;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 122, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 122, value);
};


/**
 * optional bool bool_p3 = 123;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 123, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 123, value);
};


/**
 * optional bool bool_p4 = 124;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP4 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 124, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP4 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 124, value);
};


/**
 * optional bool bool_p5 = 125;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP5 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 125, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP5 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 125, value);
};


/**
 * optional bool bool_p6 = 126;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP6 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 126, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP6 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 126, value);
};


/**
 * optional bool bool_p7 = 127;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP7 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 127, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP7 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 127, value);
};


/**
 * optional bool bool_p8 = 128;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP8 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 128, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP8 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 128, value);
};


/**
 * optional bool bool_p9 = 129;
 * @return {boolean}
 */
proto.EDM.IPC.GenericMessage.prototype.getBoolP9 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 129, false));
};


/**
 * @param {boolean} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBoolP9 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 129, value);
};


/**
 * optional string string_p1 = 10;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP1 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string string_p2 = 20;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP2 = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string string_p3 = 30;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP3 = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string string_p4 = 40;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP4 = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string string_p5 = 50;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP5 = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string string_p6 = 60;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP6 = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string string_p7 = 70;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP7 = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string string_p8 = 80;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP8 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP8 = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional string string_p9 = 90;
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getStringP9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 90, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setStringP9 = function(value) {
  return jspb.Message.setProto3StringField(this, 90, value);
};


/**
 * optional bytes bytes_p1 = 100;
 * @return {!(string|Uint8Array)}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP1 = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * optional bytes bytes_p1 = 100;
 * This is a type-conversion wrapper around `getBytesP1()`
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP1_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBytesP1()));
};


/**
 * optional bytes bytes_p1 = 100;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytesP1()`
 * @return {!Uint8Array}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP1_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBytesP1()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBytesP1 = function(value) {
  return jspb.Message.setProto3BytesField(this, 100, value);
};


/**
 * optional bytes bytes_p2 = 110;
 * @return {!(string|Uint8Array)}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP2 = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 110, ""));
};


/**
 * optional bytes bytes_p2 = 110;
 * This is a type-conversion wrapper around `getBytesP2()`
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP2_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBytesP2()));
};


/**
 * optional bytes bytes_p2 = 110;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytesP2()`
 * @return {!Uint8Array}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP2_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBytesP2()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBytesP2 = function(value) {
  return jspb.Message.setProto3BytesField(this, 110, value);
};


/**
 * optional bytes bytes_p3 = 120;
 * @return {!(string|Uint8Array)}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP3 = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 120, ""));
};


/**
 * optional bytes bytes_p3 = 120;
 * This is a type-conversion wrapper around `getBytesP3()`
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP3_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBytesP3()));
};


/**
 * optional bytes bytes_p3 = 120;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytesP3()`
 * @return {!Uint8Array}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP3_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBytesP3()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBytesP3 = function(value) {
  return jspb.Message.setProto3BytesField(this, 120, value);
};


/**
 * optional bytes bytes_p4 = 130;
 * @return {!(string|Uint8Array)}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP4 = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 130, ""));
};


/**
 * optional bytes bytes_p4 = 130;
 * This is a type-conversion wrapper around `getBytesP4()`
 * @return {string}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP4_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBytesP4()));
};


/**
 * optional bytes bytes_p4 = 130;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytesP4()`
 * @return {!Uint8Array}
 */
proto.EDM.IPC.GenericMessage.prototype.getBytesP4_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBytesP4()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.EDM.IPC.GenericMessage} returns this
 */
proto.EDM.IPC.GenericMessage.prototype.setBytesP4 = function(value) {
  return jspb.Message.setProto3BytesField(this, 130, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.PbNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.PbNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.PbNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.PbNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    time: jspb.Message.getFieldWithDefault(msg, 3, ""),
    importance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    priority: jspb.Message.getFieldWithDefault(msg, 5, 0),
    source: jspb.Message.getFieldWithDefault(msg, 6, ""),
    message: jspb.Message.getFieldWithDefault(msg, 7, ""),
    testId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    runId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    testName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    runName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    alarmCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    testStatus: jspb.Message.getFieldWithDefault(msg, 13, 0),
    softwareMode: jspb.Message.getFieldWithDefault(msg, 14, 0),
    measureType: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.PbNotification}
 */
proto.EDM.IPC.PbNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.PbNotification;
  return proto.EDM.IPC.PbNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.PbNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.PbNotification}
 */
proto.EDM.IPC.PbNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImportance(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlarmCode(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTestStatus(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSoftwareMode(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMeasureType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.PbNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.PbNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.PbNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.PbNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImportance();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTestId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTestName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAlarmCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTestStatus();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getSoftwareMode();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getMeasureType();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string time = 3;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 importance = 4;
 * @return {number}
 */
proto.EDM.IPC.PbNotification.prototype.getImportance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setImportance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 priority = 5;
 * @return {number}
 */
proto.EDM.IPC.PbNotification.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string source = 6;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string message = 7;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string test_id = 8;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string run_id = 9;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string test_name = 10;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getTestName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setTestName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string run_name = 11;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string alarm_code = 12;
 * @return {string}
 */
proto.EDM.IPC.PbNotification.prototype.getAlarmCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setAlarmCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 test_status = 13;
 * @return {number}
 */
proto.EDM.IPC.PbNotification.prototype.getTestStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setTestStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 software_mode = 14;
 * @return {number}
 */
proto.EDM.IPC.PbNotification.prototype.getSoftwareMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setSoftwareMode = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 measure_type = 15;
 * @return {number}
 */
proto.EDM.IPC.PbNotification.prototype.getMeasureType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.EDM.IPC.PbNotification} returns this
 */
proto.EDM.IPC.PbNotification.prototype.setMeasureType = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EDM.IPC.PbNotifications.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EDM.IPC.PbNotifications.prototype.toObject = function(opt_includeInstance) {
  return proto.EDM.IPC.PbNotifications.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EDM.IPC.PbNotifications} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.PbNotifications.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.EDM.IPC.PbNotification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EDM.IPC.PbNotifications}
 */
proto.EDM.IPC.PbNotifications.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EDM.IPC.PbNotifications;
  return proto.EDM.IPC.PbNotifications.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EDM.IPC.PbNotifications} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EDM.IPC.PbNotifications}
 */
proto.EDM.IPC.PbNotifications.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EDM.IPC.PbNotification;
      reader.readMessage(value,proto.EDM.IPC.PbNotification.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EDM.IPC.PbNotifications.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EDM.IPC.PbNotifications.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EDM.IPC.PbNotifications} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EDM.IPC.PbNotifications.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EDM.IPC.PbNotification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PbNotification items = 1;
 * @return {!Array<!proto.EDM.IPC.PbNotification>}
 */
proto.EDM.IPC.PbNotifications.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.EDM.IPC.PbNotification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EDM.IPC.PbNotification, 1));
};


/**
 * @param {!Array<!proto.EDM.IPC.PbNotification>} value
 * @return {!proto.EDM.IPC.PbNotifications} returns this
*/
proto.EDM.IPC.PbNotifications.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EDM.IPC.PbNotification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EDM.IPC.PbNotification}
 */
proto.EDM.IPC.PbNotifications.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EDM.IPC.PbNotification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EDM.IPC.PbNotifications} returns this
 */
proto.EDM.IPC.PbNotifications.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


goog.object.extend(exports, proto.EDM.IPC);
