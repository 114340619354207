
import { OpenLicensePage } from "@/helpers";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "AboutCard",
})
export default class AboutCard extends Vue {
    get Version() {
        return "1.0.0";
    }

    onOpenLicense() {
        OpenLicensePage();
    }
}
