/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/components/managers/SocketManager.ts
 * @create:      2018-12-13 17:56:07.292
 * @modify:      2023-04-14 16:37:26.394
 * @version:     0.1.1
 * @times:       159
 * @lines:       228
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

/// <reference types="socket.io-client" />

import store from "@/store";
import { ClientInfo, CloudMessage, PbNotification, UploadOptions } from "protoc/cloud_messages_pb";
import { AbstractSocket, CreateCloudMessage, GetNameByValue, Now, SocketEventName } from "socket/common";
import { CloudMessageType, CloudMessageTypeMap } from "socket/protoc/generated/cloud_enums_pb";
import { EDMClient, ShowBrowserNotification } from "../../helpers/base";

class SocketManager extends AbstractSocket<SocketIOClient.Socket, CloudMessage> {
    public get SocketId() {
        return this.Socket.id;
    }

    // public OwnerPage: IPage;
    private readonly _clients = new Map<string, ClientInfo>();
    private readonly _edmClients: EDMClient[] = [];

    public get Clients() {
        return this._clients.values();
    }

    public get EDMClients() {
        return this._edmClients;
    }

    public constructor() {
        super(io());
    }

    public Initialize() {
        this.Socket.on(SocketEventName.BROWSER_MESSAGE, (msg: Buffer) => this.MessageReceived(msg));

        this.Socket.on("connect", () => {
            console.log(`[${Now()}] socket connect id: ${this.Socket.id}`);
            this.OnConnected();
        });

        this.Socket.on("reconnect", () => {
            console.log(`[${Now()}] socket reconnect id: ${this.Socket.id}`);
            this.OnConnected();
        });

        this.Socket.on("reconnect_failed", () => {
            console.warn(`[${Now()}] socket reconnect_failed`);
            this.OnConnected();
        });

        this.Socket.on("error", (error: unknown) => {
            console.error(error);
        });

        this.Socket.on("reconnect_error", (error: unknown) => {
            console.error(error);
        });
    }

    protected MessageReceived(data: Buffer) {
        if (typeof data === "string") {
            console.warn(data);
            return;
        }

        const msg = CloudMessage.deserializeBinary(data);
        const msgType = msg.getType();

        console.log(`[${Now()}] server message received: ${GetNameByValue(msgType)}`);

        if (this.PreHandleCloudMessage(msg)) {
            this.OnDataReceived(msg);
        }
    }

    protected PreHandleCloudMessage(msg: CloudMessage): boolean {
        switch (msg.getType()) {
            case CloudMessageType.NOTIFY_CLIENT_DISCONNECTED:
                {
                    const clientInfo = ClientInfo.deserializeBinary(msg.getData_asU8());
                    // console.log(clientInfo);
                    // HomePageController.RemoveClient(clientInfo);
                    this._clients.delete(clientInfo.getMachineCode());
                    this.OnClientChanged();
                }
                break;
            case CloudMessageType.NOTIFY_CLIENT_CONNECTED:
                {
                    const clientInfo = ClientInfo.deserializeBinary(msg.getData_asU8());
                    // console.log(clientInfo);
                    // HomePageController.AddClient(clientInfo);
                    const mc = clientInfo.getMachineCode();
                    if (mc) {
                        this._clients.set(mc, clientInfo);
                        this.OnClientChanged();
                    }
                }
                break;

            case CloudMessageType.GET_CLIENT_INFO_OK:
                {
                    const clientInfo = ClientInfo.deserializeBinary(msg.getData_asU8());
                    // console.log(clientInfo);
                    // HomePageController.AddClient(clientInfo);
                    const mc = clientInfo.getMachineCode();
                    if (mc) {
                        const clients = clientInfo.getAppsList();

                        if (clients.length > 0) {
                            if (clients[0].getAppId() > 0) {
                                this._clients.set(mc, clientInfo);
                                this.OnClientChanged();
                            }
                        } else {
                            console.warn("No EDM Clients");
                        }
                    } else {
                        console.warn(`Invalid Machine Code: ${mc}`);
                    }
                }
                break;

            case CloudMessageType.EDM_NOTIFICATION:
                {
                    const notification = PbNotification.deserializeBinary(msg.getData_asU8());
                    console.info(notification.toObject());

                    ShowBrowserNotification(notification);
                }
                break;

            default:
                break;
        }

        return true;
    }

    public Send(msg: CloudMessage/*, forwarded = false */) {
        console.log(`[${Now()}] send message: ${GetNameByValue(msg.getType())}`);

        const buffer = msg.serializeBinary();
        const arrayBuffer = Array.from(buffer);

        this.Socket.emit(SocketEventName.BROWSER_MESSAGE, arrayBuffer);
    }

    public GetClientInfo() {
        this.Send(CreateCloudMessage(
            CloudMessageType.GET_CLIENT_INFO,
            undefined,
            undefined,
            undefined,
            undefined));
    }

    public SendCloudMessage(client: EDMClient, type: CloudMessageTypeMap[keyof CloudMessageTypeMap], data?: Uint8Array) {
        const msg = CreateCloudMessage(
            type,
            client.MachineCode,
            client.AppId,
            undefined,
            data);

        if (client.Email) {
            msg.setUserEmail(client.Email);
        }

        this.Send(msg);
    }

    public RequestEDMUpload(client: EDMClient, options?: UploadOptions) {
        console.log("RequestEDMUpload", options?.toObject());
        this.SendCloudMessage(client, CloudMessageType.REQUEST_EDM_UPLOAD, options?.serializeBinary());
    }

    protected OnClientChanged() {
        const idList: string[] = [];
        for (const client of this.Clients) {
            for (const edm of client.getAppsList()) {
                let item = this._edmClients.find(
                    (element) =>
                        element.MachineCode === client.getMachineCode() &&
                        element.AppId === edm.getAppId());

                if (!item) {
                    item = new EDMClient(client.getMachineCode(), edm.getAppId());
                    this._edmClients.push(item);
                }

                item.MachineUserName = client.getMachineUserName();
                item.MachineName = client.getMachineName();
                item.IP = client.getIp();
                item.SoftwareMode = edm.getSoftwareMode();
                item.Email = edm.getUserEmail();
                item.Version = edm.getVersion();

                item.TestId = edm.getTestId();
                item.IsRunning = edm.getIsRunning();

                idList.push(item.ID);
            }
        }

        // remove
        const removed = this._edmClients.filter((element) => !idList.includes(element.ID));
        for (const item of removed) {
            this._edmClients.splice(this._edmClients.indexOf(item), 1);
        }

        console.log("EDM Clients:", this._edmClients);

        store.state.clients = this.EDMClients;
    }
}

export default new SocketManager();
