
import store from "@/store";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "Reset",
})
export default class Reset extends Vue {
    form = {
        password: "",
        confirmPassword: "",
    };

    rules = {
        password: [
            { required: true, message: "Please input password", trigger: "blur" },
        ],
        confirmPassword: [
            { required: true, message: "Please input the password again", trigger: "blur" },
            {
                validator: (rule: never, value: string, callback: (err?: Error) => void): void => {
                    if (value === "") {
                        callback(new Error("Please input the password again"));
                    } else if (value !== this.form.password) {
                        callback(new Error("Two inputs don't match"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
    };

    get token() {
        return this.$router.currentRoute.params.token;
    }

    async mounted() {
        const r = await axios.get(`/api/v1/users/reset/${this.token}`);
        if (r.status !== HttpStatusCode.OK) {
            this.$message.error(r.data.msg);
            this.$router.push({ name: "Forgot" });
        }
    }

    submitForm(formName: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const f = this.$refs[formName] as any;
        f.validate(async (valid: boolean) => {
            if (valid) {
                const loading = this.$loading({
                    lock: true,
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.3)",
                });

                try {
                    const r = await axios.post(`/api/v1/users/reset/${this.token}`, this.form);

                    if (r.status === HttpStatusCode.OK) {
                        await store.dispatch("updateToken", r.data.data);

                        if (r.data.msg) {
                            this.$message.success(r.data.msg);
                        }
                    } else {
                        if (r.data.msg) {
                            this.$message.error(r.data.msg);
                        } else {
                            console.warn(r);
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                } finally {
                    loading.close();
                }
            } else {
                return false;
            }
        });
    }
}
