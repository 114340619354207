/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-server] /src/socket/common/constant.ts
 * @create:      2022-05-11 17:46:55.319
 * @modify:      2022-12-13 13:21:41.630
 * @version:     1.0.1
 * @times:       6
 * @lines:       34
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import { CloudMessageType } from "../protoc/generated/cloud_enums_pb";

export const CloudMessageTypeKeys = Object.keys(CloudMessageType);

// #region HTTP Headers
export const HEADER_API_ACCESS_TOKEN = "api-access-token";
export const HEADER_SOFTWARE_LANGUAGE = "software-language";
export const HEADER_SOFTWARE_MODE = "software-mode";
export const HEADER_SOFTWARE_OEM = "software-oem";
export const HEADER_SOFTWARE_OS = "software-os";
export const HEADER_SOFTWARE_OS_VERSION = "software-os-version";
export const HEADER_SOFTWARE_VERSION = "software-version";

export const BROWSER_PLATFORM_NAME = "browser-platform-name";
export const BROWSER_PLATFORM_VERSION = "browser-platform-version";
export const BROWSER_PLATFORM_OS = "browser-platform-os";
export const BROWSER_PLATFORM_DESCRIPTION = "browser-platform-description";
// #endregion

export const COOKIE_NAME_DEVICE_ID = "edm_cloud_device_id";
