<template>
    <div>
        <live-status-card />

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <h2>{{ $t("run_logs") }}</h2>
            </div>

            <el-table
                v-loading="loading"
                highlight-current-row
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="AbsoluteTime"
                    :label="$t('run_log_columns.absolute_time')"
                ></el-table-column>
                <el-table-column
                    prop="ScheduleTime"
                    :label="$t('run_log_columns.test_time')"
                ></el-table-column>
                <el-table-column
                    prop="EventType"
                    :label="$t('run_log_columns.event_type')"
                ></el-table-column>
                <el-table-column
                    prop="FrequencyOrPulse"
                    :label="$t('run_log_columns.elapsed')"
                    v-if="IsElapsedVisible"
                ></el-table-column>
                <el-table-column
                    prop="CtrlDemandValue"
                    :label="$t('run_log_columns.control_target_rms')"
                    v-if="IsCtrlDemandValueVisible && IsRMS"
                ></el-table-column>
                <el-table-column
                    prop="CtrlDemandValue"
                    :label="$t('run_log_columns.control_target_peak')"
                    v-else-if="IsTHCVisible"
                ></el-table-column>
                <el-table-column
                    prop="FrequencyOrPulse"
                    :label="$t('run_log_columns.frequency')"
                    v-if="IsFrequencyVisible"
                ></el-table-column>
                <el-table-column :label="$t('run_log_columns.event_data')">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <div
                                style="max-width: 400px; word-wrap: break-word"
                            >
                                {{ scope.row.EventData }}
                            </div>
                            <div slot="reference" class="name-wrapper">
                                {{ getRowData(scope.row.EventData) }}
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="EventSource"
                    :label="$t('run_log_columns.event_source')"
                ></el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<i18n>
{
    "en-US": {
        "control_panel": "Control Panel"
    },
    "zh-CHS": {
        "control_panel": "控制面板"
    }
}
</i18n>

<style>
.widget-content {
    border: none;
}

.widget-box {
    border: none;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}
</style>

<script>
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import {
    IsRunLogElapsedVisible,
    AddToRefreshList,
    FormatTimeDuration,
    FormatValue,
    GetTestStatus,
} from "@/helpers";

import {
    CloudDataType,
    MeasurementConfigType,
    CloudTestStatus,
    SoftwareMode,
} from "socket/common";

// import ControlPanel from "@/pages/Data Service/Data Table/ControlPanel.vue";
import LiveStatusCard from "@/components/cards/LiveStatusCard.vue";
import store from "@/store";

@Component({
    name: "RunLogs",

    components: {
        // ControlPanel
        LiveStatusCard,
    },

    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
})
export default class RunLogs extends Vue {
    tableData = [];
    controlpanelData = {};
    softwareMode = 0;
    MeasureType = 0;
    status = 0;
    loading = false;
    page = CloudDataType.RunLogs;

    created() {
        // this.updateBreadcrumb();
        // this.fetchData();

    }

    mounted() {
        AddToRefreshList(this);
    }

    activated() {
        this.updateBreadcrumb();
        this.fetchData();
    }

    async fetchData() {
        try {
            const RunLogGUID = this.$route.params.RunLogGUID || this.$route.query.RunLogGUID;
            if (RunLogGUID) {
                this.loading = true;
                const res = await axios.get(`/api/v1/runlog?SharedTo&GUID=${RunLogGUID}`);

                console.log(res.data);
                this.tableData = res.data.data[0].RunLogItems;
                this.controlpanelData = res.data.data[0].ControlPanel;
                this.softwareMode = res.data.data[0].SoftwareMode;
                this.MeasureType = res.data.data[0].MeasureType;
                this.status = res.data.data[0].Status;
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    updateBreadcrumb() {
        store.commit("updateBreadcrumb",
            [
                "sidebar.view_tests",
                "run_histories",
                "run_logs",
            ]);
    }

    getRowData(data) {
        if (data) {
            return data.length > 20 ? data.substr(0, 20) + "..." : data;
        }

        return "";
    }

    get IsFrequencyVisible() {
        switch (this.MeasureType) {
            case MeasurementConfigType.VCS_Sine:
            case MeasurementConfigType.THV_Sine:
            case MeasurementConfigType.VCS_SineReduction:
            case MeasurementConfigType.MIMO_Sine:
            case MeasurementConfigType.VCS_RSTD:
            case MeasurementConfigType.THV_RSTD:
                return true;
        }
    }

    get IsTHCVisible() {
        switch (this.MeasureType) {
            case MeasurementConfigType.THV_THC:
                return false;
            default:
                return true;
        }
    }
    // eslint-disable-next-line lines-between-class-members
    get IsRMS() {
        switch (this.MeasureType) {
            case MeasurementConfigType.VCS_Random:
            case MeasurementConfigType.THV_Random:
            case MeasurementConfigType.VCS_RORSOR:
            case MeasurementConfigType.THV_RORSOR:
            case MeasurementConfigType.MIMO_Random:
            case MeasurementConfigType.MESA_Random:
            case MeasurementConfigType.MDOF_Random:
                return true;
            case MeasurementConfigType.VCS_Shock:
            case MeasurementConfigType.THV_Shock:
            case MeasurementConfigType.VCS_SRS:
            case MeasurementConfigType.THV_SRS:
            case MeasurementConfigType.VCS_Earthquake:
            case MeasurementConfigType.THV_Earthquake:
            case MeasurementConfigType.VCS_TTH:
            case MeasurementConfigType.THV_TTH:
                return false;
            case MeasurementConfigType.VCS_EDR:
            case MeasurementConfigType.VCS_ShockOnRandom:
            case MeasurementConfigType.THV_ShockOnRandom:
            case MeasurementConfigType.MIMO_Shock:
            case MeasurementConfigType.MIMO_TTH:
            case MeasurementConfigType.MIMO_SRS:
                return true;
            case MeasurementConfigType.VCS_Sine:
            case MeasurementConfigType.THV_Sine:
            case MeasurementConfigType.VCS_RSTD:
            case MeasurementConfigType.VCS_BFT:
            case MeasurementConfigType.THV_RSTD:
            case MeasurementConfigType.VCS_SineOscillator:
            case MeasurementConfigType.THV_SineOscillator:
            case MeasurementConfigType.VCS_MultiSine:
            case MeasurementConfigType.THV_MultiSine:
            case MeasurementConfigType.MDA_MIMO_SSSine:
            case MeasurementConfigType.MIMO_Sine:
            case MeasurementConfigType.MESA_Sine:
            case MeasurementConfigType.MDOF_Sine:
                return false;
            case MeasurementConfigType.VCS_TDR:
            case MeasurementConfigType.THV_TDR:
            case MeasurementConfigType.MIMO_TWR:
            case MeasurementConfigType.MDOF_TWR:
                return true;
        }
    }

    // common
    get TimeElapsed() {
        return FormatTimeDuration(this.controlpanelData.TimeElapsed);
    }

    // VCS
    get Level() {
        return FormatValue(this.controlpanelData.Level * 100, 4, 2) + "%";
    }

    get DrivePk() {
        return FormatValue(this.controlpanelData.DrivePk);
    }

    get ControlRMSpk() {
        return FormatValue(this.controlpanelData.ControlRMSpk);
    }

    get TargetRMSPK() {
        return FormatValue(this.controlpanelData.TargetRMSPK);
    }

    get TimeRemaining() {
        return FormatTimeDuration(this.controlpanelData.TimeRemaining);
    }

    get TimeElapsedAtFullLevel() {
        return FormatTimeDuration(this.controlpanelData.TimeElapsedAtFullLevel);
    }

    // DSA
    get RecordingStatus() {
        return this.controlpanelData.RecordingStatus;
    }

    get SoftwareMode() {
        if (this.softwareMode !== 0) {
            return this.softwareMode;
        }

        if (this.controlpanelData) {
            return this.controlpanelData.SoftwareMode;
        }

        return 0;
    }

    get IsElapsedVisible() {
        return IsRunLogElapsedVisible(this.MeasureType);
    }

    get IsCtrlDemandValueVisible() {
        return this.SoftwareMode === SoftwareMode.SPIDER_VCS || this.SoftwareMode === SoftwareMode.SPIDER_THV;
    }

    get IsDSA() {
        return this.SoftwareMode === SoftwareMode.SPIDER_DSA;
    }

    get IsRunning() {
        return this.status === CloudTestStatus.Running;
    }

    get Status() {
        return this.$t(GetTestStatus(this.status));
    }

    get StatusClass() {
        return `test-status-${this.status}`;
    }
}
</script>
