
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { IPcInfo, GeneralSearchForArray, DeleteOS, CompareVersion } from "@/helpers";
import SystemCard from "@/components/cards/SystemCard.vue";

interface IRow extends IPcInfo {
    children?: IPcInfo[];
}

@Component({
    name: "PCInformationCard",
    components: {
        SystemCard,
    },
})
export default class PCInformationCard extends Vue {
    pcList: IPcInfo[] = [];

    loading = true;
    search = "";

    dialogSystemVisible = false;
    currentPC?: IPcInfo;

    get tableData() {
        return this.pcList.map(p => {
            return { Type: p.Is64BitOperatingSystem ? "x64" : "x86", ...p };
        });
    }

    get TableData() {
        return GeneralSearchForArray(this.search, this.tableData);
    }

    get Systems() {
        return this.currentPC?.Systems ?? [];
    }

    get CurrentPcName() {
        return this.currentPC?.MachineName ?? "";
    }

    data() {
        return {
            currentPC: undefined,
        };
    }

    created() {
        this.fetchData();
    }

    async fetchData() {
        this.loading = true;

        try {
            const res = await axios.get("/api/v1/oss");
            if (res.status === HttpStatusCode.OK) {
                const groupedData = new Map<string, IPcInfo[]>();
                const items = res.data.data as IPcInfo[];
                for (const item of items) {
                    const array = groupedData.get(item.MachineName);
                    if (array) {
                        array.push(item);
                    } else {
                        groupedData.set(item.MachineName, [item]);
                    }
                }

                const rows: IRow[] = [];
                for (const array of groupedData.values()) {
                    array.sort((a, b) => CompareVersion(a.Version, b.Version));

                    rows.push({
                        ...array[0],
                        children: array.slice(1),
                    });
                }

                this.pcList = rows;
            } else {
                console.error(res);
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }

    displaySystems(pc: IPcInfo) {
        this.currentPC = pc;
        this.dialogSystemVisible = true;
    }

    async handleDelete(pc: IPcInfo) {
        // console.log(pc);

        try {
            this.loading = true;
            const d = await this.$confirm(this.$t("DeleteWarning").toString().replace("{0}", pc.MachineName), this.$t("Warning").toString(), {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("ok").toString(),
                cancelButtonText: this.$t("Cancel").toString(),
                type: "warning",
            });

            if (d === "confirm") {
                if (await DeleteOS(pc.MachineCode) !== false) {
                    await this.fetchData();
                }
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loading = false;
        }
    }
}
