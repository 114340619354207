<template>
    <div class="main-content">
        <el-card class="box-card">
            <UploadsTable />
        </el-card>
    </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import UploadsTable from "@/components/tables/UploadsTable.vue";
import store from "@/store";

@Component({
    components: {
        UploadsTable,
    },
})
export default class Uploads extends Vue {
    created() {
        store.commit("updateBreadcrumb", ["sidebar.uploaded_files"]);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
