
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { HttpStatusCode, HumanFileSize } from "node-share";
import { AddToRefreshList, IShareOption, ISharedFile } from "@/helpers";

import EditShareDialog from "@/components/dialogs/EditShareDialog.vue";
import store from "@/store";

type IRow = ISharedFile;

@Component({
    name: "UploadsTable",
    components: {
        EditShareDialog,
    },
})
export default class UploadsTable extends Vue {
    loading = false;

    files: ISharedFile[] = [];
    search = "";

    editSharingFormVisible = false;
    editSharingFile?: ISharedFile;

    selectedFiles: ISharedFile[] = [];

    get SharedOption() {
        const option: IShareOption = {
            fileId: this.editSharingFile?._id,
        };

        return option;
    }

    get SharedTitle() {
        return this.editSharingFile?.originalName;
    }

    get ShowBottomButton() {
        if (this.selectedFiles.length < 1) {
            return false;
        }

        return true;
    }

    // ------------------------------------

    data() {
        return {
            editSharingFile: undefined,
        };
    }

    created() {
        this.fetchData();
    }

    mounted() {
        AddToRefreshList(this, this.fetchData);
    }

    // ------------------------------------

    getFileSize(bytes: number) {
        return HumanFileSize(bytes);
    }

    getOwnerName(row: ISharedFile) {
        if (this.isOwner(row)) {
            return store.getters.UserName;
        }

        if (row.sharedInfo?.owner) {
            return row.sharedInfo.owner.name || row.sharedInfo.owner.email;
        }

        return "";
    }

    isOwner(row: ISharedFile) {
        return row.owner === store.getters.UserId;
    }

    editSharing(row: ISharedFile) {
        this.editSharingFile = row;
        this.editSharingFormVisible = true;
    }

    onEditShareFinished(changed: boolean) {
        this.editSharingFormVisible = false;

        if (changed) {
            this.fetchData();
        }
    }

    getSharedLength(row: ISharedFile) {
        return row.sharedInfo?.members?.length ?? 0;
    }

    hasShared(row: ISharedFile) {
        return this.getSharedLength(row) > 0;
    }

    getPopTitle(row: ISharedFile) {
        if (this.hasShared(row)) {
            return `${this.$t("ShareDate")} ${this.FormatDateTime(row.sharedInfo.resource.updatedAt)}`;
        }

        return "";
    }

    getSharedMembers(row: ISharedFile) {
        if (this.hasShared(row)) {
            return row.sharedInfo.members;
        }

        return [];
    }

    getShareCellText(row: ISharedFile) {
        // console.log(row);

        const len = this.getSharedLength(row);
        if (len > 0) {
            return (this.$t("SharedWithNMembers") as string).replace("{0}", len.toString());
        }

        return this.$t("Private");
    }

    getDownloadLink(row: ISharedFile) {
        const id = row._id;
        return `/api/v1/uploads/file/${id}`;
    }

    async fetchData() {
        try {
            this.loading = true;

            const res = await axios.get("/api/v1/uploads?share");
            this.files = res.data.data;

            // console.log(this.files);
        } catch (err) {
            console.error(err);
        }

        this.loading = false;
    }

    download(row: ISharedFile) {
        window.open(this.getDownloadLink(row), "_blank");
    }

    async deleteFiles(...files: ISharedFile[]) {
        try {
            if (files.length === 0) {
                files = this.selectedFiles;
            }

            if (files.length === 0) {
                return;
            }

            const d = await this.$confirm(this.$t("DeleteFileWarning").toString(), this.$t("Warning").toString(),
                {
                    confirmButtonText: this.$t("ok").toString(),
                    cancelButtonText: this.$t("Cancel").toString(),
                    type: "warning",
                },
            );

            if (d !== "confirm") {
                return;
            }

            this.loading = true;

            const successIds: string[] = [];
            for (const row of files) {
                const res = await axios.delete(`/api/v1/uploads/${row._id}`);
                if (res.status === HttpStatusCode.OK) {
                    successIds.push(row._id);
                }
            }

            if (successIds.length > 0) {
                this.files = this.files.filter(p => !successIds.includes(p._id));
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    headerRowClassName() {
        if (this.selectedFiles.length === this.files.length) {
            return "cloud-table-header";
        }

        return "hoverable-table-header";
    }

    rowClassName(r: { row: IRow, rowIndex: number }) {
        if (this.selectedFiles.includes(r.row)) {
            return "";
        }

        return "hoverable-table-row";
    }

    selectionChange(selection: IRow[]) {
        console.info(selection);
        this.selectedFiles = selection;
    }

    rowClick(row: IRow) {
        this.download(row);
    }
}
