/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/api.ts
 * @create:      2021-05-18 17:25:51.534
 * @modify:      2023-02-28 16:29:39.744
 * @version:     0.1.1
 * @times:       24
 * @lines:       168
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import axios from "axios";
import { HttpStatusCode, UpdateQueryString } from "node-share";
import { IEmailOrGroup } from ".";
import { IMember, ShareType } from "./base";

const apiUserSetting = "/api/v2/userSetting";

export async function PostUserSetting(name: string, value: unknown) {
    const r = await axios.post(apiUserSetting, {
        Name: name,
        Value: value,
    });

    return r.status === HttpStatusCode.OK;
}

export async function GetUserSetting<T>(name: string): Promise<T | undefined> {
    let api = apiUserSetting;

    api = UpdateQueryString("Name", name, api);

    const r = await axios.get(api);

    if (r.status === HttpStatusCode.OK) {
        return r.data.data;
    }
}

export async function DeleteSoftware(_id: string) {
    const api = `/api/v1/softwares/${_id}`;

    const r = await axios.delete(api);

    if (r.status === HttpStatusCode.OK) {
        return r.data.data;
    }

    return false;
}

export async function DeleteOS(MachineCode: string) {
    const api = "/api/v1/oss/remove";

    const r = await axios.delete(api, {
        data: {
            MachineCode,
        },
    });

    if (r.status === HttpStatusCode.OK) {
        return r.data.data;
    }

    return false;
}

export async function DeleteSystem(GUID: string) {
    const api = "/api/v1/systems/remove";

    const r = await axios.delete(api, {
        data: {
            GUID,
        },
    });

    if (r.status === HttpStatusCode.OK) {
        return r.data.data;
    }

    return false;
}

export async function DeleteModule(SN: string) {
    const api = "/api/v1/modules/remove";

    const r = await axios.delete(api, {
        data: {
            SN,
        },
    });

    if (r.status === HttpStatusCode.OK) {
        return r.data.data;
    }

    return false;
}

export async function GetMembers() {
    const api = "/api/v1/teams/members";

    const r = await axios.get(api);

    if (r.status === HttpStatusCode.OK) {
        return r.data.data as IMember[];
    }

    return [];
}

export async function PostShare(type: ShareType, resource: string, members: string[]) {
    const api = "/api/v1/shares";

    const r = await axios.post(api, {
        type,
        resource,
        members,
    });

    if (r.status === HttpStatusCode.OK) {
        return r.data.data as IMember[];
    }

    return [];
}

export async function InviteTeamMember(teamId: unknown, email: string): Promise<true | string> {
    const r = await axios.post("/api/v1/teams/invite", {
        team: teamId,
        email: email.trim(),
    });

    const resData = r.data;

    if (r.status === HttpStatusCode.OK) {
        return true;
    } else {
        return resData.msg;
    }
}

export async function SearchEmailOrGroup(key: string) {
    let api = "/api/v2/search/emailOrGroupName";

    api = UpdateQueryString("key", key, api);

    const r = await axios.get(api);

    if (r.status === HttpStatusCode.OK) {
        return r.data.data as IEmailOrGroup[];
    }

    return [];
}

export async function GetApiVersion() {
    const api = "/api/version";

    const r = await axios.get(api);

    if (r.status === HttpStatusCode.OK) {
        return r.data.data;
    }
}
