<template>
    <DataViewer></DataViewer>
</template>

<i18n>
{
  "en-US": {

  },
  "zh-CHS": {

  }
}
</i18n>

<script>
import { Vue, Component } from "vue-property-decorator";
import DataViewer from "@/components/chart/DataViewer.vue";
import store from "@/store";

@Component({
    components: {
        DataViewer,
    },
})
export default class V2Data extends Vue {
    created() {
        store.commit("updateBreadcrumb", ["sidebar.DataViewer"]);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
