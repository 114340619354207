/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/router/index.ts
 * @create:      2019-01-09 10:45:54.871
 * @modify:      2022-12-07 15:36:14.168
 * @version:     0.1.1
 * @times:       111
 * @lines:       284
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import Vue from "vue";
import Router from "vue-router";

import BaseLayout from "@/pages/Index.vue";
import Home from "@/pages/Home.vue";

import Account from "@/pages/Account/Index.vue";
import Login from "@/pages/Account/Login.vue";
import SignUp from "@/pages/Account/SignUp.vue";
import Forgot from "@/pages/Account/Forgot.vue";
import Reset from "@/pages/Account/Reset.vue";
import Verification from "@/pages/Account/Verification.vue";
import ChangePassword from "@/pages/Account/ChangePassword.vue";

import Dashboard from "@/pages/Data Service/Dashboard/Index.vue";
import ViewTests from "@/pages/Data Service/Tests/Index.vue";
import UploadedFiles from "@/pages/Data Service/Uploads/Index.vue";
import SystemInformation from "@/pages/Data Service/SystemInformation/Index.vue";

import DataTable from "@/pages/Data Service/Data Table/Index.vue";
import Settings from "@/pages/Settings/Index.vue";
import MFASetup from "@/pages/MFA/Setup.vue";

import RunHistories from "@/pages/Data Service/RunHistories/Index.vue";

import RunLogs from "@/pages/Data Service/Data Table/RunLogs.vue";
import System from "@/pages/Data Service/Data Table/Devices.vue";
// import License from '@/pages/Data Service/Data Table/License'

import store from "../store";

// page not found
// import PageNotFound from '@/pages/Error/PageNotFound '

// EDC
import EDC from "@/pages/Data Service/EDC/Index.vue";

// RTIM
import RTIM from "@/pages/Data Service/RTIM/Index.vue";

// DataViewer
import DataViewer from "@/pages/Data Service/DataViewer/Index.vue";

// Team
import Team from "@/pages/Team/Index.vue";
import TeamMembers from "@/pages/Team/Members.vue";
import TeamGroups from "@/pages/Team/Groups.vue";
import TeamGroup from "@/pages/Team/Group.vue";
import TeamSettings from "@/pages/Team/Settings.vue";

// App
import App from "@/pages/App/Index.vue";
import AppProducts from "@/pages/App/Products.vue";
import AppShakers from "@/pages/App/Shakers.vue";

import { RouteName } from "@/helpers";

Vue.use(Router); // 路由管理

export default new Router({
    mode: "history",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        // https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
        // 模拟“滚动到锚点”的行为

        if (to.hash) {
            if (document.querySelector(to.hash)) {
                return {
                    selector: to.hash,
                };
            }

            // if the returned position is falsy or an empty object,
            // will retain current scroll position.
            return undefined;
        }
    },
    routes: [
        {
            path: "/",
            component: BaseLayout,
            children:
                [
                    {
                        path: "/",
                        name: RouteName.Home,
                        component: Home,
                    },
                    {
                        path: "forgot",
                        redirect: {
                            name: RouteName.Forgot,
                        },
                    },
                    {
                        path: "/account",
                        name: RouteName.Account,
                        component: Account,
                        children: [
                            {
                                path: "login",
                                name: RouteName.Login,
                                component: Login,
                            },
                            {
                                path: "demo",
                                name: RouteName.Demo,
                                component: Login,
                            },
                            {
                                path: "signup",
                                name: RouteName.SignUp,
                                component: SignUp,
                            },
                            {
                                path: "forgot",
                                name: RouteName.Forgot,
                                component: Forgot,
                            },
                            {
                                path: "reset/:token",
                                name: RouteName.Reset,
                                component: Reset,
                            },
                            {
                                path: "verification/:payload",
                                name: RouteName.Verification,
                                component: Verification,
                            },
                            {
                                path: "changePassword",
                                name: RouteName.ChangePassword,
                                component: ChangePassword,
                            },
                        ],
                    },
                    {
                        path: "/dashboard",
                        name: RouteName.Dashboard,
                        component: Dashboard,
                    },
                    {
                        path: "/tests",
                        name: RouteName.Tests,
                        component: ViewTests,
                    },
                    {
                        path: "/runHistories",
                        name: RouteName.RunHistories,
                        component: RunHistories,
                    },
                    {
                        path: "/uploads",
                        name: RouteName.Uploads,
                        component: UploadedFiles,
                    },
                    {
                        path: "/systemInformation",
                        name: RouteName.SystemInformation,
                        component: SystemInformation,
                    },
                    {
                        path: "/settings",
                        name: RouteName.Settings,
                        component: Settings,
                    },
                    {
                        path: "/settings/mfa/setup",
                        name: RouteName.MFASetup,
                        component: MFASetup,
                    },
                    {
                        path: "/team",
                        name: RouteName.Team,
                        component: Team,
                        children: [
                            {
                                path: "members",
                                name: RouteName.TeamMembers,
                                component: TeamMembers,
                            },
                            {
                                path: "groups",
                                name: RouteName.TeamGroups,
                                component: TeamGroups,
                            },
                            {
                                path: "groups/:id",
                                name: RouteName.TeamGroup,
                                component: TeamGroup,
                            },
                            {
                                path: "settings",
                                name: RouteName.TeamSettings,
                                component: TeamSettings,
                            },
                        ],
                    },
                ],
        },
        {
            path: "/app",
            name: RouteName.App,
            component: App,
            children: [
                {
                    path: "Products",
                    name: RouteName.Products,
                    component: AppProducts,
                },
                {
                    path: "Shakers",
                    name: RouteName.Shakers,
                    component: AppShakers,
                },
            ],
        },
        {
            path: "/dataTable",
            name: RouteName.DataTable,
            component: DataTable,
            children: [{
                path: "RunLogs",
                name: RouteName.RunLogs,
                component: RunLogs,
            }, {
                path: "System",
                name: RouteName.System,
                component: System,
            },
                /*, {
                  path: 'License',
                  name: 'License',
                  component: License
                } */
            ],
        },

        {
            path: "/edc",
            name: RouteName.EDC,
            component: EDC,
        },
        {
            path: "/dataViewer",
            name: RouteName.DataViewer,
            component: DataViewer,
        },
        {
            path: "/rtim",
            name: RouteName.RTIM,
            component: RTIM,
        },
        {
            path: "*",
            beforeEnter: (to, from, next) => {
                if (store.getters.IsLogged) {
                    next({
                        name: RouteName.Dashboard,
                    });
                } else {
                    next({
                        name: RouteName.Home,
                    });
                }
            },
        },
    ],
});
