
import { IShareOption, PostShare, ShareType, IEmailOrGroup, SearchEmailOrGroup } from "@/helpers";
import { Distinct } from "node-share";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "EditShareDialog",
})
export default class EditShareDialog extends Vue {
    @Prop()
    visible!: boolean;

    @Prop()
    title!: string;

    @Prop()
    option!: IShareOption;

    loading = false;

    emailOrGroupArray: IEmailOrGroup[] = [];
    input = "";

    get InputPlaceholder() {
        return this.emailOrGroupArray.length > 0 ? "Add another" : "To: Name, group or email";
    }

    handleClose(/* done: () => void */) {
        // console.log("close");
        // done();
        this.close();
    }

    close(changed = false) {
        this.$emit("dialog-closed", changed);

        this.emailOrGroupArray = [];
        this.input = "";
    }

    handleCloseTag(index: number) {
        this.emailOrGroupArray.splice(index, 1);
    }

    getSharedEmails(): string[] {
        const result = this.emailOrGroupArray.flatMap(p => p.emails);

        return Distinct(result);
    }

    async submitEditSharing() {
        this.loading = true;

        let changed = false;

        try {
            const emails = this.getSharedEmails();

            console.log(`Shared to: ${emails.join(", ")}`);

            if (this.option.fileId) {
                // share files

                if (await PostShare(ShareType.File, this.option.fileId, emails)) {
                    changed = true;
                }
            } else if (this.option.testId) {
                // share tests

                if (await PostShare(ShareType.V2Test, this.option.testId, emails)) {
                    changed = true;
                }
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
        this.close(changed);
    }

    async querySearchAsync(queryString: string, cbResult: (result: IEmailOrGroup[]) => void) {
        // if (!queryString) {
        //     cbResult([]);
        //     return;
        // }

        if (!queryString || queryString.length >= 3) {
            const result = await SearchEmailOrGroup(queryString);
            // console.log(result);
            cbResult(result);
        } else {
            cbResult([]);
        }
    }

    handleSelect(item: IEmailOrGroup) {
        // console.log(item);
        this.emailOrGroupArray.push(item);

        this.input = "";
    }
}
