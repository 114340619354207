
import { Vue, Component } from "vue-property-decorator";
// import TestTable from "@/components/tables/TestTable.vue";
import TestTable from "@/components/tables/TestTableV2.vue";
import store from "@/store";

@Component({
    name: "Tests",
    components: {
        TestTable,
    },
})
export default class Tests extends Vue {
    created() {
        store.commit("updateBreadcrumb", [
            "sidebar.view_tests"]);
    }
}
