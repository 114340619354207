var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"account-body"},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("ForgotPassword")))]),_c('div',{staticStyle:{"padding":"2rem 0px 1rem"}},[_c('span',[_vm._v(_vm._s(_vm.$t( "EnterYourEmailAddressBelowAndWeWillSendYouPasswordResetInstructions" )))])]),_c('el-form',{ref:"form",attrs:{"label-position":"left","label-width":"0px","model":_vm.form}},[_c('el-form-item',{attrs:{"prop":"email","rules":[
                {
                    required: true,
                    message: 'Please input email address',
                    trigger: 'blur',
                },
                {
                    type: 'email',
                    message: 'Please input correct email address',
                    trigger: ['blur', 'change'],
                },
            ]}},[_c('el-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},[_c('template',{slot:"prepend"},[_c('i',{staticClass:"el-icon-message"})])],2)],1),_c('div',{staticClass:"el-form-item",staticStyle:{"display":"flex","margin-top":"1rem"}},[_c('div',[_c('el-link',{attrs:{"type":"primary"},on:{"click":_vm.signIn}},[_vm._v(_vm._s(_vm.$t("SignIn")))])],1),_c('div',{staticStyle:{"flex":"1"}}),_c('div',[_c('el-button',{staticClass:"ci-btn",attrs:{"type":"primary"},on:{"click":_vm.resetPassword}},[_vm._v(_vm._s(_vm.$t("ResetPassword"))+" ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }