/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/i18n/i18n.zh-CHS.ts
 * @create:      2019-01-09 10:45:54.872
 * @modify:      2023-04-11 09:39:03.812
 * @version:     0.1.1
 * @times:       157
 * @lines:       458
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

// import zhLocale from "element-ui/lib/locale/lang/zh-CN";

export default {
    // ...zhLocale,
    language: "语言",
    date_time_format: "日期/时间格式",
    example: "示例",
    operation: "操作",
    detail: "详情",
    delete: "删除",
    user_name: "用户名",
    email_id: "邮箱",
    register: "注册",
    login: "登陆",
    name: "名称",
    email: "邮箱",
    company: "公司",
    phone_number: "电话号码",
    password: "密码",
    confirm_password: "确认密码",
    run_histories: "运行历史",
    run_logs: "运行日志",
    account: {
        free: "免费账户",
        plus: "标准账户",
        corporate: "公司账户",
    },
    profile: "配置",
    myAccount: "我的账户",
    team: "团队",
    uploads: "上传",
    messages: "消息",
    logout: "注销",
    sidebar: {
        dashboard: "仪表盘",
        view_tests: "测试管理",
        uploaded_files: "上传文件",
        software_info: "软件信息",
        license_systems: "授权 & 系统",
        system_information: "系统信息",
        system_management: "系统管理",
        account_management: "账户管理",
        settings: "设置",
        team: {
            members: "成员",
            groups: "组",
            settings: "团队设置",
        },
    },
    view: "查看",
    sys_info: "系统信息",
    device_info: "设备信息",
    license_info: "授权信息",

    license: {
        hardware_device: "设备",
        serial_number: "序列号",
        license_type: "类型",
        vsn_info: "VSN信息",
        vsn_activated: "VSN是否已激活",
        vsn: "虚拟序列号",
        total_license_count: "总共授权次数",
        available_license_count: "可用授权次数",
        license_key_info: "授权文件信息",
        software_renewal_expiration: "软件更新截止日期",
        software_activation_expiration: "软件使用截止日期",
        software_renewal_expiration_note: "超过这个时间，软件不能再更新.",
        software_activation_expiration_note: "超过这个时间，软件不能再使用.",
        installed_edm_software_options: "EDM 软件授权功能列表：",
    },
    message: {
        email_invalid: "无效电子邮箱",
        password_diff: "输入密码不一致",
        password_length_limit: "密码长度限制为6-30个字符长",
        user_name_length_limit: "用户名长度限制为4-16个字符长",
        pwdChart_limit: "密码只能包含数字、字母和!@ # $ % & *()等特殊字符",
        user_name_characters_limit: "用户名只能包含数字、字母和!@ # $ % & *()等特殊字符",

    },
    yes: "是",
    no: "否",
    pc_info: "计算机信息",
    edm_info: "EDM信息",
    hardware_system: "系统信息",
    hardware_device: "设备信息",
    add: "添加",
    ok: "确定",
    cancel: "取消",
    gender: "性别",
    male: "男",
    female: "女",
    reset: "重置",
    get_started: "立即购买",
    month: "月",
    year: "年",
    Standard: "标准版",
    Premium: "高级版",
    Save: "保存",
    home: "首页",

    column_name: {
        pc_name: "电脑名称",
        database_name: "数据库名称",
        test_name: "测试名称",
        test_type: "测试类型",
        test_description: "测试描述",
        number_of_channels: "通道数",
        test_status: "测试状态",
        time_elapsed: "已用时间",
        last_updated: "上次更新时间",
        actions: "动作",
        run_count: "运行次数",
    },

    view_live_status: "查看实时状态",
    view_run_histories: "查看运行历史",
    ViewRunLogAndDIO: "详情",
    remove_from_dashboard: "从仪表盘中移除",
    remove_from_cloud: "删除选中项",
    current_status: "当前状态",

    edm_created: "创建EDM",
    edc_created: "创建EDC",
    current_edm: "当前EDM",
    current_edc: "当前EDC",
    pc: "电脑",
    database: "数据库",
    control_panel: "控制面板",

    run_name: "运行名称",
    start_time: "开始时间",
    end_time: "结束时间",
    run_description: "运行描述",
    alarm: "警报",
    end_condition: "结束条件",

    view_run_log: "查看运行日志",
    create_report: "创建报表",
    remove_this_run: "移除该运行",

    run_history: "运行历史",
    run_duration: "运行时长",

    run_log: "运行日志",

    system_information: "系统信息",
    spider_system_name: "Spider系统名称",
    spider_serial_numbers: "Spider序列号",

    run_log_columns: {
        absolute_time: "绝对时间",
        test_time: "测试时间",
        event_type: "事件类型",
        elapsed: "脉冲计数",
        frequency: "频率 (Hz)",
        control_target_rms: "控制/目标 RMS",
        control_target_peak: "控制/目标 峰值",
        event_data: "事件数据",
        event_source: "事件源",
    },

    hardware_information: "硬件信息",
    account_overview: "账户概览",

    number_of_live_running_tests: "正在运行的测试数量",
    total_number_of_tests_on_the_cloud: "测试总数",
    recent_tests: "最近的测试",
    no_tests: "当前没有测试",

    filter_results_using_search_terms: "使用搜索词筛选结果",
    search: "搜索",

    configured_spider_systems: "配置的Spider系统：",
    spider_hardware_information: "Spider硬件信息：",

    shipping_address: "送货地址",
    billing_address: "账单地址",

    same_as_shipping_address: "与送货地址相同",

    address: "地址",
    city: "城市",
    state: "州",
    zip: "邮编",
    country: "国家",

    update: "更新",
    permissions: "权限",
    remove_selected_viewers: "移除选中项",

    upgrade_storage: "升级存储",
    current: "当前： 免费 (20 MB 可用空间)",

    please_select_at_least_one_test: "请至少选择一个测试。",

    Running: "正在运行",
    Stopped: "已停止",
    Unknown: "未知",

    Updated: "更新于",
    LastUpdated: "最后更新于",

    TeamMemberList: "团队成员列表",
    TeamMemberListTip: "被邀请的成员可以在小组中列出。 测试结果和文件可以在不同的团队之间共享。",
    SearchMembers: "搜索成员",
    NameOrEmail: "名称或邮箱",
    InviteMembers: "邀请成员",
    InviteMembersText1: "输入团队成员的电子邮件地址，用逗号分隔。",
    InviteMembersText2: "邀请将发送到他们的电子邮件地址",
    ShowDeletedUsers: "显示已删除的成员",

    Name: "名称",
    Status: "状态",
    Members: "成员",
    Managers: "管理者",

    ResetPassword: "重置密码",
    RemoveAdminPermissions: "设为成员",
    DeleteMember: "删除成员",
    AddAdminPermissions: "设为管理员",
    ReSendInvite: "再次邀请",
    UndoInvite: "取消邀请",
    ReActivateMember: "激活成员",

    TeamGroups: "团队组",
    TeamGroupsTip: "测试结果和文件可以在不同的团队之间共享。",
    SearchGroups: "搜索组",
    GroupName: "组名",
    CreateGroup: "创建组",
    AddMembers: "添加成员",
    LeaveGroup: "离开组",
    DeleteGroup: "删除组",
    EditGroup: "编辑组",

    Warning: "警告",
    AreYouSureYouWantToLeaveThisGroup: "您确定要离开这个小组吗？",
    Yes: "是",
    No: "否",
    DeleteGroupWarning: "您确定要删除该组吗？某些测试可能不再对组成员可见",

    Edit: "编辑",
    SaveChanges: "保存修改",
    Cancel: "取消",

    TeamProfile: "团队设置",
    TeamName: "团队名称",
    Update: "更新",

    GroupCreationForm: "创建组",
    InviteGroupMembers: "邀请组员",
    EnterTheEmailAddressesOfYourTeamMembersSeparatedByCommas: "输入团队成员的电子邮件地址，用逗号分隔。",
    InvitesWillBeSentToTheirEmailAddresses: "邀请将发送到他们的电子邮件地址",
    Submit: "提交",

    CreateATeam: "创建一个团队",
    CreateTeamLabel: "创建一个团队，以便管理其他用户，并进行共享",
    CreateTeam: "创建团队",

    TeamCreationForm: "创建团队",
    InviteTeamMembers: "邀请团队成员",

    Run: "运行",

    Filters: "过滤器",
    TestType: "测试类型",
    CreatedBy: "创建者",
    SearchTerms: "搜索条件",

    AnyUser: "任意用户",
    Apply: "应用",

    UploadedFiles: "上传的文件",
    Owner: "所有者",
    LastModified: "修改日期",
    Modified: "修改日期",
    FileSize: "文件大小",
    Share: "共享",
    Sharing: "共享",
    Operations: "操作",
    Delete: "删除",

    TypeToSearch: "搜索",
    SelectTheGroupsToBeShared: "选择要共享的组：",

    CheckAll: "全选",

    Private: "私有",
    SharedWithNMembers: "与{0}个成员共享",
    ShareDate: "分享日期：",

    Temperature_PV: "温度当前值： ",
    Temperature_SV: "温度目标值： ",
    Humidity_PV: "湿度当前值： ",
    Humidity_SV: "湿度目标值： ",

    WelcomeTo: "欢迎来到",
    EDMCloud: "EDM云",

    PcNameDatabaseHardware: "PC名称/数据库/硬件",
    LiveStatus: "实时状态",

    SelectSignalTitle: "选择要显示的信号",
    UploadTitle: "下列文件是由EDM或EDC软件上传。它们可以共享给Team Group。要添加或删除共享成员，请更改Team Group设置。",
    SystemInfoTitle: "在将测试数据上传到云端时，提取如下系统信息。",
    SystemInfos: "系统信息",
    About: "关于我们",
    LicenseTerms: "软件许可条款和条件",
    Version: "当前版本",
    NoData: "暂无数据",
    TestProperties: "测试属性",
    Table: "表格",
    Parameters: "参数",
    Signals: "信号",
    Notification: "通知",
    Attributes: "属性",
    Confirm: "确定",
    AreYouCloseAll: "这将关闭所有信号图。继续吗?",

    LeaveTheTeam: "离开此团队",
    AreYouSureYouWantToLeaveThisTeam: "你确定要离开这个团队吗？",

    ShareTest: "共享测试",
    EnterTheEmailAddressesOrGroupNamesBelow: "在下面输入电子邮件地址或组名",
    Send: "完成",
    AutoRefresh: "自动刷新",

    DeleteWarning: "您确定要删除吗？名称:<b>{0}</b>",
    DeleteFileWarning: "您确定要删除吗？",
    Success: "成功",
    DeleteInvitationWarning: "您确定要删除此邀请吗?",
    DeleteInvitation: "删除邀请",
    CloseDialog: "你确定要关闭对话框吗?",
    CloseAllSignalCharts: "这将关闭所有信号图。继续吗?",
    RangeSelectionError: "选择的最小值不能大于最大值,请重新选择",

    CreateAccount: "创建帐户",
    SignIn: "登录",
    Demo: "演示",
    RemoteOnlineAccessForYourTestingSystem: "远程在线访问您的测试系统",
    EdmCloudAndEdmMobileShareTheSameLogInAccount: "EDM Cloud和EDM Mobile共享同一个登录帐户",
    DownloadEdmMobileFor: "下载适用于",
    iOS: "iOS",
    or: "或",
    Android: "Android",
    DownloadEdmMobileForEnd: "的EDM Mobile",

    RegisterAccount: "注册帐户",
    AlreadyHaveAnAccount: "已经有账户了吗？",
    DonTHaveAnAccount: "没有账户？",
    SignUp: "注册",
    RememberMe: "记住我",
    ForgotYourPassword: "忘记密码了吗？",
    ForgotPassword: "忘记密码",
    EnterYourEmailAddressBelowAndWeWillSendYouPasswordResetInstructions: "在下面输入您的电子邮件地址，我们将向您发送密码重置说明。",

    PcMayNotBeConnected: "PC可能未连接",
    PcMayNotBeConnectedWarning: "测试状态已超过30分钟未更新。请确保控制器软件正在运行并连接到EDM云。",
    LocationNotFound: "未找到位置",

    DeleteAccount: "删除账户",
    deleteYourAccount: "删除您的帐户",
    onceYouDeleteYourAccountThereIsNoGoingBackPleaseBeCertain: "您的账户一旦删除，就无法恢复，请确定。",
    yourEmail: "您的电子邮件",
    confirmYourPassword: "确认您的密码",
    deleteThisAccount: "删除此帐户",
    pleaseEnterTheEmailAddressOfTheCurrentAccount: "请输入当前帐户的电子邮件地址。",

    Description: "描述",
    CreatedAt: "创建时间",
    ModifiedAt: "修改时间",
    LastRunTime: "上次运行时间",
    SpiderSystem: "Spider系统",
    CreatedVersion: "创建版本",
    LastRunVersion: "上次运行版本",

    TwoFactorAuthentication: "双因素身份验证",
    ConfirmAccess: "确认访问",
    EnableTwoFactorAuthentication: "启用双因素身份验证 (2FA)",
    TwoFactorAuthenticationIsNotEnabledYet: "尚未启用双因素身份验证。",
    TwoFactorAuthenticationDescription: "双因素身份验证为您的帐户增加了一层额外的安全性，它不仅需要密码才能登录。",
    Enable: "启用",
    Disable: "禁用",

    SetupAuthenticatorApp: "安装验证器应用程序",
    SetupText1: "使用手机应用程序，如 ",
    SetupText2: ", 等，以在登录期间提示时获取2FA代码。",
    ScanTheQrCode: "扫描二维码",
    ScanNote1: "使用手机中的验证器应用程序进行扫描。如果您无法扫描，请改为",
    ScanNote2: "输入此文本代码",
    ScanNote3: "。",
    VerifyTheCodeFromTheApp: "验证应用程序中的代码",

    AuthenticationCode: "身份验证代码",
    Verify: "验证",
    VerifyNote: "打开移动设备上的双因素验证器（TOTP）应用程序以查看您的验证码。",
    HavingProblems: "有问题吗？ ",
    SendCodeToEmail: "将代码发送到电子邮件",
    DonTAskMeAgainOnThisDevice: "在此设备上不再询问我",

    YourTwoFactorSecret: "你的双因素密码",

    SubscriptionPlan: "订阅计划",
    SubscriptionExpiration: "订阅到期时间",
    TODAY: "今天",
    Expired: "已过期",
    CloudSubscriptionPlanFree: "未订阅",
    CloudSubscriptionPlanBasic: "基本",
    CloudSubscriptionPlanStandard: "标准",

    Join: "加入",
    YouCurrentlyHaveAnInvitationToJoinTheTeam: "您当前收到了加入团队 {0} 的邀请。",
    Note: "注",
    JoiningATeam: "：加入团队会将您帐户的所有权转移给团队管理员。您和您的团队成员将能够查看彼此的测试信息。",
    JoinTeam: "加入团队",

    AvailableStorage: "（可用存储空间：{0}）",
    NoRestrictions: "无限制",

    AddSignals: "添加信号",
    AddPlots: "添加视图",
    SelectPlotsToDisplay: "选择要显示的视图",
    AddRemoveSignal: "添加/删除信号",
    SavePlot: "保存视图",
    DataView: "数据视图",
    PlotTitle: "视图标题",

    PlotProperties: "绘图属性",
    TimeFormat: "时间格式",
    DarkMode: "深色模式",

    MinimumValue: "最小值",
    MaximumValue: "最大值",
    DisplayFormat: "显示格式",
    HorizontalAxis: "横轴",
    VerticalAxis: "纵轴",

    PleaseEnterTheEmailAddressOfTheTeamAdministrator: "将请求发送到电子邮件地址：",
    RequestToJoinTheTeam: "请求加入团队",
    PleaseEnterAValidEmailAddress: "请输入有效的电子邮件地址。",
    TheRequestWasSuccessfullySentPleaseWaitForTheAdministratorSConfirmation: "请求已成功发送，请等待管理员的确认。",

    Accept: "接受",
    Reject: "拒绝",
};
