
import SignalChart from "@/components/chart/SignalChart.vue";
import { BuildSignalTree, ChartEventType, CreatePlot, GetUserSetting, GroupSignalsForChart, IChartLayout, IElementTree, IPlot, IRun, IsFrequencySignal, IsSameGroup, IsSignal, IsTimeStreamSignal, PlotDateTimeFormat, PlotSetting, PostUserSetting, UserSettingKey } from "@/helpers";
import store from "@/store";
import { ConvertTo, Distinct } from "node-share";
import { ISignal, MeasurementConfigType, SoftwareMode, TryGetAttribute } from "socket/common";
import { UploadOptions } from "socket/protoc/generated/cloud_messages_pb";
import { v4 as uuidv4 } from "uuid";
import { GridItem, GridLayout } from "vue-grid-layout";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
        GridLayout,
        GridItem,
        SignalChart,
    },
})
export default class SignalViewer extends Vue {
    @Prop()
    signals!: ISignal[];

    @Prop()
    run?: IRun;

    @Prop()
    uploadOptions?: UploadOptions;

    currentLayoutName = "";

    layout: IChartLayout[] = [
        // { x: 0, y: 0, w: 6, h: 9, i: "0" },
        // { x: 6, y: 0, w: 6, h: 9, i: "1" },
    ];

    originalChartPlot = new Map<string, IPlot>();

    plots: IPlot[] = [];
    removedPlots: IPlot[] = [];

    plot?: IChartLayout;

    draggable = true;
    resizable = true;

    isDragging = false;
    isResizing = false;

    colNum = 12;

    chartMap = new Map<string, ISignal[]>();

    dialogAddSignalsVisible = false;
    dialogAddPlotsVisible = false;

    dialogEditSignalsVisible = false;

    plotAvailableSignals: unknown[] = [];
    plotSignals: string[] = [];

    defaultExpandedKeys: string[] = [];

    logEvents = false;

    plotSettingForDialog = new PlotSetting();
    dialogPlotSettingsVisible = false;
    plotDateTimeFormatOptions = [
        {
            label: "Local (controller)",
            value: PlotDateTimeFormat.LocalController,
        },
        {
            label: "Local (client)",
            value: PlotDateTimeFormat.LocalCloud,
        },
        {
            label: "UTC",
            value: PlotDateTimeFormat.UTC,
        },
    ];

    isLoadingLayout = false;

    @Watch("signals")
    onSignalsChanged() {
        this.loadLayout();
    }

    get signalTree() {
        return ConvertTo<IElementTree | undefined>(this.$refs.signalTree);
    }

    get plotTree() {
        return ConvertTo<IElementTree | undefined>(this.$refs.plotTree);
    }

    get plotSignalTree() {
        return ConvertTo<IElementTree | undefined>(this.$refs.plotSignalTree);
    }

    get Name() {
        return this.run?.TestName;
    }

    get editing() {
        return this.draggable || this.resizable;
    }

    set editing(value: boolean) {
        this.draggable = this.resizable = value;
    }

    get isDraggingOrResizing() {
        return this.isDragging || this.isResizing;
    }

    get ignoreRefresh() {
        if (this.dialogAddSignalsVisible || this.dialogAddPlotsVisible || this.isDraggingOrResizing) {
            return true;
        }

        return false;
    }

    get charts() {
        return ConvertTo<SignalChart[]>(this.$refs.signalChart);
    }

    get signalTreeData() {
        const rootName = this.Name ?? "Root";
        this.defaultExpandedKeys = [rootName];

        const data = BuildSignalTree(this.signals, rootName);
        return data;
    }

    get selectedSignals() {
        const nodes = this.signalTree?.getCheckedNodes();
        // console.log(nodes);

        if (!nodes) {
            return [];
        }

        const selected = nodes.filter((p) => IsSignal(p)) as ISignal[];
        return selected;
    }

    get selectedPlots() {
        const nodes = this.plotTree?.getCheckedNodes() as IPlot[];
        // console.log(nodes);

        if (!nodes) {
            return [];
        }

        const selected = this.allPlots.filter(p => nodes.includes(p));
        return selected;
    }

    get selectedPlotSignals() {
        const nodes = this.plotSignalTree?.getCheckedNodes();
        // console.log(nodes);

        if (!nodes) {
            return [];
        }

        const selected = nodes.filter((p) => IsSignal(p)) as ISignal[];
        return selected;
    }

    get layoutName() {
        const testId = this.run?.TestId;
        let name = UserSettingKey.SignalViewerLayout;

        if (testId) {
            name = `${name}_test_${testId}`;
        }

        return name;
    }

    get plotsName() {
        const testId = this.run?.TestId;
        let name = UserSettingKey.SignalViewerPlots;

        if (testId) {
            name = `${name}_test_${testId}`;
        }

        return name;
    }

    get plotSetting() {
        const plotSetting = store.state.plotSetting;
        return plotSetting;
    }

    get displayedSignals() {
        const signals: string[] = [];
        for (const l of this.layout) {
            if (l && l.signals) {
                signals.push(...l.signals);
            }
        }

        return Distinct(signals);
    }

    get measureType() {
        if (!this.run) {
            return MeasurementConfigType.None;
        }

        const attr = TryGetAttribute(this.run.Attributes, "MeasureType");
        if (!attr) {
            return MeasurementConfigType.None;
        }

        if (attr.ValueInt64) {
            return attr.ValueInt64 as MeasurementConfigType;
        }

        return MeasurementConfigType.None;
    }

    get softwareMode() {
        if (!this.run) {
            return SoftwareMode.UNKNOWN;
        }

        const attr = TryGetAttribute(this.run.Attributes, "SoftwareMode");
        if (!attr) {
            return SoftwareMode.UNKNOWN;
        }

        if (attr.ValueInt64) {
            return attr.ValueInt64 as SoftwareMode;
        }

        return SoftwareMode.UNKNOWN;
    }

    get allFrequencySignals() {
        return this.signals.filter(p => IsFrequencySignal(p));
    }

    get allInputAps() {
        return this.allFrequencySignals.filter(p => p.Name.startsWith("APS(") && p.Name !== "APS(drive)");
    }

    get allInputSpectrum() {
        return this.allFrequencySignals.filter(p => p.Name.startsWith("Spectrum(") && p.Name !== "Spectrum(drive)");
    }

    get allInputBlockSignals() {
        return this.signals.filter(p => p.Name.startsWith("Block(") && p.Name !== "Block(drive)");
    }

    get allInputTimeStream() {
        return this.signals.filter(p => IsTimeStreamSignal(p) && !p.Name.startsWith("drive"));
    }

    get allInputOctaveSpectrum() {
        return this.allFrequencySignals.filter(p => p.Name.startsWith("OCT("));
    }

    get defaultPlots(): IPlot[] {
        if ([MeasurementConfigType.VCS_Random, MeasurementConfigType.VCS_RORSOR].includes(this.measureType)) {
            return [
                CreatePlot(["HighAbort(f)", "HighAlarm(f)", "LowAbort(f)", "LowAlarm(f)", "control(f)", "profile(f)"], "Control composite"),
                CreatePlot(["H(f)"], "H(f)"),
                CreatePlot(["APS(drive)"], "APS(drive)"),
                CreatePlot(["drive"], "drive(t)"),
                CreatePlot(this.allInputAps.map(p => p.Name), "All input APS"),
            ];
        } else if ([MeasurementConfigType.VCS_Sine, MeasurementConfigType.VCS_RSTD, MeasurementConfigType.VCS_MultiSine].includes(this.measureType)) {
            return [
                CreatePlot(["HighAbort(f)", "HighAlarm(f)", "LowAbort(f)", "LowAlarm(f)", "control(f)", "profile(f)"], "Control composite"),
                CreatePlot(["H(f)"], "H(f)"),
                CreatePlot(["Spectrum(drive)"], "Spectrum(drive)"),
                CreatePlot(["drive"], "drive(t)"),
                CreatePlot(this.allInputSpectrum.map(p => p.Name), "All input Spectrum"),
            ];
        } else if ([MeasurementConfigType.VCS_Shock, MeasurementConfigType.VCS_TTH, MeasurementConfigType.VCS_SRS, MeasurementConfigType.VCS_ShockOnRandom, MeasurementConfigType.VCS_Earthquake].includes(this.measureType)) {
            return [
                CreatePlot(["HighAbort(f)", "HighAlarm(f)", "LowAbort(f)", "LowAlarm(f)", "control(f)", "profile(f)"], "Control composite"),
                CreatePlot(["hinv(f)"], "Hinv(f)"),
                CreatePlot(["Block(drive)"], "Block(drive)"),
                CreatePlot(this.allInputBlockSignals.map(p => p.Name), "All input Block signals"),
                CreatePlot(this.signals.filter(p => p.Name.includes("MaxiMax")).map(p => p.Name), "All input MaxiMax signals (if available)"),
            ];
        } else if ([MeasurementConfigType.VCS_TDR].includes(this.measureType)) {
            return [
                CreatePlot(["HighAbort(f)", "HighAlarm(f)", "LowAbort(f)", "LowAlarm(f)", "control(f)", "profile(f)"], "Control composite"),
                CreatePlot(["Hinv(f)"], "Hinv(f)"),
                CreatePlot(["Drive(t)"], "Drive(t)"),
                CreatePlot(this.allInputTimeStream.map(p => p.Name), "All input time stream"),
            ];
        } else if ([MeasurementConfigType.VCS_AcousticControl].includes(this.measureType)) {
            return [
                CreatePlot(["HighAbort(f)", "HighAlarm(f)", "LowAbort(f)", "LowAlarm(f)", "control(f)", "profile(f)"], "Control composite"),
                CreatePlot(["H(f)"], "H(f)"),
                CreatePlot(["APS(drive)"], "APS(drive)"),
                CreatePlot(["drive"], "drive(t)"),
                CreatePlot(this.allInputOctaveSpectrum.map(p => p.Name), "All input Octave spectrum"),
            ];
        } else if ([SoftwareMode.SPIDER_THV, SoftwareMode.EDC].includes(this.softwareMode)) {
            return [
                CreatePlot(["Tp(SV)", "Tp1"], "Tp(SV) + Tp1"),
                CreatePlot(["Rh(SV)", "Rh1"], "Rh(SV) + Rh1"),
            ];
        }

        return [];
    }

    get customPlots() {
        return this.plots.filter(p => !this.removedPlots.includes(p));
    }

    get allPlots() {
        return [...this.defaultPlots, ...this.customPlots];
    }

    suspend(val: boolean) {
        this.$emit("suspend", val);
    }

    updateSignalDataByName(s: ISignal, name?: string) {
        if (!name) {
            name = s.Name;
        }

        const signal = this.signals.find(p => p.Name === name);
        if (!signal) {
            return;
        }

        s.Data = signal.Data;
    }

    getSignalsByName(item: IChartLayout) {
        const exist = this.chartMap.get(item.i);
        if (exist) {
            for (const s of exist) {
                this.updateSignalDataByName(s);
            }
            return exist;
        }

        const signalNames = item.signals;

        if (this.isDraggingOrResizing) {
            return;
        }

        const signals: ISignal[] = [];

        for (const s of this.signals) {
            if (signalNames.includes(s.Name)) {
                signals.push(s);
            }
        }

        // console.log(signalNames, signals);

        this.chartMap.set(item.i, signals);

        return signals;
    }

    addChart(plot: IPlot) {
        const newChart: IChartLayout = {
            x: this.layout.length === 0 ? 0 : this.layout[this.layout.length - 1].x === 0 ? 6 : 0,
            y: this.layout.length + (this.colNum || 12), // puts it at the bottom
            w: 6,
            h: 9,
            i: uuidv4(),
            signals: this.removeInvalidSignals(plot.signals),
            isDataView: plot.isDataView,
            title: plot.title,
        };

        this.originalChartPlot.set(newChart.i, plot);

        // Add a new item. It must have a unique key!
        this.layout.push(newChart);
    }

    addSignals() {
        if (this.dialogAddSignalsVisible) {
            const groups = GroupSignalsForChart(this.selectedSignals);

            if (groups) {
                console.log(groups);
                for (const group of groups?.values()) {
                    this.addChart(CreatePlot(Array.from(group).map((p) => p.Name)));
                }
            }

            // reset
            this.signalTree?.setCheckedKeys([]);

            this.dialogAddSignalsVisible = false;
            this.suspend(false);
        } else {
            this.dialogAddSignalsVisible = true;
            this.suspend(true);
        }
    }

    addPlots() {
        if (this.dialogAddPlotsVisible) {
            for (const plot of this.selectedPlots) {
                this.addChart(plot);
            }

            // reset
            this.plotTree?.setCheckedKeys([]);

            if (this.removedPlots.length > 0) {
                this.plots = this.customPlots;
                this.removedPlots = [];

                this.savePlots();
            }

            this.dialogAddPlotsVisible = false;
            this.suspend(false);
        } else {
            this.removedPlots = [];
            this.dialogAddPlotsVisible = true;
            this.suspend(true);
        }
    }

    closeAllCharts() {
        this.$confirm(this.$t("CloseAllSignalCharts").toString(), this.$t("Warning").toString(), {
            confirmButtonText: this.$t("ok").toString(),
            cancelButtonText: this.$t("Cancel").toString(),
            type: "warning",
        }).then(() => {
            this.layout = [];
            this.chartMap.clear();

            // this.$message({
            //     type: "success",
            //     message: "Delete completed",
            // });
        }).catch(() => {
            // this.$message({
            //     type: "info",
            //     message: "Delete canceled",
            // });
        });
    }

    openPlotSettingDialog() {
        if (!this.dialogPlotSettingsVisible) {
            this.plotSettingForDialog = this.plotSetting.Clone();
            this.dialogPlotSettingsVisible = true;
        }
    }

    async saveLayout() {
        this.uploadOptions?.setSignalsList(this.displayedSignals);

        if (this.isLoadingLayout) {
            return;
        }

        if (await PostUserSetting(this.layoutName, this.layout)) {
            this.originalChartPlot.clear();
        }
    }

    async savePlots() {
        await PostUserSetting(this.plotsName, this.plots);
    }

    async loadLayout() {
        if (this.currentLayoutName === this.layoutName) {
            this.refresh();
            return;
        }

        this.isLoadingLayout = true;

        try {
            const setting = await GetUserSetting<IChartLayout[]>(this.layoutName);

            this.layout = [];
            this.originalChartPlot.clear();

            if (setting) {
                setting.forEach(p => this.addChart(p));
            }

            this.currentLayoutName = this.layoutName;

            await this.$nextTick();
        } finally {
            this.isLoadingLayout = false;
        }
    }

    async loadPlots() {
        const setting = await GetUserSetting<IPlot[]>(this.plotsName);
        if (setting) {
            this.plots = setting;
        }
    }

    async mounted() {
        await Promise.all([
            this.loadLayout(),
            this.loadPlots(),
        ]);
    }

    // async destroyed() {
    //     await this.saveLayout();
    // }

    resize() {
        for (const c of this.charts) {
            c.resize();
        }
    }

    removeItem(id: string) {
        const index = this.layout.map((item) => item.i).indexOf(id);
        if (index !== -1) {
            this.layout.splice(index, 1);
            this.chartMap.delete(id);
        }
    }

    moveEvent(i: number, newX: number, newY: number) {
        this.suspend(true);
        this.isDragging = true;

        if (this.logEvents) {
            const msg = "MOVE i=" + i + ", X=" + newX + ", Y=" + newY;
            console.log(msg);
        }
    }

    movedEvent(i: number, newX: number, newY: number) {
        this.isDragging = false;
        this.suspend(false);

        if (this.logEvents) {
            const msg = "MOVED i=" + i + ", X=" + newX + ", Y=" + newY;
            console.log(msg);
        }
    }

    resizeEvent(i: number, newH: number, newW: number, newHPx: number, newWPx: number) {
        this.suspend(true);
        this.isResizing = true;

        if (this.logEvents) {
            const msg = "RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            console.log(msg);
        }
    }

    resizedEvent(i: number, newX: number, newY: number, newHPx: number, newWPx: number) {
        this.isResizing = false;
        this.suspend(false);

        if (this.logEvents) {
            const msg = "RESIZED i=" + i + ", X=" + newX + ", Y=" + newY + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            console.log(msg);
        }
    }

    layoutUpdatedEvent() {
        this.saveLayout();
    }

    onChartEvent(type: ChartEventType, id: string, data?: unknown, callback?: (err?: Error, data?: unknown) => void) {
        // console.info(type, id, data);
        switch (type) {
            case ChartEventType.close:
                this.removeItem(id);
                break;

            case ChartEventType.saveLayout:
                {
                    if (data) {
                        const plot = this.layout.find(p => p.i === id);
                        if (plot) {
                            plot.title = data as string;
                        }
                    }

                    this.saveLayout();
                }
                break;

            case ChartEventType.savePlot:
                {
                    const plot = data as IPlot;

                    const save = () => {
                        this.plots.push(plot);
                        this.savePlots();
                    };

                    if (callback) {
                        if (this.plots.some(p => p.title === plot.title)) {
                            callback(new Error("Duplicate plot titles"));
                        } else {
                            save();
                            callback();
                        }
                    } else {
                        save();
                    }
                }
                break;

            case ChartEventType.editSignals:
                {
                    this.plot = this.layout.find(p => p.i === id);
                    if (this.plot) {
                        let validSignals: ISignal[] = [];
                        if (this.plot.signals.length > 0) {
                            const name = this.plot.signals[0];
                            const firstSignal = this.signals.find(p => p.Name === name);
                            if (firstSignal) {
                                validSignals = this.signals.filter(p => IsSameGroup(p, firstSignal));
                            }
                        }

                        if (validSignals.length === 0) {
                            validSignals = [...this.signals];
                        }

                        const rootName = this.Name ?? "Root";
                        this.defaultExpandedKeys = [rootName];

                        this.plotAvailableSignals = BuildSignalTree(validSignals, rootName);
                        this.plotSignals = this.plot.signals;

                        this.dialogEditSignalsVisible = true;
                    }
                }
                break;

            default:
                console.warn(type, id);
                break;
        }
    }

    editSignals() {
        if (this.plot) {
            this.chartMap.delete(this.plot.i);
            this.plot.signals = this.selectedPlotSignals.map(p => p.Name);

            this.saveLayout();
        }

        this.dialogEditSignalsVisible = false;
    }

    removeInvalidSignals(signalNames: string[]) {
        const signals: ISignal[] = [];

        for (const sig of signalNames) {
            const signal = this.signals.find(p => p.Name === sig);
            if (signal) {
                if (signals.length > 0) {
                    if (IsSameGroup(signals[0], signal)) {
                        signals.push(signal);
                    } else {
                        // console.warn(`Remove invalid signal from chart: ${signal.Name}`);
                    }
                } else {
                    signals.push(signal);
                }
            }
        }

        return signals.map(p => p.Name);
    }

    refresh() {
        if (this.layout) {
            this.chartMap.clear();
            for (const c of this.layout) {
                c.signals = this.removeInvalidSignals(this.originalChartPlot.get(c.i)?.signals ?? c.signals);
            }
        }
    }

    async applyPlotSetting() {
        await store.dispatch("updatePlotSetting", this.plotSettingForDialog);

        await this.$nextTick();

        this.refresh();

        this.dialogPlotSettingsVisible = false;
    }

    removePlot(plot: IPlot) {
        // console.info(plot);
        this.removedPlots.push(plot);

        // const index = this.plots.indexOf(plot);
        // if (index !== -1) {
        //     this.plots.splice(index, 1);
        //     this.savePlots();
        // }
    }
}
