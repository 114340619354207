
import { Vue, Component } from "vue-property-decorator";
import { Message } from "element-ui";
import { HttpStatusCode } from "node-share";
import axios, { AxiosRequestConfig } from "axios";

interface IShaker {
    _id: string;

    manufacturer: string;
    name: string;

    force_unit: string;
    acceleration_unit: string;
    velocity_unit: string;
    displacement_unit: string;
    voltage_unit: string;
    armature_mass_unit: string;
    diameter_unit: string;
    sine_max_force_peak: number;
    sine_max_acc_peak: number;
    sine_max_pos_disp: number;
    sine_max_neg_disp: number;
    random_max_force_rms: number;
    random_max_acc_rms: number;
    random_max_pos_disp: number;
    random_max_neg_disp: number;
    shock_max_force_peak: number;
    shock_max_acc_peak: number;
    shock_max_pos_disp: number;
    shock_max_neg_disp: number;
    max_velocity: number;
    max_drive_voltage: number;
    min_drive_frequency: number;
    max_drive_frequency: number;
    diameter: number;
    armature_mass: number;
    measurement_nosiy: number;
    shaker_orientation: string;
    image: string;
    price: number;
    currency_unit: string;

    cooling_method: string;

    updatedAt?: Date;
    createdAt?: Date;
}

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

@Component({
    name: "Shakers",
    components: {
        ImageUploader: require("vue-image-upload-resize"),
    },
})
export default class Shakers extends Vue {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    readonly AccelerationUnits = ["m/s²", "cm/s²", "mm/s²", "g", "ft/s²", "in/s²", "mil/s²", "gal"];
    readonly VelocityUnits = ["m/s", "cm/s", "mm/s", "μm/s", "nm/s", "ft/s", "in/s", "mil/s"];
    readonly DisplacementUnits = ["m", "cm", "mm", "μm", "nm", "ft", "in", "mil"];
    readonly CurrentUnits = ["A", "mA"];
    readonly VoltageUnits = ["V", "mV"];
    readonly ForceUnits = ["Newton", "Dyne", "kN", "kgf", "kIPF", "LBF", "OZF"];
    readonly MomentUnits = ["N*m", "kN*m", "dyne*cm", "kfg*m", "gf*m", "lbf*ft", "lbf*in", "ozf*in"];
    readonly PressureUnits = ["Pa", "uPa", "Bar", "PSI", "KSI", "MPa"];
    readonly StressUnits = ["Pa", "uPa", "Bar", "PSI", "KSI", "MPa"];
    readonly SoundPressureUnits = ["Pa"];
    readonly TimeUnits = ["s", "ms", "h", "m"];
    readonly FrequencyUnits = ["Hz", "mHz", "kHZ", "MHZ", "CPM", "Rad/s"];
    readonly AngularVelocityUnits = ["Rad/s", "Degree/s", "RPM"];
    readonly MASSUnits = ["kg", "g", "LBS", "Ounce"];
    readonly TachoUnits = ["RPM"];
    readonly AngleUnits = ["Rad", "Degree"];
    readonly LevelUnits = ["dB", "%"];
    readonly StrainUnits = ["ε", "με"];
    readonly TemperatureUnits = ["K", "°C", "°F", "°R"];
    readonly ResistanceUnits = ["Ohm"];
    readonly HumidityUnits = ["% RH"];
    readonly SoundIntensityUnits = ["w/m²"];
    /* eslint-ebable @typescript-eslint/no-unused-vars */

    loading = false;

    radioOption = "create";

    shakerId = "";
    shaker: IShaker = this.defaultShaker();
    shakers: IShaker[] = [];

    appToken = "";
    resetTable = false;

    imageDialogVisible = false;

    shakerForm = {
        name: "",
        manufacturer: "",
    }

    manufacturerOptions = [
        {
            value: "Sentek Dynamics",
            label: "Sentek Dynamics",
        },
        {
            value: "STI",
            label: "STI",
        },
    ]

    rules = {
        name: [
            { required: true, message: "Please input name", trigger: "blur" },
        ],
        manufacturer: [
            { required: true, message: "Please input manufacturer", trigger: "blur" },
        ],
    }

    get IsEdit() {
        return this.radioOption === "edit";
    }

    get RequestConfig(): AxiosRequestConfig {
        return {
            headers: {
                "api-token-app": this.appToken,
            },
        };
    }

    get Form() {
        return this.$refs.shakerForm as Vue & { validate: (callback: (valid: boolean) => void) => void };
    }

    get Image() {
        let image = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPk4+erBwABCgCsW/hKpgAAAABJRU5ErkJggg==";

        if (this.shaker.image) {
            image = this.shaker.image;
        }

        return `data:image/gif;base64,${image}`;
    }

    set Image(img: string) {
        this.shaker.image = img;
        this.$forceUpdate();
    }

    get UploadInput() {
        return document.getElementById("fileUpload") as HTMLInputElement;
    }

    buildQueryFunction(units: string[]) {
        return function(queryString: string, cb: (results: { value: string }[]) => void) {
            const results = queryString ? units.filter(p => p.toLowerCase().includes(queryString.toLowerCase())) : units;
            // call callback function to return suggestions
            cb(results.map(p => {
                return { value: p };
            }));
        };
    }

    uploadClick() {
        this.UploadInput.click();
    }

    resetUploadInput() {
        this.UploadInput.value = "";
    }

    setImage(base64: string) {
        const index = base64.indexOf(",");
        this.Image = base64.substr(index + 1);
    }

    defaultShaker(): IShaker {
        return {
            _id: "",

            name: "",
            manufacturer: "",

            force_unit: "",
            acceleration_unit: "",
            velocity_unit: "",
            displacement_unit: "",
            voltage_unit: "",
            armature_mass_unit: "",
            diameter_unit: "",
            sine_max_force_peak: 0,
            sine_max_acc_peak: 0,
            sine_max_pos_disp: 0,
            sine_max_neg_disp: 0,
            random_max_force_rms: 0,
            random_max_acc_rms: 0,
            random_max_pos_disp: 0,
            random_max_neg_disp: 0,
            shock_max_force_peak: 0,
            shock_max_acc_peak: 0,
            shock_max_pos_disp: 0,
            shock_max_neg_disp: 0,
            max_velocity: 0,
            max_drive_voltage: 0,
            min_drive_frequency: 0,
            max_drive_frequency: 0,
            diameter: 0,
            armature_mass: 0,
            measurement_nosiy: 0,
            shaker_orientation: "",
            image: "",
            price: 0,
            currency_unit: "",

            cooling_method: "",
        };
    }

    resetShaker() {
        this.shakerId = "";
        this.shaker = this.defaultShaker();

        this.resetUploadInput();
    }

    changeRadioOption(option: string) {
        this.resetShaker();

        if (option === "edit") {
            this.fetchShakers();
        }
    }

    changeShaker() {
        const p = this.shakers.find(p => p._id === this.shakerId);
        if (p) {
            this.shaker = p;
        } else {
            this.shaker = this.defaultShaker();
        }

        this.resetUploadInput();
    }

    async deleteShaker() {
        this.loading = true;

        try {
            const result = await axios.delete(`/api/v1/app/shakers/${this.shaker._id}`, this.RequestConfig);
            if (result.status === HttpStatusCode.OK) {
                Message({
                    message: "Success",
                    type: "success",
                });

                await this.fetchShakers();
            } else {
                Message({
                    message: "Sorry, something went wrong. Please check your input ...",
                    type: "error",
                });
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    saveShaker() {
        this.Form.validate(async (valid) => {
            if (valid) {
                this.loading = true;

                try {
                    let isError = false;

                    if (this.IsEdit) {
                        const result = await axios.put(`/api/v1/app/shakers/${this.shaker._id}`, this.shaker, this.RequestConfig);
                        if (result.status === HttpStatusCode.OK) {
                            Message({
                                message: "Success",
                                type: "success",
                            });
                        } else {
                            isError = true;
                        }
                    } else {
                        const result = await axios.post("/api/v1/app/shakers", this.shaker, this.RequestConfig);
                        if (result.status === HttpStatusCode.Created) {
                            Message({
                                message: "Success",
                                type: "success",
                            });
                        } else {
                            isError = true;
                        }
                    }

                    if (isError) {
                        Message({
                            message: "Sorry, something went wrong. Please check your input ...",
                            type: "error",
                        });
                    } else {
                        // update reset flag
                        if (this.resetTable) {
                            await axios.post("/api/v1/app/reset/table/shakers", { reset: true }, this.RequestConfig);
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                }

                this.loading = false;
            } else {
                return false;
            }
        });
    }

    async fetchShakers() {
        this.loading = true;

        try {
            const result = await axios.get("/api/v1/app/shakers");
            if (result.status === HttpStatusCode.OK) {
                this.shakers = result.data.data;

                this.resetShaker();
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    created() {
        this.fetchShakers();
    }
}
