/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/plugins/i18n.ts
 * @create:      2020-06-01 13:43:39.376
 * @modify:      2022-07-06 10:44:32.500
 * @version:     0.1.1
 * @times:       8
 * @lines:       30
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "../store";
import cloudI18n from "../i18n/i18n";

Vue.use(VueI18n);

// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/vue-i18n/vue-i18n-tests.ts
const i18n = new VueI18n({
    // locale: 'en-US',
    locale: store.getters.language,
    silentTranslationWarn: true,
    messages: cloudI18n,
});

export default i18n;
