<template>
    <div class="run-log-table">
        <h3 class="ci-dark" style="padding: 6px">{{ $t("run_log") }}</h3>
        <h4 class="run-description">
            {{ $t("run_description") }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                runHistory.Description
            }}
        </h4>
        <el-table
            v-loading="loading"
            highlight-current-row
            :data="tableData"
            border
            style="width: 100%"
            header-row-class-name="cloud-table-header"
        >
            <template slot="empty">
                <span>{{ $t("NoData") }}</span>
            </template>
            <el-table-column
                prop="AbsoluteTime"
                :label="$t('run_log_columns.absolute_time')"
            ></el-table-column>
            <el-table-column
                prop="ScheduleTime"
                :label="$t('run_log_columns.test_time')"
            ></el-table-column>
            <el-table-column
                prop="EventType"
                :label="$t('run_log_columns.event_type')"
            ></el-table-column>
            <el-table-column
                prop="FrequencyOrPulse"
                :label="$t('run_log_columns.elapsed')"
                v-if="IsElapsedVisible"
            ></el-table-column>
            <el-table-column
                prop="CtrlDemandValue"
                :label="$t('run_log_columns.control_target_rms')"
                v-if="IsCtrlDemandValueVisible && IsRMS"
            ></el-table-column>
            <el-table-column
                prop="CtrlDemandValue"
                :label="$t('run_log_columns.control_target_peak')"
                v-else-if="IsTHCVisible"
            ></el-table-column>
            <el-table-column
                prop="FrequencyOrPulse"
                :label="$t('run_log_columns.frequency')"
                v-if="IsFrequencyVisible"
            ></el-table-column>
            <el-table-column :label="$t('run_log_columns.event_data')">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <div style="max-width: 400px; word-wrap: break-word">
                            {{ scope.row.EventData }}
                        </div>
                        <div slot="reference" class="name-wrapper">
                            {{ getRowData(scope.row.EventData) }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column
                prop="EventSource"
                :label="$t('run_log_columns.event_source')"
            ></el-table-column>
        </el-table>
    </div>
</template>

<i18n>
{
    "en-US": {
        "control_panel": "Control Panel"
    },
    "zh-CHS": {
        "control_panel": "控制面板"
    }
}
</i18n>

<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { IsRunLogElapsedVisible } from "@/helpers";

import {
    MeasurementConfigType,
    SoftwareMode,
} from "socket/common";

@Component({
    name: "RunLogTable",
})
export default class RunLogTable extends Vue {
    @Prop()
    runHistory;

    tableData = [];
    loading = false;
    softwareMode = 0;
    MeasureType = 0;
    status = 0;

    get RunLogGUID() {
        return this.runHistory.RunLogGUID;
    }

    get IsCtrlDemandValueVisible() {
        return this.softwareMode === SoftwareMode.SPIDER_VCS || this.softwareMode === SoftwareMode.SPIDER_THV;
    }

    get IsDSA() {
        return this.softwareMode === SoftwareMode.SPIDER_DSA;
    }

    get IsElapsedVisible() {
        return IsRunLogElapsedVisible(this.MeasureType);
    }

    get IsTHCVisible() {
        switch (this.MeasureType) {
            case MeasurementConfigType.THV_THC:
                return false;
            default:
                return true;
        }
    }

    get IsFrequencyVisible() {
        switch (this.MeasureType) {
            case MeasurementConfigType.VCS_Sine:
            case MeasurementConfigType.THV_Sine:
            case MeasurementConfigType.VCS_SineReduction:
            case MeasurementConfigType.MIMO_Sine:
            case MeasurementConfigType.VCS_RSTD:
            case MeasurementConfigType.THV_RSTD:
                return true;
        }
    }

    get IsRMS() {
        switch (this.MeasureType) {
            case MeasurementConfigType.VCS_Random:
            case MeasurementConfigType.THV_Random:
            case MeasurementConfigType.VCS_RORSOR:
            case MeasurementConfigType.THV_RORSOR:
            case MeasurementConfigType.MIMO_Random:
            case MeasurementConfigType.MESA_Random:
            case MeasurementConfigType.MDOF_Random:
                return true;
            case MeasurementConfigType.VCS_Shock:
            case MeasurementConfigType.THV_Shock:
            case MeasurementConfigType.VCS_SRS:
            case MeasurementConfigType.THV_SRS:
            case MeasurementConfigType.VCS_Earthquake:
            case MeasurementConfigType.THV_Earthquake:
            case MeasurementConfigType.VCS_TTH:
            case MeasurementConfigType.THV_TTH:
                return false;
            case MeasurementConfigType.VCS_EDR:
            case MeasurementConfigType.VCS_ShockOnRandom:
            case MeasurementConfigType.THV_ShockOnRandom:
            case MeasurementConfigType.MIMO_Shock:
            case MeasurementConfigType.MIMO_TTH:
            case MeasurementConfigType.MIMO_SRS:
                return true;
            case MeasurementConfigType.VCS_Sine:
            case MeasurementConfigType.THV_Sine:
            case MeasurementConfigType.VCS_RSTD:
            case MeasurementConfigType.VCS_BFT:
            case MeasurementConfigType.THV_RSTD:
            case MeasurementConfigType.VCS_SineOscillator:
            case MeasurementConfigType.THV_SineOscillator:
            case MeasurementConfigType.VCS_MultiSine:
            case MeasurementConfigType.THV_MultiSine:
            case MeasurementConfigType.MDA_MIMO_SSSine:
            case MeasurementConfigType.MIMO_Sine:
            case MeasurementConfigType.MESA_Sine:
            case MeasurementConfigType.MDOF_Sine:
                return false;
            case MeasurementConfigType.VCS_TDR:
            case MeasurementConfigType.THV_TDR:
            case MeasurementConfigType.MIMO_TWR:
            case MeasurementConfigType.MDOF_TWR:
                return true;
        }
    }

    mounted() {
        if (this.runHistory.RunlogData) {
            this.setRunlogData(this.runHistory.RunlogData);
        } else {
            this.fetchData();
        }
    }

    setRunlogData(data) {
        this.tableData = data.RunLogItems;
        // vm.controlpanelData = res.data.data[0].ControlPanel;
        this.softwareMode = data.SoftwareMode;
        this.MeasureType = data.MeasureType;
        this.status = data.Status;
    }

    async fetchData() {
        try {
            if (this.RunLogGUID) {
                this.loading = true;
                const res = await axios.get(`/api/v1/runlog?SharedTo&GUID=${this.RunLogGUID}`);
                this.setRunlogData(res.data.data[0]);
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    getRowData(data) {
        if (data) {
            return data.length > 20 ? data.substr(0, 20) + "..." : data;
        }

        return "";
    }
}
</script>

<style lang="scss" scoped>
.run-log-table {
    border: 1px solid #d9d9d9;
}

.run-description {
    padding: 1ex 10px;
}
</style>
