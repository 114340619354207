/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/base/enum.ts
 * @create:      2022-05-12 16:11:16.814
 * @modify:      2023-03-21 14:05:56.861
 * @version:     0.1.1
 * @times:       19
 * @lines:       125
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

export enum RouteName {
    Home = "Home",

    Account = "Account",
    Login = "Login",
    Demo = "Demo",
    SignUp = "SignUp",
    Forgot = "Forgot",
    Reset = "Reset",
    Verification = "Verification",
    ChangePassword = "ChangePassword",

    Dashboard = "Dashboard",
    Tests = "Tests",
    RunHistories = "RunHistories",
    Uploads = "Uploads",
    SystemInformation = "SystemInformation",
    Settings = "Settings",
    MFASetup = "MFASetup",
    Team = "Team",
    TeamMembers = "TeamMembers",
    TeamGroups = "TeamGroups",
    TeamGroup = "TeamGroup",
    TeamSettings = "TeamSettings",
    App = "App",
    Products = "Products",
    Shakers = "Shakers",
    DataTable = "DataTable",
    RunLogs = "RunLogs",
    System = "System",
    EDC = "EDC",
    DataViewer = "DataViewer",
    RTIM = "RTIM",
}

export enum DisplayFormatX {
    Unknown,
    Log = 1000,
    Linear = 2000,
}

export enum DisplayFormatY {
    Unknown,
    dB = 100,
    Linear = 110,

    Mag = 120,
    dBMag = 130,
    LogMag = 140,

    Phons = 150,
    Sones = 160,

    Real = 200,
    dBReal = 210,

    Imaginary = 300,
    dBImaginary = 310,

    Phase = 400,
    UnwrapPhase = 410,

    RealImaginary = 500,

    MagPhase = 600,
    dBMagPhase = 610,
    LogMagPhase = 620,

    Polar = 700,

    Vector = 800,

    HistNormalized = 900,
    HistCumulative = 920,
    HistFreq = 930,
}

export enum StrainConversionMode {
    Default,
    Strain,
    Stress,
}

export enum ShareType {
    Test = "Test",
    V2Test = "V2Test",
    File = "File",
}

export enum PlotDateTimeFormat {
    LocalController,
    LocalCloud,
    UTC,
}

export enum NvhType {
    Equidistant = "Equidistant",
    NonEquidistant = "NonEquidistant",
}

export enum AuthenticationType {
    Login,
    ConfirmAccess,
}

export enum ChartEventType {
    close,
    saveLayout,
    savePlot,
    editSignals,
}
