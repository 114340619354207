/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/store.ts
 * @create:      2019-03-06 17:35:58.610
 * @modify:      2023-04-14 16:38:34.306
 * @version:     0.1.1
 * @times:       189
 * @lines:       371
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

import SocketManager from "@/components/managers/SocketManager";
import axios from "axios";
import { Message } from "element-ui";
import jwt from "jsonwebtoken";
import { HttpStatusCode } from "node-share";
import { Language } from "socket/common";
import { CloudMessageType } from "socket/protoc/generated/cloud_enums_pb";
import { CloudMessage } from "socket/protoc/generated/cloud_messages_pb";
import Vue from "vue";
import Vuex from "vuex";
import { ConstNames, DeleteCookie, EDMClient, eventHub, GetApiVersion, GetCookie, IProfile, ITeamMember, PlotSetting, SetCookie } from "./helpers";

// [vuex] must call Vue.use(Vuex) before creating a store instance.
Vue.use(Vuex);

function defaultProfile(): IProfile {
    return {
        name: "",
        gender: "",
        company: "",
        phone1: "",
        // phone2: { type: String, default: '' },
        country: "",
        state: "",
        location: "",
        website: "",
        picture: "",

        // 2018年10月15日
        city: "",
        postal_code: "",
        sameAsShippingAddress: true,

        billingAddress: {
            city: "",
            country: "",
            location: "",
            postal_code: "",
            state: "",
        },
    };
}

function defaultUserInfo() {
    return {
        id: "",
        email: "",
        profile: defaultProfile(),
        team: {},
        invites: [],
        tips: [] as string[],
        is2faEnabled: false,
    };
}

const cloudState = {
    user: defaultUserInfo(),
    token: GetCookie(ConstNames.Token, ""),
    breadcrumb: ["sidebar.dashboard"],
    language: GetCookie(ConstNames.Language, ConstNames.LanguageEnglish) as string,
    dateFormatString: GetCookie(ConstNames.DateFormat, "YYYY-MM-DD HH:mm:ss") as string,
    plotSetting: new PlotSetting().FromJsonString(GetCookie(ConstNames.PlotSetting)),
    returnUrl: "",
    redirectUrl: "",
    useBaseLayout: true,
    liveTestCount: 0,
    testCount: 0,
    storageAvailable: 0,
    storageTotal: 0,
    oem: Number(GetCookie(ConstNames.OEM) ?? 0),
    clients: [] as EDMClient[],
    server: {},
};

const store = new Vuex.Store<typeof cloudState>({
    state: cloudState,
    getters: {
        UserName: (state) => {
            return state.user.profile.name || state.user.email;
        },
        IsLogged: (state) => {
            if (state.token) {
                return true;
            }

            return false;
        },
        UseBaseLayout: (state, { IsLogged }) => {
            return IsLogged && state.useBaseLayout;
        },
        JwtPayload: state => {
            if (!state.token) {
                return undefined;
            }

            return jwt.decode(state.token);
        },
        IsDemo: (_, g) => {
            return g.JwtPayload?.type === "demo";
        },
        UserId: (state, g) => {
            if (g.IsLogged) {
                return state.user.id;
            }

            return undefined;
        },
        Team: (state) => {
            return state.user.team;
        },
        NoTeam: (_, getters) => !getters.Team,
        Invites: (state) => {
            return state.user.invites ?? [];
        },
        NoInvites: (_, getters) => getters.Invites === undefined || getters.Invites.length === 0,
        IsTeamAdmin: (_, g) => {
            if (g.NoTeam) {
                return false;
            }

            return g.Team.members?.findIndex((p: ITeamMember) => p.user === g.UserId && p.status === "Admin") !== -1;
        },
        IsTeamCreator: (_, g) => {
            if (g.NoTeam) {
                return false;
            }

            return g.Team.creator === g.UserId;
        },
        IsDebug: (_, getters) => getters.UserName === "Debug",
        ShowOnlineClients: (_, getters) => getters.IsDebug,
        CurrentBreadcrumb: state => {
            if (state.breadcrumb.length !== 1) {
                return undefined;
            }

            return state.breadcrumb[0];
        },
        Language: (state) => {
            if (state.language === ConstNames.LanguageChinese) {
                return Language.Chinese;
            }

            return Language.English;
        },
        Clients: (state) => state.clients,
    },
    mutations: {
        updateServerInfo(state, info) {
            state.server = info;
        },

        updateUserInfo(state, newUserInfo) {
            state.user = newUserInfo;
        },

        showUserTips(state) {
            const { tips } = state.user;

            if (tips && tips.length > 0) {
                Message.warning({
                    message: tips.join("\n"),
                    duration: 30000,
                    showClose: true,
                });
            }
        },

        updateTeamInfo(state, newInfo) {
            if (newInfo) {
                // console.log(newInfo);

                state.user.team = newInfo.team;
                state.user.invites = newInfo.invites;
            }
        },

        sendUserInfo(state) {
            if (state.user.email) {
                const msg = new CloudMessage();
                msg.setType(CloudMessageType.OLD_UPDATE_CLIENT_INFO);
                msg.setP3(state.user.email);
                SocketManager.Send(msg);
            }
        },

        changeLanguage(state, lang) {
            state.language = lang;
        },

        saveLanguageToCookie(state) {
            SetCookie(ConstNames.Language, state.language);
        },

        updateToken(state, token) {
            state.token = token;
        },

        updateOEM(state, oem) {
            state.oem = oem;
        },

        saveTokenToCookie(state) {
            SetCookie(ConstNames.Token, state.token);
        },

        saveOEMToCookie(state) {
            SetCookie(ConstNames.OEM, state.oem.toString());
        },

        updateDateFormat(state, format) {
            state.dateFormatString = format;
        },

        saveDateFormatToCookie(start) {
            SetCookie(ConstNames.DateFormat, start.dateFormatString);
        },

        savePlotSettingToCookie(start) {
            SetCookie(ConstNames.PlotSetting, start.plotSetting.ToJsonString());
        },

        updatePlotSetting(state, setting) {
            state.plotSetting = setting;
        },

        resetUser(state) {
            state.user = defaultUserInfo();
        },

        resetToken(state) {
            state.token = "";
            state.redirectUrl = "/";
        },

        resetReturnUrl(state) {
            state.returnUrl = "";
        },

        updateBreadcrumb(state, val) {
            state.breadcrumb = val;
        },

        refreshFrame: (state, { name, frame }) => {
            eventHub.$emit(name, frame);
        },

        refreshSignalList: (state, { name, signalList }) => {
            eventHub.$emit(name, signalList);
        },

        refreshRecordStatus: (state, { name, status }) => {
            eventHub.$emit(name, status);
        },

        refreshVDSFactoryTree: (state, { name, tree }) => {
            eventHub.$emit(name, tree);
        },
    },
    actions: {
        async updateServerInfo({
            commit,
        }) {
            const info = await GetApiVersion();
            if (info) {
                commit("updateServerInfo", info);
            }
        },

        async refreshUserInfo({
            commit,
        }) {
            // console.log('refreshUserInfo')
            if (this.getters.IsLogged) {
                const r = await axios.get("/api/v1/users/me");
                if (r.status === HttpStatusCode.OK) {
                    const user = r.data.data;
                    // 提交mutation到Store
                    commit("updateUserInfo", user);
                    commit("sendUserInfo");
                    commit("showUserTips");
                } else {
                    console.warn(r);
                }
            }
        },

        async refreshTeamInfo({ commit }) {
            if (this.getters.IsLogged) {
                const res = await axios.get("/api/v1/teams/me");
                if (res.status === HttpStatusCode.OK) {
                    commit("updateTeamInfo", res.data.data);
                } else {
                    console.error(res.data);
                }
            }
        },

        changeLanguage({ commit }, lang) {
            commit("changeLanguage", lang);
            commit("saveLanguageToCookie");
        },

        async updateToken({ commit, dispatch }, token) {
            commit("updateToken", token);
            commit("saveTokenToCookie");

            await dispatch("refreshUserInfo");
        },

        updateOEM({ commit }, oem) {
            commit("updateOEM", oem);
            commit("saveOEMToCookie");
        },

        updateDateFormat({ commit }, format) {
            commit("updateDateFormat", format);
            commit("saveDateFormatToCookie");
        },

        updatePlotSetting({ commit }, setting) {
            commit("updatePlotSetting", setting);
            commit("savePlotSettingToCookie");
        },

        logout({ commit }) {
            console.log("logout");
            DeleteCookie(ConstNames.Token);
            commit("resetUser");
            commit("resetToken");
        },

        async refreshDashboardValues({ state }) {
            try {
                if (this.getters.IsLogged) {
                    const r = await axios.get("/api/v1/dashboard");
                    state.testCount = r.data.data[0];
                    state.liveTestCount = r.data.data[1];
                    state.storageAvailable = r.data.data[2];
                    state.storageTotal = r.data.data[3];
                }
            } catch (ex) {
                console.error(ex);
            }
        },

        updateOpenToken: async ({ getters, dispatch }, token) => {
            if (getters.IsLogged) {
                await dispatch("logout");
            }

            await dispatch("updateToken", token);
        },
    },
});

export default store;
