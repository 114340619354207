
import { Vue, Component } from "vue-property-decorator";
import FooterBar from "@/components/FooterBar.vue";
import store from "@/store";

@Component({
    name: "Account",
    components: {
        FooterBar,
    },
})
export default class Account extends Vue {
    mounted() {
        if (store.getters.IsLogged) {
            this.RedirectIfNecessary();
        }
    }
}
