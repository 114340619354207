
import { Vue, Component, Prop } from "vue-property-decorator";
import axios from "axios";

import RunLogTable from "@/components/tables/RunLogTable.vue";
import SystemCard from "@/components/cards/SystemCard.vue";

import { AddToRefreshList, CreateReport, IsRunLogElapsedVisible } from "@/helpers";
import { CloudDataType } from "socket/common";

interface IParentInfo {
    GUID: string;
    UniqueIdentifier: string;
}

interface IEDM {
    UpdateDateTime: string;
    Version: string;
}

interface IRunLogItem {
    _id: string;
    AbsoluteTime?: string;
    CtrlDemandValue?: string;
    EventData?: string;
    EventSource?: string;
    EventType?: string;
    EventTypeInt: number;
    FrequencyOrPulse?: string;
    IsFinishStatus: boolean;
    IsStartStatus: boolean;
    IsTestFailed: boolean;
    ScheduleTime?: string;
}

interface IRunlogData {
    ParentInfo: IParentInfo;
    SoftwareMode: number;
    MeasureType: number;
    Status: number;
    SharedTo?: null[] | null;
    _id: string;
    GUID: string;
    UniqueIdentifier: string;
    RunHistoryGUID: string;
    RunLogItems: IRunLogItem[];
    userId: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

interface IRunHistory {
    ParentInfo: IParentInfo;
    EDM: IEDM;
    Status: number;
    IsOutOfDate: boolean;
    SharedTo?: null[] | null;
    _id: string;
    Description: string;
    GUID: string;
    UniqueIdentifier: string;
    Duration: string;
    EndCondition: string;
    LicenseGUID: string;
    Name: string;
    OS: string;
    RunEndTime: string;
    RunLogGUID: string;
    RunName: string;
    RunStartTime: string;
    RunUser: string;
    SystemGUID: string;
    TestGUID: string;
    TestType: string;
    userId: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    RunlogData: IRunlogData;
}

@Component({
    name: "RunHistoryTable",

    components: {
        RunLogTable,
        SystemCard,
    },
})
export default class RunHistoryTable extends Vue {
    @Prop()
    TestGUID!: string;

    tableData = [];
    loading = true;
    currentPage = 1;
    pageTotal = 0;
    pageSize = 10;

    // vue-convert: This property will initialized in data() method, with `this` reference.
    page = undefined;

    data() {
        return {
            page: CloudDataType.RunHistories | CloudDataType.TestProperties,
        };
    }

    mounted() {
        AddToRefreshList(this, this.fetchCount);
        this.fetchCount();
    }

    async fetchCount() {
        this.loading = true;

        try {
            if (this.TestGUID) {
                const res = await axios.get(`/api/v1/runhistories/count?SharedTo&TestGUID=${this.TestGUID}`);
                this.pageTotal = Number(res.data.data);
                this.fetchData();
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    async fetchData() {
        this.loading = true;

        try {
            if (this.TestGUID) {
                const res = await axios.get(`/api/v1/runhistories?SharedTo&TestGUID=${this.TestGUID}&limit=${this.pageSize}&skip=${this.skipCount}&sort=-RunStartTime&runlog`);
                this.tableData = res.data.data;
            }
        } catch (ex) {
            console.error(ex);
        }

        this.loading = false;
    }

    handleClickViewRunLog(row: IRunHistory) {
        // View Run Log should open a new window and display the Run Logs

        // this.$router.push({
        //     name: "RunLogs",
        //     params: {
        //         RunLogGUID: row.RunLogGUID
        //     },
        //     query: {
        //         RunLogGUID: row.RunLogGUID
        //     }
        // });

        window.open(`/DataTable/RunLogs?RunLogGUID=${row.RunLogGUID}`, "_blank");
    }

    formatRunName(runName: string) {
        const index = Number(runName);
        if (isNaN(index)) {
            return runName;
        }

        return `${this.$t("Run")}${index}`;
    }

    handleClickCreateReport(data: IRunHistory) {
        // TODO: Create a Report should create a Report similar to the EDM style.
        console.log(data);

        const isElapsedVisible = IsRunLogElapsedVisible(data.RunlogData.MeasureType);

        // #region Table Header
        const tableTitleRow = [];
        tableTitleRow.push(this.$t("run_log_columns.absolute_time"));
        tableTitleRow.push(this.$t("run_log_columns.test_time"));
        tableTitleRow.push(this.$t("run_log_columns.event_type"));
        if (isElapsedVisible) {
            tableTitleRow.push(this.$t("run_log_columns.elapsed"));
        }
        tableTitleRow.push(this.$t("run_log_columns.control_target"));
        tableTitleRow.push(this.$t("run_log_columns.event_data"));
        tableTitleRow.push(this.$t("run_log_columns.event_source"));
        // #endregion

        // #region Table Data
        const tableDataRows = data.RunlogData.RunLogItems.map((p) => {
            const values: string[] = [];

            const add = (v?: string) => {
                values.push(v ?? "");
            };

            add(p.AbsoluteTime);
            add(p.ScheduleTime);
            add(p.EventType);
            if (isElapsedVisible) {
                add(p.FrequencyOrPulse);
            }
            add(p.CtrlDemandValue);
            add(p.EventData);
            add(p.EventSource);

            return values;
        });
        // #endregion

        const docxData = {
            TestName: data.Name,
            RunHistoryTable: [
                // title
                [this.$t("run_name"), this.$t("start_time"), this.$t("end_time"), this.$t("run_duration"), this.$t("run_description"), this.$t("alarm"), this.$t("end_condition")],
                // data
                [
                    this.formatRunName(data.RunName),
                    this.FormatDateTime(data.RunStartTime),
                    this.FormatDateTime(data.RunEndTime),
                    data.Duration,
                    data.Description,
                    "", // todo
                    data.EndCondition,
                ],
            ],
            RunLogTable: [
                // row 1
                tableTitleRow,

                // row 2 - n
            ].concat(tableDataRows),
        };

        CreateReport(docxData);
    }

    async handleClickRemoveThisRun(row: IRunHistory) {
        try {
            const d = await this.$confirm(this.$t("DeleteWarning").toString().replace("{0}", this.formatRunName(row.RunName)), this.$t("Warning").toString(), {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("ok").toString(),
                cancelButtonText: this.$t("Cancel").toString(),
                type: "warning",
            });

            if (d === "confirm") {
                await axios.delete(`/api/v1/runhistories/${row._id}`);
                this.tableData = this.tableData.filter((p) => p !== row);
                await this.fetchCount();
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    handleSizeChange(val: number) {
        this.pageSize = val;
        this.fetchData();
    }

    handleCurrentChange(val: number) {
        this.currentPage = val;
        this.fetchData();
    }

    get skipCount() {
        if (this.currentPage < 2) return 0;
        return this.pageSize * (this.currentPage - 1);
    }
}
