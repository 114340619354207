/********************************************************************
 * @author:      Kaven [wenkai.wu]
 * @email:       wenkai.wu@hzrad.com
 * @file:        [edm-cloud-browser] /src/helpers/base/class/EDMClient.ts
 * @create:      2022-05-12 16:13:30.557
 * @modify:      2023-04-11 16:13:54.933
 * @version:     0.1.1
 * @times:       3
 * @lines:       38
 * @description: [description]
 * @license:     [license]
 ********************************************************************/

export class EDMClient {
    public constructor(machineCode: string, appId: number) {
        this.MachineCode = machineCode;
        this.AppId = appId;
    }

    public AppId: number;
    public MachineCode: string;

    public Email?: string;

    public MachineUserName?: string;
    public MachineName?: string;
    public IP?: string;
    public SoftwareMode?: string;
    public Version?: string;

    public TestId?: string;
    public IsRunning?: boolean;

    public get ID(): string {
        return `${this.MachineCode}${this.AppId}`;
    }
}
