
import { RouteName } from "@/helpers";
import store from "@/store";
import axios from "axios";
import { HttpStatusCode } from "node-share";
import { CloudCode } from "socket/common";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "SignUp",
})
export default class SignUp extends Vue {
    form = {
        email: "",
        password: "",
        confirmPassword: "",
        company: "",
        phoneNumber: "",
    };

    rules = {
        email: [
            { required: true, message: "Please input email address", trigger: "blur" },
            { type: "email", message: "Please input correct email address", trigger: ["blur", "change"] },
        ],
        password: [
            { required: true, message: "Please input password", trigger: "blur" },
            { min: 6, message: "A password must be at least six characters long.", trigger: "blur" },
        ],
        confirmPassword: [
            { required: true, message: "Please input the password again", trigger: "blur" },
            {
                validator: (rule: never, value: string, callback: (err?: Error) => void): void => {
                    if (value === "") {
                        callback(new Error("Please input the password again"));
                    } else if (value !== this.form.password) {
                        callback(new Error("Two inputs don't match"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
        name: [
            { required: true, message: "Please input name", trigger: "change" },
        ],
        company: [
            { required: true, message: "Please input company", trigger: "change" },
        ],
    };

    remember = false;

    submitForm(formName: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const f = this.$refs[formName] as any;
        f.validate(async (valid: boolean) => {
            if (valid) {
                const loading = this.$loading({
                    lock: true,
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.3)",
                });

                try {
                    const r = await axios.post("/api/v1/users", this.form);

                    if (r.status === HttpStatusCode.OK) {
                        if (r.data.cloudCode === CloudCode.EmailNotVerified) {
                            this.$router.push({
                                name: RouteName.Verification,
                                params: {
                                    payload: r.data.data,
                                },
                            });
                        } else {
                            await store.dispatch("updateToken", r.data.data);
                        }
                    } else {
                        const { msg, cloudCode } = r.data;
                        if (cloudCode === CloudCode.AccountWithThatEmailAddressIsDeleted) {
                            this.$router.push("/account/forgot");
                        }

                        if (msg) {
                            this.$message({
                                type: "error",
                                message: msg,
                                showClose: true,
                                duration: 15000,
                            });
                        } else {
                            console.warn(r);
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                } finally {
                    loading.close();
                }
            } else {
                return false;
            }
        });
    }

    signIn() {
        this.$router.push("/account/login");
    }
}
