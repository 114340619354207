<template>
    <div v-if="clientInfo">
        <RCMLiveStatus
            v-bind:clientInfo="clientInfo"
            v-if="clientInfo.AppId >= 500 && clientInfo.AppId < 600"
        />
        <VDSLiveStatus
            v-bind:clientInfo="clientInfo"
            v-if="clientInfo.AppId === 5"
        />
        <ClientLiveStatus v-bind:clientInfo="clientInfo" v-else />
    </div>
    <div v-else>
        <h2 style="text-align: center; padding: 2em">Client is not online</h2>
    </div>
</template>

<script>
import ClientLiveStatus from "@/components/RTIM/ClientLiveStatus.vue";
import RCMLiveStatus from "@/components/RTIM/RCMLiveStatus.vue";
import VDSLiveStatus from "@/components/RTIM/VDSLiveStatus.vue";
import store from "@/store";

export default {
    name: "RTIM",
    components: {
        ClientLiveStatus,
        RCMLiveStatus,
        VDSLiveStatus,
    },
    data() {
        return {};
    },
    computed: {
        id() {
            return this.$route.query.id;
        },
        clientInfo() {
            return store.getters.Clients.find(p => p.ID === this.id);
        },
    },
};
</script>
